import * as Yup from "yup"
import {yupRequiredEmail, yupRequiredPositiveNumber} from "../../yup/Validators"

export const AdminConfigurationSchema = Yup.object().shape({
  dashboardBookingPendingWindow: yupRequiredPositiveNumber,
  dashboardBookingTodoWindow: yupRequiredPositiveNumber,
  dashboardStaffingWindow: yupRequiredPositiveNumber,
  bookingPreBookingDelay: yupRequiredPositiveNumber,
  bookingServiceDelay: yupRequiredPositiveNumber,
  departureTimeMinuteDelta: yupRequiredPositiveNumber,
})

export const AdminConfigurationMailSchema = Yup.object().shape({
  mailContact: yupRequiredEmail(3,50),
})
