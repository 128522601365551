import {API_DAY_BY_DAY_CLIENTS_PATH} from "../constants/routes/RoutePaths"
import {httpService} from "./HttpService"
import {
  DayByDayClient,
  DayByDayClientRequest,
  DayByDayClientFields
} from "../interfaces/DayByDayClientInterfaces";
import {CustomerCatalog} from "../interfaces/TourInterfaces";
import {Contact} from "../interfaces/ContactInterfaces";

function createDayByDaysForClient (dayByDayRequest?: DayByDayClientRequest): Promise<void> {
    return httpService.post(API_DAY_BY_DAY_CLIENTS_PATH, dayByDayRequest)
}

function updateDayByDayClient (id: string, dayByDayClientRequest: DayByDayClientRequest): Promise<DayByDayClient> {
  return httpService.put<DayByDayClient>(`${API_DAY_BY_DAY_CLIENTS_PATH}/${id}`, dayByDayClientRequest)
}

function deleteDayByDayClient (id: string): Promise<Response> {
  return httpService.delete(`${API_DAY_BY_DAY_CLIENTS_PATH}/${id}`)
}

const buildDayByDayClientFields = (customerCatalog: CustomerCatalog): DayByDayClientFields => {
  if (!customerCatalog) return undefined;

  return {
    customerTourName: customerCatalog?.customerTourName,
    paxClientNumber: customerCatalog?.paxClientNumber,
    managerContacts: customerCatalog?.managerContacts.map(contact => ({
      value: contact.id,
      label: `${contact.identity.firstName} ${contact.identity.lastName}`
    })),
  }
}

const buildDayByDayClientRequest = (values: DayByDayClientFields, customerContacts: Contact[]): DayByDayClientRequest => {
  return {
    paxClientNumber: values.paxClientNumber,
    customerTourName: values.customerTourName,
    managerContacts: values.managerContacts.map(contact => (customerContacts.find(c => c.id === contact.value))),
  }
}

export const dayByDayClientService = Object.freeze({
  createDayByDaysForClient,
  updateDayByDayClient,
  deleteDayByDayClient,
  buildDayByDayClientFields,
  buildDayByDayClientRequest,
})
