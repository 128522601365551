import {Data} from "./TableInterfaces";
import {ProductType} from "./ProductTypeInterfaces";

export interface PlanItem extends Data {
  geographicalZone: string;
  productType: ProductType;
  purchaseAccount: string;
  saleAccount: string;
}

export const PLAN_ITEM_INITIAL_VALUES: PlanItemFormData = {
  geographicalZone: "",
  productTypeId: "",
  purchaseAccount: "",
  saleAccount: ""
}

export interface PlanItemFormData extends Data {
  geographicalZone: string;
  productTypeId: string;
  purchaseAccount: string;
  saleAccount: string;
}
