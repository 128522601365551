import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {RouteProps} from "react-router-dom";
import Layout from "../components/layout/Layout";
import PageTitle from "../components/molecules/header/PageTitle";
import {
  OrderMassRequest,
  OrderSearchObject,
  OrderState,
} from "../interfaces/OrderInterfaces";
import useSearchFilter from "../hooks/useSearchFilter";
import {orderService} from "../services/OrderService";
import {Col, Row} from "reactstrap";
import Button from "../components/atoms/Button";
import DateFilter from "../components/molecules/filters/DateFilter";
import {ColorType} from "../types/bootstrap/BootstrapType";
import FilterGroup from "../components/molecules/filters/FilterGroup";
import useLoading from "../hooks/useLoading";
import AutocompleteFilterPageable from "../components/molecules/filters/AutocompleteFilterPageable";
import {customerService} from "../services/CustomerService";
import {toastUtils} from "../utils/toastUtils";
import OrderFormListItem from "../components/molecules/OrderFormListItem";


export type OrderFormTourLeader = {
  tourLeaders?: {
    id: string,
    fullName: string
  }[],
  orderId: string,
  paxConfirmed?: boolean,
  defaultPax: number,
  customerReference?: string,
  orderStatus?: string,
  customerId?: string,
  touched: boolean
};
const OrderFormListView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()

  const initialSearch: OrderSearchObject = {
    customerOrCode: "",
    customerId: "",
    startDate: "",
    endDate: "",
    status: [OrderState.DRAFT, OrderState.PENDING, OrderState.CANCELLED],
  }

  const {searchObject, updateSearchField, onReset} = useSearchFilter<OrderSearchObject>(initialSearch)
  const [orderFormRecord, setOrderFormRecord] = React.useState<Record<string, OrderFormTourLeader>>({});
  const {loading, stopLoading, startLoading} = useLoading();

  const fetchOrders = (searchObject: OrderSearchObject) => {
    startLoading();
    orderService.getOrdersPage({pageSize: 50, page: 0}, {
      ...searchObject,
      hasNullPaxConfirmOrTourLeader: true
    }).then((response) => {
      const newOrderFormRecord: Record<string, OrderFormTourLeader> = {}
      response.content.forEach(order => {
        newOrderFormRecord[order.orderNumber] = {
          tourLeaders: order.tourLeaders.map(tl => ({
            id: tl.id,
            fullName: `${tl.identity?.firstName} ${tl.identity?.lastName}`
          })),
          defaultPax: order.defaultPax,
          paxConfirmed: order.paxConfirmed,
          orderId: order.id,
          touched: false,
          customerReference: order.customerReference,
          orderStatus: order.orderStatus,
          customerId: order.customer.id
        }
      })

      setOrderFormRecord(newOrderFormRecord)

    }).catch(() => console.error(intl.formatMessage({id: "server_error"})))
      .finally(() => stopLoading())
  }

  const onUpdateMass = () => {
    const orderFormRequest: OrderMassRequest[] = Object.entries(orderFormRecord)
      .filter(([, orderForm]) => orderForm.touched)
      .map(([, orderForm]) => (
        {
          tourLeaderIds: orderForm.tourLeaders.map(tl => tl.id),
          orderId: orderForm.orderId,
          defaultPax: orderForm.defaultPax,
          paxConfirmed: orderForm.paxConfirmed,
        }));

    orderService.updateMassOrder(Object.values(orderFormRequest))
      .then(() => {
        fetchOrders(searchObject)
        toastUtils.successToast(intl.formatMessage({id: "order_mass_update_success"}));
      })
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "server_error"})))
  }

  const onSearch = (searchObject: OrderSearchObject) => {
    fetchOrders(searchObject)
  }

  const onChangeItem = (orderNumber: string, item: OrderFormTourLeader) => {
    setOrderFormRecord({
      ...orderFormRecord,
      [orderNumber]: item
    })
  };

  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column epow-order-form">

        <Row className="mb-5">
          <Col xs={7}>
            <PageTitle pageTitle={intl.formatMessage({id: "orders_form_title"})}></PageTitle>
          </Col>
        </Row>

        <div>
          <FilterGroup className="mb-2">
            <AutocompleteFilterPageable
              className="me-2"
              onChange={(value) => updateSearchField("customerId", value)}
              title={intl.formatMessage({id: "order_filter_customer"})}
              value={searchObject?.customerId}
              fetchData={customerService.getCustomersPage}
              filterFieldName="name"
            />
            <DateFilter
              value={searchObject.startDate}
              className="me-2"
              onChangeDate={(value) => updateSearchField("startDate", value)}
              title={intl.formatMessage({id: "order_filter_period_start"})}
            />
            <DateFilter
              value={searchObject.endDate}
              className="me-2"
              onChangeDate={(value) => updateSearchField("endDate", value)}
              title={intl.formatMessage({id: "order_filter_period_end"})}
            />
            <div>
              <Button loading={loading} className="me-2" onClick={() => onSearch(searchObject)}>
                <FormattedMessage id="order_form_search"/>
              </Button>
              <Button onClick={() => onReset()}
                      color={ColorType.SECONDARY}>
                <FormattedMessage id="header_menu_clear_search"/>
              </Button>
            </div>
          </FilterGroup>
        </div>
        <div>
          {Object.entries(orderFormRecord).map(([orderNumber, order]) =>
            <OrderFormListItem
              key={orderNumber}
              orderFormTourLeader={order}
              onChange={(value) => onChangeItem(orderNumber, value)}
              orderNumber={orderNumber}/>
          )}
        </div>

        {orderFormRecord && Object.values(orderFormRecord).find(record => !!record.touched) &&
          <div>
            <Button onClick={onUpdateMass} className="d-flex justify-content-end">
              <FormattedMessage id="order_form_confirm"/>
            </Button>
          </div>}
      </div>
    </Layout>
  )
}

export default OrderFormListView;
