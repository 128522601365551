import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {AdminConfigurationMailDto} from "../../../../interfaces/AdminConfigurationInterfaces";
import {adminConfigurationService} from "../../../../services/AdminConfigurationService";
import {toastUtils} from "../../../../utils/toastUtils";
import CardField from "../CardField";
import Button from "../../../atoms/Button";
import AdminConfigurationMailForm from "../../form/admin/AdminConfigurationMailForm";

interface AdminConfigMailProps {
  className?: string,
}

const AdminConfigMail: FunctionComponent<AdminConfigMailProps> = ({
    className,
  }) => {
  const intl = useIntl()
  const [editing, setEditing] = useState<boolean>(false)
  const [adminConfigurationMail, setAdminConfigurationMail] = useState<AdminConfigurationMailDto>({})

  const getAdminConfiguration = () => {
    adminConfigurationService.getAdminConfigurationMail()
      .then(setAdminConfigurationMail)
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "error_toast_admin_config"})))
  }

  useEffect(() => {
    getAdminConfiguration()
  }, [])

  const onUpdate = () => {
    setEditing(false)
    getAdminConfiguration()
  }

  return (
    <div className={className}>
      {editing ? (
        <AdminConfigurationMailForm onUpdate={onUpdate} onCancel={() => setEditing(false)} adminConfiguration={adminConfigurationMail} />
      ) : (
        <>
          <CardField
            label={intl.formatMessage({id: "admin_config_mail_contact"})}
            value={adminConfigurationMail.mailContact}
          />

          <CardField
            label={intl.formatMessage({id: "admin_config_mail_signature"})}
            popover="admin_config_mail_signature_tooltip"
            value={adminConfigurationMail.mailSignature}
          >
            <div dangerouslySetInnerHTML={{__html: adminConfigurationMail.mailSignature}}></div>
          </CardField>
          <Button onClick={() => setEditing(true)} >
            <FormattedMessage id="edit_button" />
          </Button>
        </>
      )}
    </div>
  )
}

export default AdminConfigMail;
