import { FileType } from "constants/FileConstants";
import {BookingDocumentFormCategory, BookingHeader} from "../../interfaces/BookingInterfaces";

export const getBookingHeaders = (payAction: () => void): BookingHeader[] => {
  return [
    {
      id: "requiredActionsBookings",
      title: "booking_actions",
      barClassName: "warning",
      statusBadge: true,
      hideIfEmpty: true
    },
    {
      id: "openBookings",
      title: "booking_open",
      barClassName: "light-blue",
      warningLabel: "booking_open_warning"
    },
    {
      id: "preBookedBookings",
      title: "booking_waiting",
      barClassName: "secondary",
      warningLabel: "booking_pre_booked_warning",
      completionIndicators: true
    },
    {
      id: "paymentBookings",
      title: "booking_to_pay",
      barClassName: "dark-red",
      warningLabel: "booking_to_pay_warning",
      completionIndicators: true,
      iconName: "CreditCard",
      iconClickAction: payAction
    },
    {
      id: "confirmedBookings",
      title: "booking_confirmed",
      barClassName: "primary",
      warningLabel: "booking_confirmed_warning",
      completionIndicators: true
    }
  ]
}

export enum STATUS_BADGE {
  TO_UPDATE = "to-update",
  TO_CANCEL = "to-cancel"
}

export const GET_BOOKING_DOCUMENT_FORM_CATEGORIES = (isPaid: boolean, payButtonAction: () => void): BookingDocumentFormCategory[] => {
  return [
    {
      title: "booking_form_supporting",
      addButtonLabel: "booking_form_button_supporting",
      addButtonIcon: "PaperClip",
      idCheckbox: "isConfirmed",
      idFile: "supportingFile",
      isSupporting: true,
      idDocument: "supportingDocuments",
      fileLabel: "booking_add_confirmation_file",
      disableWhenUnpaid: true,
      fileType: FileType.CONFIRMATION,
      displayFileType: true
    },
    {
      title: "booking_form_bill",
      addButtonLabel: "booking_form_button_supplier_bill",
      addButtonIcon: "Invoice",
      idCheckbox: "isBilled",
      hasDate: true,
      idDate: "invoiceDate",
      dateLabel: "booking_form_supplier_bill_field",
      idFile: "billFile",
      hasFileDetails: true,
      idDocument: "billDocuments",
      fileLabel: "booking_add_bill_file",
      editDateLabel: "editInvoiceDate",
      fileType: FileType.BILL,
    },
    {
      title: "booking_form_payment",
      addButtonLabel: "booking_form_button_supporting",
      addButtonIcon: "Database",
      idCheckbox: "isPaid",
      hasDate: true,
      idDate: "paymentDate",
      dateLabel: "booking_form_supplier_payment_field",
      idFile: "paymentFile",
      idDocument: "paymentDocuments",
      fileLabel: "booking_add_payment_file",
      fileType: FileType.PAYMENT,
      editDateLabel: "editPaymentDate",
      shouldDisplayExtraButton: (isPaid: boolean) => { return isPaid },
      extraButtonLabel: "booking_form_button_payment",
      extraButtonIcon: "CreditCard",
      extraButtonAction: payButtonAction
    },
  ]
}

