import {Service} from "../../../interfaces/ServiceInterfaces";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {serviceService} from "../../../services/ServiceService";
import {SERVICES_PATH} from "../../../constants/routes/RoutePaths";
import {toastUtils} from "../../../utils/toastUtils";
import {Formik} from "formik";
import {SERVICE_INITIAL_VALUES} from "../../../constants/ServiceConstants";
import {CreateServiceSchema} from "../../../constants/validation/ServiceValidationSchemas";
import FormInput from "../input/FormInput";
import {useIntl} from "react-intl";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {supplierService} from "../../../services/SupplierService";
import {WorkflowStatesEnum} from "constants/workflow/WorkflowStates";
import FormRadio from "../input/FormRadio";
import {UNIT_TYPE_OPTIONS} from "../../../constants/OptionConstants";
import {numberUtils} from "../../../utils/numberUtils";
import EpowForm from "./EpowForm";
import FormAutocompleteMultiselect from "../input/FormAutocompleteMultiSelect";
import FormSelect from "../input/FormSelect";
import {ApiError} from "../../../interfaces/ErrorInterfaces";
import {LoadingComponent} from "../../../constants/menu/Layout";
import {vatRateService} from "../../../services/VATRateService";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import {productTypeService} from "../../../services/ProductTypeService";

interface CreateServiceFormProps extends LoadingComponent  {
  id?: string,
  className?: string,
  service?: Service,
  setService?: (service: Service) => void,
  setEditInfos?: (editing: boolean) => void
}

const CreateServiceForm: FunctionComponent<CreateServiceFormProps> = ({
  id,
  className = "",
  service,
  setService,
  setEditInfos,
  stopLoading,
  startLoading,
  closeForm = () => null,
}) => {
  const intl = useIntl()
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const initialService = {
    ...service,
    suppliers: service?.suppliersForService.map(supplier => ({value: supplier.id, label: supplier.name} as Option<string>)),
    defaultSupplierId: service?.defaultSupplier?.id,
    vatRateId: service?.vatRate?.id,
    productTypeId: service?.productType?.id
  }
  const [supplierOptions, setSupplierOptions] = useState<Option<string>[]>([])
  const [vatRateOptions, setVATRateOptions] = useState<Option<string>[]>([])

  const handleSubmitService = (serviceFields: Service) => {
    serviceFields = {
      ...serviceFields,
      suppliersId: serviceFields.suppliers?.map(supplier => supplier.value)
    }
    startLoading?.()
    if (!service?.id) {
      serviceService.createService(serviceFields)
        .then((service) => {
          // Rustine des dieux. Lorsqu'on ajoute un service depuis la page d'un service la page n'est pas totalement rechargée, ce qui fait que la popup est encore ouverte,
          // car react-router-dom rerender uniquement le contenu. Soit on fait un hook de context pour faire en sorte de pouvoir fermer correctement la popup
          // Soit on fait ça.
          if (pathname.includes(`${SERVICES_PATH}/`)) {
            window.location.href = `${SERVICES_PATH}/${service?.id}`
          } else {
            navigate(`${SERVICES_PATH}/${service?.id}`)
          }
        })
        .catch((error: ApiError) => {
          toastUtils.errorToast(error.message)
        }).finally(() => {
          stopLoading?.()
          closeForm()
        })
    } else {
      serviceService.updateService(service?.id, serviceFields)
        .then((service) => {
          setService(service)
          setEditInfos(false)
        })
        .catch((error: ApiError) => {
          toastUtils.errorToast(error.message)
        }).finally(() => {
          stopLoading?.()
          closeForm()
        })
    }
  }

  const getSupplierOptions = () => {
    if (service) {
      supplierService.getSuppliers()
        .then((suppliers) => {
          const formatSupplier = suppliers.map(supplier => {
            return {value: supplier.id, status: supplier.currentState, label: supplier.name}
          })
          setSupplierOptions(formatSupplier)
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_suppliers"}))
        })
    }
  }

  const getVATRateOptions = () => {
    vatRateService.getVATRates()
      .then((vatRates) => setVATRateOptions(vatRates.content.map((vatRate) => {
        return {value: vatRate.id, rate: vatRate.rate, label: `${vatRate.name} : ${numberUtils.numberToPercent(vatRate.rate)}`}
      })))
      .catch(() =>  toastUtils.errorToast(intl.formatMessage({id: "vat_rate_toast_read_error"})))
  }

  const getReference = async (serviceName: string) => {
    if (serviceName?.length >= 3) {
      const response = await serviceService.getReference(serviceName)
      const reference = response.reference.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      if (initialService) {
        initialService.reference = response.reference
      }
      return reference;
    }
    return "";
  }

  const handleOnSuppliersChange = (selectedOptions: Option<string>[], setFieldValue: (field: string, values: Option<string>[], shouldValidate?: boolean) => void) => {
    setFieldValue("suppliers", selectedOptions)
    setFieldValue("defaultSupplierId", null)
    setSupplierOptions(selectedOptions)
  }

  useEffect(() => {
    getSupplierOptions()
    getVATRateOptions()
    getReference(service?.name).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_one_reference"}))
    })
  }, [])

  return (
    <Formik initialValues={initialService || SERVICE_INITIAL_VALUES} validationSchema={CreateServiceSchema(!!service)} onSubmit={(values) => handleSubmitService(values)}>
      {({ errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className} >
          <FormInput
            id="name"
            label="service_name"
            value={values?.name}
            onChange={(e) => {
              setFieldValue("name", e.target.value)
              getReference(e.target.value).then(reference => {
                setFieldValue("reference", reference)
              }).catch(() => {
                toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_one_reference"}))
              })
            }}
            required
            error={errors.name}
            touched={touched.name}
          />
          <FormInput
            id="reference"
            label="service_reference"
            value={values?.reference}
            readOnly
            error={errors.reference}
            touched={touched.reference}
          />
          <FormInput
            id="description"
            label="service_description"
            type="textarea"
            value={values?.description}
            onChange={(e) => setFieldValue("description", e.target.value)}
            required
            error={errors.description}
            touched={touched.description}
            rows="5"
          />
          {service && (
            <>
              <FormInput
                id="numberPaxMax"
                label="service_number_pax"
                type="number"
                value={values?.numberPaxMax}
                onChange={(e) => setFieldValue("numberPaxMax", e.target.value)}
                required
                error={errors.numberPaxMax}
                touched={touched.numberPaxMax}
              />
              <FormRadio
                id="defaultUnitType"
                label="service_default_unit"
                value={UNIT_TYPE_OPTIONS.find((option) => option?.value === values?.defaultUnitType?.toString())}
                options={UNIT_TYPE_OPTIONS}
                onChange={(e) => setFieldValue("defaultUnitType", e.value)}
                required
                error={errors.defaultUnitType}
                touched={touched.defaultUnitType}
              />
              <FormInput
                id="defaultPrice"
                label="service_default_price"
                type="number"
                step="0.01"
                value={values?.defaultPrice}
                onChange={(e) => setFieldValue("defaultPrice", e.target.value)}
                onBlur={(e) => setFieldValue("defaultPrice", numberUtils.numberFormatField(e.target.value))}
                required
                error={errors.defaultPrice}
                touched={touched.defaultPrice}
              />
              <FormRadio
                id="defaultSellUnitType"
                label="service_default_sell_unit"
                value={UNIT_TYPE_OPTIONS.find((option) => option?.value === values?.defaultSellUnitType?.toString())}
                options={UNIT_TYPE_OPTIONS}
                onChange={(e) => setFieldValue("defaultSellUnitType", e.value)}
                required
                error={errors.defaultSellUnitType}
                touched={touched.defaultSellUnitType}
              />
              <FormInput
                id="defaultSellPrice"
                label="service_default_sell_price"
                type="number"
                step="0.01"
                value={values?.defaultSellPrice}
                onChange={(e) => setFieldValue("defaultSellPrice", e.target.value)}
                onBlur={(e) => setFieldValue("defaultSellPrice", numberUtils.numberFormatField(e.target.value))}
                required
                error={errors.defaultSellPrice}
                touched={touched.defaultSellPrice}
              />
              <FormAutocompleteMultiselect
                id="suppliersId"
                label={intl.formatMessage({id: "suppliers_for_service"})}
                values={values.suppliers}
                onChange={(selectedOptions) => handleOnSuppliersChange(selectedOptions, setFieldValue)}
                required
                error={errors.suppliers}
                touched={touched.suppliers}
                fetchData={(inputValue) => supplierService.getSuppliersPaginated({pageSize: 50, page: 0}, {name: inputValue, state: WorkflowStatesEnum.ACTIVE})}
                filterFieldName="name"
              />
              <FormSelect
                id="defaultSupplierId"
                label="service_default_supplier"
                value={supplierOptions.find((option) => option?.value === values?.defaultSupplierId)}
                onChange={(option?: Option<string>) => setFieldValue("defaultSupplierId", option?.value)}
                options={supplierOptions.filter(option => values?.suppliers?.includes(option))}
                required
                isSearchable
                isClearable
                error={errors.defaultSupplierId}
                touched={touched.defaultSupplierId}
              />
              <FormSelect
                id="vatRateId"
                label="service_vat_rate"
                value={vatRateOptions.find((option) => option?.value === values?.vatRateId)}
                onChange={(option?: Option<string>) => setFieldValue("vatRateId", option?.value)}
                options={vatRateOptions}
                required
                isSearchable
                isClearable
                error={errors?.vatRateId}
                touched={touched?.vatRateId}
              />
              <FormAutocompleteSelectPageable
                id="productTypeId"
                label={intl.formatMessage({id: "activity_product_type_field"})}
                value={values.productTypeId}
                onChange={(value) => setFieldValue("productTypeId", value)}
                required
                error={errors.productTypeId}
                touched={touched.productTypeId}
                fetchData={productTypeService.getProductTypesPage}
                filterFieldName="name"
              />
            </>
          )}
        </EpowForm>
      )}
    </Formik>
  )

}
export default CreateServiceForm
