import React, {FunctionComponent} from "react";
import {OrderItemStaffingPageItemDto} from "../../../../../interfaces/OrderItemInterfaces";
import Icon from "../../../../icon/Icon";
import {ColorType} from "../../../../../types/bootstrap/BootstrapType";
import {dateUtils} from "../../../../../utils/dateUtils";
import {useIntl} from "react-intl";
import Button from "../../../../atoms/Button";
import {Dictionary, groupBy} from "lodash";
import {StaffedResource} from "../../../../../interfaces/ActivityStaffingInterface";
import {ActivityStaffingStatus} from "../../../../../constants/ActivityStaffingConstants";
import {STAFFING_PATH} from "../../../../../constants/routes/RoutePaths";
import {ResourceStaffingRequest} from "../../../../../interfaces/ResourceStaffingInterfaces";
import {Resource} from "../../../../../interfaces/ResourceInterfaces";
import {orderItemService} from "../../../../../services/OrderItemService";
import {toastUtils} from "../../../../../utils/toastUtils";
import {UncontrolledTooltip} from "reactstrap";

interface CalendarOrderItemCardProps {
  className?: string,
  orderItem: OrderItemStaffingPageItemDto,
  resource: Resource,
  isResourceAvailable?: boolean,
  refresh: () => void,
}

const CalendarOrderItemCard: FunctionComponent<CalendarOrderItemCardProps> = ({
  className = "",
  orderItem,
  resource,
  isResourceAvailable = false,
  refresh,
}) => {
  const intl = useIntl()
  const maxResources = orderItem.orderItemResources?.reduce((acc, orderItemResource) => {
    return acc + orderItemResource.quantity
  }, 0)

  const staffingByAvailability: Dictionary<StaffedResource[]> = groupBy(orderItem.resourceStaffing ?? [], "status")

  const confirmStaffing = () => {
    const request: ResourceStaffingRequest = {
      resourceId: resource.id,
      status: ActivityStaffingStatus.CONFIRMED
    }

    const relevantStaffing = orderItem.resourceStaffing.find(s => s.resourceId === resource.id)

    orderItemService.updateStaffingForOrderItem(orderItem.id, relevantStaffing.staffingId, request)
      .then(() => {
        refresh()
        toastUtils.successToast(intl.formatMessage({id: "success_toast_staffing_update"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_staffing_update"}))
      })
  }

  const directStaffing = () => {
    orderItemService.sendDirectStaffingForOrderItem(orderItem.id, [resource.id])
      .then(() => {
        refresh()
        toastUtils.successToast(intl.formatMessage({id: "success_toast_staffing_update"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_staffing_update"}))
      })
  }

  const redirectToStaffing = () => {
    window.open(`${STAFFING_PATH}?staffingStatus=${orderItem.staffingStatus}&ids=${orderItem.id}`)
  }

  const getResourcesLeftToStaff = () => (
    maxResources - (staffingByAvailability[ActivityStaffingStatus.CONFIRMED]?.length ?? 0)
  )

  const getHeaderText = () => {
    const place = orderItem.rendezVousPlace
    const date = dateUtils.formatDateDayJs(orderItem.rendezVousDate)
    const hour = dateUtils.formatLocalTime(orderItem.rendezVousHour)
    const duration = dateUtils.formatDuration(orderItem.duration)

    return `${place} | ${date} - ${hour} | ${duration}`
  }

  return (
    <div className={`border br-sm p-2 mb-2 ${className}`}>
      <div>
        {getHeaderText()}
      </div>

      <div className="mb-2">
        <span className="color-gray me-2">{`${orderItem.order.customerName} | ${orderItem.order.customerReference}`}</span>
        <span className="bold fs-14">{orderItem.name}</span>
      </div>

      <div className="d-flex gap-3 mb-2">
        <div className="d-flex flex-column">
          {staffingByAvailability[ActivityStaffingStatus.CONFIRMED]?.map(staffing => (
            <div key={staffing.staffingId}>
              <div>
                <Icon name="User" className="me-1" color={ColorType.GREEN} />
                <span className="color-green">
                  {`${staffing.firstName} ${staffing.lastName[0]}`}
                </span>
              </div>
            </div>
          ))}
        </div>

        <div id={`peopleLeftToStaff-${orderItem.id}`}>
          <Icon name="User" className="me-1" />
          <span >
            {intl.formatMessage({id: "calendar_day_panel_to_staff"}, {number: getResourcesLeftToStaff()})}
          </span>
          <UncontrolledTooltip className="epow-tooltip" placement="bottom" target={`peopleLeftToStaff-${orderItem.id}`}>
            <div>
              {staffingByAvailability[ActivityStaffingStatus.AVAILABLE]?.length > 0 ? (
                staffingByAvailability[ActivityStaffingStatus.AVAILABLE]?.map(resource => (
                  <div key={resource.resourceId}>
                    {`${resource.firstName} ${resource.lastName[0]}`}
                  </div>
                ))
              ) : (
                <span>
                  {intl.formatMessage({id: "calendar_day_panel_to_staff_tooltip_no_available"})}
                </span>
              )}
            </div>
          </UncontrolledTooltip>
        </div>
      </div>

      <div className="d-flex gap-2 justify-content-end">
        {isResourceAvailable ? (
          <Button className="py-0" onClick={() => confirmStaffing()} >
            <span>{intl.formatMessage({id: "calendar_day_panel_staffing_confirm"})}</span>
          </Button>
        ) : (
          <>
            <Button className="py-0" color={ColorType.SECONDARY} onClick={() => redirectToStaffing()} >
              <span>{intl.formatMessage({id: "calendar_day_panel_staffing_ask_availability"})}</span>
            </Button>
            <Button className="py-0" color={ColorType.SECONDARY} onClick={() => directStaffing()} >
              <span>{intl.formatMessage({id: "calendar_day_panel_staffing_staff"})}</span>
            </Button>
          </>
        )}

      </div>

    </div>
  )
}

export default CalendarOrderItemCard;
