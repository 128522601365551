
function formatNumberToCurrency(value: number, minimumFractionDigits = 3) {
  if(value === undefined || value == null) {
    return "-";
  }
  return Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR", minimumFractionDigits }).format(value);
}

export const moneyUtils = Object.freeze({
  formatNumberToCurrency

})
