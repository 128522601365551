import {capitalize, Dictionary, groupBy} from "lodash";
import React, {FunctionComponent, useState} from "react";
import {Quote} from "../../../../interfaces/QuoteInterfaces";
import {dateUtils} from "../../../../utils/dateUtils";
import {quoteService} from "../../../../services/QuoteService";
import {toastUtils} from "../../../../utils/toastUtils";
import {FormattedMessage, useIntl} from "react-intl";
import {QuoteItem} from "../../../../interfaces/QuoteItemInterfaces";
import QuoteItemPreviewCard from "./QuoteItemPreviewCard";
import {DateFormats} from "../../../../constants/DateConstants";

interface QuoteTableRowDetailsProps {
  quote: Quote,
}

const QuoteTableRowDetails: FunctionComponent<QuoteTableRowDetailsProps> = ({
  quote,
}) => {

  const intl = useIntl();
  const getSortedItemsByDate = (items: QuoteItem[]): [string, QuoteItem[]][] => {
    const itemsByDate: Dictionary<QuoteItem[]> = groupBy(items, "rendezVousDate")
    const sortedItemsByDate = Object.entries(itemsByDate) ?? [];
    sortedItemsByDate.sort((a, b) => dateUtils.parseDate(a[0]) < dateUtils.parseDate(b[0]) ? -1 : 1)
    return sortedItemsByDate;
  }

  const [quoteItems, setQuoteItems] = useState<[string, QuoteItem[]][]>(getSortedItemsByDate(quote?.quoteItems ?? []))

  const buildDate = (date: string) => {
    const localized = dateUtils.formatDateDayJs(date, DateFormats.longDate)
    return capitalize(localized)
  }

  const refreshQuoteItems = () => {
    quoteService.getQuoteById(quote.id)
      .then((quote) => {
        setQuoteItems(getSortedItemsByDate(quote?.quoteItems))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_quote_get"}))
      })
  }

  return (
    <>
      {quoteItems?.length === 0 && <div><FormattedMessage id="no_items"/></div>}
      {quoteItems?.map(([date, quoteItemsByDate]) => (
        <div key={date} className="mt-2">
          <div className="epow-custom-table-row-details-date">
            <div className="d-flex align-items-center w-100">
              <hr className="flex-grow-1" />
              <span className="bold">{buildDate(date)}</span>
              <hr className="flex-grow-1" />
            </div>
          </div>
          {quoteItemsByDate?.map(quoteItem => (
            <QuoteItemPreviewCard
              key={quoteItem.id}
              quoteItem={quoteItem}
              onQuoteItemChange={refreshQuoteItems}
            />
          ))}
        </div>
      ))}
    </>
  )

}

export default QuoteTableRowDetails;
