import React, {FunctionComponent, useEffect, useState} from "react";
import {LoadingComponent} from "../../../constants/menu/Layout";
import {OrderItemResponse} from "../../../interfaces/OrderItemInterfaces";
import RemindFormMessage from "./staffing/messages/RemindFormMessage";
import {orderService} from "../../../services/OrderService";
import {toastUtils} from "../../../utils/toastUtils";
import {FormattedMessage, useIntl} from "react-intl";
import {TourLeader} from "../../../interfaces/TourLeaderInterfaces";
import {RemindResourceMail} from "../../../interfaces/ResourceInterfaces";
import {tourLeaderService} from "../../../services/TourLeaderService";
import {resourceService} from "../../../services/ResourceService";
import {ActivityStaffingStatus} from "../../../constants/ActivityStaffingConstants";
import Title from "../../atoms/Title";
import {ColorType, SizeType, TitleSizeType} from "../../../types/bootstrap/BootstrapType";
import Button from "../../atoms/Button";
import ModalStaffingRemindVoucher from "../modal/orderitem/ModalStaffingRemindVoucher";

interface RemindResourceFormProps extends LoadingComponent {
  id?: string,
  className?: string
  orderItem: OrderItemResponse,
}

const RemindResourceForm: FunctionComponent<RemindResourceFormProps> = ({
  className = "",
  orderItem,
}) => {
  const [tourLeaders, setTourLeaders] = useState<TourLeader[]>([])
  const [openModal, setOpenModal] = useState(false)
  const [sendToTourLeader, setSendToTourLeader] = useState(false)
  const [sendToResource, setSendToResource] = useState(false)
  const confirmedResources = orderItem.resourceStaffing
    .filter(resource => resource.status === ActivityStaffingStatus.CONFIRMED)
  const intl = useIntl();

  useEffect(() => {
    orderService.getAssignedTourLeader(orderItem.order.id)
      .then((response) => setTourLeaders(response))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_user"})))
  }, []);

  const sendAllResourceMail = (resourceIds: string[], content: RemindResourceMail, orderItemId: string) => {
    resourceService.remindResourcesMail(resourceIds, content.template, orderItemId)
      .then(() => toastUtils.successToast(intl.formatMessage({id: "resource_remind_all_toast_success"})))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "resource_remind_all_toast_error"})));
  }

  const sendTourLeadersMail = (tourLeaderIds: string[], content: RemindResourceMail, orderItemId: string) => {
    tourLeaderService.remindTourLeaderMail(tourLeaderIds[0], content.template, orderItemId)
      .then(() => toastUtils.successToast(intl.formatMessage({id: "tour_leader_remind_toast_success"})))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "tour_leader_remind_toast_error"})));
  }

  const handleSendMail = (content: RemindResourceMail) => {
    if (sendToResource) {
      sendAllResourceMail(confirmedResources.map(resource => resource.resourceId), content, orderItem.id)
      setSendToResource(false)
    }
    if (sendToTourLeader) {
      tourLeaders.map(tl => sendTourLeadersMail([tl.id], content, orderItem.id))
      setSendToTourLeader(false)
    }
    setOpenModal(false)
  }

  return (
    <div className={`${className} w-100`}>
      {confirmedResources.length > 0 && (
        <div className="my-2">
          <Title size={TitleSizeType.H6} title={intl.formatMessage({id: "resource_remind_resource_voucher_upload"})}/>
          {confirmedResources.map(resource => (
            <RemindFormMessage
              key={resource.resourceId}
              userId={resource.resourceId}
              userFirstName={resource.firstName}
              userLastName={resource.lastName}
              orderItem={orderItem}
              handleSendMail={sendAllResourceMail}
            />
          ))}
        </div>
      )}
      {confirmedResources.length >= 2 && (
        <div className="d-flex flex-row justify-content-end">
          <Button
            size={SizeType.XS}
            onClick={() => {
              setOpenModal(true)
              setSendToResource(true)
            }}
            color={ColorType.SECONDARY}
            className="extended"
          >
            <FormattedMessage id="resource_remind_all_resource_voucher_upload_btn"/>
          </Button>
        </div>
      )}
      {tourLeaders.length > 0 && (
        <>
          <Title size={TitleSizeType.H6}
                 title={intl.formatMessage({id: "resource_remind_all_tour_leaders_voucher_upload_btn"})}/>
          {tourLeaders.map(tourLeader =>
          <div key={tourLeader.id}>
            <RemindFormMessage
              key={tourLeader.id}
              userId={tourLeader.id}
              userFirstName={tourLeader?.identity.firstName}
              userLastName={tourLeader?.identity.lastName}
              orderItem={orderItem}
              handleSendMail={sendTourLeadersMail}
            />
          </div>)}
        </>
      )}
      {tourLeaders.length >= 2 && (
        <div className="d-flex flex-row justify-content-end">
          <Button
            size={SizeType.XS}
            onClick={() => {
              setOpenModal(true)
              setSendToTourLeader(true)
            }}            color={ColorType.SECONDARY}
            className="extended mb-5"
          >
            <FormattedMessage id="resource_remind_all_tour_leaders_voucher_upload_btn"/>
          </Button>
        </div>
      )}
      {confirmedResources.length !== 0 && tourLeaders.length !== 0 && (
        <div className="d-flex flex-row justify-content-end">
          <Button
            size={SizeType.XS}
            onClick={() => {
              setOpenModal(true)
              setSendToTourLeader(true)
              setSendToResource(true)
            }}
            color={ColorType.TERTIARY}
            className="extended"
          >
            <FormattedMessage id="resource_remind_everyone_voucher_upload_btn"/>
          </Button>
        </div>
      )}
      <ModalStaffingRemindVoucher
        onSubmit={handleSendMail}
        orderItem={orderItem}
        open={openModal}
        onOpen={(open) => setOpenModal(open)}
        onClose={() => setOpenModal(false)}/>
    </div>
  )
}

export default RemindResourceForm
