import {httpService} from "./HttpService";
import {API_TOUR_LEADERS_PATH} from "../constants/routes/RoutePaths";
import {TourLeader, TourLeaderFormFields, TourLeaderRequest} from "../interfaces/TourLeaderInterfaces";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {SearchFilter} from "../interfaces/api/FiltersInterface";
import {queryUtils} from "../utils/queryUtils";
import {OrderItem} from "../interfaces/OrderItemInterfaces";

function getTourLeaderPage(params: PaginationQueryParams, filterParams: SearchFilter): Promise<PageResponse<TourLeader>> {
    const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
    return httpService.get<PageResponse<TourLeader>>(`${API_TOUR_LEADERS_PATH}/page${queryParams}`)
}

function getTourLeader(id: string): Promise<TourLeader> {
    return httpService.get<TourLeader>(`${API_TOUR_LEADERS_PATH}/${id}`)
}

function getSelfTourLeader(): Promise<TourLeader> {
    return httpService.get<TourLeader>(`${API_TOUR_LEADERS_PATH}/self`)
}
function createTourLeader (tourLeaderRequest: TourLeader): Promise<TourLeader> {
    return httpService.post<TourLeader>(API_TOUR_LEADERS_PATH, tourLeaderRequest)
}

function updateTourLeader(id: string, tourLeader: TourLeaderRequest): Promise<TourLeader> {
    return httpService.put<TourLeader>(`${API_TOUR_LEADERS_PATH}/${id}`, tourLeader)
}

function updateSelfTourLeader(tourLeader: TourLeaderRequest): Promise<TourLeader> {
    return httpService.put<TourLeader>(`${API_TOUR_LEADERS_PATH}/self`, tourLeader)
}

function deleteTourLeader(tourLeaderId: string): Promise<Response> {
    return httpService.delete(`${API_TOUR_LEADERS_PATH}/${tourLeaderId}`)
}

function buildTourLeaderFields (tourLeader: TourLeader): TourLeaderFormFields {
    if (!tourLeader) return undefined;

    const customersOptions = tourLeader?.customers?.map(customer => ({
        value: customer.id,
        label: `${customer.code} - ${customer.name}`
    }))

    return {
        id: tourLeader?.id,
        identity: tourLeader?.identity,
        customersOptions: customersOptions ?? [],
        hasAccessPlatform: tourLeader?.hasAccessPlatform ? "true" : "false"
    }
}

function invite(tourLeaderId: string): Promise<TourLeader> {
    return httpService.post<TourLeader>(`${API_TOUR_LEADERS_PATH}/${tourLeaderId}/invite`, {})
}

function getTourLeaderOrderItemPage (params?: PaginationQueryParams): Promise<PageResponse<OrderItem>> {
    const queryParams = queryUtils.buildPageAndFilterQueryParams(params, null)
    return httpService.get(`${API_TOUR_LEADERS_PATH}/self/order-items${queryParams}`)
}

function remindTourLeaderMail(tourLeaderId: string, template: string, orderItemId: string): Promise<void> {
  return httpService.post(`${API_TOUR_LEADERS_PATH}/${tourLeaderId}/reminder/${orderItemId}`, template)
}


export const tourLeaderService = {
    getSelfTourLeader,
    getTourLeaderPage,
    getTourLeader,
    createTourLeader,
    updateTourLeader,
    updateSelfTourLeader,
    deleteTourLeader,
    buildTourLeaderFields,
    getTourLeaderOrderItemPage,
    remindTourLeaderMail,
    invite
}
