import React, {ChangeEvent, DragEvent, FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Label} from "reactstrap";
import {FileType} from "../../../../../constants/FileConstants";
import {OrderItem} from "../../../../../interfaces/OrderItemInterfaces";
import {bookingService} from "../../../../../services/BookingService";
import {orderItemService} from "../../../../../services/OrderItemService";
import {ColorType, IconSizeType} from "../../../../../types/bootstrap/BootstrapType";
import {fileUtils} from "../../../../../utils/fileUtils";
import {toastUtils} from "../../../../../utils/toastUtils";
import Icon from "../../../../icon/Icon";
import GenericModalDelete from "../../../modal/GenericModalDelete";
import {DocumentDTO} from "../../../../../interfaces/FileInterfaces";
import {authUtils} from "../../../../../utils/authUtils";
import {PROFILE} from "../../../../../interfaces/UserInterfaces";
import {orderItemUtils} from "../../../../../utils/orderItemUtils";
import FileDropZone from "../../../../atoms/input/FileDropZone";

interface OrderItemPreviewCardDocumentsProps {
  downloadDocument: (fileId: string, name: string, extension: string) => void
  orderItem: OrderItem,
  onOrderItemChange: () => void,
}

const OrderItemPreviewCardDocuments: FunctionComponent<OrderItemPreviewCardDocumentsProps> = ({
  downloadDocument,
  orderItem,
  onOrderItemChange
}) => {
  const intl = useIntl()
  const [selectedDocument, setSelectedDocument] = useState<DocumentDTO>()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

  const onDeleteDocument = (document: DocumentDTO) => {
    setSelectedDocument(document);
    setOpenDeleteModal(true);
  };

  const doAfterDelete = () => {
    setOpenDeleteModal(false);
    onOrderItemChange();
  }

  const onChangeOrderItemFile = (file: FileList, fileType: FileType) => {
    orderItemService.addFiles(orderItem.id, file, {fileType})
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_add_order_item_file"}));
        onOrderItemChange();
      })
      .catch((err) => {
        toastUtils.errorToast(`${intl.formatMessage({id: "error_toast_add_order_item_file"})} ${err.message}`);
      });
  };

  return (
    <div className="d-flex flex-column">
      <span className="bold">{`${intl.formatMessage({id: "order_item_documents"})} : `}</span>

      <div className="d-flex gap-1 flex-wrap">
        {(!orderItem?.bookingDocuments || orderItem?.bookingDocuments.length === 0) && <span>{intl.formatMessage({id: "order_item_no_documents"})}</span>}
        {(orderItemUtils.orderItemNeedsTicket(orderItem)) && (
          <div>
            <Icon className="me-1" name="Warning" color={ColorType.WARNING} size={IconSizeType.XS} />
            <span className="color-warning">{intl.formatMessage({id: "order_item_needs_tickets"})}</span>
          </div>
        )}
      </div>

      {
        orderItem?.bookingDocuments?.map(bookingDocument => {
          const fullName = fileUtils.getCompleteFileName({name: bookingDocument.name, extension: bookingDocument.extension});
          return (
            <div key={bookingDocument.fileId} className="d-flex justify-content-between align-items-center mb-2">
              <div>
                <span
                  className="epow-text-link cursor-pointer me-3"
                  onClick={() => downloadDocument(bookingDocument.fileId, bookingDocument.name, bookingDocument.extension)} >
                  {fullName}
                </span>
                <Label className="color-gray mb-0">
                  <FormattedMessage id={bookingDocument.fileType} />
                </Label>
              </div>
              {authUtils.getProfile() !== PROFILE.TOUR_LEADER &&
                <Icon name="Trash" size={IconSizeType.XS} className="cursor-pointer m-0" onClick={() => onDeleteDocument(bookingDocument)} />
              }
            </div>
          )
        })
      }
      {[PROFILE.TOUR_LEADER, PROFILE.ADMIN, PROFILE.INTERN, PROFILE.RESOURCE].includes(authUtils.getProfile()) && (
        <div className="mt-3">
          <FileDropZone
            label="order_item_add_voucher"
            handleInputChange={(e: ChangeEvent<HTMLInputElement>) => onChangeOrderItemFile(e.target.files, FileType.VOUCHER)}
            handleDrop={(e: DragEvent<HTMLDivElement>) => onChangeOrderItemFile(e.dataTransfer.files, FileType.VOUCHER)}
            id={`voucherFile-${orderItem.id}`}
          />
        </div>
      )}

      {[PROFILE.ADMIN, PROFILE.INTERN].includes(authUtils.getProfile()) &&
<>
        <div className="mt-3">
          <FileDropZone
            label="order_item_add_file"
            handleInputChange={(e: ChangeEvent<HTMLInputElement>) => onChangeOrderItemFile(e.target.files, FileType.ORDER_ITEM_FILE)}
            handleDrop={(e: DragEvent<HTMLDivElement>) => onChangeOrderItemFile(e.dataTransfer.files, FileType.ORDER_ITEM_FILE)}
            id={`otherFile-${orderItem.id}`}
          />
        </div>

</>
      }

      {[PROFILE.CLIENT, PROFILE.ADMIN, PROFILE.INTERN ].includes(authUtils.getProfile()) &&
        <div className="mt-3">
          <FileDropZone
            label="order_item_add_ticket"
            handleInputChange={(e: ChangeEvent<HTMLInputElement>) => onChangeOrderItemFile(e.target.files, FileType.TICKET)}
            handleDrop={(e: DragEvent<HTMLDivElement>) => onChangeOrderItemFile(e.dataTransfer.files, FileType.TICKET)}
            id={`ticketFile-${orderItem.id}`}
          />
        </div>
      }

      <GenericModalDelete
        titleId="modal_title_delete_document"
        open={openDeleteModal}
        onValidate={doAfterDelete}
        setOpen={setOpenDeleteModal}
        idEntity={selectedDocument?.fileId}
        onDelete={(selectedDocument?.fileType === FileType.ORDER_ITEM_FILE || selectedDocument?.fileType === FileType.VOUCHER) ? orderItemService.deleteDocumentById : bookingService.deleteDocumentById}
        successToastMessageId="success_delete_document"
        errorToastMessageId="error_toast_delete_document"
        bodyDeleteMessage={intl.formatMessage({id: "modal_body_delete_document"})}
      />
    </div>
  )
}

export default OrderItemPreviewCardDocuments;
