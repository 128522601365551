import React, {FunctionComponent, useMemo} from "react";
import {ActivityBillingPageData, ActivityBillingSearchObject} from "../../../interfaces/ActivityBllingInterface";
import useSearchFilter from "../../../hooks/useSearchFilter";
import usePaginatedData from "../../../hooks/usePaginatedData";
import {activityBillingService} from "../../../services/ActivityBillingService";
import Pagination from "../pagination/Pagination";
import FilterGroup from "../filters/FilterGroup";
import DateFilter from "../filters/DateFilter";
import TextFilter from "../filters/TextFilter";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {FormattedMessage, useIntl} from "react-intl";
import CustomTable, {CustomTableRef} from "../table/CustomTable";
import {CustomTableColumn, CustomTableType} from "../../../interfaces/TableInterfaces";
import ActivityBillingOverviewForResourceTableRow
  from "../table/activityBilling/ActivityBillingOverviewForResourceTableRow";
import {TRUE_FALSE_ENUM} from "../../../constants/OptionConstants";
import ActivityBillingOverviewForResourceTableRowDetailsHeader
  from "../table/activityBilling/ActivityBillingOverviewForResourceTableRowDetailsHeader";
import ActivityBillingOverviewForResourceTableRowDetails
  from "../table/activityBilling/ActivityBillingOverviewForResourceTableRowDetails";

interface ActivityBillingOverviewForResourceListProps {
  className?: string,
  onOrderItemClicked?: (id: string) => void
}

const ActivityBillingOverviewForResourceList: FunctionComponent<ActivityBillingOverviewForResourceListProps> = ({
  className = "",
  onOrderItemClicked
}) => {
  const intl = useIntl();
  const initialSearchObject: ActivityBillingSearchObject = {
    resourceId: "",
    number: "",
    startDate: "",
    endDate: "",
    resourceTypeIds: []
  }
  const {searchObject, updateSearchField, onReset} = useSearchFilter<ActivityBillingSearchObject>(initialSearchObject)
  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortOptions,
    setSortOptions,
    total,
    refresh
  } = usePaginatedData(activityBillingService.getResourceActivityBillingPage, searchObject, "date", 50, "DES")
  const ref = React.useRef<CustomTableRef>();

  const columns: CustomTableColumn[] = useMemo(() =>
    [
      {title: "billing_overview_number", accessor: "number", sortable: true, className:"col-4"},
      {title: "billing_overview_date", accessor: "date", sortable: true, className:"col-3"},
      {title: "billing_overview_creation_date", accessor: "creationDate", sortable: true, className:"col-3"},
      {title: "billing_overview_period", accessor: "firstStaffingDate", sortable: false, className:"col-4"},
      {title: "billing_overview_amount_bill", accessor: "amount", sortable: true, className:"col-2"},
      {title: "billing_overview_amount_bill_TTC", accessor: "amount", sortable: true, className:"col-2"},
      {title: "billing_overview_payment_date", accessor: "paymentDate", sortable: true, className:"`col-4"},
      {title: "billing_overview_download", accessor: "", sortable: false, className:"col-2 px-0 ms-auto me-3"},
    ], [data])

  const totalNbActivity = data.reduce((acc, objet) => acc + objet.activityStaffing.length, 0);

  return (
    <div className={className}>
      <FilterGroup className="d-flex gap-2 mb-3">
        <TextFilter
          title={intl.formatMessage({id: "billing_overview_number"})}
          value={searchObject.number}
          onChange={(value) => updateSearchField("number", value)}
          placeholder={intl.formatMessage({id: "billing_overview_number_placeholder"})}
        />

        <DateFilter
          title={intl.formatMessage({id: "billing_overview_period_start"})}
          value={searchObject.startDate}
          onChangeDate={(value) => updateSearchField("startDate", value)}
        />

        <DateFilter
          title={intl.formatMessage({id: "billing_overview_period_end"})}
          value={searchObject.endDate}
          onChangeDate={(value) => updateSearchField("endDate", value)}
        />

        <Button onClick={() => onReset({billed: TRUE_FALSE_ENUM.TRUE})} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        <div className="ms-auto">
            <span>
              <FormattedMessage id="activity_bill_results_number" /> : <b>{totalNbActivity}</b>
            </span>
        </div>
      </FilterGroup>
      <div className={className}>
        <CustomTable
          ref={ref}
          onSortOptions={setSortOptions}
          sortOptions={sortOptions}
          className={`${className}`}
          type={CustomTableType.ACTIVITY_BILLING_OVERVIEW}
          datas={data}
          columns={columns}
          RowComponent={({data, open, ...rest}) => {
            const activityBilling = data as ActivityBillingPageData;
            return (
              <div>
                <ActivityBillingOverviewForResourceTableRow
                  className="activity-billing-overview-row"
                  activityBilling={activityBilling}
                  open={open}
                  refresh={refresh}
                  {...rest}
                />
                {open && (
                  <div className="epow-custom-table-row-details pt-2">
                    <ActivityBillingOverviewForResourceTableRowDetailsHeader showFirstColumn={false} />
                    {activityBilling.activityStaffing?.map(activityStaffing =>
                      <ActivityBillingOverviewForResourceTableRowDetails
                        key={activityStaffing.id}
                        activityStaffing={activityStaffing}
                        className="detail-row"
                        openOrderItemDetails={onOrderItemClicked}
                      />
                    )}
                  </div>
                )}
              </div>);
          }}
        />

      <Pagination page={page} setPage={setPage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total} />

      </div>
    </div>


    );
}


export default ActivityBillingOverviewForResourceList;
