import React, {UseTableCellProps} from "react-table";
import {Table} from "../interfaces/TableInterfaces";
import {Identity} from "../interfaces/ContactInterfaces";
import Icon from "../components/icon/Icon";
import {ColorType, IconSizeType} from "../types/bootstrap/BootstrapType";
import {PROFILE, User, UserTableDTO} from "../interfaces/UserInterfaces";
import {Link} from "react-router-dom";
import {
  ACTIVITIES_PATH,
  BILLING_OVERVIEW_PATH,
  BOOKINGS_PATH,
  CUSTOMERS_PATH,
  getProfilePicture, INVOICE_PAYMENTS_PATH,
  ORDERS_PATH,
  RESOURCES_PATH,
  SERVICES_PATH,
  STAFFING_PATH,
  SUPPLIERS_PATH,
  TOUR_LEADERS_PATH,
  TOURS_PATH
} from "./routes/RoutePaths";
import {Tour, TourTable} from "interfaces/TourInterfaces";
import {ActivityTableDTO} from "interfaces/ActivityInterfaces";
import {SupplierDetails} from "interfaces/SupplierInterfaces";
import {TourLeader} from "../interfaces/TourLeaderInterfaces";
import {ServiceTableDTO} from "../interfaces/ServiceInterfaces";
import {Customer} from "../interfaces/CustomerInterfaces";
import {ResourceTableDto} from "../interfaces/ResourceInterfaces";
import {FormattedMessage, IntlShape} from "react-intl";
import {dateUtils} from "../utils/dateUtils";
import {
  OrderItemResponse,
  OrderItemStaffingStatus, OrderItemStateMap,
  OrderItemWithActivityBillingDto
} from "../interfaces/OrderItemInterfaces";
import Badge from "../components/atoms/Badge";
import TextLink from "../components/atoms/TextLink";
import {WorkflowStateMap} from "./workflow/WorkflowStates";
import {Rule} from "../interfaces/RuleInterface";
import {fileUtils} from "../utils/fileUtils";
import {USER_PROFILE} from "./user/UserConstants";
import {ruleService} from "../services/RuleService";
import {orderUtils} from "../utils/orderUtils";
import {optionUtils} from "../utils/optionUtils";
import {YES_NO_OPTIONS} from "./OptionConstants";
import {ORDER_STATUS_PENDING} from "../interfaces/OrderInterfaces";
import {ResourceType} from "../interfaces/ResourceTypeInterfaces";
import ResourceCircle from "../components/molecules/circle/ResourceCircle";
import Avatar from "../components/molecules/images/Avatar";
import {VATRate} from "../interfaces/VATRateInterfaces";
import {numberUtils} from "../utils/numberUtils";
import {ActivityBillingPaymentPageData} from "../interfaces/ActivityBillingPaymentInterface";
import {ActivityBillingStatus} from "../interfaces/ActivityBllingInterface";
import {moneyUtils} from "../utils/moneyUtils";
import Tooltip from "../components/atoms/Tooltip";
import {InvoicePayment} from "../interfaces/InvoicePaymentInterfaces";
import ActionVoucherCell from "../components/molecules/cell/ActionVoucherCell";
import {ProductType} from "../interfaces/ProductTypeInterfaces";
import {PlanItem} from "../interfaces/PlanItemInterfaces";
import {CUSTOMER_GEOGRAPHICAL_AREA_OPTIONS} from "./CustomerConstants";

export const CUSTOMER_TABLE = (intl: IntlShape): Table<Customer> => {
  return {
    NAME: {
      Header: intl.formatMessage({id: "customer_table_header_name"}),
      accessor: "name",
      sortable: true
    },
    CONTACT: {
      Header: intl.formatMessage({id: "customer_table_header_contact"}),
      accessor: "mainContact",
      Cell: ({row}: UseTableCellProps<Customer>) => {
        const identity: Identity = row.original.mainContact?.identity
        return <span>{identity?.firstName} {identity?.lastName}</span>
      },
      sortable: true,
    },
    TOUR: {
      Header: intl.formatMessage({id: "customer_table_header_tours"}),
      accessor: "tourCount",
      sortable: true,
      Cell: ({row}: UseTableCellProps<Customer>) => {
        const tourCount = row.original.tourCount;
        if (tourCount === 0) {
          return <span>-</span>
        } else {
          return <TextLink to={`${TOURS_PATH}?customerId=${row.original.id}&orderStatus=${ORDER_STATUS_PENDING.join(",")}`}>
            <FormattedMessage id={"customer_table_tour_number"} values={{tourNumber: tourCount}}></FormattedMessage>
          </TextLink>
        }
      },
    },
    ORDER: {
      Header: intl.formatMessage({id: "customer_table_header_orders"}),
      accessor: "orderCount",
      sortable: true,
      Cell: ({row}: UseTableCellProps<Customer>) => {
        const orderCount = row.original.orderCount;
        if (orderCount === 0) {
          return <span>-</span>
        } else {
          return <TextLink to={`${ORDERS_PATH}?customerId=${row.original.id}&status=${ORDER_STATUS_PENDING.join(",")}`}>
            <FormattedMessage id={"customer_table_order_number"} values={{orderNumber: orderCount}}></FormattedMessage>
          </TextLink>
        }
      },
    },
    STATUS: {
      Header: intl.formatMessage({id: "status"}),
      accessor: "currentState",
      width: "5%",
      Cell: ({row}: UseTableCellProps<Customer>) => {
        const state = row.original.currentState;
        return (
          <Badge pill color={WorkflowStateMap[state]?.color}>
            {intl.formatMessage({id: WorkflowStateMap[state]?.label})}
          </Badge>
        )
      }
    },
    ACTION: {
      Header: "",
      accessor: "actions",
      width: "5%",
      Cell: ({row}: UseTableCellProps<Customer>) => {
        return <div className="float-end">
          <Link className="table-action-link" to={`${CUSTOMERS_PATH}/${row.original.id}`}>
            <Icon name="Search" size={IconSizeType.XS} className={"pe-2"}/>
          </Link>
        </div>
      },
    }
  }
}

export const TOUR_TABLE = (intl: IntlShape): Table<TourTable> => {
  return {
    TOUR_CODE: {
      Header: intl.formatMessage({id: "tour_table_header_code"}),
      width: "12%",
      accessor: "tourCode",
      sortable: true
    },
    TOUR_NAME: {
      Header: intl.formatMessage({id: "tour_table_header_name"}),
      accessor: "tourName",
      width: "25%",
      sortable: true,
      ignoreCase: true,
    },
    TOUR_CUSTOMERS: {
      Header: intl.formatMessage({id: "tour_table_header_customers"}),
      accessor: "customers",
      Cell: ({row}: UseTableCellProps<TourTable>) => {
        const customerCount = row.original.customerCount;
        if (customerCount === 0) {
          return <span>-</span>
        }
        return <TextLink to={`${CUSTOMERS_PATH}?tourId=${row.original.id}`}>
          <FormattedMessage id="tour_table_customer_number" values={{customerCount: customerCount}}></FormattedMessage>
        </TextLink>
      },
    },
    TOUR_ACTIVITIES: {
      Header: intl.formatMessage({id: "tour_table_header_activities"}),
      accessor: "activities",
      Cell: ({row}: UseTableCellProps<TourTable>) => {
        const activityCount = row.original.activityCount;
        return <TextLink to={`${ACTIVITIES_PATH}?tourId=${row.original.id}&orderStatus=${ORDER_STATUS_PENDING.join(",")}`}>
          <FormattedMessage id="tour_table_activity_number" values={{activityCount: activityCount}}></FormattedMessage>
        </TextLink>
      },
    },
    TOUR_ORDERS: {
      Header: intl.formatMessage({id: "tour_table_header_orders"}),
      accessor: "orderCount",
      Cell: ({row}: UseTableCellProps<TourTable>) => {
        const orderCount = row.original.orderCount;
        return <TextLink to={`${ORDERS_PATH}?tourId=${row.original.id}&status=${ORDER_STATUS_PENDING.join(",")}`}>
          <FormattedMessage id="tour_table_order_number" values={{orderCount: orderCount}}></FormattedMessage>
        </TextLink>
      },
    },
    STATUS: {
      Header: intl.formatMessage({id: "status"}),
      accessor: "currentState",
      width: "5%",
      Cell: ({row}: UseTableCellProps<TourTable>) => {
        const state = row.original.currentState;
        return (
          <Badge pill color={WorkflowStateMap[state]?.color}>
            {intl.formatMessage({id: WorkflowStateMap[state]?.label})}
          </Badge>
        )
      }
    },
    ACTION: {
      Header: "",
      accessor: "actions",
      width: "5%",
      Cell: ({row}: UseTableCellProps<Tour>) => {
        return <div className="float-end">
          <Link className="table-action-link" to={`${TOURS_PATH}/${row.original.id}`}><Icon name="Search" size={IconSizeType.XS} className={"pe-2"} /></Link>
        </div>
      },
    }
  }
}

export const TOUR_LEADER_TABLE = (intl: IntlShape): Table<TourLeader> => {
  return {
    TOUR_LEADER_NAME: {
      Header: intl.formatMessage({id: "tour_leader_table_header_name"}),
      accessor: "identity.firstName",
      sortable: true,
      Cell: ({row}: UseTableCellProps<TourLeader>) => {
        const identity: Identity = row.original.identity
        return <span>{identity?.firstName} {identity?.lastName}</span>
      },
    },
    TOUR_LEADER_EMAIL: {
      Header: intl.formatMessage({id: "tour_leader_table_header_email"}),
      accessor: "identity.email",
      sortable: true,
      Cell: ({row}: UseTableCellProps<TourLeader>) => {
        return <TextLink to={`mailto:${row.original.identity.email}`} external><>{row.original.identity.email}</></TextLink>
      },
    },
    TOUR_LEADER_PHONE_NUMBER: {
      Header: intl.formatMessage({id: "tour_leader_table_header_phone"}),
      accessor: "identity.phoneNumber",
      sortable: true,
      Cell: ({row}: UseTableCellProps<TourLeader>) => {
        return <TextLink to={`tel:${row.original.identity.phoneNumber}`} external><>{row.original.identity.phoneNumber}</></TextLink>
      },
    },
    TOUR_LEADER_CLIENTS: {
      Header: intl.formatMessage({id: "tour_leader_table_header_customers"}),
      accessor: "tourLeaderCustomers",
      sortable: true,
      Cell: ({row}: UseTableCellProps<TourLeader>) => {
        const customerCount = row.original.customerCount;
        if (customerCount > 0) {
          return <TextLink to={`${CUSTOMERS_PATH}?tourLeaderId=${row.original.id}`}>
            <FormattedMessage id="tour_leader_table_customer_number" values={{customerNumber: customerCount}}></FormattedMessage>
          </TextLink>
        }

        return <span>-</span>
      },
    },
    TOUR_LEADER_CODE_AMOUNT: {
      Header: intl.formatMessage({id: "tour_leader_table_header_orders"}),
      accessor: "orders",
      sortable: true,
      Cell: ({row}: UseTableCellProps<TourLeader>) => {
        const orderCount = row.original.orderCount
        if (orderCount > 0) {
          return <TextLink to={`${ORDERS_PATH}?tourLeaderId=${row.original.id}`}>
            <FormattedMessage id="tour_leader_table_order_number" values={{orderNumber: orderCount}}></FormattedMessage>
          </TextLink>
        }
        return <span>-</span>
      },
    },
    STATUS: {
      Header: intl.formatMessage({id: "status"}),
      accessor: "currentState",
      width: "5%",
      Cell: ({row}: UseTableCellProps<TourLeader>) => {
        const state = row.original.currentState;
        return (
          <Badge pill color={WorkflowStateMap[state]?.color}>
            {intl.formatMessage({id: WorkflowStateMap[state]?.label})}
          </Badge>
        )
      }
    },
    ACTION: {
      Header: "",
      accessor: "actions",
      width: "5%",
      Cell: ({row}: UseTableCellProps<TourLeader>) => {
        return <div className="float-end">
          <Link className="table-action-link" to={`${TOUR_LEADERS_PATH}/${row.original.id}`}>
            <Icon name="Search" size={IconSizeType.XS} className={"pe-2"} />
          </Link>
        </div>
      },
    }
  }
}

export const SUPPLIER_TABLE = (intl: IntlShape): Table<SupplierDetails> => {
  return {
    SUPPLIER_NAME: {
      Header: intl.formatMessage({id: "supplier_table_header_name"}),
      accessor: "name",
      sortable: true
    },
    SUPPLIER_MAIN_CONTACT_PHONE_NUMBER: {
      Header: intl.formatMessage({id: "contact_table_phone"}),
      accessor: "mainContact.identity.phoneNumber",
      sortable: true,
      Cell: ({row}: UseTableCellProps<SupplierDetails>) => {
        const tel = row.original.entityPhone || row.original.mainContact?.identity.phoneNumber
        if (tel) {
          return <TextLink to={`tel:${tel}`} external><>{tel}</></TextLink>
        } else {
          return <span>-</span>
        }
      },
    },
    SUPPLIER_MAIN_CONTACT_EMAIL: {
      Header: intl.formatMessage({id: "supplier_table_header_contact_email"}),
      accessor: "mainContact.identity.email",
      sortable: true,
      Cell: ({row}: UseTableCellProps<SupplierDetails>) => {
        const email = row.original.entityEmail || row.original.mainContact?.identity.email
        if (email) {
          return <TextLink to={`mailto:${email}`} external><>{email}</></TextLink>
        } else {
          return <span>-</span>
        }
      },
    },
    SUPPLIER_MAIN_CONTACT: {
      Header: intl.formatMessage({id: "supplier_table_header_contact"}),
      accessor: "mainContact.identity.firstName",
      sortable: true,
      Cell: ({row}: UseTableCellProps<SupplierDetails>) => {
        const identity: Identity = row.original.mainContact?.identity
        return <span>{identity?.firstName} {identity?.lastName}</span>
      },
    },
    SUPPLIER_SERVICES_COUNT: {
      Header: intl.formatMessage({id: "supplier_table_header_services"}),
      accessor: "services",
      Cell: ({row}: UseTableCellProps<SupplierDetails>) => {
        const serviceCount = row.original.serviceCount
        if (serviceCount === 0) {
          return <span>-</span>
        } else {
          return <TextLink to={`${SERVICES_PATH}?supplierId=${row.original.id}`}>
            <FormattedMessage id={"supplier_table_service_number"} values={{serviceCount: serviceCount}}></FormattedMessage>
          </TextLink>
        }
      }
    },
    SUPPLIER_CURRENT_BOOKINGS_COUNT: {
      Header: intl.formatMessage({id: "supplier_table_header_bookings"}),
      accessor: "currentBookingCount",
      sortable: true,
      Cell: ({row}: UseTableCellProps<SupplierDetails>) => {
        const currentBookingCount = row.original.currentBookingCount;
        if (currentBookingCount === 0) {
          return <span></span>
        } else {
          return <TextLink to={`${BOOKINGS_PATH}?supplierIds=${row.original.id}&displayPending=true`}>
            <FormattedMessage id={"supplier_table_booking_number"} values={{bookingCount: currentBookingCount}}></FormattedMessage>
          </TextLink>
        }
      }
    },
    STATUS: {
      Header: intl.formatMessage({id: "status"}),
      accessor: "currentState",
      width: "5%",
      Cell: ({row}: UseTableCellProps<SupplierDetails>) => {
        const state = row.original.currentState;
        return (
          <Badge pill color={WorkflowStateMap[state]?.color} >
            {intl.formatMessage({id: WorkflowStateMap[state]?.label})}
          </Badge>
        )
      }
    },
    ACTION: {
      Header: "",
      accessor: "actions",
      width: "5%",
      Cell: ({row}: UseTableCellProps<SupplierDetails>) => {
        return <div className="float-end">
          <Link className="table-action-link" to={`${SUPPLIERS_PATH}/${row.original.id}`}><Icon name="Search" size={IconSizeType.XS} className={"pe-2"} /></Link>
        </div>
      },
    }
  }
}

export const RESOURCE_TABLE = (intl: IntlShape): Table<ResourceTableDto> => {
  return {
    RESOURCE_TYPE: {
      Header: intl.formatMessage({id: "resource_type_short"}),
      accessor: "resourceType",
      width: "5%",
      sortable: true,
      Cell: ({row}: UseTableCellProps<ResourceTableDto>) => {
        const resourceType: ResourceType = row?.original?.resourceType
        return (
          <ResourceCircle resourceType={resourceType} />
        )
      },
    },
    RESOURCE_NAME: {
      Header: intl.formatMessage({id: "resource_table_header_name"}),
      accessor: "identity.lastName",
      sortable: true,
      Cell: ({row}: UseTableCellProps<ResourceTableDto>) => {
        const identity: Identity = row?.original?.identity
        return (
          <>
            <Avatar url={getProfilePicture(row?.original?.id)} className="m-0 me-2"/>
            <span>
              {identity?.firstName} {identity?.lastName}
            </span>
          </>
        )
      },
    },
    RESOURCE_EMAIL: {
      Header: intl.formatMessage({id: "resource_table_header_email"}),
      accessor: "identity.email",
      sortable: true,
      width: "10%",
      Cell: ({row}: UseTableCellProps<ResourceTableDto>) => {
        return <TextLink to={`mailto:${row.original.identity.email}`} external><>{row.original.identity.email}</></TextLink>
      },
    },
    RESOURCE_PHONE: {
      Header: intl.formatMessage({id: "resource_table_header_phone"}),
      accessor: "identity.phoneNumber",
      sortable: true,
      Cell: ({row}: UseTableCellProps<ResourceTableDto>) => {
        return <TextLink to={`tel:${row.original.identity.phoneNumber}`} external><>{row.original.identity.phoneNumber}</></TextLink>
      },
    },
    RESOURCE_STATUS: {
      Header: intl.formatMessage({id: "resource_table_header_area"}),
      accessor: "activityAreas",
      sortable: false,
      Cell: ({row}: UseTableCellProps<ResourceTableDto>) => {
        return <span>{row.original?.areas ? row.original.areas.join(", ") : "-"}</span>
      },
    },
    RESOURCE_NUMBER_ACTIVITIES: {
      Header: intl.formatMessage({id: "resource_table_header_activity_number"}),
      accessor: "activitiesCount",
      sortable: false,
      Cell: ({row}: UseTableCellProps<ResourceTableDto>) => {
        const activityCount = row.original.activityCount;
        if (activityCount === 0) {
          return <span>-</span>
        } else {
          return <TextLink to={`${STAFFING_PATH}?staffingStatus=${OrderItemStaffingStatus.STAFFED}&startDate=${dateUtils.formatDateYYYYMMDD(dateUtils.today())}&resourceIds=${row.original.id}`}>
            <FormattedMessage id={"resource_table_activity_number"} values={{activityCount}}></FormattedMessage>
          </TextLink>
        }
      },
    },
    STATUS: {
      Header: intl.formatMessage({id: "status"}),
      accessor: "currentState",
      width: "5%",
      Cell: ({row}: UseTableCellProps<ResourceTableDto>) => {
        const state = row.original.currentState;
        return (
          <Badge pill color={WorkflowStateMap[state]?.color} >
            {intl.formatMessage({id: WorkflowStateMap[state]?.label})}
          </Badge>
        )
      }
    },
    ACTION: {
      Header: "",
      accessor: "actions",
      width: "5%",
      Cell: ({row}: UseTableCellProps<ResourceTableDto>) => {
        return <div className="float-end">
          <Link className="table-action-link" to={`${RESOURCES_PATH}/${row.original.id}`}><Icon name="Search" size={IconSizeType.XS} className={"pe-2"} /></Link>
        </div>
      },
    }
  }
}

export const ACTIVITY_TABLE = (intl: IntlShape): Table<ActivityTableDTO> => {
  return {
    ACTIVITY_NEW: {
      Header: "",
      accessor: "date",
      width: "3%",
      sortable: false,
      Cell: ({row}: UseTableCellProps<ActivityTableDTO>) => {
        const oneMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 1));
        const isNewActivity = new Date(row.original.activationDate) > oneMonthAgo;

        return (<span>{isNewActivity && <Badge>{intl.formatMessage({id: "activity_table_new"})}</Badge>}</span>);
      },
    },
    ACTIVITY_NAME: {
      Header: intl.formatMessage({id: "activity_table_header_name"}),
      accessor: "name",
      width: "30%",
      sortable: true
    },
    ACTIVITY_REFERENCE: {
      Header: intl.formatMessage({id: "activity_table_header_reference"}),
      width: "5%",
      accessor: "reference",
      sortable: true
    },
    ACTIVITY_ACTIVATION_DATE: {
      Header: intl.formatMessage({id: "activity_table_header_activation_date"}),
      accessor: "activationDate",
      sortable: true,
      Cell: ({row}: UseTableCellProps<ActivityTableDTO>) => {
        return (<span>{dateUtils.formatDateDayJs(row.original.activationDate)}</span>);
        },
    },
    ACTIVITY_DURATION: {
      Header: intl.formatMessage({id: "activity_table_header_duration"}),
      accessor: "duration",
      Cell: ({row}: UseTableCellProps<ActivityTableDTO>) => {
        return <span>{dateUtils.formatDuration(row.original.duration)}</span>
      },
      sortable: true,
    },
    ACTIVITY_MAX_PAX: {
      Header: intl.formatMessage({id: "activity_table_header_maxpax"}),
      accessor: "numberPaxMax",
      sortable: true
    },
    ACTIVITY_RELATED_TOURS: {
      Header: intl.formatMessage({id: "activity_table_header_tours"}),
      accessor: "tourCount",
      sortable: false,
      Cell: ({row}: UseTableCellProps<ActivityTableDTO>) => {
        const tourCount = row.original.tourCount;
        if (tourCount === 0) {
          return <span>-</span>
        } else {
          return <TextLink to={`${TOURS_PATH}?activityId=${row.original.id}`}>
            <FormattedMessage id={"activity_table_tour_number"} values={{tourCount: tourCount}}></FormattedMessage>
          </TextLink>
        }
      },
    },
    STATUS: {
      Header: intl.formatMessage({id: "status"}),
      accessor: "status",
      width: "5%",
      Cell: ({row}: UseTableCellProps<ActivityTableDTO>) => {
        const state = row.original.currentState;
        return (
          <Badge pill color={WorkflowStateMap[state]?.color}>
            {intl.formatMessage({id: WorkflowStateMap[state]?.label})}
          </Badge>
        )
      }
    },
    ACTION: {
      Header: "",
      accessor: "actions",
      width: "5%",
      Cell: ({row}: UseTableCellProps<ActivityTableDTO>) => {
        return <div className="float-end">
          <Link className="table-action-link" to={`${ACTIVITIES_PATH}/${row.original.id}`}><Icon name="Search" size={IconSizeType.XS} className={"pe-2"} /></Link>
        </div>
      },
    }
  }
}

export const SERVICE_TABLE = (intl: IntlShape): Table<ServiceTableDTO> => {
  return {
    SERVICE_NAME: {
      Header: intl.formatMessage({id: "service_table_header_name"}),
      accessor: "name",
      sortable: true
    },
    SERVICE_DEFAULT_SUPPLIER: {
      Header: intl.formatMessage({id: "service_table_header_supplier"}),
      accessor: "defaultSupplier.name",
      sortable: true,
      Cell: ({row}: UseTableCellProps<ServiceTableDTO>) => {
        return <TextLink to={`${SUPPLIERS_PATH}/${row.original.defaultSupplier?.id}`}><>{row.original.defaultSupplier?.name}</></TextLink>
      },
    },
    SERVICE_BOOKINGS: {
      Header: intl.formatMessage({id: "service_table_header_bookings"}),
      accessor: "currentBookings",
      sortable: true,
      Cell: ({row}: UseTableCellProps<ServiceTableDTO>) => {
        const bookingCount = row.original.currentBookingCount;
        if (bookingCount === 0) {
          return <span>-</span>
        } else {
          return <TextLink to={`${BOOKINGS_PATH}?serviceIds=${row.original.id}&displayPending=true`}>
            <FormattedMessage id={"service_table_booking_number"} values={{bookingCount: bookingCount}}></FormattedMessage>
          </TextLink>
        }
      },
    },
    STATUS: {
      Header: intl.formatMessage({id: "status"}),
      accessor: "currentState",
      width: "5%",
      Cell: ({row}: UseTableCellProps<ServiceTableDTO>) => {
        const state = row.original.currentState;
        return (
          <Badge pill color={WorkflowStateMap[state]?.color}>
            {intl.formatMessage({id: WorkflowStateMap[state]?.label})}
          </Badge>
        )
      }
    },
    ACTION: {
      Header: "",
      accessor: "actions",
      width: "5%",
      Cell: ({row}: UseTableCellProps<ServiceTableDTO>) => {
        return <div className="float-end">
          <Link className="table-action-link" to={`${SERVICES_PATH}/${row.original.id}`}>
            <Icon name="Search" size={IconSizeType.XS} className={"pe-2"} />
          </Link>
        </div>
      },
    }
  }
}

export const USER_TABLE = (intl: IntlShape, onClickActivated: (user: User) => void, onUserEdition: (user: User) => void): Table<UserTableDTO> => {
  return {
    NAME: {
      Header: intl.formatMessage({id: "users_table_header_name"}),
      accessor: "lastName",
      sortable: true,
      Cell: ({row}: UseTableCellProps<UserTableDTO>) => {
        return <span>{row?.original?.firstName} {row?.original?.lastName}</span>
      },
    },
    EMAIL: {
      Header: intl.formatMessage({id: "users_table_header_email"}),
      accessor: "email",
      sortable: true
    },
    PROFILE: {
      Header: intl.formatMessage({id: "users_table_header_profiles"}),
      accessor: "profile",
      Cell: ({row}: UseTableCellProps<UserTableDTO>) => {
        const profiles = row.original.profile.map((profile) => intl.formatMessage({id:  USER_PROFILE[profile].label}));
        return <span>{profiles.join(", ")}</span>
      }
    },
    STATUS: {
      Header: intl.formatMessage({id: "users_table_header_active"}),
      accessor: (user?: UserTableDTO) => user?.validated ? intl.formatMessage({id: "yes_option"}) : intl.formatMessage({id: "no_option"})
    },
    CREATION_DATE: {
      Header: intl.formatMessage({id: "users_table_header_created_on"}),
      accessor: (user?: UserTableDTO) => dateUtils.formatDateToLocalDateTime(dateUtils.parseDate(user?.creationDate))
    },
    LAST_CONNECTION: {
      Header: intl.formatMessage({id: "users_table_header_last_connection"}),
      accessor: (user?: UserTableDTO) => user?.lastConnectionDate ? dateUtils.formatDateToLocalDateTime(dateUtils.parseDate(user?.lastConnectionDate)) : "-"
    },
    ACTION: {
      Header: "",
      accessor: "actions",
      width: "15%",
      Cell: ({row}: UseTableCellProps<UserTableDTO>) => {
        const user = row.original;
        const isUserAdminOrIntern = user?.profile?.includes(PROFILE.ADMIN) || user?.profile?.includes(PROFILE.INTERN);
        return (
          <div className="float-end">
            <Icon
              name={user?.validated ? "DeactivateUser" : "UserPlus"}
              size={IconSizeType.XS}
              className={"pe-2 cursor-pointer"}
              onClick={() => onClickActivated(user)}
            />

            {isUserAdminOrIntern &&
              <Icon name="Pen" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => onUserEdition(user)}/>
            }
          </div>
        )
      },
    }
  }
}

export const ORDER_ITEM_TABLE = (intl: IntlShape, onRemindButtonClick: (orderItemResponse: OrderItemResponse) => void, onCheckButtonClick: (orderItemResponse: OrderItemResponse, fileId: string) => void, onValidateButtonClick: (orderItemResponse: OrderItemResponse) => void): Table<OrderItemResponse> => {

  return {
    NAME: {
      Header: intl.formatMessage({id: "billing_table_header_name"}),
      accessor: "name",
      sortable: true,
    },
    ORDER: {
      Header: intl.formatMessage({id: "billing_table_header_order"}),
      accessor: "order.orderNumber",
      sortable: true,
      Cell: ({row}: UseTableCellProps<OrderItemResponse>) => {
        const order = row.original.order;
        return <TextLink to={`${ORDERS_PATH}/${order.id}`}>
          <>{orderUtils.getOrderRefDisplay(order.orderNumber, order.customerReference, row.original.customerName)}</>
        </TextLink>
      },
    },
    BILLED_CUSTOMER: {
      Header: intl.formatMessage({id: "billing_table_header_billing_service"}),
      accessor: "billingServiceName",
      Cell: ({row}: UseTableCellProps<OrderItemResponse>) => {
        return <TextLink to={`${CUSTOMERS_PATH}/${row.original.billingServiceId}`}><>{row.original.billingServiceName}</></TextLink>
      },
    },
    ORDER_DATE: {
      Header: intl.formatMessage({id: "billing_table_header_date"}),
      accessor: "rendezVousDate",
      sortable: true,
      Cell: ({row}: UseTableCellProps<OrderItemResponse>) => {
        return <div>
          {dateUtils.formatDateDayJs(row.original.rendezVousDate)}
        </div>
      },
    },
    STATUS: {
      Header: intl.formatMessage({id: "billing_table_header_status"}),
      accessor: "status",
      sortable: true,
      Cell: ({row}: UseTableCellProps<OrderItemResponse>) => {
        return <Badge pill color={OrderItemStateMap[row.original.status]?.color}>
          {intl.formatMessage({id: OrderItemStateMap[row.original.status]?.label || "STATUS_DEFAULT"})}
        </Badge>
      }
    },
    ACTION_VOUCHER: {
      Header: intl.formatMessage({id: "billing_table_header_action_voucher"}),
      accessor: "voucherStatus",
      Cell: ({row}: UseTableCellProps<OrderItemResponse>) => {
        return (
          <ActionVoucherCell
            intl={intl}
            rowData={row.original}
            onRemindButtonClick={() => onRemindButtonClick(row.original)}
            onCheckButtonClick={(orderItemResponse: OrderItemResponse, fileId: string) => onCheckButtonClick(row.original, fileId)}
            onValidateButtonClick={() => onValidateButtonClick(row.original)}
          />
        )
      }
    },
  }
}

export const RULE_TABLE = (
  onRuleEdition: (rule: Rule) => void,
  toggleRuleActivation: (id: string, activated: boolean) => void,
  intl: IntlShape,
): Table<Rule> => {
  const downloadFile = (rule: Rule) => {
    fileUtils.downloadFile(ruleService.getFile(rule.id), rule.attachment, intl)
  }

  return {
    PROFILES: {
      Header: intl.formatMessage({id: "rules_table_header_profiles"}),
      accessor: "profiles",
      Cell: ({row}: UseTableCellProps<Rule>) => {
        const profiles = row.original.profiles;

        if (profiles.length === Object.keys(PROFILE).length) {
          return (<span>{intl.formatMessage({id: "rules_table_all_profiles"})}</span>)
        }

        return (
          <span>{profiles.map(profile => intl.formatMessage({id: `PROFILE_${profile}`})).join(", ")}</span>
        )
      }
    },
    RULE: {
      Header: intl.formatMessage({id: "rules_table_header_description"}),
      accessor: "description",
      width: "70%"
    },
    STATUS: {
      Header: intl.formatMessage({id: "rules_table_header_status"}),
      accessor: "isActivated",
      Cell: ({row}: UseTableCellProps<Rule>) => (
        <FormattedMessage id={row.original.isActivated ? "rules_table_status_active" : "rules_table_status_inactive"} />
      )
    },
    ACTION: {
      Header: "",
      accessor: "actions",
      Cell: ({row}: UseTableCellProps<Rule>) => {
        const rule = row.original;
        return <div className="float-end">
          {rule?.attachment && (
            <Icon name="File" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => downloadFile(rule)} />
          )}
          <Icon name="Pen" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => onRuleEdition(rule)} />
          <Icon
            name={rule.isActivated ? "Ban" : "Check"}
            size={IconSizeType.XS}
            className={"pe-2 cursor-pointer"}
            onClick={() => toggleRuleActivation(rule.id, !rule.isActivated)}
          />
        </div>
      }
    }
  }
}

export const ACTIVITY_BILLING_TABLE = (intl: IntlShape, openOrderItemDetails: (id: string) => void): Table<OrderItemWithActivityBillingDto> => {
  return {
    NAME: {
      Header: intl.formatMessage({id: "activity_bill_name"}),
      accessor: "name",
      width: "20%",
      sortable: false
    },
    CUSTOMER: {
      Header: intl.formatMessage({id: "activity_bill_customer"}),
      accessor: "customerName",
      width: "20%",
      sortable: false
    },
    REFERENCE: {
      Header: intl.formatMessage({id: "activity_bill_reference"}),
      accessor: "customerReference",
      width: "20%",
      sortable: false
    },
    ACTIVITY_DATE: {
      Header: intl.formatMessage({id: "activity_bill_date"}),
      accessor: "orderItem.rendezVousDate",
      sortable: true,
      Cell: ({row}: UseTableCellProps<OrderItemWithActivityBillingDto>) => {
        return <>{dateUtils.formatDateDayJs(row.original.date)}</>
      }
    },
    DURATION: {
      Header: intl.formatMessage({id: "activity_bill_activity_duration"}),
      accessor: "activityDuration",
      sortable: false,
      Cell: ({row}: UseTableCellProps<OrderItemWithActivityBillingDto>) => {
        return <>{dateUtils.formatDuration(row.original.activityDuration)}</>
      }
    },
    BILLED: {
      Header: intl.formatMessage({id: "activity_bill_bill_state"}),
      accessor: "billState.length",
      sortable: false,
      Cell: ({row}: UseTableCellProps<OrderItemWithActivityBillingDto>) => {
        return <>{optionUtils.translateOption(intl, YES_NO_OPTIONS, row.original.billed)}</>
      }
    },
    ACTION: {
      Header: "",
      accessor: "id",
      sortable: false,
      Cell: ({row}: UseTableCellProps<OrderItemWithActivityBillingDto>) => {
        return <div className="float-end">
          <Icon name="Search" size={IconSizeType.XS} className={"pe-2 cursor-pointer"}  onClick={() => openOrderItemDetails(row.original.id)}/>
        </div>
      }
    }
  }
}

export const VAT_RATE_TABLE = (onVATEditionSelection: (vat: VATRate) => void, onVATDeleteSelection: (vat: VATRate) => void, intl: IntlShape): Table<VATRate> => {
  return {
    NAME: {
      Header: intl.formatMessage({id: "vat_name"}),
      accessor: "name",
      width: "70%",
      sortable: false
    },
    RATE: {
      Header: intl.formatMessage({id: "vat_rate_table"}),
      accessor: "rate",
      width: "20%",
      sortable: false,
      Cell: ({row}: UseTableCellProps<VATRate>) => {
        return <>{numberUtils.numberToPercent(row.original.rate)}</>
      },
    },
    ACTION: {
      Header: "",
      width: "10%",
      accessor: "actions",
      Cell: ({row}: UseTableCellProps<VATRate>) => {
        const vat = row.original;
        return <div className="float-end">
          <Icon name="Pen" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => onVATEditionSelection(vat)}/>
          <Icon name="Trash" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => onVATDeleteSelection(vat)}/>
        </div>
      }
    }
  }
}

export const PRODUCT_TYPE_TABLE = (onProductTypeEditionSelection: (productType: ProductType) => void, onProductTypeDeleteSelection: (productType: ProductType) => void, intl: IntlShape): Table<ProductType> => {
  return {
    NAME: {
      Header: intl.formatMessage({id: "product_type_name"}),
      accessor: "name",
      width: "70%",
      sortable: false
    },
    ACTION: {
      Header: "",
      width: "10%",
      accessor: "actions",
      Cell: ({row}: UseTableCellProps<ProductType>) => {
        const productType = row.original;
        return <div className="float-end">
          <Icon name="Pen" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => onProductTypeEditionSelection(productType)}/>
          <Icon name="Trash" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => onProductTypeDeleteSelection(productType)}/>
        </div>
      }
    }
  }
}

export const PLAN_ITEM_TABLE = (onPlanItemEditionSelection: (planItem: PlanItem) => void, onPlanItemDeleteSelection: (planItem: PlanItem) => void, intl: IntlShape): Table<PlanItem> => {
  return {
    GEOGRAPHICAL_ZONE: {
      Header: intl.formatMessage({id: "plan_item_geographical_zone"}),
      accessor: "geographicalZone",
      width: "20%",
      sortable: false,
      Cell: ({row}: UseTableCellProps<PlanItem>) => {
        return <span>{optionUtils.translateOption(intl, CUSTOMER_GEOGRAPHICAL_AREA_OPTIONS, row?.original.geographicalZone)}</span>
      }
    },
    PRODUCT_TYPE: {
      Header: intl.formatMessage({id: "plan_item_product_type"}),
      accessor: "productType.name",
      width: "30%",
      sortable: false
    },
    SALE_ACCOUNT: {
      Header: intl.formatMessage({id: "plan_item_sale_account"}),
      accessor: "saleAccount",
      width: "20%",
      sortable: false
    },
    PURCHASE_ACCOUNT: {
      Header: intl.formatMessage({id: "plan_item_purchase_account"}),
      accessor: "purchaseAccount",
      width: "20%",
      sortable: false
    },
    ACTION: {
      Header: "",
      width: "10%",
      accessor: "actions",
      Cell: ({row}: UseTableCellProps<PlanItem>) => {
        const planItem = row.original;
        return <div className="float-end">
          <Icon name="Pen" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => onPlanItemEditionSelection(planItem)}/>
          <Icon name="Trash" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => onPlanItemDeleteSelection(planItem)}/>
        </div>
      }
    }
  }
}

export const ACTIVITY_BILLING_PAYMENT_TABLE = (intl: IntlShape, onClickDownloadComptaFile: (selected: ActivityBillingPaymentPageData) => void, onClickDownloadSepaFile: (selected: ActivityBillingPaymentPageData) => void, onClickDownloadInvoices: (paymentId: string) => void): Table<ActivityBillingPaymentPageData> => {
  return {
    NUMBER: {
      Header: intl.formatMessage({id: "payment_table_number"}),
      accessor: "paymentNumber",
      width: "20%",
      sortable: true
    },
    DATE: {
      Header: intl.formatMessage({id: "payment_table_creation_date"}),
      accessor: "creationDate",
      width: "20%",
      sortable: true,
      Cell: ({row}: UseTableCellProps<ActivityBillingPaymentPageData>) => {
        return <>
          <span>{dateUtils.formatDateDayJs(row.original?.creationDate)}</span>
        </>
      }    },
    COUNT: {
      Header: intl.formatMessage({id: "payment_table_count"}),
      accessor: "activityBillingList.length",
      width: "20%",
      sortable: false,
      Cell: ({row}: UseTableCellProps<ActivityBillingPaymentPageData>) => {
        return <TextLink to={`${BILLING_OVERVIEW_PATH}?paymentId=${row.original.id}&status=${ActivityBillingStatus.SETTLED}`}>
          {row.original.activityBillingList?.length}
        </TextLink>
      }
    },
    AMOUNT: {
      Header: intl.formatMessage({id: "payment_table_amount"}),
      accessor: "amountPrice",
      width: "10%",
      sortable: true,
      Cell: ({row}: UseTableCellProps<ActivityBillingPaymentPageData>) => {
        return <div className="float-end">
          <span>{moneyUtils.formatNumberToCurrency(row.original?.billAmount)}</span>
        </div>
      }
    },
    ACTION: {
      Header:  intl.formatMessage({id: "payment_action"}),
      width: "10%",
      accessor: "actions",
      Cell: ({row}: UseTableCellProps<ActivityBillingPaymentPageData>) => {
        return <div className="float-end d-flex">
          <Tooltip text={intl.formatMessage({id: "payment_sepa_file"})}>
            <Icon color={ColorType.SECONDARY} name="FileInvoiceDollar" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => onClickDownloadSepaFile(row.original)}/>
          </Tooltip>
          <Tooltip text={intl.formatMessage({id: "payment_compta_file"})}>
            <Icon color={ColorType.GREEN} name="FileContract" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() =>  onClickDownloadComptaFile(row.original)}/>
          </Tooltip>
          <Tooltip text={intl.formatMessage({id: "payment_invoices_file"})}>
            <Icon color={ColorType.SUCCESS} name="FileZipper" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() =>  onClickDownloadInvoices(row.original.id)}/>
          </Tooltip>
        </div>
      }
    }
  }
}

export const INVOICE_PAYMENT = (intl: IntlShape, onClickDownload: (invoicePaymentId: InvoicePayment) => void): Table<InvoicePayment> => {
  return {
    NAME: {
      Header: intl.formatMessage({id: "invoice_payment_reference_table"}),
      accessor: "referenceNumber",
      sortable: true
    },
    DATE: {
      Header: intl.formatMessage({id: "invoice_payment_date_table"}),
      accessor: "paymentDate",
      sortable: true,
      Cell: ({row}: UseTableCellProps<InvoicePayment>) => {
        return <div>
          {dateUtils.formatDateDayJs(row.original.paymentDate)}
        </div>
      },
    },
    CUSTOMER: {
      Header: intl.formatMessage({id: "invoice_payment_customer_table"}),
      accessor: "customer.name",
      sortable: true,
      Cell: ({row}: UseTableCellProps<InvoicePayment>) => {
        if (row.original.customerName) {
          return <TextLink to={`${CUSTOMERS_PATH}/${row.original.customerId}`}>
            {row.original.customerName}
          </TextLink>
        }
        return <span>-</span>
      },
    },
    AMOUNT: {
      Header: intl.formatMessage({id: "invoice_payment_amount_table"}),
      accessor: "assoInvoicePaymentInvoiceBillingItems.amount",
      sortable: true,
      Cell: ({row}: UseTableCellProps<InvoicePayment>) => {
        return <div className="">
          <span>{moneyUtils.formatNumberToCurrency(row.original?.amount)}</span>
        </div>
      }
    },
    ACTION: {
      Header: intl.formatMessage({id: "invoice_payment_action_table"}),
      accessor: "actions",
      width: "5%",
      Cell: ({row}: UseTableCellProps<InvoicePayment>) => {
        return <div className="float-end d-flex">
          <Link className="table-action-link" to={`${INVOICE_PAYMENTS_PATH}/${row.original.id}`}>
            <Icon name="Search" size={IconSizeType.XS} className={"pe-2"}/>
          </Link>
          <div className="table-action-link cursor-pointer"
               onClick={() =>  onClickDownload(row.original)}
          >
            <Icon name="Download" size={IconSizeType.XS} className={"pe-2"}/>
          </div>
        </div>
      },
    }
  }
}
