import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import FormInput from "../input/FormInput";
import {toastUtils} from "../../../utils/toastUtils";
import {CreateCustomerSchema} from "../../../constants/validation/CustomerValidationSchemas";
import {CUSTOMER_INITIAL_VALUES, FRENCH_CUSTOMER} from "../../../constants/CustomerConstants";
import {customerService} from "../../../services/CustomerService";
import {Customer, CustomerFields} from "../../../interfaces/CustomerInterfaces";
import {useNavigate} from "react-router-dom";
import {CUSTOMERS_PATH} from "../../../constants/routes/RoutePaths";
import {useIntl} from "react-intl";
import EpowForm from "./EpowForm";
import AddressForm from "./address/AddressForm";
import {LoadingComponent} from "../../../constants/menu/Layout";
import FormRadio from "../input/FormRadio";
import {TRUE_FALSE_ENUM, YES_NO_OPTIONS} from "../../../constants/OptionConstants";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {FormikFieldSetter} from "../../../interfaces/FormikInterfaces";
import {PublicType} from "../../../constants/ResourceConstants";

interface CreateCustomerFormProps extends LoadingComponent {
  id?: string;
  className?: string;
  initialCustomer?: Customer;
  setCustomer?: (customer: Customer) => void;
  setEditInfo?: (editInfo: boolean) => void;
}

const CreateCustomerForm: FunctionComponent<CreateCustomerFormProps> = ({
  id,
  className = "",
  initialCustomer,
  setCustomer,
  setEditInfo,
  stopLoading,
  startLoading,
  closeForm = () => null,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const customerId = initialCustomer?.id;

  const handleSubmitCustomer = (customerFields: CustomerFields) => {
    const customerRequest: Customer = customerService.buildCustomerRequest(
      customerFields,
      initialCustomer
    );

    startLoading?.();
    if (customerId) {
      customerService
        .updateCustomer(customerId, customerRequest)
        .then((customer: Customer) => {
          toastUtils.successToast(
            intl.formatMessage({ id: "success_update_customer" })
          );
          setEditInfo(false);
          setCustomer(customer);
        })
        .catch(() => {
          toastUtils.errorToast(
            intl.formatMessage({ id: "error_toast_update_customer" })
          );
        }).finally(() => {
          stopLoading?.()
          closeForm()
        })
    } else {
      customerService
        .createCustomer(customerRequest)
        .then((customer) => {
          toastUtils.successToast(
            intl.formatMessage({ id: "success_create_customer" })
          );
          navigate(`${CUSTOMERS_PATH}/${customer?.id}`);
        })
        .catch(() => {
          toastUtils.errorToast(
            intl.formatMessage({ id: "error_toast_create_customer" })
          );
        }).finally(() => {
          stopLoading?.()
          closeForm()
        })
    }
  };

  const initialCustomerFields = customerService.buildCustomerFields(initialCustomer);
  const handleIndividualOnChange = (option: Option<string>, setFieldValue: FormikFieldSetter) => {
    setFieldValue("individual", option.value)
    if (option.value === TRUE_FALSE_ENUM.TRUE) {
      setFieldValue("geographicalZone", FRENCH_CUSTOMER)
      setFieldValue("publicType", PublicType.PRIVATE)
      setFieldValue("isReservationManagement", TRUE_FALSE_ENUM.TRUE)
      setFieldValue("disbursement", TRUE_FALSE_ENUM.TRUE)
    }
  }
  return (
    <Formik
      initialValues={initialCustomerFields || CUSTOMER_INITIAL_VALUES}
      validationSchema={CreateCustomerSchema}
      onSubmit={(value) => {
        handleSubmitCustomer(value);
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <EpowForm id={id} className={className}>
          <FormRadio
            id="individual"
            label="customer_individual_field"
            value={YES_NO_OPTIONS.find((option) => option?.value === values?.individual)}
            options={YES_NO_OPTIONS}
            onChange={(value) => handleIndividualOnChange(value, setFieldValue)}
            error={errors.individual}
            touched={touched.individual}
          />

          <FormInput
            id="name"
            label="customer_name_field"
            value={values?.name}
            onChange={(e) => setFieldValue("name", e.target.value)}
            required
            error={errors.name}
            touched={touched.name}
          />

          { values.individual === TRUE_FALSE_ENUM.TRUE && <FormInput
            id="firstname"
            label="customer_firstname_field"
            value={values?.firstname}
            onChange={(e) => setFieldValue("firstname", e.target.value)}
            required={values.individual === TRUE_FALSE_ENUM.TRUE}
            error={errors.firstname}
            touched={touched.firstname}
          />}

          <FormInput
            id="code"
            label="customer_code_field"
            value={values?.code}
            onChange={(e) => setFieldValue("code", e.target.value)}
            required
            error={errors.code}
            touched={touched.code}
          />
          <AddressForm
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
        </EpowForm>
      )}
    </Formik>
  );
};

export default CreateCustomerForm;
