import {ColorType} from "../../types/bootstrap/BootstrapType";
import {StateMap} from "./StateConstants";

export const enum VoucherStatesEnum {
  ADDED = "ADDED",
  MISSING = "MISSING",
  NOT_REQUIRED = "NOT_REQUIRED",
  VALIDATED = "VALIDATED"
}

export const VoucherStateMap: StateMap = {
  ADDED: {
    id: VoucherStatesEnum.ADDED,
    label: "billing_field_voucher_added",
    color: ColorType.WARNING,
  },
  MISSING: {
    id: VoucherStatesEnum.MISSING,
    label: "billing_field_voucher_missing",
    color: ColorType.DANGER,
  },
  NOT_REQUIRED: {
    id: VoucherStatesEnum.NOT_REQUIRED,
    label: "billing_field_voucher_missing",
    color: ColorType.SECONDARY,
  },
  VALIDATED: {
    id: VoucherStatesEnum.VALIDATED,
    label: "billing_field_voucher_validated",
    color: ColorType.PRIMARY,
  }
}
