import {BookingStatus, BookingTableDto, BookingToDoDeleteRequest} from "../../../../interfaces/BookingInterfaces";
import {SortOptions} from "../../../../interfaces/api/PaginationInterface";
import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {BookingFields, PurchasesFromBookingsRequest} from "../../../../interfaces/PurchaseInterfaces";
import {purchaseService} from "../../../../services/PurchaseService";
import BookingTableRow from "./BookingTableRow";
import {toastUtils} from "../../../../utils/toastUtils";
import {PURCHASES_PATH} from "../../../../constants/routes/RoutePaths";
import {useNavigate} from "react-router-dom";
import CustomTable from "../CustomTable";
import {Input} from "reactstrap";
import ButtonRowSelectAction from "../ButtonRowSelectAction";
import {CustomTableType} from "../../../../interfaces/TableInterfaces";
import ModalBookingsToDo from "../../modal/ModalBookingsToDo";
import Button from "../../../atoms/Button";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import {bookingService} from "../../../../services/BookingService";
import Panel from "components/molecules/panel/Panel";
import BookingDocumentForm from "components/molecules/form/booking/BookingDocumentForm";

interface BookingTableProps {
  className?: string,
  bookings: BookingTableDto[],
  status?: BookingStatus,
  sortOptions: SortOptions,
  onSortOptions: (sortOptions: SortOptions) => void,
  refresh?: () => void,
  defaultPendingBookingToOpenId?: string
}

const BookingTable: FunctionComponent<BookingTableProps> = ({
  className = "",
  bookings,
  sortOptions,
  onSortOptions,
  status,
  refresh = () => null,
  defaultPendingBookingToOpenId
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [bookingAsForms, setBookingAsForms] = useState<BookingFields[]>([]);
  const [rowOpen, setRowOpen] = useState<{[index: string]: boolean}>({})
  const [onCreateModalOpen, setOnCreateModalOpen] = useState<boolean>(false);
  const [formOpen, setFormOpen] = useState<boolean>();
  const [bookingTable, setBookingTable] = useState<BookingTableDto>();
  const [onDeleteModalOpen, setOnDeleteModalOpen] = useState<boolean>(false);
  const [multipleDelete, setMultipleDelete] = useState<boolean>(false);
  const [bookingToDeleteId, setBookingToDeleteId] = useState<string>();
  const [bookingFormPanelToOpen, setBookingFormPanelToOpen] = useState<string>(defaultPendingBookingToOpenId);
  const allBookingsSelected = bookingAsForms.every(bookingAsForm => bookingAsForm.isSelected)
  const atLeastOneBookingSelected = bookingAsForms.some(bookingAsForm => bookingAsForm.isSelected)

  useEffect(() => {
    setBookingAsForms(bookings.map(bookingTableDto => {
      return {
        isSelected: false,
        bookingId: bookingTableDto.id,
        quantity: bookingTableDto.quantity,
        supplierId: bookingTableDto.defaultSupplierId,
        comment: "",
        orderItemId: bookingTableDto.orderItemId
      }
    }))
  }, [bookings])

  const headerProps = {
    sortOptions,
    onSortOptions,
    className: "epow-custom-table-header",
  }

  const handleSelectAll = (allSelected: boolean) => {
    setBookingAsForms(bookingAsForms.map(bookingAsForm => {
      return ({
        ...bookingAsForm,
        isSelected: !allSelected,
      });
    }))
  }

  const handleFormValueChange = (bookingUpdated: BookingFields) => {
    const index = bookingAsForms.findIndex(selectedBooking => selectedBooking.bookingId === bookingUpdated.bookingId)
    if (index !== -1) {
      const updated = [...bookingAsForms]
      updated.splice(index, 1)
      setBookingAsForms([...updated, bookingUpdated])
    }
  }

  const createPurchaseRequest = () => {
    const purchasesRequest : PurchasesFromBookingsRequest = {
      bookings: bookingAsForms.filter(bookingAsForm => bookingAsForm.isSelected)
    }
    purchaseService.createPurchasesFromBookings(purchasesRequest)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_purchases_created"}))
        navigate(PURCHASES_PATH)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_purchases_created"}))
      })
  }

  const handleDeleteModalOpen = (multipleDelete: boolean, bookingId?: string) => {
    setBookingToDeleteId(bookingId);
    setOnDeleteModalOpen(true)
    setMultipleDelete(multipleDelete);
  };

  const onDeleteBookingsToDo = (bookingToDoDeleteRequest: BookingToDoDeleteRequest) => {
    bookingService.deleteBookingsToDo(bookingToDoDeleteRequest)
      .then(() => {
        setOnDeleteModalOpen(false);
        refresh();
        toastUtils.successToast(intl.formatMessage({id: "success_toast_delete_bookings_to_do"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_delete_bookings_to_do"}))
      })
  }

  const handleDeleteBookingToDo = () => {
    if (!!bookingToDeleteId) {
      const bookingToDelete = bookingAsForms.find(bookingAsForm => bookingAsForm.bookingId === bookingToDeleteId);
      const bookingToDoDeleteRequest: BookingToDoDeleteRequest = {
        bookingIds: Array.of(bookingToDelete.bookingId),
        orderItemIds: Array.of(bookingToDelete.orderItemId),
      }

      onDeleteBookingsToDo(bookingToDoDeleteRequest);
    }
  }

  const handleDeleteBookingsToDo = () => {
    const bookingsToDelete = bookingAsForms.filter(bookingAsForm => bookingAsForm.isSelected);
    const bookingToDoDeleteRequest: BookingToDoDeleteRequest = {
      bookingIds: bookingsToDelete.map(booking => booking.bookingId),
      orderItemIds: Array.from(new Set(bookingsToDelete.map(booking => booking.orderItemId)))
    }

    onDeleteBookingsToDo(bookingToDoDeleteRequest);
  }

  const handleKeep = () => {
    if (bookingTable.booking.id) {
      bookingService.keepBooking(bookingTable.booking.id)
        .then(() => {
          setFormOpen(false);
          toastUtils.successToast(intl.formatMessage({id: "success_toast_booking_keep"}));
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_keep_booking_document"}))
        })
    }
  }

  const deleteDocument = (documentId: string) => {
    bookingService.deleteDocumentById(documentId)
    .then(() => {
      refresh();
      setFormOpen(false);
      toastUtils.successToast(intl.formatMessage({id: "success_delete_document"}))
    })
    .catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "error_toast_delete_document"}))
    })
  }

  const handleCancel = () => {
    if (bookingTable.id) {
      bookingService.cancelBooking(bookingTable.id)
        .then(() => {
          setFormOpen(false);
          toastUtils.successToast(intl.formatMessage({id: "success_toast_booking_cancelled"}));
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_cancel_booking_document"}))
        })
    }
  }

  const handleValidate = () => {
    refresh();
    setFormOpen(false);
  }

  const bookingDocumentData = () => {
    return({
      isBilled: bookingTable?.booking?.isBilled,
      isPaid: bookingTable?.booking?.isPaid,
      invoiceDate: bookingTable?.booking?.invoiceDate,
      paymentDate: bookingTable?.booking?.paymentDate,
      reference: bookingTable?.booking?.reference,
      id: bookingTable?.id,
      orderCode: bookingTable?.orderCode,
      orderRef: bookingTable?.orderRef,
      orderCustomerName: bookingTable?.orderCustomerName,
      date: bookingTable?.date,
      hour: bookingTable?.hour,
      pax: bookingTable?.pax,
      quantity: bookingTable?.quantity,
      purchaseNumber: bookingTable?.purchase?.purchaseNumber,
      defaultSupplierName: bookingTable?.defaultSupplierName,
      serviceName: bookingTable?.serviceName,
      status: bookingTable?.booking?.status,
      supportingDocuments: bookingTable?.supportingDocuments,
      billDocuments: bookingTable?.billDocuments,
      paymentDocuments: bookingTable?.paymentDocuments,
      documentCount: bookingTable?.documentCount,
    })
  }

  const columns = useMemo(() => 
    status !== BookingStatus.CLOSED ? [
    {
      accessor: "id", title: "booking_service", ...headerProps,
      Cell: (<>
        <Input
          className="me-2"
          type="checkbox"
          checked={allBookingsSelected}
          onChange={() => handleSelectAll(allBookingsSelected)}
        />
        <span>{intl.formatMessage({id: "booking_service"})}</span>
      </>)
    },
    { accessor: "defaultSupplierName", title: "booking_default_supplier", ...headerProps },
    { accessor: "referenceCustomer", title: "booking_order_reference", ...headerProps },
    { accessor: "dateHour", title: "booking_date_hour", ...headerProps },
    { accessor: "orderItemPax", title: "booking_order_pax", ...headerProps },
    { accessor: "pax", title: "booking_pax", ...headerProps },
    { accessor: "actions", title: "table_actions", ...headerProps },]
     :
    [{ accessor: "id", title: "booking_service", ...headerProps },
    { accessor: "supplierName", title: "booking_supplier", ...headerProps },
    { accessor: "referenceCustomer", title: "booking_order_reference", ...headerProps },
    { accessor: "dateHour", title: "booking_date_hour", ...headerProps },
    { accessor: "quantity", title: "booking_only_quantity", ...headerProps },
    { accessor: "booking.totalPrice", title: "booking_price_ttc", ...headerProps },
    { accessor: "actions", title: "table_actions", ...headerProps },]
  , [allBookingsSelected, bookings, bookingAsForms])

  const managePanelCancel = () => {
    setBookingFormPanelToOpen(undefined)
    setFormOpen(false);
  }

  return (
    <div className={`epow-custom-table booking ${className ?? ""}`}>

      <CustomTable
        onSortOptions={onSortOptions}
        sortOptions={sortOptions}
        datas={bookings}
        type={CustomTableType.BOOKING}
        columns={columns}
        RowComponent={({data, index}) => {
          const booking = data as BookingTableDto;
          return <BookingTableRow
            key={data.id}
            index={index}
            booking={booking}
            setFormOpen={setFormOpen}
            setBooking={setBookingTable}
            status={status}
            bookingForm={bookingAsForms.find(bookingAsForm => bookingAsForm.isSelected && bookingAsForm.bookingId === data.id)}
            isSelected={bookingAsForms.some(bookingAsForm => bookingAsForm.isSelected && bookingAsForm.bookingId === data.id)}
            rowOpen={rowOpen}
            setRowOpen={setRowOpen}
            handleValueChange={handleFormValueChange}
            handleDeleteModalOpen={handleDeleteModalOpen}
            defaultPendingBookingToOpenId={bookingFormPanelToOpen}
          />;
        }}
       />

      {atLeastOneBookingSelected &&
        <div className="d-flex justify-content-end">
          <ButtonRowSelectAction
              onClick={() => setOnCreateModalOpen(true)}
              numberOfElements={bookingAsForms.filter(b => b.isSelected).length}
              label="booking_create_purchases"/>
          <Button className="ms-2 mb-4" onClick={() => handleDeleteModalOpen(true)} color={ColorType.DANGER}>
              <FormattedMessage id="booking_delete_to_do"/>
          </Button>
        </div>
      }

      <ModalBookingsToDo
        className={className}
        title={intl.formatMessage({id: "booking_create_purchases"})}
        open={onCreateModalOpen}
        setOpen={setOnCreateModalOpen}
        bookingAsForms={bookingAsForms}
        bookings={bookings}
        action={createPurchaseRequest}
        isSelectionModal
        modalBody="booking_request_modal_body"/>

      <ModalBookingsToDo
        className={className}
        title={intl.formatMessage({id: "booking_delete_to_do"})}
        open={onDeleteModalOpen}
        setOpen={setOnDeleteModalOpen}
        bookingAsForms={bookingAsForms}
        bookings={bookings}
        action={multipleDelete ? handleDeleteBookingsToDo : handleDeleteBookingToDo}
        isSelectionModal={multipleDelete}
        modalBody={multipleDelete ? "booking_body_multiple_delete_to_do" : "booking_body_single_delete_to_do"}/>
      
      {formOpen &&
        <Panel
            formId="bookingDocumentForm"
            className="border border-info"
            title={bookingTable.serviceName}
            open={formOpen}
            onCancel={managePanelCancel}
            cancelButtonLabel={status !== BookingStatus.CLOSED ? "cancel_button" : "close_button"}
            displayValidateButton={status !== BookingStatus.CLOSED}
        >
            <BookingDocumentForm
              id="bookingDocumentForm"
              bookingDocumentData={bookingDocumentData()}
              onValidate={handleValidate}
              onDeleteDocument={deleteDocument}
              onKeep={handleKeep}
              onCancel={handleCancel}
              />
        </Panel>
      }

    </div>
  )
}

export default BookingTable
