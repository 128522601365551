import React, {FunctionComponent, useMemo, useState} from "react";
import {RouteProps, useNavigate, useSearchParams} from "react-router-dom";
import Layout from "../../components/layout/Layout";
import PageTitle from "../../components/molecules/header/PageTitle";
import {useIntl} from "react-intl";
import Button from "../../components/atoms/Button";
import InvoicePaymentList from "../../components/molecules/list/InvoicePaymentList";
import useSearchFilter from "../../hooks/useSearchFilter";
import {InvoicePaymentSearchObject} from "../../interfaces/InvoicePaymentInterfaces";
import Panel from "../../components/molecules/panel/Panel";
import CustomerSelectorForm from "../../components/molecules/form/CustomerSelectorForm";
import {INVOICE_PAYMENTS_CREATE_PATH} from "../../constants/routes/RoutePaths";

const InvoicePaymentListView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  const [openedPanel, setOpenedPanel] = useState(false);
  const [params] = useSearchParams();

  const initialSearchState: InvoicePaymentSearchObject = useMemo(() => ({
    referenceNumber: "",
    customerId: "",
    paymentDate: "",
    fromCreditNote: false,
    invoiceId: params.get("invoiceId") ?? ""
  }), [])

  const {searchObject, onReset, updateSearchField} = useSearchFilter<InvoicePaymentSearchObject>(initialSearchState);
  const navigate = useNavigate()

  const handleSelectedCustomer = (customerId: string) => {
    setOpenedPanel(false)
    navigate(INVOICE_PAYMENTS_CREATE_PATH.replace(":customerId", customerId))
  }

  return (
    <Layout>
      <div className="epow-content-body">
        <div className="d-flex mb-5 justify-content-between">
          <PageTitle pageTitle={intl.formatMessage({id: "menu_invoice_payment"})}></PageTitle>
          <Button className="btn-header" onClick={() => setOpenedPanel(true)}>
            {intl.formatMessage({id: "invoice_payment_create"})}
          </Button>
        </div>

        <InvoicePaymentList
          searchObject={searchObject}
          onReset={() => onReset({invoiceId: ""})}
          updateSearchField={updateSearchField}
        />

      </div>

      <Panel
        title={intl.formatMessage({id: "invoice_payment_create"})}
        open={openedPanel}
        formId="customer"
        onCancel={() => setOpenedPanel(false)}
      >
        <CustomerSelectorForm handleSelectedCustomer={handleSelectedCustomer}/>
      </Panel>

    </Layout>
  );
}
export default InvoicePaymentListView;
