import React, {FunctionComponent, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {PLAN_ITEM_TABLE} from "../../../constants/Table";
import {tableUtils} from "../../../utils/tableUtils";
import Button from "../../atoms/Button";
import Panel from "../panel/Panel";
import PagedTable from "../table/PagedTable";
import {PagedTableRef} from "../table/PagedTableWithRowSelection";
import {toastUtils} from "../../../utils/toastUtils";
import CreatePlanItemForm from "../form/CreatePlanItemForm";
import {planItemService} from "../../../services/PlanItemService";
import {PlanItem} from "../../../interfaces/PlanItemInterfaces";

interface PlanItemListProps {
  className?: string;
}
const PlanItemList: FunctionComponent<PlanItemListProps> = ({
                                                      className = ""
                                                    }) => {
  const ref = useRef<PagedTableRef>()
  const intl = useIntl();
  const [PlanItemFormOpen, setPlanItemFormOpen] = useState<boolean>(false)
  const [selectedPlanItem, setSelectedPlanItem] = useState<PlanItem>(null)

  const onPlanItemEditionSelection = (planItem: PlanItem) => {
    setSelectedPlanItem(planItem)
    setPlanItemFormOpen(true)
  }

  const onPlanItemDeletionSelection = (planItem: PlanItem) => {
    planItemService.deletePlanItem(planItem.id)
      .then(() => toastUtils.successToast(intl.formatMessage({id : "plan_item_toast_delete_success"})))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id : "plan_item_toast_delete_error"})))
      .finally(() => ref.current.refresh())
  }

  const columns = useMemo(() => tableUtils.getColumns(
    PLAN_ITEM_TABLE(onPlanItemEditionSelection, onPlanItemDeletionSelection, intl)
  ), [intl]);

  const closeForm = () => {
    setPlanItemFormOpen(false)
    setSelectedPlanItem(null)
  }

  const onCreatePlanItem = () => {
    closeForm()
    ref.current.refresh()
  }

  return (
    <>
      <PagedTable
        ref={ref}
        columns={columns}
        labelNoResult="plan_item_no_result"
        className={`${className} table`}
        fetchData={planItemService.getPlanItems}
        filters={{}}
      />
      <Button onClick={() => setPlanItemFormOpen(true)}>
        {intl.formatMessage({id: "plan_item_create"})}
      </Button>

      {PlanItemFormOpen && (
        <Panel
          formId="createPlanItemForm"
          title={selectedPlanItem ? "plan_item_edit" : "plan_item_create"}
          open={PlanItemFormOpen}
          onCancel={() => closeForm()}
        >
          <CreatePlanItemForm
            id="createPlanItemForm"
            onCreatePlanItem={onCreatePlanItem}
            initialPlanItem={selectedPlanItem}
          />
        </Panel>
      )}
    </>
  )
}

export default PlanItemList;
