import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {Row, Col} from "reactstrap";
import ActionButtonBilling from "../../../components/molecules/billing/ActionButtonBilling";
import {BillingItem, Invoice, InvoiceState, InvoiceType} from "../../../interfaces/InvoiceInterfaces";
import {moneyUtils} from "../../../utils/moneyUtils";

interface BillingInvoiceAdditionalChargesProps {
  className?: string,
  invoice: Invoice,
  onDeleteBillingItem: (item: BillingItem) => void,
  onOpenUpdateModal: (item: BillingItem) => void,
}

const BillingInvoiceAdditionalCharges: FunctionComponent<BillingInvoiceAdditionalChargesProps> = ({
  className = "",
  invoice,
  onDeleteBillingItem,
  onOpenUpdateModal,
}) => {
  return (
    <div className={`${className} p-2`}>
      <div className="d-flex flex-row align-items-center mb-2">
        <h6><FormattedMessage id="billing_item_additionnal_charge" /></h6>
      </div>
      <div className="card p-3 mb-3">
        <Row className="fw-bold">
          <Col xs={11}><FormattedMessage id="invoice_billing_item_header_service" /></Col>
          <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_title" /></Col>
          <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_unit_price" /></Col>
          <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_price" /></Col>
          <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_priceTTC" /></Col>
          {invoice.type === InvoiceType.INVOICE &&
            <>
              <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_paid" /></Col>
              <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_remaining" /></Col>
            </>
          }
          {invoice.status === InvoiceState.DRAFT && <Col xs={2} />}
        </Row>
        {invoice.additionnalCharges.map((additionnalCharge) => (
          <Row className={`${additionnalCharge.disbursement ? "bg-danger" : ""} pt-2`}
            key={additionnalCharge.id}>
            <Col className={`${additionnalCharge.disbursement ?  "color-secondary": ""}`} xs={11}>{additionnalCharge.name}</Col>
            <Col className="text-end">{additionnalCharge.quantity}</Col>
            <Col className="text-end">{moneyUtils.formatNumberToCurrency(additionnalCharge.unitPriceHT)}</Col>
            <Col
              className="text-end">{moneyUtils.formatNumberToCurrency(additionnalCharge.amountHT)}</Col>
            <Col className={`${additionnalCharge.disbursement ?  "color-secondary": ""} text-end`}>{moneyUtils.formatNumberToCurrency(additionnalCharge.amountTTC)}</Col>
            {invoice.type === InvoiceType.INVOICE &&
              <>
                <Col className={`${additionnalCharge.amountRemaining == 0 ? "color-primary" : "color-warning"} text-end`}>{moneyUtils.formatNumberToCurrency(additionnalCharge.amountPaid)}</Col>
                <Col className={`${additionnalCharge.amountRemaining == 0 ? "color-primary" : "color-warning"} text-end`}>{moneyUtils.formatNumberToCurrency(additionnalCharge.amountRemaining)}</Col>
              </>
            }
            {invoice.status === InvoiceState.DRAFT &&
              <Col xs={2} className="d-flex justify-content-center">
                <div>
                  <ActionButtonBilling
                    onClickDelete={() => onDeleteBillingItem(additionnalCharge)}
                    onClickUpdate={() => onOpenUpdateModal(additionnalCharge)} />
                </div>
              </Col>}
          </Row>))
        }
      </div>
    </div>
  )
}

export default BillingInvoiceAdditionalCharges;
