import React, {FunctionComponent} from "react";
import {FileData, Resource} from "../../../../../interfaces/ResourceInterfaces";
import {resourceService} from "../../../../../services/ResourceService";
import {ColorType} from "../../../../../types/bootstrap/BootstrapType";
import AutocompleteFilterPageable from "../../../filters/AutocompleteFilterPageable";
import FilterGroup from "../../../filters/FilterGroup";
import {OrderItemSearchObject} from "../../../../../interfaces/OrderItemInterfaces";
import {FormattedMessage, useIntl} from "react-intl";
import useSearchFilter from "../../../../../hooks/useSearchFilter";
import {activityService} from "../../../../../services/ActivityService";
import {fileUtils} from "../../../../../utils/fileUtils";
import Button from "../../../../atoms/Button";
import DateFilter from "../../../filters/DateFilter";

interface AdminExportCsvCardProps {
  className?: string,
}

const AdminExportCsvCard: FunctionComponent<AdminExportCsvCardProps> = ({
  className,
}) => {
  const intl = useIntl()
  const {searchObject, updateSearchField, onReset} = useSearchFilter<OrderItemSearchObject>({})
  const [loading, setLoading] = React.useState<boolean>(false)

  const downloadExport = (searchObject) => {
    setLoading(true)
    const fileData: FileData = {name: "export-activity-bills", extension: "csv"}
    fileUtils.downloadFile(
      activityService.getActivitySummaryCsv(searchObject),
      fileData,
      intl,
      () => setLoading(false)
    )

  }

  return (
    <div className={`${className ?? ""}`}>

      <div className="fs-14"><FormattedMessage id="exports_resource_services" /></div>

      <FilterGroup className="mb-4">
        <DateFilter value={searchObject.startDate} onChangeDate={(value) => updateSearchField("startDate", value)} title={intl.formatMessage({id: "filter_from"})} />
        <DateFilter value={searchObject.endDate} onChangeDate={(value) => updateSearchField("endDate", value)} title={intl.formatMessage({id: "filter_to"})} />

        <AutocompleteFilterPageable
          title={intl.formatMessage({id: "billing_overview_resource"})}
          value={searchObject?.resourceIds ? searchObject?.resourceIds[0] : ""}
          onChange={(value) => updateSearchField("resourceIds", [value])}
          fetchData={resourceService.getResourcesPage}
          filterFieldName="nameSurname"
          manageOptions={(response: Resource[]) => response.map(option =>
            ({value: option.id, label: `${option.identity?.firstName} - ${option.identity?.lastName}`})
          )}
          optionFromValue={(response: Resource[], value: string) => {
            const option = response?.find((resource) => resource.id === value);
            return option ? {
              label: `${option.identity?.firstName} - ${option.identity?.lastName}`,
              value: option?.id
            } : null;
          }}
        />

        <div>
          <Button onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <Button onClick={() => downloadExport(searchObject)} color={ColorType.SECONDARY}>
        {loading && <span className="loader loader-button-group ms-1"/>}
        {intl.formatMessage({id: "exports_tab_summary"})}
      </Button>
    </div>
  )
}

export default AdminExportCsvCard;
