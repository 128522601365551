import {useEffect, useState} from "react";
import {toastUtils} from "../../utils/toastUtils";
import {useIntl} from "react-intl";
import {InvoicePaymentDetail} from "../../interfaces/InvoicePaymentInterfaces";
import {CommentRequest} from "../../interfaces/CommentInterface";
import {invoicePaymentService} from "../../services/InvoicePaymentService";


interface UseInvoicePaymentParams {
  id: string;
}


function useInvoicePayment({ id }: UseInvoicePaymentParams) {

  const intl = useIntl();
  const [invoicePayment, setInvoicePayment] = useState<InvoicePaymentDetail>(null);

  const fetchInvoicePayment = () => {
    invoicePaymentService.getInvoicePayment(id).then((invoicePayment) => {
      setInvoicePayment(invoicePayment);
    }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "invoice_error_message"}));
    })
  }

  const createComment = (comment: CommentRequest) => invoicePaymentService.createComment(invoicePayment.id, comment.content)
    .then((newInvoicePayment) => {
      setInvoicePayment(newInvoicePayment);
      toastUtils.successToast(
        intl.formatMessage({id: "comment_toast_success_creation"})
      )
    })
    .catch(() => {
      toastUtils.errorToast(
        intl.formatMessage({ id: "comment_toast_error_creation" })
      )
    });

  const deleteComment = () => invoicePaymentService.deleteComment(invoicePayment.id).then(() => {
    setInvoicePayment({...invoicePayment, comment: null});
    toastUtils.successToast(
      intl.formatMessage({id: "comment_toast_success_deletion"})
    )
  }).catch(() => {
    toastUtils.errorToast(
      intl.formatMessage({id: "comment_toast_error_deletion"})
    )
  });

  useEffect(() => {
    fetchInvoicePayment()
  }, []);

  return {
    invoicePayment,
    fetchInvoicePayment,
    createComment,
    deleteComment,
  }
}

export default useInvoicePayment;
