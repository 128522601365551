import {WorkflowStatesEnum} from "../constants/workflow/WorkflowStates";
import {SearchFilter} from "./api/FiltersInterface";
import {OrderBasicDto} from "./OrderInterfaces";
import {Data} from "./TableInterfaces";
import {StateMap} from "../constants/workflow/StateConstants";
import {ColorType} from "../types/bootstrap/BootstrapType";
import {SimpleStaffing, StaffedResource} from "./ActivityStaffingInterface";
import {Customer} from "./CustomerInterfaces";
import {Tour} from "./TourInterfaces";
import {Service} from "./ServiceInterfaces";
import {ActivityStaffingStatus} from "../constants/ActivityStaffingConstants";
import {DocumentDTO} from "./FileInterfaces";
import {BookingShortDto} from "./BookingInterfaces";
import {FileType} from "../constants/FileConstants";
import {ResourceType} from "./ResourceTypeInterfaces";
import {VoucherStatesEnum} from "../constants/voucher/VoucherStates";
import {TourLeaderContract} from "./TourLeaderInterfaces";


export interface OrderMail {
  template: string,
  emailCc: string;
  files: File[]
}

export interface OrderItem extends Data {
  order?: OrderBasicDto,
  activityId?: string,
  service?: Service,
  serviceId?: string,
  type?: ORDER_ITEM_TYPE,
  reference?: string,
  name?: string,
  rendezVousPlace?: string,
  rendezVousDate?: string,
  rendezVousEndHour?: string,
  rendezVousHour?: string,
  departureHour?: string,
  pax?: number,
  paxConfirmed?: boolean,
  quantity?: number,
  optional?: boolean,
  staffingManagement?: boolean,
  bookingManagement?: boolean,
  orderItemResources?: OrderItemResourceDTO[];
  unitType?: UNIT_TYPE,
  paxPrice?: number,
  sellPrice?: number,
  description?: string,
  tour?: Tour,
  sellPriceTVA?: number,
  billingService?: Customer,
  billingServiceId?: string,
  disbursements?: boolean,
  showActivityTourLeader?: boolean,
  noAvailableSupplier?: boolean,
  staffingStatus?: WorkflowStatesEnum,
  bookingStatus?: WorkflowStatesEnum,
  billingStatus?: OrderItemBillingStatus,
  status?: string,
  duration?: number,
  resourceStaffing?: StaffedResource[],
  simpleStaffingList: Array<SimpleStaffing>,
  bookings?: BookingShortDto[],
  bookingDocuments?: DocumentDTO[],
  additionalDocuments: Array<DocumentDTO>,
  canAddTicket: boolean,
}

export interface OrderItemDetailsResponse extends OrderItemResponse {
  tourLeaders: TourLeaderContract[]
  documents: Array<DocumentDTO>,
  additionalDocuments: Array<DocumentDTO>,
}

export interface OrderItemResourceDTO {
  id?: string;
  quantity?: number;
  resourceType?: ResourceType;
}

export interface OrderItemResponse extends Data {
  order: OrderBasicDto,
  customerName: string,
  customerId: string,
  billingServiceId: string,
  billingServiceName: string,
  activityId: string,
  serviceId: string,
  rendezVousDate: string,
  rendezVousPlace: string,
  rendezVousHour: string,
  departureHour: string,
  type: string,
  reference: string,
  name: string,
  description: string,
  optional: boolean,
  staffingManagement: boolean,
  bookingManagement:boolean,
  pax: number,
  quantity: number,
  orderItemResources?: OrderItemResourceDTO[],
  unitType: string,
  paxPrice: number,
  sellPrice: number,
  sellPriceTVA: number,
  disbursements: boolean,
  isValidated: boolean,
  orderNumber: string,
  duration: number,
  simpleStaffingList: Array<SimpleStaffing>,
  resourceStaffing: StaffedResource[],
  parallelStaffings: Array<SimpleStaffing>,
  voucherStatus: VoucherStatesEnum,
  bookingDocuments?: DocumentDTO[],
  status: OrderItemState,
}

export interface OrderItemStaffing {
  id: string,
  order: OrderBasicDto,
  name: string,
  rendezVousDate: string,
  rendezVousHour: string,
  rendezVousPlace: string,
  duration: number,
  orderItemResources?: OrderItemResourceDTO[],
  billed: boolean
}

export interface OrderItemStaffingPageItemDto extends OrderItemStaffing {
  resourceStaffing: StaffedResource[],
  staffingStatus: OrderItemStaffingStatus,
}

export interface OrderItemDayStaffingPageItemDto {
  id: string,
  orderId: string,
  orderItemId: string,
  resourceName: string,
  activityName: string,
  rendezVousHour: string,
  departureHour: string,
  rendezVousDate: string,
  duration: number,
  customerReference: string,
  resourceType?: ResourceType;
}

export interface OrderItemStaffingRequest extends OrderItemStaffing {
  activityStaffingStatus: ActivityStaffingStatus,
  activityStaffingId: string,
}

export interface OrderItemRequest extends Data {
  order?: string,
  activity?: string,
  service?: string,
  type?: string,
  name?: string,
  title?: string
  place?: string,
  date?: string,
  hour?: string,
  departureHour?: string,
  pax?: number,
  isPaxConfirmed?: boolean,
  quantity?: number,
  optional?: boolean,
  isStaffingManagement?: boolean,
  orderItemResources?: OrderItemResourceDTO[],
  unitType?: string,
  paxPrice?: number,
  sellPrice?: number,
  isBookingManagement?: boolean,
  showActivityTourLeader?: boolean,
  description?: string,
  sellPriceTVA?: number,
  billingService?: string,
  disbursements?: boolean,
  staffingStatus?: OrderItemStaffingStatus,
  bookingStatus?: WorkflowStatesEnum,
  billingStatus?: WorkflowStatesEnum,
}

export interface OrderItemCancellationRequest {
  cancellationDate: string,
}

export interface OrderItemFileRequest {
  fileType: FileType
}

export const enum ORDER_ITEM_TYPE {
  ACTIVITY = "ACTIVITY",
  SERVICE = "SERVICE",
}

export const enum UNIT_TYPE {
  PAX = "PAX",
  FIXED_RATE = "FIXED_RATE",
}

export const enum OrderItemState {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  VALIDATED = "VALIDATED",
  CANCELLED = "CANCELLED",
}

export const enum OrderItemBillingStatus {
  TODO = "TODO",
  DONE = "DONE",
}

export const enum OrderItemStaffingStatus {
  TO_STAFF = "TO_STAFF",
  DISPONIBILITY_REQUEST_SENT = "DISPONIBILITY_REQUEST_SENT",
  STAFFED = "STAFFED",
}

export const OrderItemStateMap: StateMap = {
  DRAFT: {
    id: OrderItemState.DRAFT,
    label: "order_item_state_draft",
    color: ColorType.SECONDARY,
  },
  PENDING: {
    id: OrderItemState.PENDING,
    label: "order_item_state_pending",
    color: ColorType.WARNING,
  },
  VALIDATED: {
    id: OrderItemState.VALIDATED,
    label: "order_item_state_validated",
    color: ColorType.PRIMARY,
  },
  CANCELLED: {
    id: OrderItemState.CANCELLED,
    label: "order_item_state_cancelled",
    color: ColorType.DARK_GRAY,
  }
}

export interface OrderItemSearchObject extends SearchFilter {
  supplierId?: string,
  serviceId?: string,
  activityIds?: string[],
  customersIds?: string[],
  resourceIds?: string[],
  activityAreaIds?: string[],
  ids?: string[],
  isStaffingManagement?: boolean,
  orderStatus?: string,
  status?: string[],
  staffingStatus?: string,
  startDate?: string,
  endDate?: string,
  startOrderDate?: string,
  endOrderDate?: string,
  codeOrReference?: string,
  billingServicesIds?: string[],
  checkHasStaffing?: boolean,
  orderIds?: string[],
  activityStaffingStatus?: string,
  resourceTypeIds?: string[],
  paymentId?: string
  voucherStatus?: VoucherStatesEnum,
  customerOrCode?: string,
}

export interface CustomerOrderItemSearchObject extends SearchFilter {
  customerId?: string,
  status?: string[],
  date?: string,
}

export interface OrderItemWithActivityBillingDto extends Data {
  activityStaffingId?: string,
  billId?: string,
  date?: string,
  name?: string,
  activityDuration?: number,
  price?: number,
  billed?: boolean,
  billNumber?: string,
  billDate?: string,
  billFileId?: string,
}

export interface RemoveVoucherFromOrderItemRequest {
  template: string,
}
