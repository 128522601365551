import {lowerCase} from "lodash";
import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Col} from "reactstrap";
import {ActivityStaffingStatus, ActivityStaffingStatusColor} from "../../../../constants/ActivityStaffingConstants";
import {StaffedResource} from "../../../../interfaces/ActivityStaffingInterface";
import {orderItemService} from "../../../../services/OrderItemService";
import {ColorType, IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../../utils/toastUtils";
import Badge from "../../../atoms/Badge";
import Button from "../../../atoms/Button";
import Icon from "../../../icon/Icon";
import GenericModal from "../../modal/GenericModal";
import {ResourceStaffingRequest} from "../../../../interfaces/ResourceStaffingInterfaces";
import StaffingFormStepTwo from "../../form/staffing/StaffingFormStepTwo";
import Panel from "../../panel/Panel";
import ResourceCircle from "../../circle/ResourceCircle";

interface StaffingPendingTableRowStaffingProps {
  className?: string,
  resource: StaffedResource,
  onStaffingChange: () => void,
}

const StaffingPendingTableRowStaffing: FunctionComponent<StaffingPendingTableRowStaffingProps> = ({
  className = "",
  resource,
  onStaffingChange,
}) => {
  const intl = useIntl()
  const [staffingDeleteModalOpen, setStaffingDeleteModalOpen] = useState<boolean>(false)
  const [renewStaffingModalOpen, setRenewStaffingModalOpen] = useState<boolean>(false)
  const [staffingRequests, setStaffingRequests] = React.useState<ResourceStaffingRequest[]>([{
    resourceId: resource.resourceId,
    message: "",
    status: ActivityStaffingStatus.SENT
  }])

  const sendStaffingRequest = () => {
    const request = {
      ...staffingRequests[0],
      status: ActivityStaffingStatus.SENT
    };
    orderItemService.updateStaffingForOrderItem(resource.orderItemId, resource.staffingId, request).then(() => {
        onStaffingChange()
        setRenewStaffingModalOpen(false)
        toastUtils.successToast(intl.formatMessage({id: "success_toast_staffing_update"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_staffing_update"}))
      })
  }

  const updateStaffing = () => {
    const request: ResourceStaffingRequest = {
      resourceId: resource.resourceId,
      status: ActivityStaffingStatus.CONFIRMED
    }
    orderItemService.updateStaffingForOrderItem(resource.orderItemId, resource.staffingId, request)
      .then(() => {
        onStaffingChange()
        toastUtils.successToast(intl.formatMessage({id: "success_toast_staffing_update"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_staffing_update"}))
      })
  }

  const cancelStaffing = () => {
    orderItemService.deleteStaffingForOrderItem(resource.orderItemId, resource.staffingId)
      .then(() => {
        onStaffingChange()
        setStaffingDeleteModalOpen(false)
        toastUtils.successToast(intl.formatMessage({id: "success_toast_staffing_update"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_staffing_update"}))
      })
  }

  return (
    <div className={className}>
      <Col xs={5} className="d-flex align-items-center">
        <Icon
          className={`m-0 ms-4 me-2 ${resource.status === ActivityStaffingStatus.CONFIRMED ? "icon-green" : "icon-gray"}`}
          name="CheckCircle"
          size={IconSizeType.XS}
        />
        {`${resource.firstName} ${resource.lastName}`}
        <ResourceCircle className="ms-2" resourceType={resource.resourceType} />
      </Col>

      <Col xs={5}>
        <Badge color={ActivityStaffingStatusColor[resource.status]}><FormattedMessage id={`staffing_${lowerCase(resource.status)}`} /></Badge>
      </Col>

      <Col xs={14}>
        <div className="float-end">
          {resource.status === ActivityStaffingStatus.CONFIRMED && (
            <Button className="pending-staffing-action-button" onClick={() => setStaffingDeleteModalOpen(true)} color={ColorType.SECONDARY}>
              <FormattedMessage id="cancel_button" />
            </Button>
          )}

          {resource.status === ActivityStaffingStatus.AVAILABLE && (
            <Button className="pending-staffing-action-button" onClick={updateStaffing}>
              <FormattedMessage id="staffing_confirm" />
            </Button>
          )}

          {(resource.status === ActivityStaffingStatus.SENT || resource.status === ActivityStaffingStatus.CANCELLED || resource.status === ActivityStaffingStatus.REFUSED) && (
            <>
              <Button className="pending-staffing-action-button me-2" onClick={updateStaffing}>
                <FormattedMessage id="staffing_confirm" />
              </Button>
              <Button className="pending-staffing-action-button" onClick={() => setRenewStaffingModalOpen(true)} color={ColorType.SECONDARY}>
                <FormattedMessage id="staffing_renew" />
              </Button>
            </>
          )}
        </div>
      </Col>

      {staffingDeleteModalOpen && (
        <GenericModal
          className={className}
          title={intl.formatMessage({id: "modal_title_delete_staffing"})}
          open={staffingDeleteModalOpen}
          onOpen={setStaffingDeleteModalOpen}
          closable
          footer={{
            primaryAction: {
              buttonLabel: intl.formatMessage({id: "validate_button"}),
              action: () => cancelStaffing()
            },
            secondaryAction: {
              buttonLabel: intl.formatMessage({id: "cancel_button"}),
              action: () => setStaffingDeleteModalOpen(false)
            }
          }}
        >
          {intl.formatMessage({id: "modal_body_delete_staffing"})}
        </GenericModal>
      )}

      {renewStaffingModalOpen && (
        <Panel
          title={`Staffing ${resource.firstName}`}
          cancelButtonLabel="close"
          displayValidateButton={false}
          open={renewStaffingModalOpen}
          onCancel={() => setRenewStaffingModalOpen(false)}>
            <div className="d-flex flex-column w-100">
              <StaffingFormStepTwo
                selectedResources={[{id: resource.resourceId, firstName: resource.firstName, lastName: resource.lastName}]}
                resourceRequests={staffingRequests}
                setResourceRequests={setStaffingRequests}
              />
              <div className="ms-auto">
                <Button className="py-0" onClick={sendStaffingRequest} >
                  <span>{intl.formatMessage({id: "send_button"})}</span>
                </Button>
              </div>
            </div>
        </Panel>
      )}
    </div >
  )
}

export default StaffingPendingTableRowStaffing;
