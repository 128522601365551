import React, {FunctionComponent} from "react";
import {BillingItem, Invoice, InvoiceOrder, InvoiceState, InvoiceType} from "../../../interfaces/InvoiceInterfaces";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Row} from "reactstrap";
import ActionButtonBilling from "../../../components/molecules/billing/ActionButtonBilling";
import {dateUtils} from "../../../utils/dateUtils";
import {moneyUtils} from "../../../utils/moneyUtils";
import Badge from "../../../components/atoms/Badge";
import {OrderItemState, OrderItemStateMap} from "../../../interfaces/OrderItemInterfaces";
import Icon from "../../../components/icon/Icon";
import Tooltip from "../../../components/atoms/Tooltip";

interface BillingInvoiceOrderDetailsProps {
  className?: string,
  invoiceOrder: InvoiceOrder,
  invoice: Invoice,
  onDeleteBillingItem: (item: BillingItem) => void,
  onOpenUpdateModal: (item: BillingItem) => void,
}

const BillingInvoiceOrderDetails: FunctionComponent<BillingInvoiceOrderDetailsProps> = ({
  className = "",
  invoiceOrder,
  invoice,
  onDeleteBillingItem,
  onOpenUpdateModal,
}) => {
   const intl = useIntl();

  return (
    <div className={`${className} p-2`}>
      <div className="d-flex flex-row align-items-center mb-2">
        <h6>Code Tour: {invoiceOrder.refCustomer || invoiceOrder.orderNumber}</h6>
      </div>
      <div className="card p-3 mb-3">
        <Row className="fw-bold">
          <Col xs={3}><FormattedMessage id="invoice_billing_item_header_date" /></Col>
          <Col xs={5}><FormattedMessage id="invoice_billing_item_header_service" /></Col>
          <Col xs={2} className="text-end"></Col>
          <Col xs={2} className="text-end"><FormattedMessage id="invoice_billing_item_header_resource" /> /<FormattedMessage id="invoice_billing_item_header_title" /></Col>
          <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_unit_price" /></Col>
          <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_price" /></Col>
          <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_priceTTC" /></Col>
          {invoice.type === InvoiceType.INVOICE &&
            <>
              <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_paid"/></Col>
              <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_remaining" /></Col>
            </>
          }
          {invoice.status !== InvoiceState.DRAFT && <Col xs={1}/>}
          {invoice.status === InvoiceState.DRAFT && <Col xs={1}/>}
        </Row>
        {invoiceOrder.billingItemDetails.map((billingItem) => (
          <>
            <Row className={`${billingItem.disbursement ? "bg-danger" : ""} pt-2`}
              key={billingItem.id}>
              <Col xs={3}>{dateUtils.formatDateDayJs(billingItem.date)}</Col>
              <Col xs={5}>{billingItem.name}</Col>
              <Col xs={2} className="text-end">
                { billingItem.status === OrderItemState.CANCELLED && <Badge pill color={OrderItemStateMap[billingItem.status]?.color}>
                  <FormattedMessage id={OrderItemStateMap[billingItem.status]?.label}/>
                </Badge>}
              </Col>
              { billingItem.showActivityTourLeader
                ? <Col xs={2} className={`text-end ${billingItem.quantity !== billingItem.resourceConfirmedCount ? "color-danger fw-bold": ""}`}>{billingItem.resourceConfirmedCount}/{billingItem.quantity}</Col>
                : <Col xs={2} className="text-end">-/{billingItem.quantity}</Col>
              }
              <Col className="text-end">{moneyUtils.formatNumberToCurrency(billingItem.unitPriceHT, 2)}</Col>
              <Col className="text-end">{moneyUtils.formatNumberToCurrency(billingItem.amountHT, 2)}</Col>
              <Col className="text-end">{moneyUtils.formatNumberToCurrency(billingItem.amountTTC, 2)}</Col>
              {invoice.type === InvoiceType.INVOICE &&
                <>
                  <Col className={`${billingItem.amountRemaining == 0 ? "color-primary" : "color-warning"} text-end`}>{moneyUtils.formatNumberToCurrency(billingItem.amountPaid, 2)}</Col>
                  <Col className={`${billingItem.amountRemaining == 0 ? "color-primary" : "color-warning"} text-end`}>{moneyUtils.formatNumberToCurrency(billingItem.amountRemaining, 2)}</Col>
                </>
              }
              {invoice.status !== InvoiceState.DRAFT &&
              <Col xs={1}>{billingItem.fromCreditNote
                ? <Tooltip text={intl.formatMessage({id: "invoice_billing_item_header_credit_note"},{credit_note: billingItem.creditNote})}>
                    <Icon name="InfoCircle"/>
                  </Tooltip>
                : <span></span>}
              </Col> }
              {invoice.status === InvoiceState.DRAFT && <Col xs={1} className="d-flex justify-content-center">
                <div>
                  <ActionButtonBilling
                    onClickDelete={() => onDeleteBillingItem(billingItem)}
                    onClickUpdate={() => onOpenUpdateModal(billingItem)} />
                </div>
              </Col>}
            </Row>

            {billingItem.billingSubItemDetails.map((billingSubItems) => (
              <Row className="pt-2" key={billingSubItems.id}>
                <Col xs={3}></Col>
                <Col xs={5}><p className={`${billingItem.disbursement ? "color-secondary" : ""} ms-3 mb-0`}>{billingSubItems.name}</p></Col>
                <Col xs={2}></Col>
                <Col xs={2} className="text-end">-/{billingSubItems.quantity}</Col>
                <Col className="text-end">{moneyUtils.formatNumberToCurrency(billingSubItems.unitPriceHT, 2)}</Col>
                <Col className="text-end">{moneyUtils.formatNumberToCurrency(billingSubItems.amountHT, 2)}</Col>
                <Col className={`${billingItem.disbursement ? "color-secondary" : ""} text-end`}>{moneyUtils.formatNumberToCurrency(billingSubItems.amountTTC, 2)}</Col>
                {invoice.type === InvoiceType.INVOICE &&
                  <>
                    <Col className={`${billingSubItems.amountRemaining == 0 ? "color-primary" : "color-warning"} text-end`}>{moneyUtils.formatNumberToCurrency(billingSubItems.amountPaid, 2)}</Col>
                    <Col className={`${billingSubItems.amountRemaining == 0 ? "color-primary" : "color-warning"} text-end`}>{moneyUtils.formatNumberToCurrency(billingSubItems.amountRemaining, 2)}</Col>
                  </>
                }
                {invoice.status !== InvoiceState.DRAFT &&
                  <Col xs={1}>{billingSubItems.fromCreditNote
                    ? <Tooltip text={intl.formatMessage({id: "invoice_billing_item_header_credit_note"}, {credit_note: billingSubItems.creditNote})}>
                        <Icon name="InfoCircle"/>
                      </Tooltip>
                    : <span></span>}
                  </Col>
                }
                {invoice.status === InvoiceState.DRAFT &&
                  <Col xs={1} className="d-flex justify-content-center">
                    <div>
                      <ActionButtonBilling
                        onClickDelete={() => onDeleteBillingItem(billingSubItems)}
                        onClickUpdate={() => onOpenUpdateModal({...billingSubItems, orderItemId: billingItem.orderItemId})} />
                    </div>
                  </Col>}
              </Row>))}
          </>
        ))}
      </div>
    </div>
  )
}

export default BillingInvoiceOrderDetails;
