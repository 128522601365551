import {API_ADMIN_CONFIG_PATH, API_ADMIN_MAIL_CONFIG_PATH} from "../constants/routes/RoutePaths";
import {AdminConfigurationDto, AdminConfigurationMailDto} from "../interfaces/AdminConfigurationInterfaces";
import {httpService} from "./HttpService"

const getAdminConfiguration = (): Promise<AdminConfigurationDto> => {
  return httpService.get<AdminConfigurationDto>(API_ADMIN_CONFIG_PATH)
}

const updateAdminConfiguration = (updatedConfiguration: AdminConfigurationDto): Promise<AdminConfigurationDto> => {
  return httpService.put<AdminConfigurationDto>(`${API_ADMIN_CONFIG_PATH}`, updatedConfiguration);
}

const getAdminConfigurationMail = (): Promise<AdminConfigurationMailDto> => {
  return httpService.get<AdminConfigurationMailDto>(API_ADMIN_MAIL_CONFIG_PATH)
}

const updateAdminConfigurationMail = (updatedConfiguration: AdminConfigurationMailDto): Promise<AdminConfigurationMailDto> => {
  return httpService.put<AdminConfigurationMailDto>(`${API_ADMIN_MAIL_CONFIG_PATH}`, updatedConfiguration);
}

export const adminConfigurationService = Object.freeze({
  getAdminConfiguration,
  updateAdminConfiguration,
  getAdminConfigurationMail,
  updateAdminConfigurationMail
})
