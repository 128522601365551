import React, {FunctionComponent, useEffect, useState} from "react";
import {Formik} from "formik";
import FormInput from "../input/FormInput";
import {toastUtils} from "../../../utils/toastUtils";
import {useLocation, useNavigate} from "react-router-dom";
import {Order, OrderField, OrderState} from "interfaces/OrderInterfaces";
import {orderService} from "services/OrderService";
import {ORDER_INITIAL_VALUES} from "constants/OrderConstants";
import {customerService} from "services/CustomerService";
import {CreateOrderSchema} from "constants/validation/OrderValidationSchemas";
import {ORDERS_PATH} from "constants/routes/RoutePaths";
import {dateUtils} from "../../../utils/dateUtils";
import {tourService} from "../../../services/TourService";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import {useIntl} from "react-intl";
import {tourLeaderService} from "../../../services/TourLeaderService";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import EpowForm from "./EpowForm";
import {YES_NO_OPTIONS} from "../../../constants/OptionConstants";
import FormRadio from "../input/FormRadio";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {LoadingComponent} from "../../../constants/menu/Layout";
import FormAutocompleteMultiselect from "../input/FormAutocompleteMultiSelect";
import {customerContactService} from "../../../services/CustomerContactService";
import {Contact} from "../../../interfaces/ContactInterfaces";

interface CreateOrderFormProps extends LoadingComponent {
  id?: string,
  className?: string,
  order?: Order,
  afterSubmit?: () => void,
  onBeforeSubmit?: () => void
  onValidate?: (order: Order) => void
}

const CreateOrderForm: FunctionComponent<CreateOrderFormProps> = ({
  id,
  className = "",
  order,
  afterSubmit = () => null,
  onBeforeSubmit = () => null,
  onValidate = () => null,
  startLoading,
  stopLoading,
  closeForm = () => null,
}) => {
  const intl = useIntl()
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const [alreadyExistRef, setAlreadyExistRef] = useState<boolean>(false);
  const [customerContacts, setCustomerContacts] = useState<Contact[]>([])


  const handleSubmitOrder = (orderFields: OrderField) => {
    const orderRequest = orderService.buildOrderRequest(orderFields, customerContacts)
    onBeforeSubmit()
    startLoading?.()
    if (order?.id) {
      orderService.updateOrder(order.id, orderRequest)
        .then((order => {
          onValidate(order)
          toastUtils.successToast(intl.formatMessage({id: "success_toast_update_order"}));
        }))
        .catch(() => {
          setAlreadyExistRef(true)
          afterSubmit()
        }).finally(() => {
          afterSubmit()
          stopLoading?.()
          closeForm()
        })
    } else {
      orderService.createOrder(orderRequest)
        .then((order: Order) => {
          if (pathname.includes(`${ORDERS_PATH}/`)) {
            window.location.href = `${ORDERS_PATH}/${order?.id}`
          } else {
            navigate(`${ORDERS_PATH}/${order?.id}`)
          }
        })
        .catch(() => {
          setAlreadyExistRef(true)
        }).finally(() => {
        afterSubmit()
        stopLoading?.()
        closeForm()
      })
    }
  }

  useEffect(() => {
    if (order?.customer?.id) {
      customerContactService.getAllContactsByCustomerIdPage(order?.customer.id, {pageSize: 50, page: 0}, null)
        .then((contacts) => setCustomerContacts(contacts.content))
        .catch(() => toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_customer_contacts"})))
    }
  }, []);

  const getDefaultValues = () => {
    if (!order?.id) {
      return ORDER_INITIAL_VALUES
    }

    return {
      id: order.id,
      customerId: order.customer?.id,
      customerReference: order.customerReference,
      dayZeroTour: dateUtils.formatDateYYYYMMDD(dateUtils.convertStringToObjectDate(order.beginTourDate)),
      date: dateUtils.formatDateYYYYMMDD(dateUtils.convertStringToObjectDate(order.date)),
      orderNumber: order.orderNumber,
      tourId: order.tour?.id,
      tourLeaderIds: order?.tourLeaders.map(tl => ({
        label: `${tl.identity.firstName} ${tl.identity.lastName}`,
        value: tl.id
      })) || [],
      defaultPax: order.defaultPax,
      paxConfirmed: order.paxConfirmed,
      isValidated: true,
      shouldUpdateOrderItemPax: order.shouldUpdateOrderItemPax,
      orderComment: order.orderComment,
      managerContacts: order.managerContacts.map(contact => ({
        value: contact.id,
        label: `${contact.identity.firstName} ${contact.identity.lastName}`
      })),
    }
  }

  return (
    <Formik initialValues={getDefaultValues()} validationSchema={CreateOrderSchema} onSubmit={value => handleSubmitOrder(value)}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={`${className}`}>
          <FormAutocompleteSelectPageable
            id="customer"
            label={intl.formatMessage({id: "order_customer"})}
            placeholder={intl.formatMessage({id: "customer_placeholder"})}
            onChange={(value) => setFieldValue("customerId", value)}
            value={values?.customerId}
            fetchData={(page, filter) => customerService.getCustomersPage(page, {
              ...filter,
              currentState: WorkflowStatesEnum.ACTIVE
            })}
            filterFieldName="fullName"
            filterFieldSearch="name"
            required
            error={errors?.customerId}
            touched={touched?.customerId}
            readOnly={!!order && order?.orderStatus !== OrderState.DRAFT}
          />
          <FormAutocompleteSelectPageable
            id="tour"
            label={intl.formatMessage({id: "order_tour"})}
            placeholder={intl.formatMessage({id: "tour_placeholder"})}
            onChange={(value) => setFieldValue("tourId", value)}
            value={values?.tourId}
            fetchData={(page, filter) => tourService.getToursPage(page, {
              ...filter,
              customerId: values?.customerId,
              currentState: WorkflowStatesEnum.ACTIVE
            })}
            filterFieldName="fullName"
            filterFieldSearch="tourName"
            error={errors?.tourId}
            touched={touched?.tourId}
            readOnly={!!order && order?.orderStatus !== OrderState.DRAFT}
          />
          {order?.customer.managerContactsEnabled && (
            <FormAutocompleteMultiselect
              id="managerContactsList"
              label={intl.formatMessage({id: "order_customer_contacts_allowed_to_see_order"})}
              values={values.managerContacts}
              onChange={(selectedOptions) => setFieldValue("managerContacts", selectedOptions)}
              required
              fetchData={(inputValue) => customerContactService.getAllContactsByCustomerIdPage(
                order?.customer.id,
                {pageSize: 50, page: 0},
                {nameSurname: inputValue})}
              filterFieldName="fullName"
            />
          )}
          <FormInput
            id="refClient"
            label="order_referenceClient"
            value={values?.customerReference}
            onChange={(e) => {
              if(alreadyExistRef) {
                setAlreadyExistRef(false)
              }
              setFieldValue("customerReference", e.target?.value)
            }}
            error={errors.customerReference || (alreadyExistRef && "already_exists_customer_ref")}
            touched={touched.customerReference}
          />
          <FormAutocompleteMultiselect
            id="tourLeader"
            label={intl.formatMessage({id: "order_tour_leader"})}
            placeholder={intl.formatMessage({id: "tour_leader_search_placeholder"})}
            onChange={(option) => setFieldValue("tourLeaderIds", option)}
            values={values?.tourLeaderIds}
            fetchData={(inputValue) => tourLeaderService.getTourLeaderPage({pageSize: 50, page: 0},{
              nameSurname: inputValue,
              customerId: order?.customer.id,
              currentState: WorkflowStatesEnum.ACTIVE
            })}
            filterFieldName="fullName"
            error={errors?.tourLeaderIds}
            touched={touched?.tourLeaderIds}
          />
          <FormInput
            id="dateBegin"
            label="order_dayZero"
            type="date"
            minValue={dateUtils.formatDateYYYYMMDD() && dateUtils.formatDateYYYYMMDD(dateUtils.convertStringToObjectDate(values?.date))}
            required
            value={values?.dayZeroTour}
            onChange={(e) => setFieldValue("dayZeroTour", e.target?.value)}
            error={errors.dayZeroTour}
            touched={touched.dayZeroTour}
          />
          <FormInput
            id="date"
            label="order_date"
            type="date"
            required
            value={values?.date}
            onChange={(e) => setFieldValue("date", e.target?.value)}
            error={errors.date}
            touched={touched.date}
          />
          <FormInput
            id="comment"
            label="order_comment"
            value={values?.orderComment}
            onChange={(e) => setFieldValue("orderComment", e.target?.value)}
            error={errors.orderComment}
            touched={touched.orderComment}
          />

          <FormInput
            id="defaultPax"
            label="order_default_pax"
            minValue={0}
            value={values?.defaultPax}
            onChange={(e) => setFieldValue("defaultPax", e.target.value)}
            error={errors.defaultPax}
            touched={touched.defaultPax}
          />
          <div className="d-flex gap-2">
            <FormRadio
              id="paxConfirmed"
              label="order_item_pax_confirmed"
              value={YES_NO_OPTIONS.find(
                (option) => option?.value === String(values?.paxConfirmed)
              )}
              options={YES_NO_OPTIONS}
              onChange={(option?: Option<string>) =>
                setFieldValue("paxConfirmed", option?.value)
              }
              required
              error={errors.paxConfirmed}
              touched={touched.paxConfirmed}
            />
            <FormRadio
              id="shouldUpdateOrderItemPax"
              label="order_item_update_lines"
              value={YES_NO_OPTIONS.find(
                (option) => option?.value === String(values?.shouldUpdateOrderItemPax)
              )}
              options={YES_NO_OPTIONS}
              onChange={(option?: Option<string>) =>
                setFieldValue("shouldUpdateOrderItemPax", option?.value)
              }
              required
              error={errors.shouldUpdateOrderItemPax}
              touched={touched.shouldUpdateOrderItemPax}
            />
          </div>
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateOrderForm
