import React, {FunctionComponent} from "react";
import {Season} from "../../../../interfaces/SeasonInterfaces";
import {dateUtils} from "../../../../utils/dateUtils";
import Icon from "../../../icon/Icon";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";

interface SeasonItemProps {
  className?: string;
  season?: Season;
  isSelected?: boolean;
  handleSelectSeason?: (season: Season) => void;
  handleDeleteSeason?: (season: Season) => void;
}

const SeasonItem: FunctionComponent<SeasonItemProps> = ({
  className= "",
  season,
  isSelected,
  handleDeleteSeason,
  handleSelectSeason
}) => (
  <div className={`${className} season-item row d-flex`} key={season.id}>
    <div className="col-20 season-pill">
      <a className={`text-center bold nav-link${isSelected ? " active" : " inactive"}`}
         id={season.id}
         data-toggle="pill"
         role="tab"
         href="#"
         onClick={() => handleSelectSeason(season)}
         aria-selected={isSelected ? "true" : "false"}
      >
        {`${season.name} - ${dateUtils.formatDateDayJs(season?.startDate)}`}
      </a>
    </div>
    {
      handleDeleteSeason !== undefined  && (
        <div className="col-4 pt-2">
          <Icon name="Trash" size={IconSizeType.XS} className="pe-2 cursor-pointer" onClick={() => handleDeleteSeason(season)}/>
        </div>
      )
    }
  </div>
)

export default SeasonItem;
