import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import Layout from "../../components/layout/Layout";
import HeaderButtonGroup, {HeaderStateButton} from "../../components/molecules/header/HeaderButtonGroup";
import StaffingList from "../../components/molecules/list/StaffingList";
import useSearchFilter from "../../hooks/useSearchFilter";
import {OrderItemSearchObject, OrderItemStaffingStatus, OrderItemState} from "../../interfaces/OrderItemInterfaces";
import PageTitle from "../../components/molecules/header/PageTitle";
import {dateUtils} from "../../utils/dateUtils";


const StaffingView: FunctionComponent = () => {
  const intl = useIntl()

  const initialSearchState: OrderItemSearchObject = {
    customerOrCode: "",
    isStaffingManagement: true,
    staffingStatus: OrderItemStaffingStatus.TO_STAFF,
    startDate: "",
    endDate: "",
    activityIds: [],
    status: [OrderItemState.DRAFT, OrderItemState.VALIDATED, OrderItemState.PENDING]
  }
  const {searchObject, onReset, updateSearchField} = useSearchFilter<OrderItemSearchObject>(initialSearchState)

  const stateFilterButtons: HeaderStateButton[] = [
    {
      title: intl.formatMessage({id: "staffing_pending"}),
      onclick: () => onReset({staffingStatus: OrderItemStaffingStatus.TO_STAFF}),
      active: searchObject.staffingStatus === OrderItemStaffingStatus.TO_STAFF
    },
    {
      title: intl.formatMessage({id: "staffing_in_progress"}),
      onclick: () => onReset({staffingStatus: OrderItemStaffingStatus.DISPONIBILITY_REQUEST_SENT, status: []}),
      active: searchObject.staffingStatus === OrderItemStaffingStatus.DISPONIBILITY_REQUEST_SENT
    },
    {
      title: intl.formatMessage({id: "staffing_in_done"}),
      onclick: () => onReset({staffingStatus: OrderItemStaffingStatus.STAFFED, startDate: dateUtils.formatDateYYYYMMDD(dateUtils.today())}),
      active: searchObject.staffingStatus === OrderItemStaffingStatus.STAFFED
    },
  ]

  return (
    <Layout>
      <div className="d-flex flex-column epow-content-body">

        <div className="d-flex mb-5">
          <PageTitle pageTitle={intl.formatMessage({id: "staffing_title"})}></PageTitle>
          <HeaderButtonGroup className="d-flex flex-1 justify-content-center me-5" buttonList={stateFilterButtons}/>
        </div>

        <StaffingList searchObject={searchObject} updateSearchField={updateSearchField} onReset={() => onReset({staffingStatus: searchObject.staffingStatus})} />
      </div>
    </Layout>
  )
}

export default StaffingView;
