import React, {FunctionComponent, useMemo} from "react";
import {DashboardActivity} from "../../../../interfaces/ActivityInterfaces";
import NextActivities from "../../dashboard/NextActivities";
import {PROFILE} from "../../../../interfaces/UserInterfaces";
import ActivityPanel from "../activity/ActivityPanel";
import {activityStaffingService} from "../../../../services/ActivityStaffingService";
import {dateUtils} from "../../../../utils/dateUtils";
import {ActivityStaffingStatus} from "../../../../constants/ActivityStaffingConstants";
import {PROFILE_PATH, STAFF_REQUEST_PATH} from "../../../../constants/routes/RoutePaths";
import {useNavigate} from "react-router-dom";
import {DashboardResourcesCounts} from "../../../../interfaces/DashboardInterfaces";
import NextTasks from "../../dashboard/NextTasks";
import {useIntl} from "react-intl";
import {activityService} from "../../../../services/ActivityService";
import {WorkflowStatesEnum} from "../../../../constants/workflow/WorkflowStates";
import {resourceService} from "../../../../services/ResourceService";
import {Resource} from "../../../../interfaces/ResourceInterfaces";

interface DashboardNextActivitiesCardProps {
  className?: string,
  activities: DashboardActivity[],
  profile: PROFILE,
}

const DashboardNextActivitiesCard: FunctionComponent<DashboardNextActivitiesCardProps> = ({
  className = "",
  activities,
  profile,
}) => {

  const [panelOpen, setPanelOpen] = React.useState<boolean>(false)

  const [orderItemId, setOrderItemId] = React.useState<string>()
  const navigate = useNavigate()
  const intl = useIntl()

  const [counters, setCounters] = React.useState<DashboardResourcesCounts>({
    newStaffingRequest: "0",
    awaitingStaffingRequest: "0",
    confirmedStaffingRequest: "0",
    newActivities: "0"
  })

  const getStaffingRequest = async (status: ActivityStaffingStatus): Promise<string> => {
    return await activityStaffingService.getOrderItemsStaffingRequest({pageSize: 1},
      {
        startDate: dateUtils.formatDateYYYYMMDD(dateUtils.today()),
        activityStaffingStatuses: [status]
      })
      .then((res) => res.totalElements)
      .catch(() => null);
  }

  const mapAreaIdsFromResource = (resource : Resource) => {
    return resource.activityAreas.map(area => area.id);
  }

  const getNewActivities = async (): Promise<string> => {
    return await
      resourceService.getSelfResource()
        .then((resource) => activityService.getActivitiesPageShort({pageSize: 1}, { activityAreaIds: mapAreaIdsFromResource(resource), status: WorkflowStatesEnum.ACTIVE, activationDate: dateUtils.getDateOneMonthAgo()}))
        .then((res) => res.totalElements)
        .catch(() => null)
  }

  const showActivityPanel = (orderItemId: string) => {
    setOrderItemId(orderItemId)
    setPanelOpen(true)
  }

  useMemo(() => {
    const loadData = async () => {
      setCounters({
        newStaffingRequest: await getStaffingRequest(ActivityStaffingStatus.SENT) ?? "0",
        awaitingStaffingRequest: await getStaffingRequest(ActivityStaffingStatus.AVAILABLE) ?? "0",
        confirmedStaffingRequest: await getStaffingRequest(ActivityStaffingStatus.CONFIRMED) ?? "0",
        newActivities: await getNewActivities()
      })
    }

    loadData().catch(() => null)
  }, []);

  const redirectToActivities = (status : ActivityStaffingStatus) => {
    navigate(`${STAFF_REQUEST_PATH}`, {state: status})
  }

  const redirectToNewActivities = () => {
    navigate(`${PROFILE_PATH}?index=4&activationDate=${dateUtils.getDateOneMonthAgo()}`)
  }

  return (
    <div className={className}>
      <NextActivities
        activities={activities}
        onClickActivity={showActivityPanel}
      />

      <NextTasks
        className="mt-3"
        tasks={[
        {title: intl.formatMessage({id: "dashboard_activities_new_counter"}), icon: "Inbox", number: counters.newStaffingRequest, onClick: () => redirectToActivities(ActivityStaffingStatus.SENT)},
        {title: intl.formatMessage({id: "dashboard_activities_awaiting_counter"}), icon: "HourglassHalf", number: counters.awaitingStaffingRequest, onClick: () => redirectToActivities(ActivityStaffingStatus.AVAILABLE)},
        {title: intl.formatMessage({id: "dashboard_activities_confirmed_counter"}), icon: "Check", number: counters.confirmedStaffingRequest, onClick: () => redirectToActivities(ActivityStaffingStatus.CONFIRMED)},
        {title: intl.formatMessage({id: "dashboard_new_activities_counter"}), icon: "Search", number: counters.newActivities, onClick: () => redirectToNewActivities()},
      ]}
        title="dashboard_activities"
      />

      <ActivityPanel
        open={panelOpen} 
        setOpen={setPanelOpen}
        profile={profile}
        orderItemId={orderItemId}
      />
    </div>
  )
}

export default DashboardNextActivitiesCard;
