import React, {FunctionComponent, useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";
import {useLocation} from "react-router-dom";
import BackgroundIcon from "../icon/BackgroundIcon";
import {IconSizeType} from "../../types/bootstrap/BootstrapType";
import { SizeMe } from "react-sizeme"
import {fileUtils} from "../../utils/fileUtils";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PdfViewerProps {
  className?: string,
}

const PdfViewer: FunctionComponent<PdfViewerProps> = ({
    className = "",
  }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const url = searchParams.get("url");
  const fileName = searchParams.get("filename");
  const [numPages, setNumPages] = useState<number>();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  }

  return (
    <>
        <BackgroundIcon className="position-fixed z10 bottom-0 end-0 m-5 bg-theme-primary border-light border cursor-pointer"
                        color="light"
                        iconName="Download" size={45}
                        iconSize={IconSizeType.SM}
                        onClick={() => fileUtils.downloadFromPdfViewer(url, fileName)}
                    />

      <SizeMe>
        {({ size }) => (
        <Document file={url} className={className} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              className="border"
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={size.width && size.width < 800 ? size.width  : 800}
            />
          ))}
        </Document>
        )}
      </SizeMe>
    </>
  )
}

export default PdfViewer
