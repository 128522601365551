import React, {FunctionComponent, useMemo} from "react";
import {useIntl} from "react-intl";
import GenericModal from "./GenericModal";
import {Formik} from "formik";
import RichTextInput from "../input/RichTextInput";
import EpowForm from "../form/EpowForm";
import * as Yup from "yup";
import {ActivityBillingPageData, ActivityBillingRefusedMail} from "../../../interfaces/ActivityBllingInterface";
import {dateUtils} from "../../../utils/dateUtils";


interface ModalActivityBillingRefusedProps {
  className?: string;
  open: boolean,
  activityBilling?: ActivityBillingPageData,
  onSubmit: (content: ActivityBillingRefusedMail) => void,
  onClose: () => void,
  onOpen: (open: boolean) => void,
}

const ModalActivityBillingRefused: FunctionComponent<ModalActivityBillingRefusedProps> = ({
    className = "",
    open,
    onClose,
    onOpen,
    activityBilling,
    onSubmit: onSubmitProps,
  }) => {
  const intl = useIntl();

  const onSubmit = (values: ActivityBillingRefusedMail) => {
    onSubmitProps(values)
  };

  const template = useMemo(() => {
      const template = `
      <p>${intl.formatMessage({id: "activity_billing_refused_email_greetings"}, {name: `${activityBilling?.resourceFirstName}`})}</p>
      <p>${intl.formatMessage({id: "activity_billing_refused_email_body_1"}, {date: dateUtils.formatDateDayJs(activityBilling.creationDate), number: activityBilling?.number, amount: activityBilling.billAmount, period: `${dateUtils.formatDateDayJs(activityBilling.firstStaffingDate)}-${dateUtils.formatDateDayJs(activityBilling.lastStaffingDate)}`})}</p>
      <p>${intl.formatMessage({id: "activity_billing_refused_email_body_2"})}</p>
      <p>${intl.formatMessage({id: "activity_billing_refused_email_body_3"})}</p>
      <p>${intl.formatMessage({id: "activity_billing_refused_email_bye"})}</p>`;
      return template.replaceAll("\n", "");
    },
    [activityBilling]);

  return (
    <Formik
      enableReinitialize
      initialValues={{template}}
      validationSchema={Yup.object().shape({
        template: Yup.string().required(),
      })}
      onSubmit={onSubmit}>
      {({values, errors, touched, setFieldValue}) => (
        <GenericModal
          className={className}
          title={intl.formatMessage({id: "activity_billing_refused_email_title"})}
          open={open}
          onOpen={onOpen}
          footer={{
            primaryAction: {buttonLabel: intl.formatMessage({id: "send_button"}), action: () => onSubmit(values)},
            secondaryAction: {buttonLabel: intl.formatMessage({id: "cancel_button"}), action: () => onClose()},
          }}
        >
          <EpowForm>
            <RichTextInput
              id="content"
              label="activity_billing_refused_email_field"
              value={values.template}
              onChange={(e) => setFieldValue("template", e.target.value)}
              required
              error={errors.template}
              touched={touched.template}
            />
          </EpowForm>
        </GenericModal>
      )}
    </Formik>
  );
};

export default ModalActivityBillingRefused;
