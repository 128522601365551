import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Formik} from "formik";
import {toastUtils} from "../../../utils/toastUtils";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import EpowForm from "./EpowForm";
import {BillingFormData, BillingItem} from "../../../interfaces/InvoiceInterfaces";
import {INVOICE_BILLING_INITIAL_VALUES} from "../../../constants/InvoiceConstants";
import {CreateInvoiceBillingItemSchema,} from "../../../constants/validation/InvoiceValidationSchemas";
import {invoiceService} from "../../../services/InvoiceService";
import {orderItemService} from "../../../services/OrderItemService";
import FormInput from "../input/FormInput";
import FormRadio from "../input/FormRadio";
import {YES_NO_OPTIONS} from "../../../constants/OptionConstants";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import PriceInput from "../input/PriceInput";
import {OrderItemResponse, OrderItemSearchObject, OrderItemState} from "../../../interfaces/OrderItemInterfaces";
import {orderService} from "../../../services/OrderService";
import {OrderOption, OrderState} from "../../../interfaces/OrderInterfaces";
import {orderUtils} from "../../../utils/orderUtils";
import {PageResponse, PaginationQueryParams} from "../../../interfaces/api/PaginationInterface";
import FormSelect from "../input/FormSelect";

interface CreateInvoiceFormProps {
  id?: string,
  className?: string,
  invoiceId: string,
  onSubmitSuccess: () => void,
  billingCustomerId: string,
  billingItem?: BillingItem
  isCreditNote?: boolean,
}

const CreateBillingItemForm: FunctionComponent<CreateInvoiceFormProps> = ({
                                                                            id,
                                                                            className = "",
                                                                            invoiceId,
                                                                            billingCustomerId,
                                                                            onSubmitSuccess,
                                                                            billingItem,
                                                                            isCreditNote = false,
                                                                          }) => {

  const intl = useIntl();
  const pageParams: PaginationQueryParams = {pageSize: 1000}

  const [orderItems, setOrderItems] = useState<OrderItemResponse[]>([]);
  const [orderItemOptions, setOrderItemOptions] = useState<Option<string>[]>([]);

  const getOrderItemsPage = (searchFilter: OrderItemSearchObject) => {
    orderItemService.getOrderItemsPage(pageParams, searchFilter).then((orderItemPage: PageResponse<OrderItemResponse>) => {
      const options: Option<string>[] = orderItemPage.content.map(orderItem => (
        {
          label: orderItem.name,
          value: orderItem.id
        }
      ))
      setOrderItems(orderItemPage.content);
      setOrderItemOptions(options)
    }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "order_item_search_error_message"}));
    })
  };

  const getOrderItems = (orderIds: string[]) => {
    const searchFilter = {
      billingServicesIds: [billingCustomerId],
      status: [OrderItemState.VALIDATED, OrderItemState.CANCELLED],
      orderIds
    };

    getOrderItemsPage(searchFilter);
  }

  useEffect(() => {
    if (billingItem?.orderItemId) {
      getOrderItemsPage({ids: [billingItem?.orderItemId]})
    }
  }, []);

  const onSubmit = (formData: BillingFormData) => {
    if(billingItem?.id) {
      invoiceService.updateBillingItem(invoiceId, billingItem.id, formData)
        .then(() => {
          onSubmitSuccess()
          toastUtils.successToast(intl.formatMessage({id: "success_toast_update_invoice"}))
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_invoice"}))
        })
    } else {
      invoiceService.addBillingItem(invoiceId, formData)
        .then(() => {
          onSubmitSuccess()
          toastUtils.successToast(intl.formatMessage({id: "success_toast_create_invoice"}))
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_create_invoice"}))
        })
    }
  }

  const onOrderChange = (value: string, setFieldValue: (field: string, value: string) => void) => {
    getOrderItems([value]);
    setFieldValue("orderId", value);
  }

  const onOrderItemChange = (orderItemId: string, setFieldValue: (field: string, value: unknown) => void) => {
    setFieldValue("orderItemId", orderItemId);
    const orderItem = orderItems.find(o => o.id === orderItemId);

    if (orderItem) {
      setFieldValue("unitPriceHT", parseFloat(orderItem.paxPrice.toString()).toFixed(2));
      setFieldValue("quantity", orderItem.quantity);
    }
  }

  return (
    <Formik
      initialValues={billingItem || INVOICE_BILLING_INITIAL_VALUES}
      validationSchema={CreateInvoiceBillingItemSchema}
      onSubmit={onSubmit}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormAutocompleteSelectPageable
            id="orderId"
            readOnly={!!billingItem?.orderId}
            label={intl.formatMessage({id: "order_title"})}
            onChange={(value) => onOrderChange(value, setFieldValue)}
            value={values?.orderId}
            fetchData={(page, filter) => orderService.getOrdersOptionPage(page, {
              ...filter,
              status: OrderState.HANDLED,
              billingServiceId: billingCustomerId,
            })}
            manageOptions={(response: OrderOption[]) => response.map(order => ({value: order.id, label: `${order.refCustomer || order.orderNumber} - ${order.customerName}`}))}
            optionFromValue={(response: OrderOption[], value: string) => {
              const orderOption = response?.find((order) => order.id === value);
              return orderOption ? {
                label: orderUtils.getOrderRefDisplay(orderOption.orderNumber, orderOption.refCustomer, orderOption.customerName),
                value: orderOption?.id
              } : null;
            }}
            filterFieldName="refCustomer"
            filterFieldSearch="customerOrCode"
            error={errors.orderId}
            touched={touched.orderId}
          />

          <FormSelect
            id="orderItemId"
            label="purchase_booking_order_item"
            placeholder="order_item_filter_code_placeholder"
            value={orderItemOptions.find((option) => option?.value === values?.orderItemId)}
            onChange={(option?: Option<string>) => onOrderItemChange(option?.value, setFieldValue)}
            options={orderItemOptions}
            required={!values.quantity && !values.unitPriceHT && !values.disbursement}
            isSearchable
            isClearable
            error={errors?.orderItemId}
            touched={touched?.orderItemId}
            isDisabled={!!billingItem?.orderItemId}
          />
          {values.orderItemId && !values.quantity && !values.unitPriceHT && !values.disbursement && !values.name &&
            <p className="mb-2 ">* <FormattedMessage id="billing_item_auto_complete_info"/></p>}

          <FormInput
            id="name"
            label="billing_item_name"
            required={!values.orderItemId}
            value={values?.name}
            onChange={(e) => setFieldValue("name", e.target?.value)}
            error={errors?.name}
            touched={touched?.name}
          />

          <FormInput
            id="quantity"
            label="billing_item_quantity"
            type="number"
            required={!values.orderItemId}
            value={values?.quantity}
            onChange={(e) => setFieldValue("quantity", e.target?.value)}
            error={errors?.quantity}
            touched={touched?.quantity}
          />

          <PriceInput
            id="unitPriceHT"
            label="billing_item_unit_price"
            value={values?.unitPriceHT}
            onChange={(e) => setFieldValue("unitPriceHT", e.target.value)}
            required={!values.orderItemId}
            error={errors?.unitPriceHT}
            touched={touched?.unitPriceHT}
          />

          {(!values.orderItemId && !isCreditNote) &&
            <FormRadio
              id="disbursement"
              label="customer_disbursement_field"
              value={YES_NO_OPTIONS.find((option) => option?.value === values?.disbursement?.toString())}
              options={YES_NO_OPTIONS}
              onChange={(option?: Option<string>) => setFieldValue("disbursement", option?.value)}
              required
              error={errors.disbursement}
              touched={touched.disbursement}
            />}

        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateBillingItemForm;
