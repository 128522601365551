import {Option} from "./inputs/OptionInterfaces";
import {Data} from "./TableInterfaces";
import {SearchFilter} from "./api/FiltersInterface";
import {ResourceDisplayOption} from "../constants/ResourceDisplayOptionConstant";

// const enums are translated to simple string literals by the TS compiler,
// so we use a simple enum to be able to call Object.keys(PROFILE) for example
export enum PROFILE {
  ADMIN = "ADMIN",
  INTERN = "INTERN",
  RESOURCE = "RESOURCE",
  CLIENT = "CLIENT",
  TOUR_LEADER = "TOUR_LEADER",
  SUPPLIER = "SUPPLIER",
  ACCOUNTANT = "ACCOUNTANT",
}

export const PROFILE_OPTIONS: Option<PROFILE>[] = Object.entries(PROFILE).map(([key, value]) => ({label: `PROFILE_${key}`, value: value}))

export interface UserSearchObject extends SearchFilter {
  lastName?: string,
  firstName?: string,
  email?: string,
  validated?: string
  profile: string
}

export interface User extends Data {
  profile?: PROFILE[],
  lastName?: string,
  firstName?: string,
  email?: string,
  validated?: boolean,

  phoneNumber?: string,
  job?: string
  entity?: string;
  resourceDisplayOption?: ResourceDisplayOption;
}

export interface AuthUser extends User {
  profileValidated?: boolean,
  token: string
}

export interface UserSelfProfile extends Data {
  lastName?: string,
  firstName?: string,
  email?: string,
  phoneNumber?: string,
  otherPhoneNumber?: string,
}

export interface UserTableDTO extends Data {
  lastName?: string,
  firstName?: string,
  email?: string,
  profile?: PROFILE[],
  validated?: boolean,
  creationDate?: string,
  lastConnectionDate?: string
}

export interface UserFormData {
  id: string,
  email: string,
  profile: PROFILE[]
  firstName: string,
  lastName: string,
  phone: string,
  function: string,

}
