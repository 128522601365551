import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {ResourceLegalInfo} from "../../../../interfaces/ResourceInterfaces";
import {ColorType, SizeType, TitleSizeType} from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import Title from "../../../atoms/Title";
import EditResourceLegalInfoForm from "../../form/resource/EditResourceLegalInfoForm";
import LegalInfoResourceCard from "./LegalInfoResourceCard";

interface LegalInfoResourceContainerProps {
  className?: string;
  resourceLegalInfo?: ResourceLegalInfo;
  setResourceAfterUpdate?: (resourceLegalInfo: ResourceLegalInfo) => void;
  self: boolean
}

const LegalInfoResourceContainer: FunctionComponent<LegalInfoResourceContainerProps> = ({
  className = "",
  resourceLegalInfo,
  setResourceAfterUpdate = () => null,
  self
}) => {
  const intl = useIntl();
  const [editInfo, setEditInfo] = useState<boolean>(false);

  return (
    <div className={className}>
      <div className="mb-3">
        <Title
          title={intl.formatMessage({ id: "resource_legal_info" })}
          size={TitleSizeType.H5}
        />
      </div>
      {editInfo ? (
        <>
          <EditResourceLegalInfoForm
            self={self}
            id="editResourceLegalInfoForm"
            initialResourceLegalInfo={resourceLegalInfo}
            setResourceAfterUpdate={setResourceAfterUpdate}
            setEditInfo={setEditInfo}
          />
          <div>
            <Button
              size={SizeType.MD}
              color={ColorType.SECONDARY}
              onClick={() => setEditInfo(false)}
              className="extended me-2"
            >
              <FormattedMessage id="cancel_button" />
            </Button>
            <Button
              form="editResourceLegalInfoForm"
              type="submit"
              size={SizeType.MD}
              className="extended"
            >
              <FormattedMessage id="save_button" />
            </Button>
          </div>
        </>
      ) : (
        <LegalInfoResourceCard
          resourceLegalInfo={resourceLegalInfo}
          editInfo={editInfo}
          setEditInfo={setEditInfo}
          onChangeResourceAfterUpdate={setResourceAfterUpdate}
          self={self}
        />
      )}
    </div>
  )
}

export default LegalInfoResourceContainer;
