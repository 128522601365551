import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {TOUR_LEADER_INITIAL_VALUES} from "../../../constants/TourLeaderConstants";
import {CreateTourLeaderSchema} from "../../../constants/validation/TourLeaderValidationSchemas";
import FormInput from "../input/FormInput";
import {useNavigate} from "react-router-dom";
import {TourLeader} from "../../../interfaces/TourLeaderInterfaces";
import {tourLeaderService} from "../../../services/TourLeaderService";
import {TOUR_LEADERS_PATH} from "../../../constants/routes/RoutePaths";
import {toastUtils} from "../../../utils/toastUtils";
import {useIntl} from "react-intl";
import EpowForm from "./EpowForm";
import {LoadingComponent} from "../../../constants/menu/Layout";

interface CreateTourLeaderFormProps extends LoadingComponent  {
  id?: string,
  className?: string
}

const CreateTourLeaderForm: FunctionComponent<CreateTourLeaderFormProps> = ({
  id,
  className = "",
  stopLoading,
  startLoading,
  closeForm = () => null,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const handleSubmitTourLeader = (tourLeader: TourLeader) => {
    startLoading?.()
    tourLeaderService.createTourLeader(tourLeader)
      .then((tourLeader) => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_create_tour_leader"}))
        navigate(`${TOUR_LEADERS_PATH}/${tourLeader?.id}`)
      })
      .catch((error) => toastUtils.errorToast(error.message ? intl.formatMessage({id: error.message})  : intl.formatMessage({id: "error_toast_create_tour_leader"})))
      .finally(() => {
        stopLoading?.()
        closeForm()
      })
  }

  return (
    <Formik initialValues={TOUR_LEADER_INITIAL_VALUES} validationSchema={CreateTourLeaderSchema} onSubmit={(value) => handleSubmitTourLeader(value)}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormInput
            id="firstName"
            label="tour_leader_first_name_field"
            value={values?.identity?.firstName}
            onChange={(e) => setFieldValue("identity.firstName", e.target.value)}
            required
            error={errors.identity?.firstName}
            touched={touched.identity?.firstName}
          />
          <FormInput
            id="lastName"
            label="tour_leader_last_name_field"
            value={values?.identity?.lastName}
            onChange={(e) => setFieldValue("identity.lastName", e.target.value)}
            required
            error={errors.identity?.lastName}
            touched={touched.identity?.lastName}
          />
          <FormInput
            id="email"
            label="tour_leader_email_field"
            value={values?.identity?.email}
            onChange={(e) => setFieldValue("identity.email", e.target.value)}
            required
            error={errors.identity?.email}
            touched={touched.identity?.email}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateTourLeaderForm
