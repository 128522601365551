import {CustomerServiceCatalog, CustomerServiceInfoCatalog, Service} from "../interfaces/ServiceInterfaces";
import {dateUtils} from "../utils/dateUtils";
import {SupplierServiceCatalog} from "../interfaces/SupplierInterfaces";

export const SERVICE_INITIAL_VALUES : Service = {
  name: "",
  description: "",
  reference: "",
  numberPaxMax: 0,
  defaultUnitType: undefined,
  defaultSellUnitType: undefined,
  defaultPrice: undefined,
  defaultSellPrice: undefined,
  suppliers: [],
  defaultSupplierId: "",
  vatRateId: "",
  productTypeId: ""
}

export const CUSTOMER_SERVICE_INITIAL_VALUES: CustomerServiceCatalog = {
  customerId: ""
}

export const CUSTOMER_SERVICE_INFO_CATALOG_INITIAL_VALUES: CustomerServiceInfoCatalog = {
  unitType: "",
  groupSize: 0,
  unitPriceExcludingTax: 0,
  startDateValidity: dateUtils.formatDateYYYYMMDD()
}

export const SUPPLIER_SERVICE_CATALOG_INITIAL_VALUES: SupplierServiceCatalog = {
  unitType: "",
  unitPrice: 0,
  paxMax: 0,
  startDateValidity: dateUtils.formatDateYYYYMMDD()
}
