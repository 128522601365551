import {SearchFilter} from "./api/FiltersInterface"
import {ResourceType} from "./ResourceTypeInterfaces";

export interface ResourceCalendarSearchFilter extends SearchFilter {
  activityLimit?: string
  resourceLimit?: string
  from?: string
  to?: string
  orderId?: string
  customerId?: string
  tourId?: string
  availability?: string
  activityIds?: string[]
  activityAreaIds?: string[]
  resourceIds?: string[]
  resourceName?: string
  resourceActivities?: string[]
  resourceFilterIds?: string[]
  resourceTypeIds?: string[]
}

export interface ResourceCalendarInfos {
  activities: ResourceCalendarRow[]
  resources: ResourceCalendarColumn[]
}

// the activity
export interface ResourceCalendarRow {
  id: string
  orderId: string
  orderItemId: string
  name: string
  orderReference: string
  staffed: number
  numberToStaff: number
  rendezVousDate: string
  rendezVousHour: string
  rendezVousEndHour: string
  place: string
  customerName: string
}

// the resource
export interface ResourceCalendarColumn {
  id: string
  name: string
  resourceType?: ResourceType;
  staffing: ResourceCalendarColumnStaffing[]
}

export interface ResourceCalendarColumnStaffing {
  activityId: string
  orderItemId: string
  status: ResourceCalendarResourceStatus
}

export const enum ResourceCalendarResourceStatus {
  SENT = "SENT",
  AVAILABLE = "AVAILABLE",
  CONFIRMED = "CONFIRMED",
  ALREADY_STAFFED = "ALREADY_STAFFED",
  UNAVAILABLE = "UNAVAILABLE",
  EMPTY = "EMPTY",
  FREE = "FREE",
  REFUSED = "REFUSED",
}

export interface ResourceCalendarResource {
  id: string
  name: string
  lastName: string
}
