import {ColorType} from "../types/bootstrap/BootstrapType"

export const enum ActivityStaffingStatus {
  SENT = "SENT",
  AVAILABLE = "AVAILABLE",
  CONFIRMED = "CONFIRMED",
  CANCELLED = "CANCELLED",
  REFUSED = "REFUSED"
}

export const ActivityStaffingStatusPriority: {[key: string]: number} = {
  [ActivityStaffingStatus.CONFIRMED]: 0,
  [ActivityStaffingStatus.AVAILABLE]: 1,
  [ActivityStaffingStatus.SENT]: 2,
  [ActivityStaffingStatus.CANCELLED]: 3,
  [ActivityStaffingStatus.REFUSED]: 4,
}

export const ActivityStaffingStatusColor: {[key: string]: ColorType} = {
  [ActivityStaffingStatus.CONFIRMED]: ColorType.PRIMARY,
  [ActivityStaffingStatus.AVAILABLE]: ColorType.SECONDARY,
  [ActivityStaffingStatus.SENT]: ColorType.GRAY,
  [ActivityStaffingStatus.CANCELLED]: ColorType.GRAY,
  [ActivityStaffingStatus.REFUSED]: ColorType.DANGER,
}
