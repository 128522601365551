import React, {FunctionComponent} from "react";
import TextInput, {TextInputProps} from "../../atoms/input/TextInput";
import {Label} from "reactstrap";

interface DateFilterProps extends TextInputProps {
  className?: string,
  title?: string,
  onChangeDate: (value: string) => void,
  value?: string,
  required?: boolean,
}

const DateFilter: FunctionComponent<DateFilterProps> = ({
  className = "",
  title,
  onChangeDate,
  value,
  required = false,
    ...rest
}) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    onChangeDate(value)
  };

  return (
    <div className={`epow-filter ${className}`}>
      {title && <Label className="epow-label">{title}</Label>} {required && <span aria-label="Champ obligatoire" className="required epow-danger ms-1 me-1">*</span>}
      <TextInput {...rest} type="date" onChange={handleChange} value={value} />
    </div>
  )
}

export default DateFilter;
