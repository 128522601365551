import React, {FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";
import {Col, Row} from "reactstrap";
import {ActivityBillingCommentRequest, ActivityBillingPageData,} from "../../../../interfaces/ActivityBllingInterface";
import {fileService} from "../../../../services/FileService";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import {dateUtils} from "../../../../utils/dateUtils";
import {fileUtils} from "../../../../utils/fileUtils";
import {toastUtils} from "../../../../utils/toastUtils";
import Icon from "../../../icon/Icon";
import {activityBillingService} from "../../../../services/ActivityBillingService";
import PanelActivityBillingComment from "../../panel/PanelActivityBillingComment";
import {moneyUtils} from "../../../../utils/moneyUtils";

interface ActivityBillingOverviewForResourceTableRowProps {
  activityBilling: ActivityBillingPageData,
  onRowClick: (orderId: string) => void,
  refresh: () => void,
  open: boolean,
  className?: string,
}

const ActivityBillingOverviewForResourceTableRow: FunctionComponent<ActivityBillingOverviewForResourceTableRowProps> = ({
    activityBilling,
    onRowClick,
    refresh,
    open,
    className = "",
  }) => {
  const intl = useIntl()
  const [modalOpened, setModalOpened] = useState<boolean>(false)
  const [fileName, setFileName] = useState<string>()

  const downloadBill = (fileId: string) => {
    const filePromise: Promise<Blob> = fileService.getDocumentById(fileId);
    fileService.getDocumentInfoById(fileId).then((response) => setFileName(response.name)).catch(() => toastUtils.errorToast(intl.formatMessage({id: "activity_bill_download_failure"})));

    fileUtils.downloadFile(filePromise, { name: fileName ?? "Facture", extension: "pdf" }, intl);
  }

  const sendComment = (content: ActivityBillingCommentRequest) => {
    setModalOpened(false);
    activityBillingService.createActivityBillingComment(activityBilling.id, content)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "activity_billing_comment_toast_success_send_comment"}))
        refresh()
      })
      .catch(() =>  toastUtils.errorToast(intl.formatMessage({id: "activity_billing_comment_toast_error_send_comment"})))
  }

  const getPeriod = () => {
    const start = dateUtils.formatDateDayJs(activityBilling.firstStaffingDate) ?? "";
    const end = dateUtils.formatDateDayJs(activityBilling.lastStaffingDate) ?? "";

    return `${start} - ${end}`
  }

  const getSubmittedDateTimer = (hours: number) => {
    return hours <= 24
      ? `${intl.formatMessage({id: "billing_overview_hour"}, {count: hours})}`
      : `${intl.formatMessage({id: "billing_overview_day"}, {count: Math.round(hours/24)})}`
  };

  return (
    <>
      <Row onClick={() => onRowClick(activityBilling.id)} className={`gx-0 py-3 px-2 ${className}`}>
        <Col xs={4}>
          <Icon className="m-0 me-2" name={open ? "ChevronUp" : "ChevronDown"} size={IconSizeType.XS} />
          {activityBilling.number ?? "-"}
        </Col>
        <Col xs={3}>
          {dateUtils.formatDateDayJs(activityBilling.date) ?? "-"}
        </Col>
        <Col xs={3} className="px-0 d-flex flex-column">
          <span>
            {dateUtils.formatDateDayJs(activityBilling.creationDate) ?? "-"}
          </span>
          <span className="epow-secondary">
            {activityBilling.creationDate ? getSubmittedDateTimer(dateUtils.getHoursBetweenTwoDates(dateUtils.parseDate(activityBilling.creationDate))) : "-"}
          </span>
        </Col>
        <Col xs={4}>
          {getPeriod()}
        </Col>
        <Col xs={2}>
          {moneyUtils.formatNumberToCurrency(activityBilling.billAmountHT)}
        </Col>
        <Col xs={2}>
          {moneyUtils.formatNumberToCurrency(activityBilling.billAmount)}
        </Col>
        <Col xs={4}>
          {dateUtils.formatDateDayJs(activityBilling.paymentDate) ?? "-"}
        </Col>
        <Col xs={2} onClick={(event) => event.stopPropagation()} className="px-0 ms-auto pe-2">
          <Icon name={activityBilling.commented ? "Comment" : "CommentRegular"} size={IconSizeType.XS} onClick={() => setModalOpened(true)} />
          <Icon name="Download" size={IconSizeType.XS} onClick={() => downloadBill(activityBilling.fileId)} />
        </Col>
      </Row>

      <PanelActivityBillingComment
        open={modalOpened}
        onClose={() => setModalOpened(false)}
        onSubmit={sendComment}
        className="flex-column"
        activityBillingId={modalOpened ? activityBilling.id : null}
        activityBillingNumber={modalOpened ? activityBilling.number : null}
      />

    </>
  )
}

export default ActivityBillingOverviewForResourceTableRow;
