import React, {FunctionComponent, useMemo} from "react";
import {resourceService} from "../../../services/ResourceService";
import {tableUtils} from "../../../utils/tableUtils";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {useIntl} from "react-intl";
import {RESOURCE_TABLE} from "../../../constants/Table";
import PagedTable from "../table/PagedTable";
import {PaginationQueryParams} from "../../../interfaces/api/PaginationInterface";
import {ResourceSearchObject} from "../../../interfaces/ResourceInterfaces";
import TextFilter from "../filters/TextFilter";
import FilterGroup from "../filters/FilterGroup";
import AutocompleteMultiselect from "../filters/AutocompleteMultiselect";
import {activityAreaService} from "../../../services/ActivityAreaService";
import {resourceTypeService} from "../../../services/ResourceTypeService";
import {activityService} from "../../../services/ActivityService";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import {PUBLIC_TYPE_OPTIONS, YES_NO_OPTIONS} from "../../../constants/OptionConstants";
import SelectFilter from "../filters/SelectFilter";

interface ResourceListProps {
  className?: string
  searchObject?: ResourceSearchObject;
  updateSearchField: (field: string, value: string | string[]) => void,
  onReset: () => void,
}

const ResourceList: FunctionComponent<ResourceListProps> = ({
  className = "",
  searchObject,
  updateSearchField,
  onReset
}) => {
  const intl = useIntl()
  const columns = useMemo(() => tableUtils.getColumns(RESOURCE_TABLE(intl)), [intl])

  const getResources = (query: PaginationQueryParams, filter: ResourceSearchObject) => {
    return resourceService.getResourcesPage(query, filter)
  }

  return (
    <>
      <FilterGroup className="gap-2">
        <TextFilter
          icon="Search"
          className="main-filter"
          value={searchObject?.nameSurname}
          onChange={(value) => updateSearchField("nameSurname", value)}
          title={intl.formatMessage({id: "resource_name_filter"})}
          placeholder={intl.formatMessage({id: "resource_search_placeholder"})}
        />
        <AutocompleteMultiselect
          title={intl.formatMessage({id: "resource_table_header_area"})}
          placeholder={intl.formatMessage({id: "resource_table_header_area"})}
          values={searchObject.activityAreas ?? []}
          onChange={(values) => updateSearchField("activityAreas", values)}
          fetchData={activityAreaService.getActivityAreasPage}
          filterFieldName="label"
          className="me-2"
          classNameLabel="mb-1 fw-bold"
        />
        <AutocompleteMultiselect
          title={intl.formatMessage({id: "resource_type"})}
          placeholder={intl.formatMessage({id: "resource_type"})}
          values={searchObject.resourceTypes ?? []}
          onChange={(values) => updateSearchField("resourceTypes", values)}
          fetchData={resourceTypeService.getResourceTypePage}
          filterFieldName="label"
          className="me-2"
          classNameLabel="mb-1 fw-bold"
        />
        <AutocompleteMultiselect
          title={intl.formatMessage({id: "resource_activities_known"})}
          placeholder={intl.formatMessage({id: "resource_activities_known"})}
          values={searchObject.activity ?? []}
          onChange={(values) => updateSearchField("activity", values)}
          fetchData={(params, filter) => activityService.getActivitiesOption(params, {...filter, status: WorkflowStatesEnum.ACTIVE})}
          filterFieldName="label"
          filterFieldSearch="name"
          className="me-2"
          classNameLabel="mb-1 fw-bold"
        />
        <AutocompleteMultiselect
          title={intl.formatMessage({id: "resource_public_type"})}
          placeholder={intl.formatMessage({id: "resource_public_type"})}
          values={searchObject.publicTypes ?? []}
          onChange={(values) => updateSearchField("publicTypes", values)}
          staticOptions={PUBLIC_TYPE_OPTIONS.map(o => ({label: intl.formatMessage({id: o.label}), value: o.value}))}
          className="me-2"
          classNameLabel="mb-1 fw-bold"
        />
        <SelectFilter
          options={YES_NO_OPTIONS.map(o => ({label: intl.formatMessage({id: o.label}), value: o.value}))}
          onChange={(value) => updateSearchField("isNocturnal", value)}
          title={intl.formatMessage({id: "resource_is_nocturnal"})}
          value={searchObject.isNocturnal ? searchObject.isNocturnal.toString() : undefined}
          placeholder={intl.formatMessage({id: "resource_is_nocturnal"})}
          isClearable
        />
        <div>
          <Button onClick={onReset} color={ColorType.SECONDARY}>
            {intl.formatMessage({id: "header_menu_clear_search"})}
          </Button>
        </div>
      </FilterGroup>

      <PagedTable
        columns={columns}
        labelNoResult="resource_no_result"
        className={`${className} table`}
        fetchData={getResources}
        filters={searchObject}
        defaultSortBy={[{sortBy: "identity.lastName"}]}
      />
    </>
  )
}

export default ResourceList;
