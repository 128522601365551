//// API PATHS
export const API_AUTH_PATH = "/auth/login";
export const API_PASSWORD_TOKEN_PATH = "/password-tokens"
export const API_RULES_PATH = "/auth/rules";
export const API_USER_RULES_PATH = "/user-rules";
export const API_CONTACTS_PATH = "/contacts";
export const API_INVOICES_PATH = "/invoices";
export const API_INVOICE_PAYMENTS_PATH = "/invoices/payments";
export const API_CREDIT_NOTES_PATH = "/credits-notes";
export const API_NOTIFICATIONS_PATH = "/notifications";
export const API_CUSTOMERS_PATH = "/customers";
export const API_CUSTOMER_CONTACTS_PATH = "/customer-contacts";
export const API_BILLING_SERVICE_PATH = "/billing-services";
export const API_TOURS_PATH = "/tours";
export const API_ACTIVITY_IN_TOUR_CUSTOMER = "/activity-in-tour-customer";
export const API_TOUR_ACTIVITIES_PATH = "/tour-activities";
export const API_TOUR_CUSTOMER_ACTIVITIES_PATH = "/tour-customer-activities";
export const API_USER_PATH = "/users";
export const API_USER_RESOURCE_PATH = "/users/resource";
export const API_ACTIVITIES_PATH = "/activities";
export const API_ACTIVITIES_BILLING_PATH = "/activity-billings";
export const API_ACTIVITY_CUSTOMERS_PATH = "/activity-customers";
export const API_CATALOG_ACTIVITY_CUSTOMERS_PATH = "/catalog-activity-customers";
export const API_CATALOG_ACTIVITY_RESOURCE_CUSTOMERS_PATH = "/catalog-activity-resource-customers";
export const API_ACTIVITY_AREA_PATH = "/activity-areas";
export const API_ACTIVITY_RESOURCE_REQUIREMENTS_PATH = "/resource-requirements";
export const API_RESOURCES_TYPE_PATH = "/resource-types";
export const API_SERVICES_PATH = "/services";
export const API_CUSTOMER_SERVICES_PATH = "/customer-services";
export const API_CATALOG_CUSTOMER_SERVICES_PATH = "/catalog-customer-services";
export const API_REFERENCES_PATH = "/references";
export const API_SUPPLIERS_PATH = "/suppliers";
export const API_BLACK_DATE_PATH = "/black-dates";
export const API_SUPPLIER_CONTACTS_PATH = "/supplier-contacts";
export const API_ACTIVITY_STAFFING_PATH = "/activity-staffing";
export const API_ORDER_ITEMS_PATH = "/order-items";
export const API_STRUCTURES_PATH = "/structures";
export const API_LANGUAGES_PATH = "/languages";
export const API_FILES_PATH = "/files";
export const API_DOCUMENTARY_BASE_PATH = "/documents"
export const API_INFO_PATH = "/infos"
export const API_MAIN_CONTACT = "main-contact";
export const API_COMMENTS = "comments";
export const API_BANK_ACCOUNTS = "bank-accounts"
export const API_BOOKINGS_PATH = "/bookings";
export const API_BOOKINGS_BY_STATUS_PATH = "/bookings/status";
export const API_BOOKINGS_DOCUMENT_PATH = "/bookings/document";
export const API_BOOKINGS_CANCEL_PATH = "/bookings/cancel";
export const API_BOOKINGS_KEEP_PATH = "/bookings/keep";
export const API_PURCHASES_PATH = "/purchases";
export const API_SUPPLIER_PURCHASES_PATH = "/supplier-purchases";
export const API_RULE_PATH = "/rules";
export const API_VAT_RATE_PATH = "/vat-rate";
export const API_PRODUCT_TYPE_PATH = "/product-type";
export const API_PLAN_ITEM_PATH = "/plan-item";
export const API_ADMIN_CONFIG_PATH = "/admin-config";
export const API_ADMIN_MAIL_CONFIG_PATH = "/admin-mail-config";
export const API_ACCOUNTING_CONFIG = "/accounting-config";
export const API_ORDERS_PATH = "/orders";
export const API_QUOTES_PATH = "/quotes";
export const API_QUOTE_ITEMS_PATH = "/quote-items";
export const API_TOUR_LEADERS_PATH = "/tour_leaders";
export const API_RESOURCES_PATH = "/resources";
export const API_RESOURCE_CALENDAR_PATH = "/resource-calendar";
export const API_DAY_BY_DAY_CLIENTS_PATH = "/day-by-day-clients";

export const getProfilePicture = (resourceId: string) => `/api/resources/${resourceId}/profile-picture`;
export const API_RESOURCE_STAFFING_PATH = "/resource-staffing";
export const API_HOME_COMMENT_PATH = "/home-comment";
export const API_RESOURCE_AVAILABILITY_PATH = "/resource-availability";
export const API_SUPPLIER_SERVICE_PATH = "/supplier-service";
export const API_CATALOG_SUPPLIER_SERVICE_PATH = "/catalog-supplier-service";

export const API_SEASONS_PATH = "/seasons";
export const API_SEASONS_FOR_TOUR_PATH = `${API_SEASONS_PATH}${API_TOURS_PATH}/:tourId`;

//// APP PATHS
// AUTH
export const HOME_PATH = "/";
export const LOGIN_PATH = "/login";
export const FORGOTTEN_PASSWORD_PATH = "/forgotten-password"
export const RESET_PASSWORD_PATH = "/reset-password"

// SELF
export const PROFILE_PATH = "/profile";
export const SELF_PROFILE_PATH = "/me";

// SALE
export const SALE_PATH = "/sale"
export const MY_TOURS_PATH = "/my-tours"
export const CUSTOMERS_PATH = `${SALE_PATH}/customers`;
export const CUSTOMER_PATH = `${CUSTOMERS_PATH}/:id`;
export const ORDERS_PATH = `${SALE_PATH}/orders`;
export const ORDERS_FORM_PATH = `${SALE_PATH}/form/orders`;
export const ORDERS_IMPORT_PATH = `${ORDERS_PATH}/import`;
export const ORDER_PATH = `${ORDERS_PATH}/:id`;
export const MY_TOUR_PATH = `${MY_TOURS_PATH}/:id`;
export const INVOICES_PATH = `${SALE_PATH}/invoices`;
export const INVOICE_PAYMENTS_PATH = `${SALE_PATH}/invoice-payments`;
export const INVOICE_PAYMENT_PATH = `${INVOICE_PAYMENTS_PATH}/:id`;
export const INVOICE_PAYMENTS_CREATE_PATH = `${INVOICE_PAYMENTS_PATH}/create/:customerId`;
export const CREDIT_NOTES_PATH = `${SALE_PATH}/credits-notes`;
export const CREDIT_NOTE_PATH = `${SALE_PATH}/credits-notes/:id`;
export const INVOICE_PATH = `${INVOICES_PATH}/:id`;
export const BILLING_PATH = `${SALE_PATH}/billing`;
export const SELF_BILLING_PATH = `${SALE_PATH}/my-bills`;
export const TOUR_LEADERS_PATH = `${SALE_PATH}/tour-leaders`;
export const TOUR_LEADER_PATH = `${TOUR_LEADERS_PATH}/:id`;
export const ACTIVITIES_BILLING_PATH = "/activity-billings";
export const QUOTES_PATH =`${SALE_PATH}/quotes`;
export const QUOTE_PATH = `${QUOTES_PATH}/:id`;
export const RESOURCE_AVAILABILITY_PATH = "/resource-availability";

// PURCHASING
export const PURCHASING_PATH = "/purchasing";
export const SUPPLIERS_PATH = `${PURCHASING_PATH}/suppliers`;
export const SUPPLIER_PATH = `${SUPPLIERS_PATH}/:id`;
export const BOOKINGS_PATH = `${PURCHASING_PATH}/bookings`;
export const PURCHASES_PATH = `${PURCHASING_PATH}/purchases`;
export const PURCHASE_PATH = `${PURCHASES_PATH}/:id`;
export const SUPPLIER_PURCHASE_PATH = "/supplier/purchases";

// STAFFING
export const STAFF_PATH = "/staff";
export const RESOURCES_PATH = `${STAFF_PATH}/resources`;
export const STAFFING_CALENDAR_PATH = `${STAFF_PATH}/calendar`;
export const STAFFING_REPORTING_PATH = `${STAFF_PATH}/reporting`;

export const RESOURCE_PATH = `${RESOURCES_PATH}/:id`;
export const STAFFING_PATH = `${STAFF_PATH}/activity-staffing`;
export const BILLING_OVERVIEW_PATH = `${STAFF_PATH}/billing-overview`;
export const PAYMENT_PATH = `${STAFF_PATH}/payments`;
export const STAFF_REQUEST_PATH =  `${STAFF_PATH}/activities/requests`;


// CATALOG
export const CATALOG_PATH = "/catalog";
export const TOURS_PATH = `${CATALOG_PATH}/tours`;
export const TOUR_PATH = `${TOURS_PATH}/:id`;
export const ACTIVITIES_PATH = `${CATALOG_PATH}/activities`;
export const ACTIVITY_PATH = `${ACTIVITIES_PATH}/:id`;
export const SERVICES_PATH = `${CATALOG_PATH}/services`;
export const SERVICE_PATH = `${SERVICES_PATH}/:id`;

// ADMIN
export const ADMIN_PATH = "/admin";
export const ADMIN_PATH_REF = `${ADMIN_PATH}/ref`;
export const ADMIN_PATH_EXPORTS = `${ADMIN_PATH}/exports`;
export const ADMIN_PATH_PARAMS = `${ADMIN_PATH}/params`;

// EXTERNAL (NOT POW)
export const STAFFING_VALIDATION_PATH = "/staffing/validate/:staffingToken";
export const DOCUMENTARY_BASE_PATH = "/documents"
export const PDF_VIEWER = "/pdf-viewer"
