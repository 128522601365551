import React, {FunctionComponent} from "react";
import AdminExportCsvCard from "../card/admin/export/AdminExportCsvCard";
import AdminExportPdfCard from "../card/admin/export/AdminExportPdfCard";
import AdminExportServicesCsvCard from "../card/admin/export/AdminExportServicesCsvCard";
import AdminExportBillingZipCard from "../card/admin/export/AdminExportBillingZipCard";

interface AdminExportsProps {
  className?: string,
}

const AdminExports: FunctionComponent<AdminExportsProps> = ({
    className,
  }) => {
  return (
    <div className={`d-flex gap-2 flex-wrap ${className ?? ""}`}>
      <AdminExportCsvCard className="epow-card-admin-export"/>
      <AdminExportPdfCard className="epow-card-admin-export"/>
      <AdminExportServicesCsvCard className="epow-card-admin-export"/>
      <AdminExportBillingZipCard className="epow-card-admin-export"/>
    </div>
)
}

export default AdminExports;
