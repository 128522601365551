import {Dictionary, groupBy} from "lodash";
import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Label} from "reactstrap";
import {Order, OrderState} from "../../../../interfaces/OrderInterfaces";
import {OrderItem, OrderMail} from "../../../../interfaces/OrderItemInterfaces";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import Icon from "../../../icon/Icon";
import CreateOrderItemForm from "../../form/orderItem/CreateOrderItemForm";
import OrderItemPreviewCard from "../orderItem/OrderItemPreviewCard";
import {dateUtils} from "../../../../utils/dateUtils";
import Panel from "../../panel/Panel";
import FieldLabel from "../../../atoms/FieldLabel";
import {BOOKINGS_PATH} from "../../../../constants/routes/RoutePaths";
import {useNavigate} from "react-router-dom";
import {profileUtils} from "../../../../utils/profileUtils";
import ModalSendMailTourLeader from "../../modal/ModalSendMailTourLeader";
import {orderService} from "../../../../services/OrderService";
import {toastUtils} from "../../../../utils/toastUtils";
import {fileUtils} from "../../../../utils/fileUtils";
import {stringUtils} from "../../../../utils/stringUtils";
import {DateFormats} from "../../../../constants/DateConstants";

interface OrderInfosCardProps {
  className?: string,
  order: Order,
  orderItems: OrderItem[],
  refeshOrderItems: () => void
}

const OrderInfosCard: FunctionComponent<OrderInfosCardProps> = ({
  className = "",
  order,
  orderItems,
  refeshOrderItems,
}) => {

  const intl = useIntl()
  const [formOpen, setFormOpen] = useState<boolean>(false)
  const [isEditable, setIsEditable] = useState<boolean>(false)
  const [editedOrderItem, setEditedOrderItem] = useState<OrderItem>(null)
  const ordersByDate: Dictionary<OrderItem[]> = groupBy(orderItems, "rendezVousDate")
  const navigate = useNavigate();
  const [modalMailTourLeaderOpen, setModalMailTourLeaderOpen] = useState<boolean>(false)

  const editOrderItem = (orderItem: OrderItem) => {
    setEditedOrderItem(orderItem)
    setFormOpen(true)
  }

  const handleOrderItemChange = () => {
    refeshOrderItems()
  }

  const handleFormvalidation = () => {
    setFormOpen(false)
    setIsEditable(false)
    refeshOrderItems()
  }

  const openOrderItemCreationForm = () => {
    setEditedOrderItem(null)
    setFormOpen(true)
  }

  const handleCancelOrderItemForm = () => {
    setIsEditable(false)
    setFormOpen(false)
  }

  const handleSendOrderMail = (mail: OrderMail) => {
    orderService.sendOrderEmail(order?.id, fileUtils.createFormDataPJ(mail.template, mail.files, mail.emailCc))
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_send_order_mail"}))
        setModalMailTourLeaderOpen(false)
        refeshOrderItems()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_send_order_mail"}))
      });
  }

  const sortOrderItemsForDay = (orderItems: OrderItem[]) => {
    orderItems.sort((a, b) => {
      if (a.rendezVousHour < b.rendezVousHour) {
        return -1
      } else if (a.rendezVousHour > b.rendezVousHour) {
        return 1
      } else {
        return a.name.localeCompare(b.name)
      }
    })
  }

  const displayOrderItems = () => {
    const sortedOrdersByDate = Object.entries(ordersByDate) ?? [];
    sortedOrdersByDate.sort((a, b) => dateUtils.parseDate(a[0]) < dateUtils.parseDate(b[0]) ? -1 : 1)

    const allOrderItems: OrderItem[][] = [];
    sortedOrdersByDate.forEach(([date]) => {
      const orderItemsForDay: OrderItem[] = [...ordersByDate[date]];
      sortOrderItemsForDay(orderItemsForDay)
      allOrderItems.push(orderItemsForDay)
    })

    return (
      <div className="d-flex flex-column">
        <div className="ms-3">
          {sortedOrdersByDate?.map(([date, orderitemsByDate]) => {
            sortOrderItemsForDay(orderitemsByDate)
            return (
              <div key={date} className="mt-3">
                <div className="d-flex align-items-center">
                  <span className="bold me-3">{stringUtils.capitalize(dateUtils.formatDateDayJs(date, DateFormats.longDate))}</span>
                  <hr className="flex-grow-1" />
                </div>
                {orderitemsByDate?.map(orderItem => (
                  <OrderItemPreviewCard
                    className="mb-2"
                    key={orderItem.id}
                    orderStatus={order?.orderStatus}
                    orderItem={orderItem}
                    isEditable={order.orderStatus !== OrderState.COMPLETED}
                    setIsEditable={setIsEditable}
                    editOrderItem={editOrderItem}
                    onOrderItemChange={handleOrderItemChange}
                  />
                ))}
                <ModalSendMailTourLeader
                  open={modalMailTourLeaderOpen}
                  onSubmit={(content) => handleSendOrderMail(content)}
                  order={order}
                  orderItems={allOrderItems.flat()}
                  onClose={() => setModalMailTourLeaderOpen(false)}
                  onOpen={() => setModalMailTourLeaderOpen(true)}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className={className}>
      <div className="d-flex align-items-center justify-content-between mt-3 flex-wrap">
        <div className="d-flex align-items-baseline flex-wrap ">
          <FieldLabel valueBold className="me-3" label={intl.formatMessage({id: "order_customer"})} value={order.customer?.name ?? "-"} />
          <FieldLabel valueBold className="me-3" label={intl.formatMessage({id: "order_referenceClient"})} value={order.customerReference ?? "-"} />
          <FieldLabel
            valueBold
            className="me-3 text-capitalize"
            label={intl.formatMessage({id: "order_dayZero"})}
            value={dateUtils.formatDateDayJs(order.beginTourDate)}
          />
          <FieldLabel valueBold className="me-3" label={intl.formatMessage({id: "tour_code"})} value={order.tour?.tourCode ?? "-"} />
          <FieldLabel
            valueBold
            className="me-3"
            label={intl.formatMessage({id: "order_tour_leader"})}
            value={order?.tourLeaders?.map(tl => tl.identity ? `${tl.identity.firstName} ${tl.identity.lastName}` : "-").join(", ") || "-"}/>
          { order?.tourLeaders?.map(tl => tl.identity.email)?.length > 0 && (
          <Button className="me-2" size={SizeType.XS} color={ColorType.SECONDARY} onClick={() => setModalMailTourLeaderOpen(true)}>
              <Icon className="me-2" color={order.mailTourLeaderSent ?  ColorType.PRIMARY : ColorType.DANGER} name="Envelope"/>
              <FormattedMessage id={order.mailTourLeaderSent ? "order_item_email_sent" : "order_item_email_send"}/>
            </Button>)
          }
        </div>
        { (profileUtils.isInterne() && orderItems?.length > 0) &&
          <div>
            <Button
              size={SizeType.XS}
              color={ColorType.SECONDARY}
              onClick={() => navigate(`${BOOKINGS_PATH}?orderIds=${order.id}`)}
              className="me-2">
              <FormattedMessage id="order_booking_to_do"/>
            </Button>
            {order.orderStatus !== OrderState.COMPLETED && (
              <Button size={SizeType.XS} color={ColorType.SECONDARY} onClick={openOrderItemCreationForm}>
                <FormattedMessage id="order_add_order_item"/>
                <Icon className="ms-2" name="Plus"/>
              </Button>
            )}
          </div>
        }
      </div>

      { order.orderComment && <span><FormattedMessage id="order_comment" /> : {order.orderComment}</span> }
      {orderItems?.length > 0 ?
        displayOrderItems()
        :
        (
          <div className="d-flex flex-column align-items-center mt-5">
            <Label><FormattedMessage id="order_no_order_item" /></Label>
            <Button
              color={ColorType.SECONDARY}
              onClick={() => setFormOpen(true)}
              className="border border-dark" >
              <FormattedMessage id="order_add_order_item" />
              <Icon className="ms-2" name="Plus" />
            </Button>
          </div>
        )}

      {formOpen && (
        <Panel
          title="create_new_order_item"
          open={formOpen}
          onCancel={() => setFormOpen(false)}
          displayButton={false}
        >
          <CreateOrderItemForm id="createOrderItemForm" onCancel={() => handleCancelOrderItemForm()} isEditable={isEditable} onValidate={handleFormvalidation} order={order} orderItem={editedOrderItem} />
        </Panel>
      )}

    </div>
  )
}

export default OrderInfosCard;
