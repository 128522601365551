import React, {FunctionComponent} from "react";
import {ResourceCalendarColumn} from "../../../interfaces/ResourceCalendarInterfaces";
import ResourceCircle from "../circle/ResourceCircle";
import {STAFFING_CALENDAR_PATH, getProfilePicture} from "../../../constants/routes/RoutePaths";
import Avatar from "../images/Avatar";
import {dateUtils} from "../../../utils/dateUtils";

interface ResourceCalendarResourceCellProps {
  className?: string,
  resource: ResourceCalendarColumn,
  year: number,
}

const ResourceCalendarResourceCell: FunctionComponent<ResourceCalendarResourceCellProps> = ({
  className = "",
  resource,
  year,
}) => {
  const redirectToReporting = () => {
    window.open(`${STAFFING_CALENDAR_PATH}?displayReporting=true&year=${year ?? dateUtils.today().getFullYear()}&resourceId=${resource.id}`)
  }

  return (
    <div className={`resource-calendar-resource-cell ${className}`} onClick={redirectToReporting}>
      <Avatar url={getProfilePicture(resource?.id)}  width={15} height={15} className="m-0 me-1"/>
      <ResourceCircle className="me-1" resourceType={resource?.resourceType} />
      {resource?.name}
    </div>
  )
}

export default ResourceCalendarResourceCell;
