import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import GenericModal from "./GenericModal";
import {toastUtils} from "../../../utils/toastUtils";

interface ModalDeleteDocumentProps {
  className?: string,
  titleId: string,
  open: boolean,
  setOpen?: (open: boolean) => void,
  idEntity?: string,
  onValidate: () => void,
  onDelete: (id: string) => Promise<Response>
  onClosed?: () => void
  successToastMessageId: string,
  errorToastMessageId: string,
  bodyDeleteMessage: string
}

const GenericModalDelete: FunctionComponent<ModalDeleteDocumentProps> = ({
  className = "",
  titleId,
  open,
  setOpen,
  idEntity,
  onValidate,
  onDelete,
  onClosed,
  successToastMessageId,
  errorToastMessageId,
  bodyDeleteMessage
}) => {
  const intl = useIntl()
  const validateButton = intl.formatMessage({id: "validate_button"})
  const cancelButton = intl.formatMessage({id: "cancel_button"})

  const handleDelete = () => {
    if (idEntity) {
      onDelete(idEntity)
        .then(() => {
          toastUtils.successToast(intl.formatMessage({id: successToastMessageId}))
          onValidate()
        })
        .catch((e) => {
          toastUtils.errorToast(intl.formatMessage({id: errorToastMessageId}) + (e === "Forbidden" ? " : " + intl.formatMessage({id: "document_delete_Forbidden"}) : ""))
        })
    }
  }

  return (
    <GenericModal
      className={className}
      title={intl.formatMessage({id: titleId})}
      open={open}
      onOpen={setOpen}
      closable
      footer={{
        primaryAction: {
          buttonLabel: validateButton,
          action: () => handleDelete()
        },
        secondaryAction: {
          buttonLabel: cancelButton,
          action: () => setOpen(false)
        }
      }}
      onClosed={onClosed}
    >
      {bodyDeleteMessage}
    </GenericModal>
  )
}

export default GenericModalDelete
