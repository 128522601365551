import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {TOUR_CUSTOMER_INITIAL_VALUES} from "../../../constants/TourConstants";
import {CreateTourCustomerSchema} from "../../../constants/validation/TourValidationSchemas";
import {useIntl} from "react-intl";
import {toastUtils} from "../../../utils/toastUtils";
import {customerService} from "../../../services/CustomerService";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import EpowForm from "./EpowForm";
import {dayByDayClientService} from "../../../services/DayByDayClientService";
import {DayByDayClientRequest} from "../../../interfaces/DayByDayClientInterfaces";
import FormInput from "../input/FormInput";

interface CreateTourCustomerFormProps {
  id?: string,
  className?: string,
  tourId,
  onValidate?: () => void,
  existingCustomerIds?: string[],
}

const CreateTourCustomerForm: FunctionComponent<CreateTourCustomerFormProps> = ({
  id,
  className = "",
  tourId,
  onValidate = () => null,
  existingCustomerIds = [],
}) => {
  const intl = useIntl()
  const handleSubmitTourCustomer = (request: DayByDayClientRequest) => {
    const dayByDayRequest: DayByDayClientRequest = {...request, tourId: tourId}
    dayByDayClientService.createDayByDaysForClient(dayByDayRequest)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_create_tour_activity_customer"}))
        onValidate()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_create_tour_activity_customer"}))
      })
  }

  return (
    <Formik initialValues={TOUR_CUSTOMER_INITIAL_VALUES} validationSchema={CreateTourCustomerSchema} onSubmit={value => {handleSubmitTourCustomer(value)}}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormAutocompleteSelectPageable
            id="customerId"
            label={intl.formatMessage({id: "customer_field"})}
            placeholder={intl.formatMessage({id: "customer_placeholder"})}
            onChange={(value) => setFieldValue("customerId", value)}
            value={values?.customerId}
            fetchData={(page, filter) => customerService.getCustomersPage(page, {...filter, currentState: WorkflowStatesEnum.ACTIVE, excludedIds: existingCustomerIds})}
            filterFieldName="name"
            required
            error={errors?.customerId}
            touched={touched?.customerId}
          />
          <FormInput
            id="paxClientNumber"
            type="number"
            label="season_pax_client"
            value={values?.paxClientNumber}
            onChange={(e) => setFieldValue("paxClientNumber", e.target.value)}
            error={errors?.paxClientNumber}
            touched={touched?.paxClientNumber}/>
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateTourCustomerForm
