import Button from "../../atoms/Button";
import {ButtonGroup} from "reactstrap";
import React, {FunctionComponent, useEffect, useState} from "react";
import Badge from "../../atoms/Badge";
import {ColorType} from "../../../types/bootstrap/BootstrapType";

export interface HeaderStateButton {
  title: string
  onclick: () => void
  active?: boolean
  badge?: string
}

interface HeaderButtonGroupProps {
  className?: string
  buttonList: HeaderStateButton[]
}

const HeaderButtonGroup: FunctionComponent<HeaderButtonGroupProps> = ({
  className = "",
  buttonList
}) => {
  const [activeButton, setActiveButton] = useState<string>(buttonList.find(button => button.active)?.title)

  useEffect(() => {
    setActiveButton(buttonList.find(button => button.active)?.title)
  }, [buttonList])

  const onButtonClick = (button: HeaderStateButton) => {
    setActiveButton(button.title)
    button.onclick()
  }

  return (
    <ButtonGroup className={className}>
      {buttonList.map((button, index) => (
        <Button key={index} onClick={() => onButtonClick(button)} className={`btn-header ${button.title === activeButton ? "btn-tertiary" : "btn-secondary"}`}>
          <span>{button.title} {button.badge && <Badge color={ColorType.DANGER} className="py-0 px-1" pill>{button.badge}</Badge>}</span>
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default HeaderButtonGroup
