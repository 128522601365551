import {SearchFilter} from "./api/FiltersInterface";
import {Data} from "./TableInterfaces";

export interface ActivityBillingRequest {
  activityStaffingIds: string[],
  amount: number,
  amountHT: number,
  date: string,
  file: File,
  number: string,
}

export interface ActivityBillingSearchObject extends SearchFilter {
  endDate?: string,
  number?: string,
  resourceId?: string,
  resourceTypeIds?: string[],
  startDate?: string
  status?: ActivityBillingStatus
}

export interface ActivityBillingPageData extends Data {
  activitiesAmount: number,
  activityStaffing: ActivityBillingStaffing[],
  billAmount: number,
  billAmountHT: number,
  commented: boolean,
  creationDate: string,
  date: string,
  fileId: string,
  firstStaffingDate: string,
  lastStaffingDate: string,
  number: string,
  paymentDate: string,
  resourceFirstName: string
  resourceLastName: string
  paymentNumber: string | null
}

export interface ActivityBillingStaffing extends Data {
  activityName: string;
  date: string;
  hour: string;
  resourcePrice: number;
  tourCode: string;
  orderItemId?: string
  customerName: string;
  customerReference: string;
  activityDuration: number;
}

export const enum ActivityBillingStatus {
  TO_CHECK = "TO_CHECK",
  ACCEPTED = "ACCEPTED",
  REFUSED = "REFUSED",
  SETTLED = "SETTLED",
}

export interface ActivityBillingCommentRequest {
  comment: string
}

export interface ActivityBillingComment extends Data {
  author: ActivityBillingCommentAuthor;
  comment: string;
  dateCreation: string;
}

export interface ActivityBillingCommentAuthor extends Data {
   firstName: string;
   email: string;
   lastName: string;
}

export interface ActivityBillingRefusedMail {
  template: string,
}
