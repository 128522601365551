import React, {FunctionComponent, useContext, useEffect, useMemo} from "react";
import {RouteProps} from "react-router-dom";
import Layout from "../components/layout/Layout";
import {useIntl} from "react-intl";
import PageTitle from "../components/molecules/header/PageTitle";
import HeaderButtonGroup, {HeaderStateButton} from "../components/molecules/header/HeaderButtonGroup";
import useSearchFilter from "../hooks/useSearchFilter";
import {PurchaseSearchObject, PurchaseStatus} from "../interfaces/PurchaseInterfaces";
import SupplierPurchaseList from "../components/molecules/list/SupplierPurchaseList";
import {ActiveSupplierContext} from "../context/ActiveSupplierContext";

const SupplierPurchaseListView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()
  const activeSupplier = useContext(ActiveSupplierContext)

  const initialSearchState: PurchaseSearchObject = {
    customerOrPurchase: "",
    supplierId: activeSupplier ? activeSupplier.id : "",
    startDate: "",
    endDate: "",
  }
  const {searchObject, onReset, updateSearchField} = useSearchFilter<PurchaseSearchObject>(initialSearchState)

  const stateFilterButtons: HeaderStateButton[] = useMemo(() => [
  {
    title: intl.formatMessage({id: "workflow_purchase_sent"}),
    onclick: () => updateSearchField("status", PurchaseStatus.SENT),
    active: true
  },
  {
    title: intl.formatMessage({id: "workflow_purchase_closed"}),
    onclick: () => updateSearchField("status", PurchaseStatus.CLOSED),
    active: false
  }], [intl])

  useEffect(() => {
      updateSearchField("supplierId", activeSupplier?.id)
      updateSearchField("status", PurchaseStatus.SENT)
  }, [activeSupplier])

  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column">
        <div className="d-flex justify-content-between mb-5">
          <PageTitle pageTitle={intl.formatMessage({id: "purchases_title"})}></PageTitle>
          <HeaderButtonGroup className="d-flex flex-1 justify-content-center me-5" buttonList={stateFilterButtons} />
        </div>

        <SupplierPurchaseList searchObject={searchObject} onReset={() => onReset({status: searchObject.status, supplierId: activeSupplier?.id})} updateSearchField={updateSearchField} />
      </div>
    </Layout>
  )
}

export default SupplierPurchaseListView
