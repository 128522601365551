import {PROFILE, User} from "../../interfaces/UserInterfaces";
import ResourceSelfView from "../../views/profile/ResourceSelfView";
import TourLeaderSelfView from "../../views/profile/TourLeaderSelfView";
import React from "react";

export const USER_PROFILE: Record<PROFILE, { id: string, label: string }> = {
  [PROFILE.ADMIN]: {id: PROFILE.ADMIN, label: "header_profile_ADMIN"},
  [PROFILE.INTERN]: {id: PROFILE.INTERN, label: "header_profile_intern"},
  [PROFILE.RESOURCE]: {id: PROFILE.RESOURCE, label: "header_profile_resource"},
  [PROFILE.CLIENT]: {id: PROFILE.CLIENT, label: "header_profile_customer"},
  [PROFILE.TOUR_LEADER]: {id: PROFILE.TOUR_LEADER, label: "header_profile_tour_leader"},
  [PROFILE.SUPPLIER]: {id: PROFILE.SUPPLIER, label: "header_profile_supplier"},
  [PROFILE.ACCOUNTANT]: {id: PROFILE.ACCOUNTANT, label: "header_profile_accountant"},
}

export const PROFILE_INTERFACE: Partial<Record<PROFILE, JSX.Element>> = {
  RESOURCE: <ResourceSelfView/>,
  TOUR_LEADER: <TourLeaderSelfView/>
}

export const USER_INITIAL_VALUES: User = {
  id: undefined,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  job: "",
  profile: [PROFILE.INTERN]
}
