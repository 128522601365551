import React, {FunctionComponent} from "react";
import {stringUtils} from "../../../../utils/stringUtils";
import {FormattedMessage, useIntl} from "react-intl";
import {CalendarTableHeader} from "../../../../interfaces/CalendarInterface";

const HeaderCalendar: FunctionComponent<{header: CalendarTableHeader}> = ({header}) => {
    const intl = useIntl();
    return (<div className="mb-1 ps-1">
        <div
            className="staffing-calendar__cell lg header">
            <p className="bold text-center mb-1">
                {stringUtils.capitalize(intl.formatDate(header.date, {
                    day: "2-digit",
                    month: "long",
                    weekday: "long"
                }))}
            </p>
            <span className="fw-normal">
                <span
                    className={header.activityStaffedCount == header.activityCount ? "color-primary" : "color-danger"}>
                    {header.activityStaffedCount ?? 0}/{header.activityCount}
                </span> <FormattedMessage id="header_count_activity" />
                {" - "}
                <span
                    className={header.resourceStaffedCount == header.resourceNeededCount ? "color-primary" : "color-danger"}>
                    {header.resourceStaffedCount ?? 0}/{header.resourceNeededCount}
                </span> <FormattedMessage id="header_count_staffing" />
            </span>
        </div>
    </div>);
}

export default HeaderCalendar;
