import * as Yup from "yup";
import {yupRequiredString} from "../yup/Validators";

export const AccountingConfigSchema = Yup.object().shape({
  frAccountNumber: yupRequiredString,
  euAccountNumber: yupRequiredString,
  notUeAccountNumber: yupRequiredString,
  tvaAccountNumber: yupRequiredString,
  activityPurchaseAccountNumber: yupRequiredString
})
