import React, {FunctionComponent, useMemo, useRef} from "react";
import FilterGroup from "../filters/FilterGroup";
import DateFilter from "../filters/DateFilter";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {useIntl} from "react-intl";
import {PagedTableRef} from "../table/PagedTableWithRowSelection";
import {Column} from "react-table";
import {
  ActivityBillingPaymentPageData,
  ActivityBillingPaymentSearchObject
} from "../../../interfaces/ActivityBillingPaymentInterface";
import useSearchFilter from "../../../hooks/useSearchFilter";
import {activityBillingPaymentService} from "../../../services/ActivityBillingPaymentService";
import PagedTable from "../table/PagedTable";
import {tableUtils} from "../../../utils/tableUtils";
import {ACTIVITY_BILLING_PAYMENT_TABLE} from "../../../constants/Table";
import {PaginationQueryParams} from "../../../interfaces/api/PaginationInterface";
import TextFilter from "../filters/TextFilter";
import {fileUtils} from "../../../utils/fileUtils";
import {fileService} from "../../../services/FileService";
import {toastUtils} from "../../../utils/toastUtils";
import {dateUtils} from "../../../utils/dateUtils";

interface ActivityBillingPaymentListProps {
  className?: string,
}

const ActivityBillingPaymentList: FunctionComponent<ActivityBillingPaymentListProps> = ({
  className = "",
}) => {

  const intl = useIntl()
  const initialSearchObject: ActivityBillingPaymentSearchObject = {
    paymentNumber: "",
    startDate: "",
    endDate: "",
  }

  const {searchObject, updateSearchField, onReset} = useSearchFilter<ActivityBillingPaymentSearchObject>(initialSearchObject)
  const ref = useRef<PagedTableRef>()

  const onClickDownloadComptaFile = (selected: ActivityBillingPaymentPageData)  => {
    fileService.getDocumentById(selected.comptaFileId)
      .then((file) => fileUtils.saveFile(file, `Compta_${selected.paymentNumber}_${dateUtils.formatDateYYYYMMDD(new Date(selected.creationDate))}.csv`))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "activity_bill_download_failure"})));
  }

  const onClickDownloadSepaFile = (selected: ActivityBillingPaymentPageData)  => {
    fileService.getDocumentById(selected.sepaFileId)
      .then((file) => fileUtils.saveFile(file, `Sepa_${selected.paymentNumber}_${dateUtils.formatDateYYYYMMDD(new Date(selected.creationDate))}.xml`))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "activity_bill_download_failure"})));
  }

  const onClickDownloadInvoices = (paymentId: string) => {
    activityBillingPaymentService.getActivityBillingPaymentInvoices(paymentId)
      .then((file) => fileUtils.saveFile(file, "invoices.zip"))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "activity_bill_download_failure"})))
  }

  const columns: Column[] = useMemo(() => tableUtils.getColumns(ACTIVITY_BILLING_PAYMENT_TABLE(intl, onClickDownloadComptaFile, onClickDownloadSepaFile, onClickDownloadInvoices)), [intl])

  const getPayments = (pageParams: PaginationQueryParams, filter: ActivityBillingPaymentSearchObject) => {
    return activityBillingPaymentService.getActivityBillingPaymentPage(pageParams, filter)
  }

  return (
    <div className={`${className} d-flex flex-column`}>
      <div className="d-flex justify-content-between">
        <FilterGroup className="d-flex gap-2">
          <TextFilter
            className="main-filter"
            title={intl.formatMessage({id: "payment_table_number"})}
            value={searchObject.paymentNumber}
            onChange={(value) => updateSearchField("paymentNumber", value)}
            placeholder={intl.formatMessage({id: "payment_table_number"})}
          />

          <DateFilter
            value={searchObject.startDate ?? ""}
            onChangeDate={(value) => updateSearchField("startDate", value)}
            title={intl.formatMessage({ id: "activities_billing_filter_from" })}
          />
          <DateFilter
            value={searchObject.endDate ?? ""}
            onChangeDate={(value) => updateSearchField("endDate", value)}
            title={intl.formatMessage({ id: "activities_billing_filter_to" })}
          />
          <div>
            <Button onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({ id: "header_menu_clear_search" })}</Button>
          </div>
        </FilterGroup>
      </div>

      <PagedTable
        ref={ref}
        columns={columns}
        labelNoResult="activities_billing_no_result"
        className={`${className} table`}
        filters={searchObject}
        defaultSortBy={[{ sortBy: "creationDate", descending: true }]}
        fetchData={getPayments}
      />
    </div>
  )
}

export default ActivityBillingPaymentList
