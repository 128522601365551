import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {httpService} from "./HttpService";
import {API_PRODUCT_TYPE_PATH} from "../constants/routes/RoutePaths";
import {ProductType} from "../interfaces/ProductTypeInterfaces";
import {
  ActivityProductTypeSearchObject
} from "../interfaces/ActivityInterfaces";
import {queryUtils} from "../utils/queryUtils";

const getProductTypes = (): Promise<PageResponse<ProductType>> => {
  return httpService.get<PageResponse<ProductType>>(API_PRODUCT_TYPE_PATH);
}

const createProductType = (ProductType: ProductType): Promise<ProductType> => {
  return httpService.post<ProductType>(API_PRODUCT_TYPE_PATH, ProductType);
}

const updateProductType = (ProductType: ProductType): Promise<ProductType> => {
  return httpService.put<ProductType>(`${API_PRODUCT_TYPE_PATH}/${ProductType.id}`, ProductType);
}

const deleteProductType = (id: string): Promise<Response> => {
  return httpService.delete(`${API_PRODUCT_TYPE_PATH}/${id}`);
}

const getProductTypesPage = (params: PaginationQueryParams, filters: ActivityProductTypeSearchObject): Promise<PageResponse<ProductType>>  =>{
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filters)
  return httpService.get<PageResponse<ProductType>>(`${API_PRODUCT_TYPE_PATH}/page${queryParams}`)
}

export const productTypeService = {
  getProductTypes,
  createProductType,
  updateProductType,
  deleteProductType,
  getProductTypesPage
}
