import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {ActivityBillingValidationSchema} from "../../../constants/validation/ActivityBillingValidationSchema";
import {ActivityBillingRequest} from "../../../interfaces/ActivityBllingInterface";
import {activityBillingService} from "../../../services/ActivityBillingService";
import {toastUtils} from "../../../utils/toastUtils";
import EpowForm from "../form/EpowForm";
import FormFileUpload from "../input/FormFileUpload";
import FormInput from "../input/FormInput";
import GenericModal from "./GenericModal";

interface ModalBillActivitiesProps {
  className?: string,
  selectedIds: string[],
  modalOpen: boolean,
  onOpenModal: (open: boolean) => void,
  onValidate: () => void,
}

const ModalBillActivities: FunctionComponent<ModalBillActivitiesProps> = ({
  className = "",
  selectedIds,
  modalOpen,
  onOpenModal,
  onValidate,
}) => {
  const intl = useIntl()

  const handleBillCreation = (request: ActivityBillingRequest) => {
    activityBillingService.createActivityBilling({
      ...request,
      activityStaffingIds: selectedIds
    })
      .then(() => {
        onValidate()
      })
      .catch((error) => {
        error?.message
          ? toastUtils.errorToast(intl.formatMessage({id: "activity_bill_create_error_message"}, {error: error.message}))
          : toastUtils.errorToast(intl.formatMessage({id: "activity_bill_create_error"}))
      })
  }

  const initialValues: ActivityBillingRequest = {
    activityStaffingIds: [],
    date: null,
    number: null,
    amount: null,
    amountHT: null,
    file: null,
  }

  return (
    <Formik initialValues={initialValues} validationSchema={ActivityBillingValidationSchema} onSubmit={value => handleBillCreation(value)}>
      {({errors, touched, values, setFieldValue, submitForm}) => (
        <GenericModal
          className={className}
          title={intl.formatMessage({id: "activity_bill_create"})}
          open={modalOpen}
          onOpen={onOpenModal}
          closable
          footer={{
            primaryAction: {
              buttonLabel: intl.formatMessage({id: "validate_button"}),
              action: () => submitForm()
            },
            secondaryAction: {
              buttonLabel: intl.formatMessage({id: "cancel_button"}),
              action: () => onOpenModal(false)
            }
          }}
        >
          <EpowForm id={"activity_billing_form"} className={`m-4 ${className}`}>
            <FormInput
              id="date"
              type="date"
              label="activity_bill_date"
              value={values?.date}
              onChange={(e) => setFieldValue("date", e.target.value)}
              required
              error={errors.date}
              touched={touched.date}
            />
            <FormInput
              id="number"
              label="activity_bill_bill_number"
              value={values?.number}
              onChange={(e) => setFieldValue("number", e.target.value)}
              required
              error={errors.number}
              touched={touched.number}
            />
            <FormInput
              id="amount"
              label="activity_bill_amount_HT"
              type="number"
              value={values?.amountHT}
              onChange={(e) => setFieldValue("amountHT", e.target.value)}
              required
              error={errors.amountHT}
              touched={touched.amountHT}
            />
            <FormInput
              id="amount"
              label="activity_bill_amount_TTC"
              type="number"
              value={values?.amount}
              onChange={(e) => setFieldValue("amount", e.target.value)}
              required
              error={errors.amount}
              touched={touched.amount}
            />
            <FormFileUpload
              className="w-50"
              id={"billId"}
              label="booking_form_supporting_field"
              required
              accept={[".pdf"]}
              onChange={(file: File) => setFieldValue("file", file)}
              error={errors.file}
              touched={touched.file}
            />
          </EpowForm>
        </GenericModal>
      )}
    </Formik>
  )
}

export default ModalBillActivities;
