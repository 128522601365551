import React, {FunctionComponent, useMemo} from "react";
import {useIntl} from "react-intl";
import GenericModal from "./GenericModal";
import {Formik} from "formik";
import RichTextInput from "../input/RichTextInput";
import EpowForm from "../form/EpowForm";
import {Order} from "../../../interfaces/OrderInterfaces";
import * as Yup from "yup";
import FormMultipleFileUpload from "../input/FormMultipleFileUpload";
import {OrderItem, OrderItemState, OrderMail} from "../../../interfaces/OrderItemInterfaces";
import {ActivityStaffingStatus} from "../../../constants/ActivityStaffingConstants";
import FormInput from "../input/FormInput";
import {yupNullableEmail} from "../../../constants/yup/Validators";


interface ModalSendMailTourLeaderProps {
    className?: string;
    open: boolean,
    order?: Order,
    orderItems?: OrderItem[],
    onSubmit: (content: OrderMail) => void,
    onClose: () => void,
    onOpen: (open: boolean) => void,
}

const ModalSendMailTourLeader: FunctionComponent<ModalSendMailTourLeaderProps> = ({
  className = "",
  open,
  onClose,
  onOpen,
  order,
  orderItems,
  onSubmit: onSubmitProps,
}) => {
  const intl = useIntl();
  const onSubmit = (values: OrderMail) => {
    onSubmitProps(values)
  };

    const tourLeaderName = order.tourLeaders?.length === 1 ? order.tourLeaders[0]?.identity.firstName : "";

    const filterOrderItems = (orderItem: OrderItem) => {
        return orderItem !== undefined && orderItem.status !== OrderItemState.CANCELLED && orderItem.showActivityTourLeader;
    }

    const template = useMemo(() => {
            const template = `
      <p>${intl.formatMessage({id: "tour_leader_order_email_greetings"}, {fullName: `${tourLeaderName}`})}</p>
      <p>
        <span>${intl.formatMessage({id: "tour_leader_order_email_body"})} <b>${order.customerReference}</b></span></br>
        <span>${intl.formatMessage({id: "tour_leader_order_email_body2"})}</span>
      </p>
      <p><b>${order.customer.name} - ${order.customerReference} - ${intl.formatMessage({id: "tour_leader_order_email_order_pax"}, {pax: order.defaultPax})} - ${tourLeaderName}</b></p>
      <p><b>${intl.formatMessage({id: "purchase_order_comment"})}</b> : ${order.orderComment || "-"}</p>
      
      <div>
      ${orderItems.filter((oi) => filterOrderItems(oi)).map(orderItem =>
                `
            <ul>
              <li key={orderItem.id}><b>${orderItem.rendezVousDate} : ${orderItem.name}</b></li>
            </ul>
            <p>
              ${intl.formatMessage({id: "tour_leader_order_email_departure"}, {departureHour: orderItem.departureHour})} -
              ${intl.formatMessage({id: "tour_leader_order_email_departure_place"}, {rendezVousPlace: orderItem.rendezVousPlace})} -
              ${intl.formatMessage({id: "tour_leader_order_email_order_item_pax"}, {pax: orderItem.pax})}
            </p>
            <p>${intl.formatMessage({id: "tour_leader_order_email_comment"}, {description: orderItem.description}).replaceAll("\n", "<br>")}</p>
            ${orderItem.staffingManagement ? `
              <ul> ${orderItem.resourceStaffing.filter((resourceStaffing) => resourceStaffing.status === ActivityStaffingStatus.CONFIRMED).map((resourceStaffing) =>
                    `
                  <li key={resourceStaffing.id}>${resourceStaffing.firstName} ${resourceStaffing.phoneNumber ? (": " + resourceStaffing.phoneNumber) : ""}</li>
                `).join("")}
              </ul>` : ""
                }
            </br>
          `).join("")
            }
      </div>
      <p>${intl.formatMessage({id: "tour_leader_order_email_ending"}, {softouringLink: "<a href='https://www.softouring.net'>www.softouring.net</a>"})}</p>
      <a>${intl.formatMessage({id: "tour_leader_order_email_bye"})}</a>`;
            return template.replaceAll("\n", "");
        },
        [order, orderItems]);

    return (
        <Formik
            enableReinitialize
            initialValues={{template, files: [], emailCc: ""}}
            validationSchema={Yup.object().shape({
                template: Yup.string().required(),
                files: Yup.array<File>().nullable()
                    .max(5, "max_files").test("file_too_large", "file_too_large", (files: File[]) => {
                        return files.every((file: File) => file.size < 3000000)
                    }),
                emailCc: yupNullableEmail,
            })}
            onSubmit={onSubmit}>
            {({values, errors, touched, setFieldValue}) => (
                <GenericModal
                    className={className}
                    title={intl.formatMessage({id: "tour_leader_order_email_title"})}
                    open={open}
                    onOpen={onOpen}
                    footer={{
                        primaryAction: {buttonLabel: intl.formatMessage({id: "send_button"}), action: () => onSubmit(values)},
                        secondaryAction: {buttonLabel: intl.formatMessage({id: "cancel_button"}), action: () => onClose()},
                    }}
                >
                    <EpowForm>
                        <RichTextInput
                            id="content"
                            label="home_comment_content"
                            value={values.template}
                            onChange={(e) => setFieldValue("template", e.target.value)}
                            required
                            error={errors.template}
                            touched={touched.template}
                        />
                        <FormMultipleFileUpload
                            id="files"
                            label="order_tour_leader_mail_pj"
                            files={values?.files}
                            accept={[".jpg", ".jpeg", ".pdf"]}
                            onChange={(files: File[]) => setFieldValue("files", files)}
                            error={errors.files}
                            touched={touched.files}
                        />
                        <FormInput
                            id="email"
                            label="order_tour_leader_mail_cc"
                            value={values?.emailCc}
                            onChange={(e) => setFieldValue("emailCc", e.target.value)}
                            error={errors.emailCc}
                            touched={touched.emailCc}
                        />
                    </EpowForm>
                </GenericModal>
            )}
        </Formik>
    );
};

export default ModalSendMailTourLeader;
