import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {AdminConfigurationMailSchema} from "../../../../constants/validation/admin/AdminConfigurationValidationSchema";
import {AdminConfigurationMailDto} from "../../../../interfaces/AdminConfigurationInterfaces";
import {adminConfigurationService} from "../../../../services/AdminConfigurationService";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../../utils/toastUtils";
import Button from "../../../atoms/Button";
import FormInput from "../../input/FormInput";
import EpowForm from "../EpowForm";
import RichTextInput from "../../input/RichTextInput";

interface AdminConfigurationMailFormProps {
  className?: string,
  onUpdate: () => void,
  onCancel: () => void,
  adminConfiguration: AdminConfigurationMailDto,
}

const AdminConfigurationMailForm: FunctionComponent<AdminConfigurationMailFormProps> = ({
    className = "",
    onUpdate,
    onCancel,
    adminConfiguration,
  }) => {
  const intl = useIntl();
  const initialValues: AdminConfigurationMailDto = {
    mailSignature: "",
    mailContact: ""
  }

  const handleSubmit = (formValue: AdminConfigurationMailDto) => {
    adminConfigurationService.updateAdminConfigurationMail(formValue)
      .then(() => {
        onUpdate()
        toastUtils.successToast(intl.formatMessage({id: "success_toast_update_admin_settings"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_save_admin_config"}))
      })
  }

  return (
    <Formik initialValues={adminConfiguration ?? initialValues} validationSchema={AdminConfigurationMailSchema} onSubmit={handleSubmit}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id="admin-config" className={className}>
          <div className="col">
            <FormInput
              id="mailContact"
              label="admin_config_mail_contact"
              type="text"
              value={values?.mailContact}
              onChange={(e) => setFieldValue("mailContact", e.target.value)}
              required
              error={errors.mailContact}
              touched={touched.mailContact}
            />

            <RichTextInput
              id="mailSignature"
              label="admin_config_mail_signature"
              value={values.mailSignature}
              onChange={(e) => setFieldValue("mailSignature", e.target.value)}
            />
          </div>

          <Button className="me-2" color={ColorType.SECONDARY} onClick={onCancel} >
            <FormattedMessage id="cancel_button" />
          </Button>

          <Button type="submit" >
            <FormattedMessage id="save_button" />
          </Button>

        </EpowForm>
      )}
    </Formik>
  )
}

export default AdminConfigurationMailForm;
