import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import {ActivityBillingPageData, ActivityBillingStatus} from "../../../../interfaces/ActivityBllingInterface";
import {SortOptions} from "../../../../interfaces/api/PaginationInterface";
import {CustomTableColumn, CustomTableType} from "../../../../interfaces/TableInterfaces";
import CustomTable, {CustomTableRef} from "../CustomTable";
import ActivityBillingOverviewTableRow from "./ActivityBillingOverviewTableRow";
import ActivityBillingOverviewTableRowDetails from "./ActivityBillingOverviewTableRowDetails";
import ActivityBillingOverviewTableRowDetailsHeader from "./ActivityBillingOverviewTableRowDetailsHeader";
import {Input} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "../../../atoms/Button";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import ModalItemsToPay from "../../modal/ModalItemsToPay";
import {activityBillingService} from "../../../../services/ActivityBillingService";
import {toastUtils} from "../../../../utils/toastUtils";
import {fileUtils} from "../../../../utils/fileUtils";
import {ApiError} from "../../../../interfaces/ErrorInterfaces";
import GenericModal from "../../modal/GenericModal";

interface ActivityBillingOverviewTableProps {
  className?: string,
  activityBillings: ActivityBillingPageData[],
  sortOptions: SortOptions,
  onSortOptions: (sortOptions: SortOptions) => void,
  disableSelect: boolean,
  status: ActivityBillingStatus,
  refresh: () => void
}

const ActivityBillingOverviewTable: FunctionComponent<ActivityBillingOverviewTableProps> = ({
  className = "",
  activityBillings,
  sortOptions,
  onSortOptions,
  disableSelect,
  status,
  refresh,
}) => {
  const intl = useIntl()
  const [selectedLines, setSelectedLines] = useState<string[]>([])
  const [switchAllValue, setSwitchAllValue] = useState<boolean>(false)
  const [modalOpened, setModalOpened] = useState<boolean>(false)
  const [acceptModalOpen, setAcceptModalOpen] = useState<boolean>(false)

  const ref = React.useRef<CustomTableRef>();

  const switchAll = (checked: boolean) => {
    setSwitchAllValue(checked)
    setSelectedLines(checked ? [...activityBillings.map(line => line.id)] : [])
  }

  const selectLine = (billId: string) => {
    const index = selectedLines.indexOf(billId)
    let newArray: string[]

    if (index > -1) {
      newArray = selectedLines.filter(line => line !== billId)
    } else {
      newArray = selectedLines.concat([billId])
    }

    setSwitchAllValue(newArray.length === activityBillings.length)
    setSelectedLines(newArray)
  }

  const calculateSum = (): number => {
    return activityBillings
      .filter(activityBilling => selectedLines.includes(activityBilling.id))
      .reduce((sum, activityBilling) => sum + activityBilling.billAmount, 0);
  }

  useEffect(() => {
    setSelectedLines([])
    setSwitchAllValue(false)
  }, [activityBillings])

  const columns: CustomTableColumn[] = useMemo(() =>
    [
      {
        accessor: "id", title: "booking_service",
        className: `col-1 ${disableSelect ? "d-none" : "p-2"}`,
        Cell: (
          <>
            <Input
              className="me-2"
              type="checkbox"
              checked={switchAllValue}
              onChange={(value) => switchAll(value.target.checked)}
            />
          </>
        )
      },
      {title: "billing_overview_name", accessor: "resourceIdentityFirstName", sortable: true, className:"col-2 ms-2"},
      {title: "billing_overview_creation_date", accessor: "creationDate", sortable: true, className:"col-2"},
      {title: "billing_overview_date", accessor: "date", sortable: true, className:"col-2"},
      {title: "billing_overview_number", accessor: "number", sortable: true, className:"col-3"},
      {title: "billing_overview_period", accessor: "firstStaffingDate", sortable: false, className:"col-3"},
      {title: "billing_overview_amount_bill", accessor: "amountHT", sortable: true, className:"col-2"},
      {title: "billing_overview_amount_bill_TTC", accessor: "amount", sortable: true, className:"col-2"},
      {title: "billing_overview_amount_activities", accessor: "activitiesAmount", sortable: false, className:"col-2"},
      {title: "billing_overview_nb_activities", accessor: "activityStaffing.length", sortable: false, className:"col-1"},
      {title: "billing_overview_payment_date", accessor: "paymentDate", sortable: true, className: `${disableSelect ? "col-3 ps-4" : "d-none"}`,},
      {title: "billing_overview_download", accessor: "", sortable: false, className:"col-2 px-0 ms-auto pe-2"}
    ], [switchAllValue, disableSelect, activityBillings])

  const generatePayment = () => {
    activityBillingService.generatePayment(selectedLines)
      .then((file) => {
        toastUtils.successToast(intl.formatMessage({id: "activity_bill_pay_modal_action_success"}))
        fileUtils.saveFile(file.blob, file.fileName)
        refresh()
        setModalOpened(false)
      }).catch((error: ApiError) => {
        if (error && error.message) {
          toastUtils.errorToast(error.message)
        } else {
          toastUtils.errorToast(intl.formatMessage({id: "activity_bill_pay_modal_action_failure"}))
        }
      })
  }

  const acceptActivitiesBilling = () => activityBillingService.acceptActivitiesBilling(selectedLines)
    .then(() => {
      toastUtils.successToast(intl.formatMessage({id: "activities_bill_accept_toast_success"}))
      refresh()
    })
    .catch(() => toastUtils.errorToast(intl.formatMessage({id: "activities_bill_accept_toast_error"})))
    .finally(() => setAcceptModalOpen(false))


  return (
    <div className={className}>
      { activityBillings.length ? <CustomTable
        ref={ref}
        onSortOptions={onSortOptions}
        sortOptions={sortOptions}
        className={`${className}`}
        type={CustomTableType.ACTIVITY_BILLING_OVERVIEW}
        datas={activityBillings}
        columns={columns}
        RowComponent={({data, open, ...rest}) => {
          const activityBilling = data as ActivityBillingPageData;
          return (
            <div>
              <ActivityBillingOverviewTableRow
                className="activity-billing-overview-row"
                activityBilling={activityBilling}
                open={open}
                selected={selectedLines.includes(activityBilling.id)}
                selectLine={selectLine}
                disableSelect={disableSelect}
                refresh={refresh}
                status={status}
                {...rest}
              />
              {open && (
                <div className="epow-custom-table-row-details pt-2">
                  <ActivityBillingOverviewTableRowDetailsHeader showFirstColumn={!disableSelect} />
                  {activityBilling.activityStaffing?.map(activityStaffing =>
                    <ActivityBillingOverviewTableRowDetails
                      key={activityStaffing.id}
                      showFirstColumn={!disableSelect}
                      activityStaffing={activityStaffing}
                      className="detail-row"
                    />
                  )}
                </div>
              )}
            </div>);
        }}
      /> :
        (
        <div className="d-flex flex-column align-items-center mt-5">
          <FormattedMessage id="activity_billing_no_result" />
        </div>
        )}
      {
        <>
          {selectedLines.length > 0 &&  (
            <div className="d-flex justify-content-end align-items-center mb-3">
              <div className="me-2 italic">
                <FormattedMessage id="autocomplete_more_than_one_selected" values={{size: selectedLines.length}}/>
              </div>

              { status === ActivityBillingStatus.ACCEPTED &&
                <Button size={SizeType.MD} className="extended" onClick={() => setModalOpened(true)}
                       color={ColorType.SECONDARY}>
                  <FormattedMessage id="activity_bill_pay_button"/>
                </Button>
              }
              { status === ActivityBillingStatus.TO_CHECK &&
                <Button size={SizeType.MD} className="extended" onClick={() => setAcceptModalOpen(true)}
                        color={ColorType.SECONDARY}>
                  <FormattedMessage id="activity_bill_accept_button"/>
                </Button>
              }
            </div>
          )}
        </>
      }

      <ModalItemsToPay
        opened={modalOpened}
        setOpen={setModalOpened}
        action={generatePayment}
        title={intl.formatMessage({id: "activity_bill_pay_modal_tile"})}
        textContent={intl.formatMessage({id: "activity_bill_pay_modal_body"}, { count: selectedLines.length, amount: calculateSum() })}
      />

      <GenericModal
        open={acceptModalOpen}
        footer={{
          primaryAction: {
            buttonLabel: intl.formatMessage({id: "validate_button"}),
            action: acceptActivitiesBilling
          },
          secondaryAction: {
            buttonLabel: intl.formatMessage({id: "cancel_button"}),
            action: () => setAcceptModalOpen(false)
          }
        }}
        title={intl.formatMessage({id: "activity_billing_accept_modal_title"})}
      >
        <FormattedMessage id={"activity_billing_accept_modal_multi_msg"} values={{count: selectedLines.length }}/>
      </GenericModal>
    </div>
  )
}

export default ActivityBillingOverviewTable;
