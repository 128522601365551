import React, {FunctionComponent} from "react";
import LateralPanel from "./LateralPanel";

interface PanelProps {
  className?: string,
  open?: boolean,
  onCancel?: (isVisible?: boolean) => void,
  title?: string,
  children?: React.ReactNode,
  formId?: string,
  cancelButtonLabel?: string,
  displayButton?: boolean
  loading?: boolean,
  displayValidateButton?: boolean,
  validateButtonLabel?: string,
  disabledValidateButton?: boolean
}

const Panel: FunctionComponent<PanelProps> = ({
  className = "",
  open = false,
  onCancel,
  title= "",
  children,
  formId,
  cancelButtonLabel,
  displayButton= true,
  loading,
  displayValidateButton= true,
  validateButtonLabel,
  disabledValidateButton,
}) => {

  return (
    <>
      <div className={`${open ? "backdrop" : "d-none"}`}></div>
      <LateralPanel
        formId={formId}
        className={className}
        title={title}
        open={open}
        loading={loading}
        onCancel={onCancel}
        displayButton={displayButton}
        displayValidateButton={displayValidateButton}
        cancelButtonLabel={cancelButtonLabel}
        validateButtonLabel={validateButtonLabel}
        disabledValidateButton={disabledValidateButton}
      >
        {children}
      </LateralPanel>
    </>

  )
}

export default Panel
