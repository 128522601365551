import {httpService} from "./HttpService";
import {API_CONTACTS_PATH} from "../constants/routes/RoutePaths";
import {Contact, Identity, LineContactCardProps,} from "../interfaces/ContactInterfaces";
import {InvoiceContact} from "../interfaces/InvoiceInterfaces";
import React from "react";

function getContacts(): Promise<Contact[]> {
  return httpService.get<Contact[]>(API_CONTACTS_PATH);
}

function updateContact(id: string, contact: Contact): Promise<Contact> {
  return httpService.put<Contact>(`${API_CONTACTS_PATH}/${id}`, contact);
}

function buildLineContact(identity: Identity): LineContactCardProps[] {
  return [
    {
      title: "email",
      content: identity?.email,
      icon: "Email",
      href: "mailto",
    },
    {
      title: "phoneNumber",
      content: identity?.phoneNumber,
      icon: "Phone",
      href: "tel",
    },
  ];
}

function buildCustomerLineContact(invoiceContact: InvoiceContact): LineContactCardProps[] {

  const address = invoiceContact?.address;

  return [
    {
      title: "address",
      customContent:
        <div className="d-flex flex-column">
          <span>{address?.mainLabel}</span>
          <span>{address?.additionalInformation}</span>
          <span>{address?.zipCode} {address?.city}</span>
        </div>,
      icon: "LocationDot",
    },
    {
      title: "email",
      content: invoiceContact?.email,
      icon: "Phone",
      href: "mailto",
    },
    {
      title: "phoneNumber",
      content: invoiceContact?.phoneNumber,
      icon: "Email",
      href: "tel",
    },
  ];
}

function deleteContact(contactId: string): Promise<Response> {
  return httpService.delete(`${API_CONTACTS_PATH}/${contactId}`)
}

export const contactService = {
  getContacts,
  buildCustomerLineContact,
  updateContact,
  buildLineContact,
  deleteContact,
};
