import {ActivitySellingPriceCatalog} from "../../../interfaces/ActivityInterfaces";
import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {dateUtils} from "../../../utils/dateUtils";
import Icon from "../../icon/Icon";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {optionUtils} from "../../../utils/optionUtils";
import {UNIT_TYPE_OPTIONS} from "../../../constants/OptionConstants";
import {numberUtils} from "../../../utils/numberUtils";
import {Col, Row} from "reactstrap";
import FieldLabel from "../../atoms/FieldLabel";
import {activityService} from "../../../services/ActivityService";
import GenericModalDelete from "../modal/GenericModalDelete";

interface ActivitySellingPriceListProps {
  className?: string,
  activitiesSellingPriceCatalog?: ActivitySellingPriceCatalog[],
  setLateralPanelActivitySellingPrice?: (open?: boolean) => void,
  setInitialActivitySellingPrice?: (activitySellingPriceCatalog?: ActivitySellingPriceCatalog) => void,
  onValidate?: () => void
}

const ActivitySellingPriceList: FunctionComponent<ActivitySellingPriceListProps> = ({
  className = "",
  activitiesSellingPriceCatalog,
  setLateralPanelActivitySellingPrice,
  setInitialActivitySellingPrice,
  onValidate
}) => {
  const intl = useIntl()
  const [openModalDeleteSellingPrice, setOpenModalDeleteSellingPrice] = useState<boolean>(false)

  const handleModalDeleteSellingPrice = () => {
    setOpenModalDeleteSellingPrice(false)
    onValidate && onValidate()
  }

  return (
    <div className={className}>
      {activitiesSellingPriceCatalog?.length > 0 && activitiesSellingPriceCatalog.map((activitySellingPrice, key) =>
        <div key={key} className="d-flex flex-row justify-content-between mb-5">
          <Row className="w-100">
            <Col xs={24} sm={3}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "activity_start_date_validity"})}
                value={dateUtils.formatDateDayJs(activitySellingPrice?.startDateValidity)}
              />
            </Col>
            <Col xs={12} sm={2}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "service_unit_type"})}
                value={optionUtils.translateOption(intl, UNIT_TYPE_OPTIONS, activitySellingPrice?.unitType)}
              />
            </Col>
            <Col xs={12} sm={2}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "activity_tariff"})}
                value={numberUtils.formatPrice(activitySellingPrice?.sellingPrice)}
              />
            </Col>
            <Col xs={24} sm={16}>
              <FieldLabel
                verticalDisplay
                className="white-space-pre-wrap"
                label={intl.formatMessage({id: "activity_description"})}
                value={activitySellingPrice?.description}
              />
            </Col>
          </Row>
          <div className="d-flex flex-column">
            <span className="text-muted"><FormattedMessage id="action_button"/></span>
            <div className="d-flex flex-row">
              <Icon
                name="Pen"
                size={IconSizeType.XS}
                className={"pe-2"}
                onClick={() => {
                  setLateralPanelActivitySellingPrice(true)
                  setInitialActivitySellingPrice(activitySellingPrice)
                }}
              />
              <Icon
                name="Trash"
                size={IconSizeType.XS}
                className={"pe-2"}
                onClick={() => setOpenModalDeleteSellingPrice(true)}
              />
            </div>
          </div>
          <GenericModalDelete
            titleId="modal_title_delete_selling_price"
            open={openModalDeleteSellingPrice}
            onValidate={handleModalDeleteSellingPrice}
            setOpen={setOpenModalDeleteSellingPrice}
            idEntity={activitySellingPrice?.id}
            onDelete={activityService.deleteActivitySellingPriceCatalog}
            successToastMessageId="success_toast_delete_activity_selling_price"
            errorToastMessageId="error_toast_delete_activity_selling_price"
            bodyDeleteMessage={intl.formatMessage({id: "modal_body_delete_selling_price"})}
          />
        </div>
      )}
    </div>
  )
}

export default ActivitySellingPriceList
