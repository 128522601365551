import {PaymentCondition} from "../interfaces/CustomerInterfaces";
import {Option} from "../interfaces/inputs/OptionInterfaces";
import {InvoiceState, InvoiceType} from "../interfaces/InvoiceInterfaces";
import {ORDER_ITEM_TYPE, OrderItemState, UNIT_TYPE} from "../interfaces/OrderItemInterfaces";
import { StatusResource, ResourceLevel, ResourceAvailability, PublicType } from "./ResourceConstants";

export const enum TRUE_FALSE_ENUM {
  TRUE = "true",
  FALSE = "false"
}

export const YES_NO_OPTIONS: Option<string>[] = [
  {label: "yes_option", value: TRUE_FALSE_ENUM.TRUE},
  {label: "no_option", value: TRUE_FALSE_ENUM.FALSE}
]

export const GENDER_OPTIONS: Option<string>[] = [
  {label: "female_option", value: "F"},
  {label: "male_option", value: "H"}
]

export const ORDER_ITEM_TYPE_OPTIONS: Option<string>[] = [
  {label: "order_item_type_activity", value: ORDER_ITEM_TYPE.ACTIVITY},
  {label: "order_item_type_service", value: ORDER_ITEM_TYPE.SERVICE}
]


export const UNIT_TYPE_LABEL: Record<UNIT_TYPE, {label: string}> = {
  [UNIT_TYPE.PAX]: {
    label: "order_item_unit_type_pax"
  },
  [UNIT_TYPE.FIXED_RATE]: {
    label: "order_item_unit_type_fixed_rate"
  }
}

export const UNIT_TYPE_OPTIONS: Option<string>[] = [
  {label: "order_item_unit_type_pax", value: UNIT_TYPE.PAX},
  {label: "order_item_unit_type_fixed_rate", value: UNIT_TYPE.FIXED_RATE}
]

export const RESOURCE_STATUS_OPTIONS: Option<string>[] = [
  {label: "EMPLOYEE", value: StatusResource.EMPLOYEE},
  {label: "INDEPENDENT", value: StatusResource.INDEPENDANT}
]

export const RESOURCE_LEVEL_OPTIONS: Option<string>[] = [
  {label: "JUNIOR", value: ResourceLevel.JUNIOR},
  {label: "SENIOR", value: ResourceLevel.SENIOR}
]

export const RESOURCE_AVAILABILITY_OPTIONS: Option<string>[] = [
  {label: "LOW", value: ResourceAvailability.LOW},
  {label: "MEDIUM", value: ResourceAvailability.MEDIUM},
  {label: "HIGH", value: ResourceAvailability.HIGH}
]

export const PAYMENT_CONDITION_OPTIONS: Option<PaymentCondition>[] = [
  {label: "payment_condition_AT_RECEPTION", value: PaymentCondition.AT_RECEPTION},
  {label: "payment_condition_THIRTY_DAYS_AFTER_RECEPTION", value: PaymentCondition.THIRTY_DAYS_AFTER_RECEPTION},
]

export const PAGE_SIZE_OPTIONS: Option<number>[] = [
  {label: "10", value: 10},
  {label: "20", value: 20},
  {label: "50", value: 50},
  {label: "100", value: 100},
  {label: "200", value: 200},
  {label: "1000", value: 1000},
]

export const CALENDAR_SIZE_OPTIONS: Option<string>[] = [
  {label: "30", value: "30"},
  {label: "50", value: "50"},
  {label: "100", value: "100"},
]


export const PUBLIC_TYPE_CUSTOMER_OPTIONS: Option<string>[] = [
  {label: "public_type_group", value: PublicType.GROUP},
  {label: "public_type_private", value: PublicType.PRIVATE}
]

export const PUBLIC_TYPE_OPTIONS: Option<string>[] = [
  {label: "public_type_group", value: PublicType.GROUP},
  {label: "public_type_private", value: PublicType.PRIVATE},
  {label: "public_type_kids_friendly", value: PublicType.KIDS_FRIENDLY}
]

export const RESOURCE_STAFFING_AVAILABILITY_OPTIONS: Option<string>[] = [
  {label: "ALL", value: ""},
  {label: "LOW", value: ResourceAvailability.LOW},
  {label: "MEDIUM", value: ResourceAvailability.MEDIUM},
  {label: "HIGH", value: ResourceAvailability.HIGH},
]

export const INVOICE_TYPE_OPTIONS: Option<string>[] = [
  {label: "INVOICE", value: InvoiceType.INVOICE},
  {label: "CREDIT_NOTE", value: InvoiceType.CREDIT_NOTE},
]

export const INVOICE_STATUS_OPTIONS: Option<string>[] = [
  {label: "BILLED", value: InvoiceState.BILLED},
  {label: "PAID", value: InvoiceState.PAID},
  {label: "LATE", value: InvoiceState.LATE},
]

export const ORDER_ITEM_BILLING_STATUS_OPTIONS: Option<string>[] = [
{label: "order_item_state_pending", value: OrderItemState.PENDING},
{label: "order_item_state_validated", value: OrderItemState.VALIDATED},
{label: "order_item_state_cancelled", value: OrderItemState.CANCELLED},
]
