import React, {createContext, FunctionComponent, useState} from "react";
import {SupplierIdentity} from "../interfaces/SupplierInterfaces";

const ActiveSupplierContext = createContext<SupplierIdentity>(undefined)
const ActiveSupplierDispatchContext = createContext<React.Dispatch<SupplierIdentity>>(undefined)

const ActiveSupplierProvider: FunctionComponent<{children: React.ReactNode}> = ({children}) => {
  const [supplier, setSupplier] = useState(JSON.parse(localStorage.getItem("activeSupplierForUser")));
  const setActiveSupplier = (supplier: SupplierIdentity) => {
    localStorage.setItem("activeSupplierForUser", JSON.stringify(supplier));
    setSupplier(supplier)
  }

  return (
    <ActiveSupplierContext.Provider value={supplier}>
      <ActiveSupplierDispatchContext.Provider value={setActiveSupplier}>
        {children}
      </ActiveSupplierDispatchContext.Provider>
    </ActiveSupplierContext.Provider>
  )
}

export {ActiveSupplierProvider, ActiveSupplierContext, ActiveSupplierDispatchContext}
