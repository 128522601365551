import React, {FunctionComponent} from "react";
import {ToggleUnpaidInvoice, ToggleUnpaidInvoiceBillingItem} from "../../../../interfaces/InvoicePaymentInterfaces";
import {Col, Row} from "reactstrap";
import Icon from "../../../icon/Icon";
import {ColorType, IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import {IconName} from "../../../icon/IconsMap";
import {moneyUtils} from "../../../../utils/moneyUtils";


interface InvoicePaymentCreateRowProps {
  onRowClicked: () => void,
  element: ToggleUnpaidInvoice,
  onIconClicked: () => void,
  predicate: (items: ToggleUnpaidInvoiceBillingItem) => boolean,
  itemsRow: (paymentItem:  ToggleUnpaidInvoiceBillingItem) => JSX.Element
  open: boolean,
  iconColor?: ColorType,
  iconName?: IconName,
}

const InvoicePaymentCreateRow: FunctionComponent<InvoicePaymentCreateRowProps> = ({
  onRowClicked,
  element,
  onIconClicked,
  predicate,
  itemsRow,
  open,
  iconColor = ColorType.SUCCESS,
  iconName = "CirclePlus",
  }) => {

  const getSumAmountToPaid = () => {
    return moneyUtils.formatNumberToCurrency(element.paymentItems?.filter(predicate).map(item => item.amountToPaid).reduce((sum: number, amountToPaid:number) => sum + amountToPaid ,0))
  }

  return (
    <Row className="epow-custom-table invoice-payment-row rounded-1 mb-1 cursor-pointer" onClick={onRowClicked}>
      <Col className="d-flex my-4">
        <Icon className="m-0 me-2" name={open ? "ChevronUp" : "ChevronDown"} size={IconSizeType.XS} />
        <span>{element.referenceNumber}</span>
      </Col>
      <Col className="my-4">
        <span>
          {getSumAmountToPaid()}
        </span>
      </Col >
      <Col className="my-4">
        <span>{moneyUtils.formatNumberToCurrency(element.totalRemainingAmountTTC)}</span>
      </Col>
      <Col className="my-4">
        <span>{moneyUtils.formatNumberToCurrency(element.totalAmountTTC)}</span>
      </Col>
      <Col className="my-4" xs={2}>
        <Icon
          name={iconName}
          size={IconSizeType.XS}
          color={iconColor}
          className="me-2 float-end cursor-pointer"
          onClick={onIconClicked}
        />
      </Col>

      {open && (element.paymentItems?.filter(predicate).map(itemsRow))}
    </Row>
  )
}

export default InvoicePaymentCreateRow;
