import React, {FunctionComponent, useEffect, useState} from "react";
import {RouteProps, useNavigate, useParams} from "react-router-dom";
import Layout from "../components/layout/Layout";
import {toastUtils} from "utils/toastUtils";
import {serviceService} from "services/ServiceService";
import {Service} from "../interfaces/ServiceInterfaces";
import {FormattedMessage, useIntl} from "react-intl";
import {SERVICES_PATH} from "../constants/routes/RoutePaths";
import {WorkflowActionsEnum, WorkflowStateMap, WorkflowStatesEnum} from "../constants/workflow/WorkflowStates";
import Badge from "../components/atoms/Badge";
import WorkflowButtonList from "../components/molecules/workflow/WorkflowButtonList";
import TabList from "../components/molecules/tab/TabList";
import {serviceTab} from "../constants/tabs/TabListConstants";
import {Supplier} from "../interfaces/SupplierInterfaces";
import PageTitle from "../components/molecules/header/PageTitle";

const ServiceView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {id} = useParams();

  const [service, setService] = useState<Service>({})
  const [suppliersForService, setSuppliersForService] = useState<Supplier[]>(null)

  const getService = () => {
    serviceService.getService(id)
      .then((service) => {
        setService(service)
        setSuppliersForService(service?.suppliersForService)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_one_service"}))
      })
  }

  const handleDeleteButton = () => {
    serviceService.deleteService(service.id).then(() => {
      toastUtils.infoToast(intl.formatMessage({id: "workflow_delete_info"}, { entity: intl.formatMessage({id: "workflow_entity_service"}) }))
      navigate(SERVICES_PATH)
    }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_delete_failure"}, { entity: intl.formatMessage({id: "workflow_entity_service"}) }))
    })
  }

  const handleActivateButton = () => {
    serviceService.updateService(service.id, {...service, currentState: WorkflowStatesEnum.ACTIVE, vatRateId: service.vatRate.id})
      .then((response: Service) => {
        toastUtils.infoToast(intl.formatMessage({id: "workflow_validate_info"}, { entity: intl.formatMessage({id: "workflow_entity_service"}) }))
        setService({...response, suppliersForService})
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_validate_failure"}, { entity: intl.formatMessage({id: "workflow_entity_service"}) }))
    })
  }

  const handleDeactivateButton = () => {
    serviceService.updateService(service.id, {...service, currentState: WorkflowStatesEnum.INACTIVE, vatRateId: service.vatRate.id})
      .then((response: Service) => {
        toastUtils.infoToast(intl.formatMessage({id: "workflow_deactivate_info"}, { entity: intl.formatMessage({id: "workflow_entity_service"}) }))
        setService({...response, suppliersForService})
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_deactivate_failure"}, { entity: intl.formatMessage({id: "workflow_entity_service"}) }))
    })
  }

  const handleReactivateButton = () => {
    serviceService.updateService(service.id, {...service, currentState: WorkflowStatesEnum.ACTIVE, vatRateId: service.vatRate.id})
      .then((response: Service) => {
        toastUtils.infoToast(intl.formatMessage({id: "workflow_reactivate_info"}, { entity: intl.formatMessage({id: "workflow_entity_service"}) }))
        setService({...response, suppliersForService})
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_reactivate_failure"}, { entity: intl.formatMessage({id: "workflow_entity_service"}) }))
    })
  }

  useEffect(() => {
    getService()
  }, [id])

  return (
    <Layout>
      <div className="epow-content-body">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <PageTitle
              pageTitle={service.name}
              className="me-4"
              breadcrumbItems={[{
                title: intl.formatMessage({id: "service_title"}),
                to: SERVICES_PATH
              }]}
            />
            <span className="me-1 text-muted"><FormattedMessage id="status" /> :</span>
            <Badge pill color={WorkflowStateMap[service.currentState]?.color} className="ms-2 mb-1">
              {intl.formatMessage({id: WorkflowStateMap[service.currentState]?.label || "STATUS_DEFAULT"})}
            </Badge>
          </div>
          <WorkflowButtonList
            currentState={service.currentState}
            entityLabel={intl.formatMessage({id: "workflow_entity_service"})}
            actions={{
              [WorkflowActionsEnum.DELETE]: handleDeleteButton,
              [WorkflowActionsEnum.ACTIVATE]: handleActivateButton,
              [WorkflowActionsEnum.DEACTIVATE]: handleDeactivateButton,
              [WorkflowActionsEnum.REACTIVATE]: handleReactivateButton
            }}
          />
        </div>
        <TabList items={serviceTab(service, getService)}/>
      </div>
    </Layout>
  )
}

export default ServiceView
