import React, {FunctionComponent} from "react";
import {Col, Row} from "reactstrap";
import {ActivityBillingStaffing} from "../../../../interfaces/ActivityBllingInterface";
import {dateUtils} from "../../../../utils/dateUtils";
import {authUtils} from "../../../../utils/authUtils";
import {PROFILE} from "../../../../interfaces/UserInterfaces";
import Icon from "../../../icon/Icon";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";

interface ActivityBillingOverviewTableRowDetailsProps {
  className?: string,
  activityStaffing: ActivityBillingStaffing,
  showFirstColumn?: boolean,
  openOrderItemDetails?: (id: string) => void
}

const ActivityBillingOverviewTableRowDetails: FunctionComponent<ActivityBillingOverviewTableRowDetailsProps> = ({
  className = "",
  activityStaffing,
  showFirstColumn,
  openOrderItemDetails
}) => {

  const getDateHour = () => {
    const date = dateUtils.formatDateDayJs(activityStaffing.date) ?? "";
    const hour = dateUtils.formatLocalTime(activityStaffing.hour) ?? ""
    return `${date} - ${hour}`
  }

  return (
    <Row className={`ms-0 ${className}`}>
      {showFirstColumn && <Col xs={1} className="ps-0" />}
      <Col xs={3} className="ps-0 ms-2">
        {`${activityStaffing.tourCode ?? "-"}`}
      </Col>
      <Col xs={6} className="ps-0">
        {activityStaffing.activityName ?? "-"}
      </Col>
      <Col xs={4} className="ps-0">
        {activityStaffing.customerName}
      </Col>
      <Col xs={3} className="ps-0">
        {activityStaffing.customerReference}
      </Col>
      <Col xs={3} className="ps-0">
        {getDateHour()}
      </Col>
      <Col xs={3} className="ps-0">
        {dateUtils.formatDuration(activityStaffing.activityDuration)}
      </Col>
      { authUtils.getProfile() === PROFILE.RESOURCE &&
        <Col xs={1} className="ps-0">
          <Icon name="Search" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => openOrderItemDetails(activityStaffing?.orderItemId)}/>
        </Col>
      }
    </Row>
  )
}

export default ActivityBillingOverviewTableRowDetails;
