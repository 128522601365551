import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Col, Row} from "reactstrap";
import {moneyUtils} from "../../../utils/moneyUtils";
import {InvoicePaymentDetail} from "../../../interfaces/InvoicePaymentInterfaces";

interface InvoicePaymentSummaryDetailsProps {
  className?: string,
  invoicePaymentDetail: InvoicePaymentDetail,
}

const InvoicePaymentSummaryDetails: FunctionComponent<InvoicePaymentSummaryDetailsProps> = ({
    className = "",
    invoicePaymentDetail,
  }) => {

  const [totalPaid, setTotalPaid] = useState<number>(0)
  const totalRemaining = invoicePaymentDetail.totalAmountTTC - totalPaid

  const computeTotalPaid = () => {
    const total = invoicePaymentDetail.invoicePaymentItems
      .flatMap(invoicePaymentItem => invoicePaymentItem.paymentItems)
      .map(paymentItem => paymentItem.amountPaid)
      .reduce((total: number, amountPaid: number) => amountPaid + total , 0);
    setTotalPaid(total);
  }

  useEffect(() => {
    computeTotalPaid()
  }, []);

  return (
    <div className={className}>
      <Col xs={{offset: 18, size: 6}}>
        <div className="card m-2 p-2">
          <Row className="text-end">
            <Col className="fw-bold"><FormattedMessage id="invoice_payment_total_paid"/></Col>
            <Col>{moneyUtils.formatNumberToCurrency(totalPaid)}</Col>
          </Row>
          <Row className="text-end pt-2">
            <Col className="fw-bold"><FormattedMessage id="invoice_payment_total_remaining"/></Col>
            <Col>{moneyUtils.formatNumberToCurrency(totalRemaining)}</Col>
          </Row>
          <Row className="text-end pt-2">
            <Col className="fw-bold"><FormattedMessage id="invoice_header_total_ttc"/></Col>
            <Col>{moneyUtils.formatNumberToCurrency(invoicePaymentDetail.totalAmountTTC)}</Col>
          </Row>
        </div>
      </Col>
    </div>
  )
}

export default InvoicePaymentSummaryDetails;
