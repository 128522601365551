import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Row} from "reactstrap";
import {FileType} from "../../../../constants/FileConstants";
import {ResourceLegalInfo} from "../../../../interfaces/ResourceInterfaces";
import {resourceService} from "../../../../services/ResourceService";
import {ColorType, IconSizeType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import {dateUtils} from "../../../../utils/dateUtils";
import {fileUtils} from "../../../../utils/fileUtils";
import Button from "../../../atoms/Button";
import CardFileField from "../CardFileField";
import FieldLabel from "../../../atoms/FieldLabel";
import Icon from "../../../icon/Icon";
import GenericModalDelete from "../../modal/GenericModalDelete";

interface LegalInfoResourceCardProps {
  className?: string;
  resourceLegalInfo?: ResourceLegalInfo;
  editInfo?: boolean;
  setEditInfo?: (editInfo: boolean) => void;
  onChangeResourceAfterUpdate?: (resourceLegalInfo: ResourceLegalInfo) => void;
  self?: boolean;
}

const LegalInfoResourceCard: FunctionComponent<LegalInfoResourceCardProps> = ({
  className = "",
  resourceLegalInfo,
  editInfo,
  setEditInfo,
  onChangeResourceAfterUpdate,
  self
}) => {
  const intl = useIntl();

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>()
  const [documentTypeToDelete, setDocumentTypeToDelete] = useState<FileType>()

  const downloadLiabilityCard = () => {
    fileUtils.downloadFile(
      resourceService.getCard(resourceLegalInfo.id, FileType.LIABILITY_CARD),
      resourceLegalInfo.liabilityCard,
      intl
    );
  }

  const downloadUrssafCard = () => {
    fileUtils.downloadFile(
      resourceService.getCard(resourceLegalInfo.id, FileType.URSSAF_CARD),
      resourceLegalInfo.urssafCard,
      intl
    );
  }

  const deleteDocument = (documentId: string, documentType: FileType) => {
    setSelectedDocumentId(documentId);
    setDocumentTypeToDelete(documentType);
    setOpenDeleteModal(true);
  }

  const deleteCardBasedOnType = () => {
    if (documentTypeToDelete === FileType.LIABILITY_CARD) {
      return self ? resourceService.deleteSelfFile(FileType.LIABILITY_CARD) : resourceService.deleteFile(resourceLegalInfo.id, FileType.LIABILITY_CARD);
    } else if (documentTypeToDelete === FileType.URSSAF_CARD) {
      return self ? resourceService.deleteSelfFile(FileType.URSSAF_CARD) : resourceService.deleteFile(resourceLegalInfo.id, FileType.URSSAF_CARD);
    }
  };

  const doAfterDelete = () => {
    setOpenDeleteModal(false);
    if(documentTypeToDelete === FileType.LIABILITY_CARD) {
      onChangeResourceAfterUpdate({...resourceLegalInfo, liabilityCard: null, rcpCoverStartDate: null, rcpValidityDate: null});
    } else if(documentTypeToDelete === FileType.URSSAF_CARD) {
      onChangeResourceAfterUpdate({...resourceLegalInfo, urssafCard: null, urssafSituationDate: null});
    }
  }

  return (
    <div className={className}>
      <Row>
        <Col xs={24} md={8}>
          <div className="d-flex flex-column gap-3 mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <CardFileField
                label={intl.formatMessage({ id: "resource_public_liability_card" })}
                value={fileUtils.getCompleteFileName(resourceLegalInfo?.liabilityCard)}
                onClick={downloadLiabilityCard}
              />
              {resourceLegalInfo?.liabilityCard &&
                <Icon name="Trash" size={IconSizeType.XS} className="cursor-pointer mb-1"
                      onClick={() => deleteDocument(resourceLegalInfo?.liabilityCard?.id, FileType.LIABILITY_CARD)}/>}
            </div>
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "resource_rcp_cover_start_date" })}
              value={dateUtils.formatDateDayJs(resourceLegalInfo?.rcpCoverStartDate)}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "resource_rcp_validity_date" })}
              value={dateUtils.formatDateDayJs(resourceLegalInfo?.rcpValidityDate)}
            />
          </div>
        </Col>

        <Col xs={24} md={8}>
          <div className="d-flex flex-column gap-3 mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <CardFileField
                label={intl.formatMessage({id: "resource_urssaf_card"})}
                value={fileUtils.getCompleteFileName(resourceLegalInfo?.urssafCard)}
                onClick={downloadUrssafCard}
              />
              {resourceLegalInfo?.urssafCard &&
                <Icon name="Trash" size={IconSizeType.XS} className="cursor-pointer mb-1"
                      onClick={() => deleteDocument(resourceLegalInfo?.urssafCard?.id, FileType.URSSAF_CARD)}/>}
            </div>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "resource_urssaf_situation_date"})}
                value={dateUtils.formatDateDayJs(resourceLegalInfo?.urssafSituationDate)}
              />
            </div>
        </Col>
      </Row>

      <div>
        <Button
          size={SizeType.MD}
          color={ColorType.SECONDARY}
          onClick={() => setEditInfo(!editInfo)}
          className="extended"
        >
          <FormattedMessage id="edit_button" />
        </Button>
      </div>
      <GenericModalDelete
        titleId="modal_title_delete_document"
        open={openDeleteModal}
        onValidate={doAfterDelete}
        setOpen={setOpenDeleteModal}
        idEntity={selectedDocumentId}
        onDelete={deleteCardBasedOnType}
        successToastMessageId="success_delete_document"
        errorToastMessageId="error_toast_delete_document"
        bodyDeleteMessage={intl.formatMessage({id: "modal_body_delete_document"})}
      />
    </div>
  )
}

export default LegalInfoResourceCard;
