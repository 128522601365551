import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {BankAccount} from "../../../interfaces/BankAccountInterface";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import Icon from "../../icon/Icon";
import Accordion from "../accordion/Accordion";
import CreateBankAccountForm from "../form/CreateBankAccountForm";
import ModalUpdateDefaultBankAccount from "../modal/ModalUpdateDefaultBankAccount";
import Panel from "../panel/Panel";
import FieldLabel from "../../atoms/FieldLabel";
import GenericModalDelete from "../modal/GenericModalDelete";
import {bankAccountService} from "../../../services/BankAccountService";

interface BankAccountItemProps {
  className?: string;
  bankAccount?: BankAccount;
  supplierId?: string;
  resourceId?: string;
  onUpdate?: (bankAccount: BankAccount) => void;
  onDelete?: (bankAccountId: string) => void;
  onUpdateDefaultBankAccount?: (bankAccountId: string) => void;
}

const BankAccountItem: FunctionComponent<BankAccountItemProps> = ({
  className = "",
  bankAccount,
  supplierId,
  resourceId,
  onUpdate = () => null,
  onDelete = () => null,
  onUpdateDefaultBankAccount = () => null
}) => {
  const intl = useIntl()
  const [lateralPanelBankAccount, setLateralPanelBankAccount] =
    useState<boolean>(false);
  const [
    openModalDeleteBankAccount,
    setOpenModalDeleteBankAccount,
  ] = useState<boolean>(false);
  const [openModalUpdateMainBankAccount, setOpenModalUpdateMainBankAccount] =
    useState<boolean>(false);

  const handleUpdate = (bankAccount: BankAccount) => {
    setLateralPanelBankAccount(false);
    onUpdate(bankAccount);
  };

  const handleModalDeleteBankAccount = () => {
    onDelete(bankAccount.id);
    setOpenModalDeleteBankAccount(false);
  };

  const handleUpdateDefaultBankAccount = () => {
    onUpdateDefaultBankAccount(bankAccount.id);
    setOpenModalUpdateMainBankAccount(false);
  };

  return (
    <div className={className}>
      <Accordion
        title={bankAccount?.accountName}
        startOpen
        displayStar={bankAccount.isDefault}
      >
        <div className="d-flex flex-row justify-content-between gap-5">
          <div className="d-flex flex-row flex-1 flex-wrap">
            <FieldLabel
              verticalDisplay
              className="me-5"
              label={intl.formatMessage({id: "bank_name"})}
              value={bankAccount?.bankName}
            />

            <div className="d-flex flex-wrap me-5">
              <FieldLabel
                verticalDisplay
                className="me-3"
                label={intl.formatMessage({id: "bank_code"})}
                value={bankAccount?.bankCode}
              />

              <FieldLabel
                verticalDisplay
                className="me-3"
                label={intl.formatMessage({id: "bank_counter_code"})}
                value={bankAccount?.counterCode}
              />

              <FieldLabel
                verticalDisplay
                className="me-3"
                label={intl.formatMessage({id: "bank_account_number"})}
                value={bankAccount?.accountNumber}
              />

              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "bank_rib_key"})}
                value={bankAccount?.ribKey}
              />
            </div>

            <FieldLabel
              verticalDisplay
              className="me-5"
              label={intl.formatMessage({id: "bank_iban_code"})}
              value={bankAccount?.ibanCode}
            />

            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({id: "bank_bic_code"})}
              value={bankAccount?.bicCode}
            />
          </div>

          <div className="mb-2 d-flex flex-column">
            <span className="text-muted mb-1">
              <FormattedMessage id="action_button" />
            </span>
            <div className="d-flex flex-row gap-2">
              {!bankAccount.isDefault && (
                <Icon
                  name="Star"
                  size={IconSizeType.XS}
                  onClick={() => setOpenModalUpdateMainBankAccount(true)}
                />
              )}
              <Icon
                name="Pen"
                size={IconSizeType.XS}
                onClick={() => setLateralPanelBankAccount(true)}
              />
              <Icon
                name="Trash"
                size={IconSizeType.XS}
                onClick={() => setOpenModalDeleteBankAccount(true)}
              />
            </div>
            {lateralPanelBankAccount && (
              <Panel
                formId="createBankAccountForm"
                title="modify_bank_account"
                open={lateralPanelBankAccount}
                onCancel={() => setLateralPanelBankAccount(false)}
              >
                <CreateBankAccountForm
                  id="createBankAccountForm"
                  resourceId={resourceId}
                  supplierId={supplierId}
                  initialBankAccount={bankAccount}
                  onUpdate={handleUpdate}
                  className="d-flex flex-column"
                />
              </Panel>
            )}
          </div>
          <GenericModalDelete
            titleId="modal_title_delete_bank_account"
            open={openModalDeleteBankAccount}
            onValidate={handleModalDeleteBankAccount}
            setOpen={setOpenModalDeleteBankAccount}
            idEntity={bankAccount.id}
            onDelete={bankAccountService.deleteBankAccount}
            successToastMessageId="success_delete_bank_account"
            errorToastMessageId="error_delete_bank_account"
            bodyDeleteMessage={intl.formatMessage({ id: "modal_body_delete_bank_account" }, { accountName: bankAccount.accountName })}
          />
          <ModalUpdateDefaultBankAccount
            resourceId={resourceId}
            supplierId={supplierId}
            bankAccount={bankAccount}
            handleUpdateDefaultBankAccount={handleUpdateDefaultBankAccount}
            openModalUpdateMainBankAccount={openModalUpdateMainBankAccount}
            setOpenModalUpdateMainBankAccount={
              setOpenModalUpdateMainBankAccount
            }
          />
        </div>
      </Accordion>
    </div>
  )
}

export default BankAccountItem;
