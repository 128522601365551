import React, {FunctionComponent, useMemo, useState} from "react";
import {RouteProps, useSearchParams} from "react-router-dom";
import Layout from "../components/layout/Layout";
import {FormattedMessage, useIntl} from "react-intl";
import PurchaseList from "../components/molecules/list/PurchaseList";
import PageTitle from "../components/molecules/header/PageTitle";
import Button from "../components/atoms/Button";
import Panel from "../components/molecules/panel/Panel";
import CreatePurchaseOrderForm from "../components/molecules/form/purchase/CreatePurchaseOrderForm";
import HeaderButtonGroup, {HeaderStateButton} from "../components/molecules/header/HeaderButtonGroup";
import useSearchFilter from "../hooks/useSearchFilter";
import {PurchaseSearchObject, PurchaseStatus} from "../interfaces/PurchaseInterfaces";

const PurchaseListView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()
  const [formOpen, setFormOpen] = useState<boolean>(false)

  const initialSearchState: PurchaseSearchObject = {
    customerOrPurchase: "",
    supplierId: "",
    customerId: "",
    startDate: "",
    endDate: "",
    status: [PurchaseStatus.OPEN, PurchaseStatus.SENT]
  }
  const {searchObject, onReset, updateSearchField} = useSearchFilter<PurchaseSearchObject>(initialSearchState)
  const [params] = useSearchParams()

  const stateFilterButtons: HeaderStateButton[] = useMemo(() => {
    return [{
        title: intl.formatMessage({id: "activity_bill_filter_all"}),
        onclick: () => updateSearchField("status", [PurchaseStatus.OPEN, PurchaseStatus.SENT]),
        active: params.get("status") === `${PurchaseStatus.OPEN},${PurchaseStatus.SENT}`
      },
      {
        title: intl.formatMessage({id: "workflow_purchase_open"}),
        onclick: () => updateSearchField("status", PurchaseStatus.OPEN),
        active: params.get("status") === PurchaseStatus.OPEN
      },
      {
        title: intl.formatMessage({id: "workflow_purchase_sent"}),
        onclick: () => updateSearchField("status", PurchaseStatus.SENT),
        active: params.get("status") === PurchaseStatus.SENT
      },
      {
        title: intl.formatMessage({id: "workflow_purchase_closed"}),
        onclick: () => updateSearchField("status", PurchaseStatus.CLOSED),
        active: params.get("status") === PurchaseStatus.CLOSED
      }]
  }, [intl, params.get("status")])

  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column">
        <div className="d-flex justify-content-between mb-5">
          <PageTitle pageTitle={intl.formatMessage({id: "purchases_title"})}></PageTitle>
          <HeaderButtonGroup buttonList={stateFilterButtons} />
          <div>
            {/*<Button color={ColorType.SECONDARY} className="btn-header me-2">*/}
            {/*  <Icon name="FileExport" size={IconSizeType.XS} className={"pe-2"} />*/}
            {/*  <FormattedMessage id="export_button"/>*/}
            {/*</Button>*/}
            <Button className="btn-header" onClick={() => setFormOpen(true)}>
              <FormattedMessage id="new_purchase_order"/>
            </Button>
          </div>
        </div>

        <PurchaseList searchObject={searchObject} onReset={() => onReset({status: searchObject.status})} updateSearchField={updateSearchField}/>

        {formOpen && (
          <Panel
            formId="createPurchaseOrderForm"
            title="create_purchase_order"
            open={formOpen}
            onCancel={() => setFormOpen(false)}
          >
            <CreatePurchaseOrderForm id="createPurchaseOrderForm" className="w-100"/>
          </Panel>
        )}

      </div>
    </Layout>
  )
}

export default PurchaseListView
