import React, {FunctionComponent, useMemo} from "react";
import {useIntl} from "react-intl";
import GenericModal from "../GenericModal";
import {Formik} from "formik";
import RichTextInput from "../../input/RichTextInput";
import EpowForm from "../../form/EpowForm";
import {OrderItemResponse} from "../../../../interfaces/OrderItemInterfaces";
import {RemindResourceMail} from "../../../../interfaces/ResourceInterfaces";
import {dateUtils} from "../../../../utils/dateUtils";
import * as Yup from "yup";
import {STAFF_REQUEST_PATH} from "../../../../constants/routes/RoutePaths";

interface ModalStaffingRemindVoucherProps {
  className?: string;
  orderItem: OrderItemResponse,
  open: boolean,
  onClose: () => void,
  onOpen: (open: boolean) => void,
  onSubmit: (content: RemindResourceMail) => void,
}

const ModalStaffingRemindVoucher: FunctionComponent<ModalStaffingRemindVoucherProps> = ({
    className = "",
    orderItem,
    open,
    onClose,
    onOpen,
    onSubmit: onSubmitProps,
  }) => {
  const intl = useIntl();


  const onSubmit = (values: RemindResourceMail) => {
    onSubmitProps(values)
  };

  const template = useMemo(() => {
    const template = `
      <p>${intl.formatMessage({id: "resource_deny_voucher_mail_greetings"})}</p>
      <p>${intl.formatMessage({id: "resource_deny_voucher_mail_body_1"})}<a href=${STAFF_REQUEST_PATH}?id=${orderItem.id}>${orderItem.name}</a>${intl.formatMessage({id: "resource_remind_voucher_mail_body_2"}, {tourCode: orderItem.order.customerReference, date: dateUtils.formatDateDayJs(orderItem?.rendezVousDate)})}</p>
      <p>${intl.formatMessage({id: "resource_deny_voucher_mail_body_4"})}</p>
      <p>${intl.formatMessage({id: "resource_deny_voucher_mail_bye_1"})}</p>`;
      return template.replaceAll("\n", "");
  }, [orderItem]);

  return (

    <Formik
      initialValues={{template}}
      validationSchema={Yup.object().shape({
        template: Yup.string().required(),
      })}
      onSubmit={onSubmit}>
      {({values, errors, touched, setFieldValue}) => (
        <GenericModal
          className={className}
          title={intl.formatMessage({id: "modal_remind_voucher_staffing"})}
          open={open}
          onOpen={onOpen}
          onClosed={() => onClose()}
          closable
          footer={{
            primaryAction: { buttonLabel: intl.formatMessage({id: "validate_button"}), action: () => onSubmit(values)},
            secondaryAction: { buttonLabel: intl.formatMessage({id: "cancel_button"}), action: () => onClose()},
          }}
        >
          <EpowForm>
            <>
              <RichTextInput
                id="content"
                label="home_comment_content"
                value={values.template}
                onChange={(e) => setFieldValue("template", e.target.value)}
                required
                error={errors.template}
                touched={touched.template}
              />
            </>
          </EpowForm>
        </GenericModal>
      )}
    </Formik>
  );
};

export default ModalStaffingRemindVoucher;
