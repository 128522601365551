import React, {FunctionComponent} from "react";
import {Col, Row} from "reactstrap";
import {ActivityBillingStaffing} from "../../../../interfaces/ActivityBllingInterface";
import {dateUtils} from "../../../../utils/dateUtils";
import {moneyUtils} from "../../../../utils/moneyUtils";

interface ActivityBillingOverviewTableRowDetailsProps {
  className?: string,
  activityStaffing: ActivityBillingStaffing,
  showFirstColumn?: boolean,
}

const ActivityBillingOverviewTableRowDetails: FunctionComponent<ActivityBillingOverviewTableRowDetailsProps> = ({
  className = "",
  activityStaffing,
  showFirstColumn,
}) => {

  const getDateHour = () => {
    const date = dateUtils.formatDateDayJs(activityStaffing.date) ?? "";
    const hour = dateUtils.formatLocalTime(activityStaffing.hour) ?? ""
    return `${date} - ${hour}`
  }

  return (
    <Row className={`ms-0 ${className}`}>
      {showFirstColumn && <Col xs={1} className="ps-0" />}
      <Col xs={3} className="ps-0 ms-2">
        {`${activityStaffing.tourCode ?? "-"}`}
      </Col>
      <Col xs={3} className="ps-0">
        {activityStaffing.activityName ?? "-"}
      </Col>
      <Col xs={3} className="ps-0">
        {getDateHour()}
      </Col>
      <Col xs={4} className="ps-0">
        {moneyUtils.formatNumberToCurrency(activityStaffing.resourcePrice)}
      </Col>
    </Row>
  )
}

export default ActivityBillingOverviewTableRowDetails;
