import React, {FunctionComponent} from "react";
import {Routes, Route} from "react-router-dom"
import {AppRoutes} from "./constants/routes/AppRoutes";
import {AppRouteInterface} from "./interfaces/routes/RouteInterfaces";
import "./styles/app.scss"
import GuardedRoute from "./components/routes/GuardedRoute";
import {ToastContainer} from "react-toastify";
import {ActiveCustomerProvider} from "./context/ActiveCustomerContext";
import {PDF_VIEWER} from "./constants/routes/RoutePaths";
import {ActiveSupplierProvider} from "./context/ActiveSupplierContext";


const App: FunctionComponent = () => {

  const isPdfViewerRoute = location.pathname === PDF_VIEWER;

  return (
    <div className="global">
      <div
        className={`${isPdfViewerRoute ? "" : "justify-content-center global-container d-flex align-items-start"}`}>
        <ToastContainer
          position="top-right"
          pauseOnHover
          pauseOnFocusLoss
          autoClose={5000}
          closeOnClick
        />
        <ActiveSupplierProvider>
          <ActiveCustomerProvider>
            <Routes>
              {
                AppRoutes.map((route: AppRouteInterface) =>
                  route.paths.map((path: string) => {
                    return (
                      <Route key={path} element={<GuardedRoute guards={route.guards}/>}>
                        <Route path={path} element={route.element}/>
                      </Route>
                    )
                  })
                )
              }
            </Routes>
          </ActiveCustomerProvider>
        </ActiveSupplierProvider>
      </div>
    </div>
  )
}

export default App
