import React, {FunctionComponent, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {Column} from "react-table";
import useSearchFilter from "../../../hooks/useSearchFilter";
import {HomeComment, HomeCommentSearchObject} from "../../../interfaces/HomeCommentInterfaces";
import {homeCommentService} from "../../../services/HomeCommentService";
import {ColorType, IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {dateUtils} from "../../../utils/dateUtils";
import Button from "../../atoms/Button";
import Icon from "../../icon/Icon";
import DateFilter from "../filters/DateFilter";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import CreateHomeCommentForm from "../form/admin/CreateHomeCommentForm";
import Panel from "../panel/Panel";
import PagedTable from "../table/PagedTable";
import {PagedTableRef} from "../table/PagedTableWithRowSelection";

interface HomeCommentListProps {
  className?: string,
}

const HomeCommentList: FunctionComponent<HomeCommentListProps> = ({
  className = "",
}) => {
  const ref = useRef<PagedTableRef>()
  const intl = useIntl();
  const {searchObject, updateSearchField, onReset} = useSearchFilter<HomeCommentSearchObject>({})
  const [selectedComment, setSelectedComment] = useState<HomeComment>(null)
  const [commentFormOpen, setCommentFormOpen] = useState<boolean>(false)

  const onCommentSelection = (comment: HomeComment) => {
    setSelectedComment(comment)
    setCommentFormOpen(true)
  }

  const columns: Column[] = useMemo(() => [
    {
      Header: intl.formatMessage({id: "home_comment_date"}), accessor: "date", sortable: false, Cell: ({row}) => {
        const comment: HomeComment = row.original
        return (<div>{dateUtils.formatDateDayJs(comment.date)}</div>)
      }
    },
    {
      Header: intl.formatMessage({id: "home_comment_content"}), accessor: "content", width: "50%", sortable: false, Cell: ({row}) => {
        const comment: HomeComment = row.original
        return (<div dangerouslySetInnerHTML={{__html: comment.content}}></div>)
      }
    },
    {
      Header: intl.formatMessage({id: "home_comment_user"}), accessor: "userFirstName", sortable: false, Cell: ({row}) => {
        const comment: HomeComment = row.original
        return (<div>{`${comment.userFirstName} ${comment.userLastName}`}</div>)
      }
    },
    {
      Header: intl.formatMessage({id: "table_actions"}), accessor: "id", sortable: false, width: "5%", Cell: ({row}) => (
        <div className="float-end">
          <Icon name="Pen" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => onCommentSelection(row.original as HomeComment)} />
        </div>
      )
    },
  ], [intl]);

  const onCreateComment = () => {
    setCommentFormOpen(false)
    ref.current.refresh()
    setSelectedComment(null)
  }

  return (
    <div className={className}>
      <FilterGroup className="d-flex gap-2">
        <DateFilter onChangeDate={(value) => updateSearchField("startDate", value)} value={searchObject?.startDate} title={intl.formatMessage({id: "order_filter_period_start"})} />
        <DateFilter onChangeDate={(value) => updateSearchField("endDate", value)} value={searchObject?.endDate} title={intl.formatMessage({id: "order_filter_period_end"})} />
        <TextFilter
          className="main-filter col-3"
          title={intl.formatMessage({id: "home_comment_user"})}
          value={searchObject?.creator}
          onChange={(value) => updateSearchField("creator", value)}
          placeholder={intl.formatMessage({id: "home_comment_user"})}
        />
        <Button className="ms-2" onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
      </FilterGroup>

      <PagedTable
        ref={ref}
        columns={columns}
        labelNoResult="home_comment_no_result"
        className={`${className} table`}
        fetchData={homeCommentService.getHomeComments}
        filters={searchObject}
        defaultSortBy={[{sortBy: "commDate", descending: true}]}
      />
      <Button onClick={() => setCommentFormOpen(true)}>
        {intl.formatMessage({id: "home_comment_create"})}
      </Button>
      {commentFormOpen && (
        <Panel
          formId="createHomeCommentForm"
          title={selectedComment ? "home_comment_edit" : "home_comment_create"}
          open={commentFormOpen}
          onCancel={() => {
            setCommentFormOpen(false)
            setSelectedComment(null)
          }}
        >
          <CreateHomeCommentForm
            id="createHomeCommentForm"
            initialComment={selectedComment}
            onCreateComment={onCreateComment}
          />
        </Panel>
      )}
    </div>
  )
}

export default HomeCommentList;
