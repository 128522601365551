import React, {FunctionComponent, useEffect, useState} from "react";
import {Formik} from "formik";
import {useIntl} from "react-intl";
import EpowForm from "./EpowForm";
import * as Yup from "yup";
import {toastUtils} from "../../../utils/toastUtils";
import FormInput from "../input/FormInput";
import {dayByDayClientService} from "../../../services/DayByDayClientService";
import FormAutocompleteMultiselect from "../input/FormAutocompleteMultiSelect";
import {customerContactService} from "../../../services/CustomerContactService";
import {CustomerCatalog} from "../../../interfaces/TourInterfaces";
import {DAY_BY_DAY_CLIENT_INITIAL_VALUES} from "../../../constants/DayByDayClientConstants";
import {DayByDayClientFields} from "../../../interfaces/DayByDayClientInterfaces";

interface UpdateDayByDayClientFormProps {
  id?: string,
  customerCatalog: CustomerCatalog,
  className?: string,
  onValidate?: () => void,
}

const UpdateDayByDayClientForm: FunctionComponent<UpdateDayByDayClientFormProps> = ({
  id,
  customerCatalog,
  className = "",
  onValidate = () => null,
}) => {
  const intl = useIntl();
  const customer = customerCatalog?.customer;
  const [customerContacts, setCustomerContacts] = useState([]);

  const initialDayByDayClientFields = dayByDayClientService.buildDayByDayClientFields(customerCatalog);


  const updateDayByDayClient = (values: DayByDayClientFields) => {
    const dayByDayClientRequest = dayByDayClientService.buildDayByDayClientRequest(values, customerContacts);
    dayByDayClientService.updateDayByDayClient(customerCatalog?.id, dayByDayClientRequest)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "season_update_day_by_day_client_success"}))
        onValidate()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "season_update_day_by_day_client_failure"}))
      })
  }

  useEffect(() => {
    customerContactService.getAllContactsByCustomerIdPage(customer.id, {pageSize: 50, page: 0}, null)
      .then((contacts) => setCustomerContacts(contacts.content))
      .catch(() =>toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_customer_contacts"})))
  }, []);

  return (
    <Formik
      initialValues={initialDayByDayClientFields || DAY_BY_DAY_CLIENT_INITIAL_VALUES}
      validationSchema={Yup.object().shape({customerTourName: Yup.string().required()})}
      onSubmit={value => updateDayByDayClient(value)}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={`${className}`}>
          <FormInput
            id="customerTourName"
            label="season_update_tour_customer_name"
            value={values?.customerTourName}
            onChange={(e) => setFieldValue("customerTourName", e.target.value)}
            required
            error={errors.customerTourName}
            touched={touched.customerTourName}
          />
          <FormInput
            id="paxClientNumber"
            type="number"
            label="season_pax_client"
            value={values?.paxClientNumber}
            onChange={(e) => setFieldValue("paxClientNumber", e.target.value)}
            required
            error={errors.paxClientNumber}
            touched={touched.paxClientNumber}
          />
          { customer.managerContactsEnabled && (
            <FormAutocompleteMultiselect
              id="managerContactsList"
              label={intl.formatMessage({id: "customer_contact_can_see_all_orders_field"})}
              values={values.managerContacts}
              onChange={(selectedOptions) => {
                setFieldValue("managerContacts", selectedOptions)
              }}
              required
              fetchData={(inputValue) => customerContactService.getAllContactsByCustomerIdPage(
                customer.id,
                {pageSize: 50, page: 0},
                {nameSurname: inputValue})}
              filterFieldName="fullName"
            />
          )}
        </EpowForm>
      )}
    </Formik>
  )
}

export default UpdateDayByDayClientForm
