import {useEffect, useState} from "react";
import {BillingItem, Invoice} from "../../interfaces/InvoiceInterfaces";
import {CommentRequest} from "../../interfaces/CommentInterface";
import {invoiceService} from "../../services/InvoiceService";
import {toastUtils} from "../../utils/toastUtils";
import {useIntl} from "react-intl";


interface UseBillingParams {
  fetchInvoice: () =>  Promise<Invoice>;
}


function useBilling({ fetchInvoice: fetchInvoiceProps }: UseBillingParams) {

  const intl = useIntl();
  const [invoice, setInvoice] = useState<Invoice>(null);
  const [selectedBillingItem, setSelectedBillingItem] = useState<BillingItem>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const fetchInvoice = () => {
    fetchInvoiceProps().then((invoice) => {
      setInvoice(invoice);
    }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "invoice_error_message"}));
    })
  }

  useEffect(() => {
    fetchInvoice()
  }, []);


  const toggleShowToCustomer = (showToCustomer: boolean) => invoiceService.showToCustomer(invoice.id, showToCustomer)
    .then((newInvoice) => {
      setInvoice(newInvoice);
      toastUtils.successToast(intl.formatMessage({id: "success_toast_update_show_to_customer_toast"}))
    })
    .catch(() => {
      toastUtils.errorToast(intl.formatMessage({ id: "error_toast_update_show_to_customer_toast" }))
    });

  const createComment = (comment: CommentRequest) => invoiceService.updateComment(invoice.id, comment.content)
    .then((newInvoice) => {
      setInvoice(newInvoice);
      toastUtils.successToast(
        intl.formatMessage({id: "comment_toast_success_creation"})
      )
    })
    .catch(() => {
      toastUtils.errorToast(
        intl.formatMessage({ id: "comment_toast_error_creation" })
      )
    });

  const deleteComment = () => invoiceService.deleteComment(invoice.id).then(() => {
    setInvoice({...invoice, comment: null});
    toastUtils.successToast(
      intl.formatMessage({id: "comment_toast_success_deletion"})
    )
  }).catch(() => {
    toastUtils.errorToast(
      intl.formatMessage({id: "comment_toast_error_deletion"})
    )
  });

  const onOpenUpdateModal = (billingItem) => {
    setOpen(true)
    setSelectedBillingItem(billingItem)
  };


  const onChangeInvoice = (newInvoice) => {
    setInvoice(newInvoice)
  }

  const onExitModal = () => {
    setSelectedBillingItem(null)
    setOpen(false);
  }

  const onOpenModal = () => {
    setOpen(true)
  }

  const onOpenCustomerEditModal = () => {
    setOpenEditModal(true)
  }

  const onExitCustomerEditModal = () => {
    setOpenEditModal(false)
  }

  const onDeleteBillingItem = (billingItem: BillingItem) => {
    invoiceService.deleteBillingItem(invoice.id, billingItem.id).then(() => {
      fetchInvoice();
      toastUtils.successToast( intl.formatMessage({id: "billing_item_toast_success_deletion"}))
    }).catch(() => {
      toastUtils.errorToast( intl.formatMessage({id: "billing_item_toast_error_deletion"}))
    })
  };

  return {
    invoice,
    open,
    openEditModal,
    selectedBillingItem,
    fetchInvoice,
    createComment,
    onOpenUpdateModal,
    deleteComment,
    onChangeInvoice,
    onExitModal,
    onOpenModal,
    onDeleteBillingItem,
    onOpenCustomerEditModal,
    onExitCustomerEditModal,
    toggleShowToCustomer
  }
}

export default useBilling;
