import * as Yup from "yup";
import {
  yupRequiredArray,
  yupRequiredPositiveNumber,
  yupRequiredPositiveOrZeroNumber,
  yupRequiredString,
} from "../yup/Validators";
import {AnyObject} from "yup/es/types";

export function CreateServiceSchema (isEdition?: boolean): Yup.ObjectSchema<AnyObject> {
  return (
    isEdition ? (
      Yup.object().shape({
        name: yupRequiredString,
        description: yupRequiredString,
        reference: yupRequiredString,
        numberPaxMax: yupRequiredPositiveNumber,
        defaultUnitType: yupRequiredString,
        defaultSellUnitType: yupRequiredString,
        defaultPrice: yupRequiredPositiveOrZeroNumber,
        defaultSellPrice: yupRequiredPositiveOrZeroNumber,
        suppliers: yupRequiredArray(1),
        defaultSupplierId: yupRequiredString,
        vatRateId: yupRequiredString,
        productTypeId: yupRequiredString
      })
    ) : (
      Yup.object().shape({
        name: yupRequiredString,
        description: yupRequiredString,
        reference: yupRequiredString
      })
    )
  )
}

export const EditServiceDefaultSupplierSchema = Yup.object().shape({
  serviceId: yupRequiredString,
  defaultSupplierId: yupRequiredString,
})

export const CreateCustomerServiceSchema = Yup.object().shape({
  customerId: yupRequiredString
})

export const CreateCustomerServiceInfoCatalogSchema = Yup.object().shape({
  unitType: yupRequiredString,
  sellUnitType: yupRequiredString,
  groupSize: yupRequiredPositiveNumber,
  sellPriceExcludingTax: yupRequiredPositiveOrZeroNumber,
  startDateValidity: yupRequiredString
})

export const CreateSupplierServiceCatalogSchema = Yup.object().shape({
  unitType: yupRequiredString,
  unitPrice: yupRequiredPositiveOrZeroNumber,
  paxMax: yupRequiredPositiveNumber,
  startDateValidity: yupRequiredString
})
