import {FormikHelpers, FormikValues} from "formik";
import React, {FunctionComponent, useContext, useState} from "react";
import {useIntl} from "react-intl";
import {RouteProps, useNavigate} from "react-router-dom";
import LoginForm from "../components/molecules/login/LoginForm";
import LoginProfileSelectionForm from "../components/molecules/login/LoginProfileSelectionForm";
import LoginRulesForm from "../components/molecules/login/LoginRulesForm";
import {HOME_PATH} from "../constants/routes/RoutePaths";
import {ErrorResponse} from "../interfaces/HttpInterfaces";
import {AuthUser, PROFILE} from "../interfaces/UserInterfaces";
import {authentificationService} from "../services/AuthentificationService";
import {toastUtils} from "../utils/toastUtils";
import LoginLayout from "../layout/LoginLayout";
import {authUtils} from "../utils/authUtils";
import {ActiveCustomerDispatchContext} from "../context/ActiveCustomerContext";
import {ActiveSupplierDispatchContext} from "../context/ActiveSupplierContext";

interface LoginField {
  email: string,
  password: string
}


const LoginView: FunctionComponent<RouteProps> = () => {
  const navigate = useNavigate()
  const intl = useIntl()

  const [error, setError] = useState<string>(null);
  const [user, setUser] = useState<AuthUser>(null)
  const [profile, setProfile] = useState<PROFILE>(null)
  const [step, setStep] = useState<number>(0)

  const setActiveCustomer = useContext(ActiveCustomerDispatchContext);
  const setActiveSupplier = useContext(ActiveSupplierDispatchContext);

  const onSubmitRules = (rules) => {
    const request = {
      userId: user.id,
      userProfile: profile,
      userRules: rules,
    }

    authentificationService.acceptRules(request)
      .then((response) => {
        localStorage.setItem("token", response.token)
        localStorage.setItem("profile", profile)
        localStorage.setItem("rulesAccepted", "true")
        if (response.customersRepresented && response.customersRepresented.length) {
          localStorage.setItem("customersRepresentedByUser", JSON.stringify(response.customersRepresented))
          setActiveCustomer(response.customersRepresented[0])
        }
        if (response.suppliersRepresented && response.suppliersRepresented.length) {
          localStorage.setItem("suppliersRepresentedByUser", JSON.stringify(response.suppliersRepresented))
          setActiveSupplier(response.suppliersRepresented[0])
        }
        if (localStorage.getItem("savedUrlFromAuthGuard")) {
          navigate(localStorage.getItem("savedUrlFromAuthGuard"))
          localStorage.removeItem("savedUrlFromAuthGuard")
        } else {
          navigate(HOME_PATH)
        }
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "login_error"}))
      })
  }

  const onSubmitProfile = (profile: PROFILE) => {
    setProfile(profile)
    setStep(2)
  }

  const onLogin = (values: LoginField, {resetForm}: FormikHelpers<FormikValues>) => {
    authUtils.cleanSession()
    authentificationService.auth(values)
      .then((user) => {
        localStorage.setItem("userFirstName", user.firstName)
        localStorage.setItem("userLastName", user.lastName)
        localStorage.setItem("userId", user.id)
        localStorage.setItem("profileValidated", user.profileValidated.toString())
        localStorage.setItem("token", user.token)

        setUser(user)
        if (user.profile && user.profile.length === 1) {
          onSubmitProfile(user.profile[0])
        } else {
          setStep(1)
          resetForm()
        }
      })
      .catch((e: ErrorResponse) => {
        setError(e.error)
        resetForm()
      })
  }

  return (
    <LoginLayout>
      {step === 0 && (
        <LoginForm error={error} onLogin={onLogin}/>
      )}

      {step === 1 && (
        <LoginProfileSelectionForm user={user} setStep={setStep} onSubmitProfile={onSubmitProfile}/>
      )}

      {step === 2 && (
        <LoginRulesForm user={user} profile={profile} setStep={setStep} onSubmitRules={onSubmitRules}/>
      )}
    </LoginLayout>
  )
}

export default LoginView;
