import React, {FunctionComponent, useEffect, useState} from "react";
import CardField from "../card/CardField";
import Button from "../../atoms/Button";
import {FormattedMessage, useIntl} from "react-intl";
import {AccountingConfig} from "../../../interfaces/AccountingConfig";
import {toastUtils} from "../../../utils/toastUtils";
import {accountingConfigService} from "../../../services/AccountingConfigService";
import AccountingConfigForm from "../form/admin/AccountingConfigForm";

interface AccountingConfigListProps {
  className?: string;
}
const AccountingConfigList: FunctionComponent<AccountingConfigListProps> = ({
                                                            className = ""
                                                          }) => {
  const intl = useIntl();
  const [adminAccounts, setAdminAccounts] = useState<AccountingConfig>(null)
  const [editing, setEditing] = useState<boolean>(false)

  const getAdminAccounts = () => {
    accountingConfigService.getAccountingConfig()
      .then((response) => {
        setAdminAccounts(response)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_admin_config"}))
      })
  }

  useEffect(() => {
    getAdminAccounts()
  }, []);

  const onUpdate = () => {
    setEditing(false)
    getAdminAccounts()
  }
  return (
    editing
      ? <AccountingConfigForm onUpdate={onUpdate} adminAccounts={adminAccounts} onCancel={() => setEditing(false)}/>
      : <div className={className}>
      <>
        <CardField
          label={intl.formatMessage({id: "accounting_config_fr"})}
          value={adminAccounts?.frAccountNumber}
        />
        <CardField
          label={intl.formatMessage({id: "accounting_config_eu"})}
          value={adminAccounts?.euAccountNumber}
        />

        <CardField
          label={intl.formatMessage({id: "accounting_config_not_ue"})}
          value={adminAccounts?.notUeAccountNumber}
        />

        <CardField
          label={intl.formatMessage({id: "accounting_config_tva"})}
          value={adminAccounts?.tvaAccountNumber}
        />

        <CardField
          label={intl.formatMessage({id: "accounting_config_purchase"})}
          value={adminAccounts?.activityPurchaseAccountNumber}
        />

        <Button onClick={() => setEditing(true)} >
          <FormattedMessage id="edit_button" />
        </Button>
      </>
    </div>
  )
}

export default AccountingConfigList;
