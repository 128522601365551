import React, {FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";
import {OrderItem, OrderItemState, OrderItemStateMap} from "../../../../interfaces/OrderItemInterfaces";
import {orderItemService} from "../../../../services/OrderItemService";
import {ColorType, IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../../utils/toastUtils";
import Badge from "../../../atoms/Badge";
import FieldLabel from "../../../atoms/FieldLabel";
import Icon from "../../../icon/Icon";
import {dateUtils} from "../../../../utils/dateUtils";
import {OrderState} from "../../../../interfaces/OrderInterfaces";
import {authUtils} from "../../../../utils/authUtils";
import {PROFILE} from "../../../../interfaces/UserInterfaces";
import Popover from "../../../atoms/Popover";
import ModalOrderItemDelete from "../../modal/orderitem/ModalOrderItemDelete";
import ModalOrderItemCancelDelete from "../../modal/orderitem/ModalOrderItemCancelDelete";
import {profileUtils} from "../../../../utils/profileUtils";

interface OrderItemPreviewCardHeaderProps {
  className?: string,
  orderItem: OrderItem,
  isEditable?: boolean,
  orderStatus?: string,
  editOrderItem: (orderItem: OrderItem) => void,
  setIsEditable?: (isEditable: boolean) => void,
  onDelete: () => void,
}

const OrderItemPreviewCardHeader: FunctionComponent<OrderItemPreviewCardHeaderProps> = ({
  className = "",
  orderItem,
  orderStatus,
  isEditable = false,
  editOrderItem,
  setIsEditable,
  onDelete,
}) => {
  const intl = useIntl()
  const [modalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false)
  const [modalCancelOpen, setModalCancelOpen] = useState<boolean>(false)

  const isDeletable = orderStatus === OrderState.DRAFT


  const editOrderItemAndEditable = (orderItem: OrderItem) => {
    editOrderItem(orderItem)
    setIsEditable(true)
  }

  const deleteOrderItem = () => {
    orderItemService.deleteOrderItem(orderItem?.id)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_delete_generic"}))
        onDelete()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_order_item_update"}))
      }).finally(() => {
        setModalDeleteOpen(false)
        setModalCancelOpen(false)
      })
  }

  const cancelOrderItem = (cancellationDate: string) => {
    orderItemService.cancelOrderItem(orderItem?.id, {cancellationDate})
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_cancel_order_item"}))
        onDelete()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_order_item_update"}))
      }).finally(() => {
        setModalCancelOpen(false)
      })
  }

  return (
    <div className={`d-flex w-100 border-bottom pb-2 px-2 mb-2 align-items-center ${className}`}>
        <div className="col-7">
            {orderItem.noAvailableSupplier && <div className="d-inline-block">
                <Popover
                    className="d-inline"
                    color={ColorType.WARNING}
                    size={IconSizeType.XS}
                    text="no_supplier_available"
                    iconName="ExclamationTriangle"
                    iconClassName="me-2"/>
            </div>}
        <span className="bold">{orderItem?.name}</span><span> - {dateUtils.formatLocalTime(orderItem?.rendezVousHour)}</span>
        {orderItem?.optional && (
          <span className="ms-2 color-warning">{intl.formatMessage({id: "order_item_optional"})}</span>
        )}
      </div>

        {orderItem.order && <FieldLabel
            className="col-4"
            label={intl.formatMessage({id: "order_customer_ref"})}
            value={orderItem?.order?.customerReference}
        />}

      <FieldLabel
        className={orderItem.order ? "col-3" : "col-4"}
        label={intl.formatMessage({id: "order_item_departure_hour"})}
        value={orderItem?.duration ? `${dateUtils.formatLocalTime(orderItem.departureHour)}` : ""}
      />

      <FieldLabel
        className="col-2"
        label={intl.formatMessage({id: "order_item_duration"})}
        value={orderItem?.duration ? `${dateUtils.formatDuration(orderItem?.duration)}` : ""}
      />

      <div className={`${orderItem.order ? "col-2" : "col-3"} d-flex align-items-center`}>
        <FieldLabel label={intl.formatMessage({id: "order_item_pax"})} value={orderItem?.pax?.toString()} />
        {orderItem?.paxConfirmed && (
          <Icon
            name="Check"
            size={IconSizeType.XS}
            className="ms-2"
            color={ColorType.PRIMARY}
          />
        )}
      </div>

      <FieldLabel
        className={orderItem.order ? "col-2" : "col-3"}
        label={intl.formatMessage({id: "order_item_type"})}
        value={orderItem?.type ? intl.formatMessage({id: orderItem?.type?.toString()}) : ""}
      />


      <div className="col-2">
        <div className="d-flex float-end">
          {(orderItem.status && orderItem.status !== OrderItemState.DRAFT) && authUtils.getProfile() !== PROFILE.TOUR_LEADER && (
            <Badge pill color={OrderItemStateMap[orderItem.status]?.color}>
              {intl.formatMessage({id: OrderItemStateMap[orderItem.status]?.label || "STATUS_DEFAULT"})}
            </Badge>
            )}
        </div>
      </div>

      <div className={orderItem.order ? "col-2" : "col-3"}>
        <div className="float-end">
          {isEditable && authUtils.getProfile() !== PROFILE.TOUR_LEADER && orderItem.billingStatus === null &&(
            <>
              <Icon name="Pen" size={IconSizeType.XS} className="cursor-pointer px-1 mx-1" onClick={() => editOrderItemAndEditable(orderItem)} />
              <Icon
                name={isDeletable ? "Trash" : "Cross"}
                size={IconSizeType.XS}
                className="cursor-pointer px-1 me-1"
                onClick={() => isDeletable ? setModalDeleteOpen(true) : setModalCancelOpen(true)}
              />
            </>
          )}
          {(orderItem.billingStatus && profileUtils.isInterne()) && (
            <>
              <Badge pill color={OrderItemStateMap[orderItem.status]?.color}>
                {intl.formatMessage({id: "billing_done"})}
              </Badge>
            </>
          )}
        </div>
      </div>

      <ModalOrderItemDelete
        open={modalDeleteOpen}
        setOpen={setModalDeleteOpen}
        deleteOrderItem={deleteOrderItem}
      />

      <ModalOrderItemCancelDelete
        open={modalCancelOpen}
        setOpen={setModalCancelOpen}
        cancelOrderItem={cancelOrderItem}
        deleteOrderItem={deleteOrderItem}
        orderItem={orderItem}
      />
    </div>
  )
}

export default OrderItemPreviewCardHeader;
