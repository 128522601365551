import {API_CONTACTS_PATH, API_CUSTOMER_CONTACTS_PATH, API_CUSTOMERS_PATH,} from "constants/routes/RoutePaths";
import {Contact, ContactOption, CustomerContact, EntityContactRequest,} from "interfaces/ContactInterfaces";
import {httpService} from "./HttpService";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {SearchFilter} from "../interfaces/api/FiltersInterface";
import {queryUtils} from "../utils/queryUtils";

function createCustomerContact(request: EntityContactRequest, entityId: string): Promise<CustomerContact> {
  const customerContact: CustomerContact = {
    ...request,
    customerId: entityId,
  }
  return httpService.post<CustomerContact>(API_CUSTOMER_CONTACTS_PATH, customerContact);
}

function updateCustomerContact(id: string,  entityId: string, request: EntityContactRequest): Promise<CustomerContact> {
  const customerContact: CustomerContact = {
    ...request,
    customerId: entityId,
  }
  return httpService.put<CustomerContact>(`${API_CUSTOMER_CONTACTS_PATH}/${id}`, customerContact);
}

function getAllContactsByCustomerIdPage(customerId: string, params: PaginationQueryParams, filterParams: SearchFilter): Promise<PageResponse<Contact>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams);
  return httpService.get<PageResponse<Contact>>(`${API_CUSTOMERS_PATH}/${customerId}${API_CONTACTS_PATH}/page${queryParams}`);
}

function getAllContactOptionsByCustomerId(customerId: string): Promise<PageResponse<ContactOption>> {
  return httpService.get<PageResponse<Contact>>(`${API_CUSTOMERS_PATH}/${customerId}${API_CONTACTS_PATH}/options`);
}

function inviteContactCustomer(id: string): Promise<Contact> {
  return httpService.post<Contact>(`${API_CUSTOMER_CONTACTS_PATH}/${id}/invite`, {});
}

export const customerContactService = {
  createCustomerContact,
  updateCustomerContact,
  getAllContactsByCustomerIdPage,
  getAllContactOptionsByCustomerId,
  inviteContactCustomer
};
