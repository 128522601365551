import {GENDER_OPTIONS} from "constants/OptionConstants";
import {EditResourceGeneralInfoSchema} from "constants/validation/ResourceValidationSchemas";
import {Formik} from "formik";
import {ResourceGeneralInfo, ResourceGeneralInfoFields} from "interfaces/ResourceInterfaces";
import React, {FunctionComponent, useMemo, useState} from "react"
import {Col, Row} from "reactstrap";
import FormInput from "../../input/FormInput";
import FormRadio from "../../input/FormRadio";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";
import {COUNTRY_OPTIONS} from "constants/CountryConstants";
import FormSelect from "../../input/FormSelect";
import {resourceService} from "services/ResourceService";
import {toastUtils} from "utils/toastUtils";
import {useIntl} from "react-intl";
import {resourceUtils} from "utils/resourceUtils";
import EpowForm from "../EpowForm";
import AddressForm from "../address/AddressForm";
import {resourceTypeService} from "../../../../services/ResourceTypeService";
import {authUtils} from "../../../../utils/authUtils";
import {PROFILE} from "../../../../interfaces/UserInterfaces";
import {ORIGIN_OPTIONS} from "../../../../constants/OriginConstant";

interface EditResourceGeneralInfoFormProps {
  id?: string;
  className?: string;
  initialResourceFields?: ResourceGeneralInfoFields;
  setResourceAfterUpdate?: (resourceGeneralInfo: ResourceGeneralInfo) => void;
  setEditInfo?: (editInfo: boolean) => void;
  self: boolean
}

const EditResourceGeneralInfoForm: FunctionComponent<EditResourceGeneralInfoFormProps> = ({
  id,
  className = "",
  initialResourceFields,
  setResourceAfterUpdate,
  setEditInfo,
  self
}) => {
  const intl = useIntl();
  const [resourceTypes, setResourceTypes] = useState<Option<string>[]>([])

  const translatedOriginOptions = ORIGIN_OPTIONS.map(option => ({...option, label: intl.formatMessage({id: option.label})}))

  const handleSubmitResource = (submittedResource: ResourceGeneralInfoFields) => {
    const resourceRequest: ResourceGeneralInfo = resourceUtils.buildResourceGeneralInfo(submittedResource);

    const promise = self ? resourceService.updateSelfResourceGeneralInfo(resourceRequest) :
      resourceService.updateResourceGeneralInfo(initialResourceFields.id, resourceRequest)

    promise
      .then((resourceGeneralInfo: ResourceGeneralInfo) => {
        setResourceAfterUpdate(resourceGeneralInfo);
        setEditInfo(false);
        toastUtils.successToast(intl.formatMessage({id: "success_update_resource"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_update_resource"}))
      })
  }

  useMemo(() => {
    if (authUtils.getProfile() === PROFILE.ADMIN || authUtils.getProfile() === PROFILE.INTERN) {
      resourceTypeService.getResourceTypes()
        .then((resourceTypes) => {
          setResourceTypes(resourceTypes.map((type) => { return { value: type.id, label: type.label }}))
        }).catch(() => toastUtils.errorToast(intl.formatMessage({id: "error_toast_fetch_resource_types"})))
    }}, [])

  return (
    <Formik initialValues={initialResourceFields} validationSchema={EditResourceGeneralInfoSchema} onSubmit={(value) => handleSubmitResource(value)}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <Row>
            <Col xs={24} md={12}>
              {(authUtils.getProfile() === PROFILE.ADMIN || authUtils.getProfile() === PROFILE.INTERN) && <FormSelect
                id="resourceType"
                label="resource_type"
                value={resourceTypes.find((option) => option?.value === values?.resourceTypeId)}
                onChange={(option?: Option<string>) => setFieldValue("resourceTypeId", option?.value)}
                options={resourceTypes}
                required
                isClearable
                error={errors.resourceTypeId}
                touched={touched.resourceTypeId}
              />}
              <FormRadio
                id="gender"
                label="resource_gender_field"
                value={GENDER_OPTIONS.find((option) => option?.value === values?.gender)}
                options={GENDER_OPTIONS}
                onChange={(option?: Option<string>) => setFieldValue("gender", option?.value)}
                required
                error={errors.gender}
                touched={touched.gender}
              />
              <FormInput
                id="firstName"
                label="resource_first_name_field"
                value={values?.firstName}
                onChange={(e) => setFieldValue("firstName", e.target.value)}
                required
                error={errors.firstName}
                touched={touched.firstName}
              />
              <FormInput
                id="lastName"
                label="resource_last_name_field"
                value={values?.lastName}
                onChange={(e) => setFieldValue("lastName", e.target.value)}
                required
                error={errors.lastName}
                touched={touched.lastName}
              />
              <FormInput
                id="birthName"
                label="resource_birth_name_field"
                value={values?.birthName}
                onChange={(e) => setFieldValue("birthName", e.target.value)}
                error={errors.birthName}
                touched={touched.birthName}
              />
              <FormInput
                id="email"
                label="resource_email_field"
                value={values?.email}
                onChange={(e) => setFieldValue("email", e.target.value)}
                required
                error={errors.email}
                touched={touched.email}
              />
              <FormInput
                id="phoneNumber"
                label="resource_phone_number_field"
                value={values?.phoneNumber}
                onChange={(e) => setFieldValue("phoneNumber", e.target.value)}
                required
                error={errors.phoneNumber}
                touched={touched.phoneNumber}
              />
              <FormInput
                id="otherPhoneNumber"
                label="resource_other_phone_number_field"
                value={values?.otherPhoneNumber}
                onChange={(e) => setFieldValue("otherPhoneNumber", e.target.value)}
                error={errors.otherPhoneNumber}
                touched={touched.otherPhoneNumber}
              />
            </Col>
            <Col xs={24} md={12}>
              <FormSelect
                id="nationality"
                label="resource_nationality_field"
                placeholder="country_placeholder"
                value={COUNTRY_OPTIONS.find(
                  (option) => option?.value === values?.nationality
                )}
                onChange={(option?: Option<string>) =>
                  setFieldValue("nationality", option?.value)
                }
                options={COUNTRY_OPTIONS}
                required
                isSearchable
                isClearable
                error={errors?.nationality}
                touched={touched?.nationality}
              />
              <FormSelect
                id="origin"
                label="resource_origin_field"
                placeholder="resource_origin_placeholder"
                value={translatedOriginOptions.find(
                  (option) => option?.value === values?.origin
                )}
                onChange={(option?: Option<string>) =>
                  setFieldValue("origin", option?.value)
                }
                options={translatedOriginOptions}
                required
                isSearchable
                isClearable
                error={errors?.origin}
                touched={touched?.origin}
              />
              <AddressForm
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
              />
            </Col>
          </Row>
        </EpowForm>
      )}
    </Formik>
  )
}

export default EditResourceGeneralInfoForm;
