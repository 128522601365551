import {SupplierPurchaseBooking} from "../../../../interfaces/PurchaseInterfaces";
import React, {FunctionComponent} from "react";
import FieldLabel from "../../../atoms/FieldLabel";
import {useIntl} from "react-intl";
import {Col} from "reactstrap";
import {numberUtils} from "../../../../utils/numberUtils";
import {dateUtils} from "../../../../utils/dateUtils";

interface SupplierPurchaseTableRowDetailsProps {
  className?: string,
  supplierPurchaseBooking: SupplierPurchaseBooking
}

const SupplierPurchaseTableRowDetails: FunctionComponent<SupplierPurchaseTableRowDetailsProps> = ({
  className = "",
  supplierPurchaseBooking
}) => {
  const intl = useIntl()
  return (
    <div className={`epow-purchase-table-row-details ${className}`}>
      <Col>
        <FieldLabel
          label={intl.formatMessage({id: "purchase_booking_service"})}
          value={supplierPurchaseBooking?.serviceName}
        />
      </Col>
      <Col>
        <FieldLabel
          label={intl.formatMessage({id: "purchase_order_date"})}
          value={dateUtils.formatDateDayJs(supplierPurchaseBooking?.date)}
        />
      </Col>
      <Col>
        <FieldLabel
          label={intl.formatMessage({id: "booking_hour"})}
          value={dateUtils.formatLocalTime(supplierPurchaseBooking?.hour)}
        />
      </Col>
      <Col>
        <FieldLabel
          label={intl.formatMessage({id: "purchase_booking_price_net"})}
          value={numberUtils.formatPrice(supplierPurchaseBooking?.price)}
        />
      </Col>
      <Col>
        <FieldLabel
          label={intl.formatMessage({id: "purchase_booking_quantity"})}
          value={supplierPurchaseBooking?.quantity}
        />
      </Col>
    </div>
  )
}

export default SupplierPurchaseTableRowDetails
