import React, {FunctionComponent, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {Column} from "react-table";
import useSearchFilter from "../../../hooks/useSearchFilter";
import {ColorType, IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../utils/toastUtils";
import Button from "../../atoms/Button";
import Icon from "../../icon/Icon";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import Panel from "../panel/Panel";
import PagedTable from "../table/PagedTable";
import {PagedTableRef} from "../table/PagedTableWithRowSelection";
import { resourceTypeService } from "services/ResourceTypeService";
import { ResourceType } from "interfaces/ResourceTypeInterfaces";
import ResourceTypeForm from "../form/admin/ResourceTypeForm";
import {activityService} from "../../../services/ActivityService";
import useLoading from "../../../hooks/useLoading";

interface ResourceTypeListProps {
  className?: string,
  label?: string,
}

const ResourceTypeList: FunctionComponent<ResourceTypeListProps> = ({
  className = "",
}) => {

  const ref = useRef<PagedTableRef>()
  const intl = useIntl();
  const {searchObject, updateSearchField, onReset} = useSearchFilter<ResourceTypeListProps>({})
  const [selectedResourceType, setSelectedResourceType] = useState<ResourceType>(null)
  const [resourceTypeFormOpen, setResourceTypeFormOpen] = useState<boolean>(false)
  const {loading, stopLoading, startLoading} = useLoading()

  const onResourceTypeSelection = (resourceType: ResourceType) => {
    setSelectedResourceType(resourceType)
    setResourceTypeFormOpen(true)
  }

  const onResourceTypeCreate = () => {
    setResourceTypeFormOpen(false)
    ref.current.refresh()
    setSelectedResourceType(null)
  }

  const handleDeleteResourceType = (type: ResourceType) => {
    resourceTypeService.deleteResourceType(type.id)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_delete_resource_type"}))
        onResourceTypeCreate()
      })
      .catch((e: Error) => toastUtils.errorToast(`${intl.formatMessage({id: "error_toast_delete_resource_type"})} : ${e.message ?? ""} `))
  }

  const columns: Column[] = useMemo(() => [
    {
      Header: intl.formatMessage({id: "resource_type_label"}), accessor: "label", sortable: true,
    },
    {
      Header: intl.formatMessage({id: "resource_type_color"}), accessor: "color", sortable: false, Cell: ({row}) => (
        <Button className="ms-2" style={{backgroundColor: (row.original as ResourceType).color, borderColor: (row.original as ResourceType).color}}/>
      )
    },
    {
      Header: intl.formatMessage({id: "table_actions"}), accessor: "id", sortable: false, width: "5%", Cell: ({row}) => (
        <div className="float-end">
          <Icon name="Pen" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => onResourceTypeSelection(row.original as ResourceType)} />
          <Icon name="Trash" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => handleDeleteResourceType(row.original as ResourceType)} />
        </div>
      )
    },
  ], [intl]);

  const sendMailNewActivity = () => {
    startLoading()
    activityService.sendMailNewActivity()
      .then(() => toastUtils.successToast(intl.formatMessage({id: "activity_send_new_activity_mail_success"})))
      .catch((e: Error) => toastUtils.errorToast(`${intl.formatMessage({id: "activity_send_new_activity_mail_error"})} : ${e.message ?? ""}`))
      .finally(() => stopLoading())
  }

  return (
    <div className={className}>
      <FilterGroup className="d-flex gap-2">
        <TextFilter
          className="main-filter col-3"
          title={intl.formatMessage({id: "resource_type_label"})}
          value={searchObject?.label}
          onChange={(value) => updateSearchField("label", value)}
          placeholder={intl.formatMessage({id: "resource_type_label"})}
        />
        <Button className="ms-2" onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
      </FilterGroup>
      <PagedTable
        ref={ref}
        columns={columns}
        labelNoResult="resource_type_no_result"
        className={`${className} table`}
        fetchData={resourceTypeService.getResourceTypePage}
        filters={searchObject}
        defaultSortBy={[{sortBy: "label", descending: true}]}
      />
      <Button onClick={() => setResourceTypeFormOpen(true)}>
        {intl.formatMessage({id: "resource_type_create"})}
      </Button>
      <Button className="btn-header ms-2" color="secondary" onClick={() => sendMailNewActivity()}>
        {loading && <span className="loader loader-button-group ms-1"/>}
        {intl.formatMessage({id: "activity_send_new_activity_mail"})}
      </Button>
      {resourceTypeFormOpen && (
        <Panel
          formId="createResourceCreaForm"
          title={selectedResourceType ? "resource_type_edit" : "resource_type_create"}
          open={resourceTypeFormOpen}
          onCancel={() => {
            setResourceTypeFormOpen(false)
            setSelectedResourceType(null)
          }}
        >
          <ResourceTypeForm
            id="createResourceCreaForm"
            initialResourceType={selectedResourceType}
            onCreateResourceType={onResourceTypeCreate}
          />
        </Panel>
      )}
    </div>
  )
}

export default ResourceTypeList;
