import React, {FunctionComponent} from "react";
import {RouteProps, useParams} from "react-router-dom";
import useInvoicePayment from "./useInvoicePayment";
import {useIntl} from "react-intl";
import Layout from "../../components/layout/Layout";
import InvoicePaymentHeader from "../../components/molecules/header/InvoicePaymentHeader";
import {Col, Row} from "reactstrap";
import InvoicePaymentDetails from "./invoice-payment-details/InvoicePaymentDetails";
import InvoicePaymentSummaryDetails from "./invoice-payment-details/InvoicePaymentSummaryDetails";
import ContactCard from "../../components/molecules/card/ContactCard";
import {contactService} from "../../services/ContactService";
import CommentCard from "../../components/molecules/card/comment/CommentCard";

const InvoicePaymentView: FunctionComponent<RouteProps> = () => {
  const {id} = useParams();
  const {
    invoicePayment,
    createComment,
    deleteComment,
  } = useInvoicePayment({id})

  const intl = useIntl();

  return (
    <Layout> {invoicePayment &&
      <div className="epow-content-body epow-purchase-card d-flex flex-column">
        <InvoicePaymentHeader
          invoicePayment={invoicePayment} />
        <Row>
          <Col xs={24} xl={16}>
            <div>
              {invoicePayment.invoicePaymentItems?.map((invoicePaymentItem) => (
                <InvoicePaymentDetails
                  key={invoicePaymentItem.id}
                  invoicePaymentItem={invoicePaymentItem}
                />
              ))}
            </div>
            {(invoicePayment.invoicePaymentItems?.length > 0 ) &&
              <InvoicePaymentSummaryDetails invoicePaymentDetail={invoicePayment} />
            }
          </Col>

          <Col xs={24} xl={8}>
            <ContactCard
              firstName={invoicePayment.billingCustomer.firstName}
              lineContact={contactService.buildCustomerLineContact(invoicePayment.billingCustomer)}
            />
            <CommentCard
              className="mt-2"
              comment={invoicePayment.comment}
              createComment={createComment}
              deleteComment={deleteComment}
              title={intl.formatMessage({id: "customer_comment"})}
            />
          </Col>
        </Row>
      </div>
    }
    </Layout>
  );
}

export default InvoicePaymentView;
