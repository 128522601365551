import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import {EditUserSchema} from "../../../../constants/validation/UserValidationSchema";
import {PROFILE, PROFILE_OPTIONS, User} from "../../../../interfaces/UserInterfaces";
import FormInput from "../../input/FormInput";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";
import {userService} from "../../../../services/UserService";
import {toastUtils} from "../../../../utils/toastUtils";
import {useIntl} from "react-intl";
import EpowForm from "../EpowForm";
import {USER_INITIAL_VALUES} from "../../../../constants/user/UserConstants";
import FormSelect from "../../input/FormSelect";
import {useNavigate} from "react-router-dom";
import {ADMIN_PATH_REF} from "../../../../constants/routes/RoutePaths";
import UserEditProfileFormFragment from "./UserEditProfileFormFragment";
import {LoadingComponent} from "../../../../constants/menu/Layout";

interface EditUserFormProps extends LoadingComponent  {
  className?: string;
  id?: string;
  user?: User;
  profiles?: PROFILE[]
  onEdit?: (user: User) => void;
}

const CreateUserForm: FunctionComponent<EditUserFormProps> = ({
  className = "",
  id,
  profiles,
  user,
  onEdit,
  stopLoading,
  startLoading,
  closeForm = () => null,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const profileOptions: Option<PROFILE>[] =
    profiles ? profiles.map(value => ({label: intl.formatMessage({id: `PROFILE_${value}`}), value: value})) :
      PROFILE_OPTIONS.map(option => ({label: intl.formatMessage({id: option.label}), value: option.value}))

  const handleSubmitUser = (submittedUser: User) => {
    startLoading?.()
    if(!submittedUser?.id) {
      userService.createUser(submittedUser)
        .then(() => {
          navigate(`${ADMIN_PATH_REF}`)
          toastUtils.successToast(intl.formatMessage({id: "success_toast_create_user"}));
        })
        .catch((error) => toastUtils.errorToast(error.message ? intl.formatMessage({id: error.message}) : intl.formatMessage({id: "error_toast_create_user"})))
        .finally(() => {
          stopLoading?.()
          closeForm()
        })
    } else {
      userService.updateUser(submittedUser.id, submittedUser)
        .then((user: User) => {
          onEdit(user);
          toastUtils.successToast(intl.formatMessage({id: "success_toast_update_user"}));
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_user"}))
        }).finally(() => {
          stopLoading?.()
          closeForm()
        })
    }

  }

  return (
    <Formik
      initialValues={user || USER_INITIAL_VALUES}
      validationSchema={EditUserSchema}
      onSubmit={(values) => handleSubmitUser(values)}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <EpowForm id={id} className={className}>
          <UserEditProfileFormFragment
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
          <FormSelect
            id="profile"
            label="user_profiles"
            options={profileOptions}
            value={profileOptions.find(option => values?.profile.includes(option.value))}
            onChange={(option: Option<string>) => setFieldValue("profile",[option.value])}
            required
            isSearchable
            isClearable
            error={errors.profile}
            touched={touched.profile}
          />
          <FormInput
            id="job"
            label="user_job"
            value={values?.job}
            onChange={(e) => setFieldValue("job", e.target.value)}
            error={errors.job}
            touched={touched.job}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateUserForm;
