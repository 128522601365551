import React, {FunctionComponent, useMemo} from "react";
import {useIntl} from "react-intl";
import HeaderButtonGroup, {HeaderStateButton} from "./HeaderButtonGroup";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import Button from "../../atoms/Button";
import PageTitle from "./PageTitle";
import {useSearchParams} from "react-router-dom";

interface CommonEntityHeaderProps {
  className?: string
  pageTitle: string,
  updateSearchField: (field: string, value: string | string[]) => void,
  setCreationPanelOpen: (val: boolean) => void,
  creationButtonLabel: string,
  fieldState?: string
}

const CommonEntityHeader: FunctionComponent<CommonEntityHeaderProps> = ({
  className= "",
  updateSearchField,
  pageTitle,
  setCreationPanelOpen,
  creationButtonLabel,
  fieldState= "currentState"
}) => {
  const intl = useIntl()
  const [params] = useSearchParams()

  const stateFilterButtons: HeaderStateButton[] = useMemo(() => [{
        title: intl.formatMessage({id: "state_filter_active"}),
        onclick: () => updateSearchField(fieldState, WorkflowStatesEnum.ACTIVE),
        active: params.get(fieldState) === WorkflowStatesEnum.ACTIVE
      },
        {
          title: intl.formatMessage({id: "state_filter_inactive"}),
          onclick: () => updateSearchField(fieldState, WorkflowStatesEnum.INACTIVE),
          active: params.get(fieldState) === WorkflowStatesEnum.INACTIVE
        },
        {
          title: intl.formatMessage({id: "state_filter_created"}),
          onclick: () => updateSearchField(fieldState, WorkflowStatesEnum.CREATED),
          active: params.get(fieldState) === WorkflowStatesEnum.CREATED
        }]
    , [params])


  return (
    <div className={`${className} d-flex justify-content-between mb-5`}>
      <PageTitle pageTitle={pageTitle}></PageTitle>
      <HeaderButtonGroup buttonList={stateFilterButtons} />
      <div>
        <Button className="btn-header" onClick={() => setCreationPanelOpen(true)}>{creationButtonLabel}</Button>
      </div>
    </div>
  )
}

export default CommonEntityHeader
