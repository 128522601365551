import Button from "components/atoms/Button";
import Title from "components/atoms/Title";
import EditResourceProfessionalSituationForm from "components/molecules/form/resource/EditResourceProfessionalSituationForm";
import {
  ResourceProfessionalSituation, Seniority,
} from "interfaces/ResourceInterfaces";
import React, { FunctionComponent, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Col } from "reactstrap";
import { SizeType, ColorType, TitleSizeType } from "types/bootstrap/BootstrapType";
import { seniorityUtils } from "../../../../utils/seniorityUtils";

import ProfessionalSituationResourceCard from "./ProfessionalSituationResourceCard";

interface ProfessionalSituationResourceContainerProps {
  className?: string;
  self: boolean;
  resourceProfessionalSituation?: ResourceProfessionalSituation;
  setResourceAfterUpdate?: (
    resourceProfessionalSituation: ResourceProfessionalSituation
  ) => void;
}

const ProfessionalSituationResourceContainer: FunctionComponent<
  ProfessionalSituationResourceContainerProps
> = ({
  className = "",
  resourceProfessionalSituation,
  setResourceAfterUpdate,
  self
}) => {
  const intl = useIntl();
  const [editInfo, setEditInfo] = useState<boolean>(false);

  const seniority: Seniority = seniorityUtils.calculateSeniorityFromFirstMissionDate(resourceProfessionalSituation?.dateFirstMission);

  return (
    <Col className={className}>
      <div className="mb-3">
        <Title
          title={intl.formatMessage({ id: "resource_professional_situation" })}
          size={TitleSizeType.H5}
        />
      </div>
      {editInfo ? (
        <>
          <EditResourceProfessionalSituationForm
            id="editResourceProfessionalSituationForm"
            initialResourceProfessionalSituation={resourceProfessionalSituation}
            onChangeResourceAfterUpdate={setResourceAfterUpdate}
            self={self}
            setEditInfo={setEditInfo}
          />
          <div>
            <Button
              size={SizeType.MD}
              color={ColorType.SECONDARY}
              onClick={() => setEditInfo(false)}
              className="extended me-2"
            >
              <FormattedMessage id="cancel_button" />
            </Button>
            <Button
              form={"editResourceProfessionalSituationForm"}
              type="submit"
              size={SizeType.MD}
              className="extended"
            >
              <FormattedMessage id="save_button" />
            </Button>
          </div>
        </>
      ) : (
        <ProfessionalSituationResourceCard
          resourceProfessionalSituation={{...resourceProfessionalSituation, seniority}}
          onChangeResourceAfterUpdate={setResourceAfterUpdate}
          setEditInfo={setEditInfo}
          self={self}
        />
      )}
    </Col>
  );
};

export default ProfessionalSituationResourceContainer;
