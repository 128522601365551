import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import useSearchFilter from "../../../../../hooks/useSearchFilter";
import {OrderItemSearchObject} from "../../../../../interfaces/OrderItemInterfaces";
import {Resource} from "../../../../../interfaces/ResourceInterfaces";
import {activityService} from "../../../../../services/ActivityService";
import {resourceService} from "../../../../../services/ResourceService";
import {ColorType} from "../../../../../types/bootstrap/BootstrapType";
import {fileUtils} from "../../../../../utils/fileUtils";
import AutocompleteFilterPageable from "../../../filters/AutocompleteFilterPageable";
import FilterGroup from "../../../filters/FilterGroup";
import Button from "../../../../atoms/Button";
import MonthYearSelector from "../../../filters/MonthYearFilter";
import {format} from "date-fns";

interface AdminExportPdfCardProps {
  className?: string,
}

const AdminExportPdfCard: FunctionComponent<AdminExportPdfCardProps> = ({
  className,
}) => {
  const intl = useIntl()
  const {searchObject, updateSearchField, onReset} = useSearchFilter<OrderItemSearchObject>({})
  const [loading, setLoading] = React.useState<boolean>(false)

  const downloadExportPdf = (searchObject) => {
    setLoading(true)
    const fileData = {name: "export-summary-resource", extension: "pdf"}
    fileUtils.downloadFile(
      activityService.getActivitySummaryPdf(searchObject),
      fileData,
      intl,
      () => setLoading(false)
    )
  }

  const onChangeDate = (month: number, year: number) => {
    const startDate = new Date(year, month, 1)
    const endDate = new Date(year, month + 1, 0)

    updateSearchField("startDate", format(startDate, "yyyy-MM-dd"));
    updateSearchField("endDate", format(endDate, "yyyy-MM-dd"));
  }

  return (
    <div className={`${className ?? ""}`}>

      <div className="fs-14"><FormattedMessage id="exports_activity_summary" /></div>

      <FilterGroup className="mb-4">
        <MonthYearSelector onMonthYearSelected={onChangeDate} />

        <AutocompleteFilterPageable
          title={intl.formatMessage({id: "billing_overview_resource"})}
          value={searchObject?.resourceIds ? searchObject?.resourceIds[0] : ""}
          onChange={(value) => updateSearchField("resourceIds", [value])}
          fetchData={resourceService.getResourcesPage}
          filterFieldName="nameSurname"
          manageOptions={(response: Resource[]) => response.map(option =>
            ({value: option.id, label: `${option.identity?.firstName} - ${option.identity?.lastName}`})
          )}
          optionFromValue={(response: Resource[], value: string) => {
            const option = response?.find((resource) => resource.id === value);
            return option ? {
              label: `${option.identity?.firstName} ${option.identity?.lastName}`,
              value: option?.id
            } : null;
          }}
        />

        <div>
          <Button onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <Button onClick={() => downloadExportPdf(searchObject)} color={ColorType.SECONDARY}>
        {loading && <span className="loader loader-button-group ms-1"/>}
        {intl.formatMessage({id: "exports_tab_summary"})}
      </Button>
    </div>
  )
}

export default AdminExportPdfCard;
