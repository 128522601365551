import saveAs from "file-saver";
import {IntlShape} from "react-intl";
import {FileData} from "../interfaces/ResourceInterfaces";
import {BookingDocumentFormData} from "../interfaces/BookingInterfaces";
import {ActivityBillingRequest} from "../interfaces/ActivityBllingInterface";
import {navigatorUtils} from "./navigatorUtils";
import {PDF_VIEWER} from "../constants/routes/RoutePaths";
import {toastUtils} from "./toastUtils";

const saveFile = (blob: Blob, fileName?: string): void => {
  blob.arrayBuffer()
    .then((response: ArrayBuffer) => {
      const data = new Blob([response]);
      saveAs(data, fileName, {autoBom: false});
    })
    .catch(() => {
      // Do nothing
    })
}

const getCompleteFileName = (file: FileData): string => {
  return file ? `${file.name}.${file.extension}` : null;
}

const downloadFile = (request: Promise<Blob>, fileData: FileData, intl: IntlShape, callback?: () => void): void => {
  request
    .then((blob: Blob) => {
       if (fileData?.extension === "pdf") {
         const file = new File([blob], fileData?.name, {type: "application/pdf"})
         const pdfUrl = window.URL.createObjectURL(file);

         if(!navigatorUtils.isMobileDevice()) {
           const windowReference = window.open()
           windowReference.location = pdfUrl
         }
         else if(navigatorUtils.isMobileDevice() && navigatorUtils.detectBrowserName() === "safari") {
           setTimeout(() => {
             window.open(`${PDF_VIEWER}?url=${pdfUrl}&filename=${getCompleteFileName(fileData)}`, "_top")
           })
         } else {
           window.open(`${PDF_VIEWER}?url=${pdfUrl}&filename=${getCompleteFileName(fileData)}`, "_blank")
         }
      } else {
        saveFile(blob, getCompleteFileName(fileData));
      }
      callback && callback();
    })
    .catch((error) => {
      if (error.message) {
        toastUtils.errorToast(intl.formatMessage({id: error.message}))
      } else {
        toastUtils.errorToast(intl.formatMessage({ id: "error_retrieving_file" }));
      }
      callback && callback();
    })
}

const createFormDataWithFile = (file: File): FormData => {
  const formData = new FormData();
  formData.append("file", file);
  return formData;
}

const createFormDataWithFiles = (files: FileList): FormData => {
  const formData = new FormData();
  Array.from(files).map((file) => formData.append("files", file))
  return formData;
}

const createFormDataWithBookingFiles = (booking: BookingDocumentFormData): FormData => {
  const formData = new FormData();
  formData.set("bookingDocumentFormRequest", new Blob([JSON.stringify(booking)],{type: "application/json"}))
  return formData;
}


const createFormDataPJ = (content: string, files: File[], emailCc: string): FormData => {
  const formData = new FormData();
  files.forEach((file: File) => {
    formData.append("files", file);
  })
  formData.append("request", new Blob([JSON.stringify({content})], {type: "application/json"}));
  formData.append("emailCc", emailCc)
  return formData;
}

const createFormDataForActivityBillingFiles = (request: ActivityBillingRequest): FormData => {
  const formData = new FormData();
  formData.append("file", request.file);
  formData.set("activityBillingRequest", new Blob([JSON.stringify(request)],{type: "application/json"}))
  return formData;
}

const downloadFromPdfViewer = (url: string, fileName? :string) => {
  const link = document.createElement("a")
  link.href = url
  link.download = fileName || "softouring.pdf"
  link.target = "_blank"
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const fileUtils = {
  saveFile,
  downloadFile,
  downloadFromPdfViewer,
  getCompleteFileName,
  createFormDataWithFile,
  createFormDataWithFiles,
  createFormDataWithBookingFiles,
  createFormDataForActivityBillingFiles,
  createFormDataPJ
}
