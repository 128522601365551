import React, {FunctionComponent, useCallback} from "react";
import {useIntl} from "react-intl";
import GenericModal from "./GenericModal";
import {Quote, QuoteConvertRequest} from "../../../interfaces/QuoteInterfaces";
import {Formik} from "formik";
import * as Yup from "yup";
import {yupRequiredString} from "../../../constants/yup/Validators";
import EpowForm from "../form/EpowForm";
import {Row} from "reactstrap";
import FormInput from "../input/FormInput";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import {dateUtils} from "../../../utils/dateUtils";
import {customerService} from "../../../services/CustomerService";
import {CustomerSearchObject} from "../../../interfaces/CustomerInterfaces";
import {PaginationQueryParams} from "../../../interfaces/api/PaginationInterface";

interface ModalDeleteDocumentProps {
  open: boolean,
  quote: Quote,
  onOpen: (open: boolean) => void,
  convertQuote: (request: QuoteConvertRequest) => void,
  onClose: () => void,
}

const GenericModalConvertQuote: FunctionComponent<ModalDeleteDocumentProps> = ({
  open,
  onOpen,
  quote,
  convertQuote,
  onClose
}) => {
  const intl = useIntl()

  const fetchCustomers = useCallback((page: PaginationQueryParams, filter: CustomerSearchObject) => {
    return customerService.getActiveCustomers(page, {...filter, name: filter.name || quote.customerName})
  }, [quote.customerId]);


  return (
    <GenericModal
      title={intl.formatMessage({id: "modal_body_convert_title"})}
      open={open}
      closable
      onOpen={onOpen}
      footer={{
        primaryAction: {
          buttonLabel: intl.formatMessage({id: "validate_button"}),
          form: "conver-to-order"
        },
        secondaryAction: {
          buttonLabel: intl.formatMessage({id: "cancel_button"}),
          action: () => {
            onClose()
          }
        }
      }}
    >
      <div>

        <Formik
          initialValues={{customerRef: quote.customerRef, orderDate: dateUtils.formatDateYYYYMMDD(dateUtils.today()), customerId: quote.customerId}}
          validationSchema={Yup.object().shape({
            customerRef: yupRequiredString,
            orderDate: yupRequiredString,
            customerId: yupRequiredString,
          })}
          onSubmit={convertQuote}>
          {({errors, touched, values, setFieldValue}) => (
            <EpowForm id="conver-to-order">
              <Row>
                <FormInput
                  id="message"
                  type="date"
                  label={intl.formatMessage({id: "order_date"})}
                  value={values.orderDate}
                  onChange={(e) => setFieldValue("orderDate", e.target?.value)}
                  required
                  error={errors.orderDate}
                  touched={touched.orderDate}
                  rows="5"
                />

                <FormInput
                  id="message"
                  label={intl.formatMessage({id: "order_referenceClient"})}
                  value={values.customerRef}
                  onChange={(e) => setFieldValue("customerRef", e.target?.value)}
                  required
                  error={errors.customerRef}
                  touched={touched.customerRef}
                  rows="5"
                />

                <FormAutocompleteSelectPageable
                  id="customerId"
                  label={intl.formatMessage({id: "quote_customer"})}
                  placeholder={intl.formatMessage({id: "quote_customer"})}
                  value={values?.customerId}
                  onChange={(value) => setFieldValue("customerId", value)}
                  error={errors?.customerId}
                  touched={touched?.customerId}
                  required
                  fetchData={fetchCustomers}
                  filterFieldName="name"
                />

              </Row>
            </EpowForm>
          )}
        </Formik>
      </div>
    </GenericModal>
  )
}

export default GenericModalConvertQuote
