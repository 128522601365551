import {ActivityResourceCatalog} from "../../../../interfaces/ActivityInterfaces";
import React, {FunctionComponent, useState} from "react";
import {FormattedMessage} from "react-intl";
import Accordion from "../../accordion/Accordion";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import Panel from "../../panel/Panel";
import CreateActivityResourceCustomerForm from "../../form/CreateActivityResourceCustomerForm";
import ActivityResourceRateList from "../../list/ActivityResourceRateList";

interface ActivityResourceRateCardProps {
  className?: string,
  activityCustomerId?: string,
  activitiesResourceCatalog?: ActivityResourceCatalog[],
  title: string,
  onValidate?: () => void
}

const ActivityResourceRateCard: FunctionComponent<ActivityResourceRateCardProps> = ({
  className = "",
  activityCustomerId,
  activitiesResourceCatalog,
  title,
  onValidate
}) => {
  const [lateralPanelActivityResourceRate, setLateralPanelActivityResourceRate] = useState<boolean>(false)
  const [initialActivityResourceRate, setInitialActivityResourceRate] = useState<ActivityResourceCatalog>(null)

  const handleValidate = () => {
    setLateralPanelActivityResourceRate(false)
    onValidate && onValidate()
  }

  return (
    <div className={className}>
      <Accordion
        className="primary"
        title={title}
        startOpen
      >
        <ActivityResourceRateList
          activitiesResourceCatalog={activitiesResourceCatalog}
          setLateralPanelActivityResourceRate={setLateralPanelActivityResourceRate}
          setInitialActivityResourceRate={setInitialActivityResourceRate}
          onValidate={onValidate}
        />
        <Button
          size={SizeType.MD}
          color={ColorType.SECONDARY}
          className="extended"
          onClick={() => {
            setLateralPanelActivityResourceRate(true)
            setInitialActivityResourceRate(null)
          }}
        >
          <FormattedMessage id="add_button"/>
        </Button>
        {lateralPanelActivityResourceRate && (
          <Panel
            formId="createActivityResourceRateForm"
            title="add_resource_rate"
            open={lateralPanelActivityResourceRate}
            onCancel={() => setLateralPanelActivityResourceRate(false)}
          >
            <CreateActivityResourceCustomerForm
              id="createActivityResourceRateForm"
              activityCustomerId={activityCustomerId}
              initialActivityResourceRate={initialActivityResourceRate}
              onValidate={handleValidate}
            />
          </Panel>
        )}
      </Accordion>
    </div>
  )
}

export default ActivityResourceRateCard
