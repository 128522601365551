import * as Yup from "yup";
import {yupNullableString, yupRequiredEmail, yupRequiredString,} from "../yup/Validators";

export const CreateContactSchema = Yup.object().shape({
  lastName: yupRequiredString,
  firstName: yupRequiredString,
  phoneNumber: yupRequiredString,
  otherPhoneNumber: yupNullableString,
  email: yupRequiredEmail(3, 50),
  hasAccessToPlatform: yupRequiredString,
  accessToAllOrders: yupRequiredString,
});
