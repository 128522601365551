import {Formik} from "formik";
import EpowForm from "./EpowForm";
import FormSelect from "../input/FormSelect";
import React, {FunctionComponent, useEffect, useState} from "react";
import {Invoice} from "../../../interfaces/InvoiceInterfaces";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {ContactOption} from "../../../interfaces/ContactInterfaces";
import {customerContactService} from "../../../services/CustomerContactService";
import {toastUtils} from "../../../utils/toastUtils";
import {useIntl} from "react-intl";
import {invoiceService} from "../../../services/InvoiceService";
import {PageResponse} from "../../../interfaces/api/PaginationInterface";

interface EditInvoiceContactProps {
  id?: string,
  invoice?: Invoice,
  onSubmitSuccess: () => void,
}

const EditInvoiceContactForm: FunctionComponent<EditInvoiceContactProps> = ({
    id,
    invoice,
    onSubmitSuccess,
  }) => {
  const intl = useIntl();
  const [contactOptions, setContactOptions] = useState<Option<ContactOption>[]>([]);
  const getSupplierContacts = () => {
    customerContactService
      .getAllContactOptionsByCustomerId(invoice?.billingCustomer?.customerId)
      .then((contacts: PageResponse<ContactOption>) => {
        const options: Option<ContactOption>[] = contacts.content.map(contact => (
          {
            label: contact.name + " - " + contact.email,
            value: contact,
          }
        ))
        setContactOptions(options);
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_contacts"}));
      });
  };


  useEffect(() => {
    getSupplierContacts()
  }, []);

  const onSubmit = (contact: Option<ContactOption>) => {
    invoiceService.updateContact(invoice.id, contact.value?.id || null).then(() => {
      onSubmitSuccess()
      toastUtils.successToast(intl.formatMessage({id: "success_toast_update_invoice_contact"}))
    }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_invoice_contact"}))
    })
  }

  const initialValues:  Option<ContactOption> = {
    label: invoice.billingCustomer.firstName,
    value: { name: invoice.billingCustomer.firstName, email: invoice.billingCustomer.email, isContact: true },
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({values, setFieldValue}) => (
        <>
          <EpowForm id={id}>
            <FormSelect
              id="contact"
              label="billing_field_contact"
              value={contactOptions.find(o => o.value?.email === values?.value?.email)}
              onChange={(value) => setFieldValue("value", value?.value)}
              options={contactOptions}
              isSearchable
              isClearable
              placeholder="billing_field_contact_placeholder"
            />
          </EpowForm>
        </>

      )}
    </Formik>
  )

}


export default EditInvoiceContactForm;
