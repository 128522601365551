import { ResourceGeneralInfoFields, ResourceGeneralInfo, ResourceProfessionalSituation, ResourceProfessionalSituationFields, ResourceJobInfo, ResourceJobInfoFields } from "interfaces/ResourceInterfaces"

const buildResourceGeneralInfo = (resourceGeneralInfoFields: ResourceGeneralInfoFields): ResourceGeneralInfo => ({
  resourceTypeId: resourceGeneralInfoFields.resourceTypeId,
  gender: resourceGeneralInfoFields.gender,
  birthName: resourceGeneralInfoFields.birthName,
  nationality: resourceGeneralInfoFields.nationality,
  origin: resourceGeneralInfoFields.origin,
  identity: {
    firstName: resourceGeneralInfoFields.firstName,
    lastName: resourceGeneralInfoFields.lastName,
    email: resourceGeneralInfoFields.email,
    phoneNumber: resourceGeneralInfoFields.phoneNumber,
    otherPhoneNumber: resourceGeneralInfoFields.otherPhoneNumber
  },
  address: {
    mainLabel: resourceGeneralInfoFields.mainLabel,
    additionalInformation: resourceGeneralInfoFields.additionalInformation,
    service: resourceGeneralInfoFields.service,
    zipCode: resourceGeneralInfoFields.zipCode,
    city: resourceGeneralInfoFields.city,
    country: resourceGeneralInfoFields.country
  }
})

const buildResourceProfessionalSituationFields = (resourceProfessionalSituation: ResourceProfessionalSituation): ResourceProfessionalSituationFields => ({
  statusResource: resourceProfessionalSituation.statusResource,
  level: resourceProfessionalSituation.level,
  dateFirstMission: resourceProfessionalSituation.dateFirstMission,
  availability: resourceProfessionalSituation.availability,
  structureId: resourceProfessionalSituation.structure?.id
})

const buildResourceJobInfoFields = (resourceJobInfo: ResourceJobInfo): ResourceJobInfoFields => ({
  activityAreas: resourceJobInfo?.activityAreas.map(activityArea => ({
    value: activityArea.id,
    label: activityArea.label
  })),
  languages: resourceJobInfo?.spokenLanguages.map(language => ({
    value: language.id,
    label: language.label
  })),
  publicTypes: resourceJobInfo?.publicTypes,
  isNocturnal: resourceJobInfo?.isNocturnal,
})

export const resourceUtils = {
  buildResourceGeneralInfo,
  buildResourceProfessionalSituationFields,
  buildResourceJobInfoFields
}
