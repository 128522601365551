import React, {FunctionComponent, useMemo} from "react";
import {customerService} from "../../../services/CustomerService";
import {tableUtils} from "../../../utils/tableUtils";
import {INVOICE_PAYMENT} from "../../../constants/Table";
import PagedTable from "../table/PagedTable";
import Button from "../../atoms/Button";
import {FormattedMessage, useIntl} from "react-intl";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import AutocompleteFilterPageable from "../filters/AutocompleteFilterPageable";
import DateFilter from "../filters/DateFilter";
import {InvoicePayment, InvoicePaymentSearchObject} from "../../../interfaces/InvoicePaymentInterfaces";
import {invoicePaymentService} from "../../../services/InvoicePaymentService";
import {fileUtils} from "../../../utils/fileUtils";
import Toggle from "../../atoms/Toggle";

interface InvoicePaymentListProps {
  className?: string;
  searchObject?: InvoicePaymentSearchObject;
  updateSearchField: (field: string, value: boolean | string | string[]) => void,
  onReset: () => void,
}

const InvoicePaymentList: FunctionComponent<InvoicePaymentListProps> = ({
    className = "",
    searchObject,
    updateSearchField,
    onReset
  }) => {
  const intl = useIntl();

    const onClickDownload = (invoicePayment: InvoicePayment) => fileUtils.downloadFile(invoicePaymentService.downloadInvoicePayments(invoicePayment.id), {
      name: invoicePayment.referenceNumber,
      extension: "pdf"
    }, intl);

  const columns = useMemo(() => tableUtils.getColumns(INVOICE_PAYMENT(intl, onClickDownload)), []);

  return (
    <>
      <FilterGroup className="d-flex gap-2">
        <TextFilter
          className="main-filter"
          title={intl.formatMessage({id: "invoice_payment_search"})}
          value={searchObject.referenceNumber}
          onChange={(value) => updateSearchField("referenceNumber", value)}
          placeholder={intl.formatMessage({id: "invoice_payment_search_placeholder"})}
        />

        <AutocompleteFilterPageable
          onChange={(value) => updateSearchField("customerId", value)}
          className="order_filter_client"
          title={intl.formatMessage({id: "order_filter_customer"})}
          value={searchObject?.customerId}
          fetchData={customerService.getCustomersPage}
          filterFieldName="name"
        />

        <DateFilter
          value={searchObject.paymentDate}
          onChangeDate={(value) => updateSearchField("paymentDate", value)}
          title={intl.formatMessage({id: "invoice_payment_search_date"})}
        />

        <div>
          <Button onClick={onReset}
                  color={ColorType.SECONDARY}>{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <div className="d-flex align-items-center justify-content-end ms-auto mb-3">
        <div className="d-flex align-items-center bold">
          <FormattedMessage id="invoice_payment_from_credit_note_filter"/>
          <Toggle className="ms-2" id="from_credit_note_filter" onChange={(checked) => updateSearchField("fromCreditNote", checked)}/>
        </div>
      </div>

      <PagedTable
        columns={columns}
        labelNoResult="invoice_payment_no_result"
        className={`${className} table`}
        fetchData={invoicePaymentService.getInvoicePaymentPage}
        filters={searchObject}
        defaultSortBy={[{sortBy: "referenceNumber"}]}
      />
    </>
  );
};

export default InvoicePaymentList;
