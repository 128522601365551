import {capitalize, Dictionary, groupBy} from "lodash";
import React, {FunctionComponent} from "react";
import {Order} from "../../../../interfaces/OrderInterfaces";
import {OrderItem} from "../../../../interfaces/OrderItemInterfaces";
import {dateUtils} from "../../../../utils/dateUtils";
import Accordion from "../../accordion/Accordion";
import Icon from "../../../icon/Icon";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import OrderPreviewRoadBook from "../orderItem/OrderItemPreviewRoadBook";
import {useIntl} from "react-intl";
import {DateFormats} from "../../../../constants/DateConstants";

interface OderRoadBookCardProps {
  className?: string,
  order: Order,
  orderItems: OrderItem[],
}

const OrderRoadBookCard: FunctionComponent<OderRoadBookCardProps> = (
  {
    className = "",
    order,
    orderItems,
    }) => {

  const intl = useIntl();
  const ordersByDate: Dictionary<OrderItem[]> = groupBy(orderItems, "rendezVousDate")

  const buildDate = (date: string) => {
    const localized = dateUtils.formatDateDayJs(date, DateFormats.longDate)
    return capitalize(localized)
  }

  const sortOrderItemsForDay = (orderItems: OrderItem[]) => {
    orderItems.sort((a, b) => {
      if (a.rendezVousHour < b.rendezVousHour) {
        return -1
      } else if (a.rendezVousHour > b.rendezVousHour) {
        return 1
      } else {
        return a.name.localeCompare(b.name)
      }
    })
  }

  const displayOrderItems = () => {
    const sortedOrdersByDate = Object.entries(ordersByDate) ?? [];
    sortedOrdersByDate.sort((a, b) => dateUtils.parseDate(a[0]) < dateUtils.parseDate(b[0]) ? -1 : 1)

    const title = (date: string, index: number) => (<div className="d-flex"><Icon name="Calendar"  size={IconSizeType.XS} className="me-2"/><span className="bold me-2">{intl.formatMessage({id: "road_book_item_title"}, {index: index + 1})}</span>{buildDate(date)}</div>)

    return (
        <div className={className}>
          <div className="d-flex flex-column">
            <div>
              {sortedOrdersByDate?.map(([date, orderitemsByDate], index) => {
                sortOrderItemsForDay(orderitemsByDate)
                return (
                <Accordion
                    key={date}
                    startOpen
                    displayStar={false}
                    fullClickable={true}
                    className={"white mb-3"}
                    header={title(date,index)}
                  >{
                  orderitemsByDate?.map((orderItem) => {
                    return (
                      <OrderPreviewRoadBook key={orderItem.id} orderItem={orderItem}></OrderPreviewRoadBook>
                    )
                  })
                }</Accordion>
                )
              })}
            </div>
          </div>
        </div>
    )
  }

  return (order && displayOrderItems())
}

export default OrderRoadBookCard;
