import React, {FunctionComponent} from "react";
import {Col, Row} from "reactstrap";
import Icon from "../../../icon/Icon";
import {ColorType, IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import {ToggleUnpaidInvoiceBillingItem} from "../../../../interfaces/InvoicePaymentInterfaces";
import {IconName} from "../../../icon/IconsMap";
import PriceInput from "../../input/PriceInput";
import {moneyUtils} from "../../../../utils/moneyUtils";
import Tooltip from "../../../atoms/Tooltip";
import {useIntl} from "react-intl";


interface InvoicePaymentCreatePaymentRowProps {
  paymentItem: ToggleUnpaidInvoiceBillingItem,
  onIconClicked: () => void,
  onAmountToPaidChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  iconColor?: ColorType,
  iconName?: IconName,
}
const InvoicePaymentCreatePaymentRow : FunctionComponent <InvoicePaymentCreatePaymentRowProps> = ({
  paymentItem,
  onIconClicked,
  onAmountToPaidChange,
  iconColor = ColorType.SUCCESS,
  iconName = "CirclePlus",
}) => {

  const intl = useIntl();

  return(
    <div className="invoice-payment-item-row-wrapper cursor-default">
      <Row className="invoice-payment-item-row align-items-center my-1 mx-0 rounded-1" onClick={(event) => event.stopPropagation()}>
        <Col className={paymentItem.disbursement ? "color-secondary" : ""}>
          <span>{paymentItem.name}</span>
        </Col>
        <Col>
          <PriceInput
            className="my-3"
            required
            max={paymentItem.amountRemaining}
            min={0}
            value={paymentItem.amountToPaid}
            onChange={onAmountToPaidChange}
            disabled={iconColor === ColorType.SUCCESS}
          />
        </Col>
        <Col className={paymentItem.disbursement ? "color-secondary" : ""}>
          <span>{moneyUtils.formatNumberToCurrency(paymentItem.amountRemaining)}</span>
        </Col>
        <Col className={paymentItem.disbursement ? "color-secondary" : ""}>
          <span className="d-flex">{moneyUtils.formatNumberToCurrency(paymentItem.amount)}
          { paymentItem.hasCreditNote &&
            <Tooltip  text={intl.formatMessage({id: "invoice_billing_item_header_credit_note"}, {credit_note: paymentItem.creditNote})}>
              <Icon className="ms-2" name="InfoCircle"/>
            </Tooltip>}
          </span>
        </Col>
        <Col xs={2}>
          <Icon
            name={iconName}
            size={IconSizeType.XS}
            color={iconColor}
            className="float-end cursor-pointer"
            onClick={onIconClicked}
          />
        </Col>
      </Row>
    </div>
  )
}

export default InvoicePaymentCreatePaymentRow;
