export const enum DateFormats {
  shortDateFr = "DD/MM/YYYY", // 16/10/2023
  apiDate = "YYYY-MM-DD", // 2023/10/16
  shortDateTimeFr = "DD/MM/YYYY HH:mm", // 16/10/2023 10:30
  longDate = "dddd DD MMMM YYYY", // Lundi 16 octobre 2023
  longDateWithoutYear = "dddd DD MMMM", // Lundi 16 octobre 2023
  parisTimezone = "Europe/Paris",
  shortYearDateFr = "DDMMYY", // 161023
  monthAndYear = "MM/YYYY", // 10/2024
}
