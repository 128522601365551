import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {queryUtils} from "../utils/queryUtils";
import {
  InvoicePayment,
  InvoicePaymentCreateRequest,
  InvoicePaymentCreateSearchObject,
  InvoicePaymentDetail,
  InvoicePaymentSearchObject,
  UnpaidInvoice
} from "../interfaces/InvoicePaymentInterfaces";
import {httpService} from "./HttpService";
import {API_INVOICE_PAYMENTS_PATH} from "../constants/routes/RoutePaths";

function getInvoicePaymentPage(params: PaginationQueryParams, filter: InvoicePaymentSearchObject): Promise<PageResponse<InvoicePayment>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filter)
  return httpService.get<PageResponse<InvoicePayment>>(`${API_INVOICE_PAYMENTS_PATH}${queryParams}`)
}

function getInvoicePayment(id: string): Promise<InvoicePaymentDetail> {
  return httpService.get<InvoicePaymentDetail>(`${API_INVOICE_PAYMENTS_PATH}/${id}`)
}

function createComment(id: string, content: string): Promise<InvoicePaymentDetail> {
  return httpService.post<InvoicePaymentDetail>(`${API_INVOICE_PAYMENTS_PATH}/${id}/comments`, {content});
}

function deleteComment(id: string): Promise<Response> {
  return httpService.delete(`${API_INVOICE_PAYMENTS_PATH}/${id}/comments`, {});
}

function createInvoicePayment(invoicePayment: InvoicePaymentCreateRequest): Promise<InvoicePayment> {
  return httpService.post<InvoicePayment>(API_INVOICE_PAYMENTS_PATH, invoicePayment)
}

function getInvoicePaymentItemUnpaid(customerId: string, filter: InvoicePaymentCreateSearchObject): Promise<UnpaidInvoice[]> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams({}, filter)
  return httpService.get<UnpaidInvoice[]>(`${API_INVOICE_PAYMENTS_PATH}/client/${customerId}${queryParams}`)
}

const downloadInvoicePayments = (invoicePaymentId: string): Promise<Blob> => {
  return httpService.getReadableStream(`${API_INVOICE_PAYMENTS_PATH}/${invoicePaymentId}/download`);
}

export const invoicePaymentService = {
  getInvoicePaymentPage,
  getInvoicePayment,
  createComment,
  deleteComment,
  createInvoicePayment,
  getInvoicePaymentItemUnpaid,
  downloadInvoicePayments
}
