import React, {FunctionComponent} from "react";
import {ColorType} from "../../../../../types/bootstrap/BootstrapType";
import FilterGroup from "../../../filters/FilterGroup";
import {FormattedMessage, useIntl} from "react-intl";
import useSearchFilter from "../../../../../hooks/useSearchFilter";
import {fileUtils} from "../../../../../utils/fileUtils";
import Button from "../../../../atoms/Button";
import DateFilter from "../../../filters/DateFilter";
import {dateUtils} from "../../../../../utils/dateUtils";
import {bookingService} from "../../../../../services/BookingService";
import {toastUtils} from "../../../../../utils/toastUtils";
import {BookingSearchObject} from "../../../../../interfaces/BookingInterfaces";
import {DateFormats} from "../../../../../constants/DateConstants";
import {supplierService} from "../../../../../services/SupplierService";
import AutocompleteFilterPageable from "../../../filters/AutocompleteFilterPageable";

interface AdminExportBillingZipCardProps {
  className?: string,
}

const AdminExportBillingZipCard: FunctionComponent<AdminExportBillingZipCardProps> = ({
    className = "",
  }) => {
  const intl = useIntl()
  const {searchObject, updateSearchField, onReset} = useSearchFilter<BookingSearchObject>({
    startDate: "",
    endDate: "",
    supplierId: null,
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const filename = "FactFrns_" + dateUtils.formatDateDayJs(searchObject.startDate, DateFormats.shortYearDateFr) + "_" + dateUtils.formatDateDayJs(searchObject.endDate, DateFormats.shortYearDateFr);

  const downloadExport = (searchObject: BookingSearchObject) => {
    setLoading(true)
    bookingService.downloadBookingBills(searchObject)
      .then(file => fileUtils.saveFile(file, `${filename}.zip`))
      .catch((error) => {
        if (error?.status === 400) {
          toastUtils.infoToast(intl.formatMessage({id: "billing_download_no_result"}))
        } else {
          toastUtils.errorToast(intl.formatMessage({id: "billing_download_error"}))
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <div className={`${className}`}>

      <div className="fs-14"><FormattedMessage id="exports_supplier_invoices" /></div>

      <FilterGroup className="mb-4">
        <DateFilter required value={searchObject.startDate} onChangeDate={(value) => updateSearchField("startDate", value)} title={intl.formatMessage({id: "filter_from"})} />
        <DateFilter required value={searchObject.endDate} onChangeDate={(value) => updateSearchField("endDate", value)} title={intl.formatMessage({id: "filter_to"})} />

        <AutocompleteFilterPageable
          id="supplierId"
          title={intl.formatMessage({id: "purchase_order_supplier"})}
          placeholder={intl.formatMessage({id: "supplier_placeholder"})}
          onChange={(value) => updateSearchField("supplierId", value)}
          value={searchObject?.supplierId}
          fetchData={(page, filter) => supplierService.getSuppliersPaginated(page, {...filter})}
          filterFieldName="name"
        />

        <div>
          <Button onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <Button onClick={() => downloadExport(searchObject)} color={ColorType.SECONDARY} >
        {loading && <span className="loader loader-button-group ms-1"/>}
        {intl.formatMessage({id: "exports_tab_supplier_billing"})}
      </Button>
    </div>
  )
}

export default AdminExportBillingZipCard;
