import {Col, Row} from "reactstrap";
import Icon from "../../../icon/Icon";
import {ColorType, IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import React, {FunctionComponent} from "react";
import {ToggleUnpaidInvoice} from "../../../../interfaces/InvoicePaymentInterfaces";
import {useIntl} from "react-intl";
import {IconName} from "../../../icon/IconsMap";

interface InvoicePaymentCreateColumnProps {
  onIconClicked: () => void,
  elements: ToggleUnpaidInvoice[],
  predicate: (element: ToggleUnpaidInvoice) => boolean,
  row: (element: ToggleUnpaidInvoice) => JSX.Element,
  iconColor?: ColorType,
  iconName?: IconName,
  label?: string,
  subLabel?: string
}

const InvoicePaymentCreateColumn: FunctionComponent<InvoicePaymentCreateColumnProps> = ({
    onIconClicked,
    elements,
    predicate,
    row,
    iconColor = ColorType.SUCCESS,
    iconName= "CirclePlus",
    label = "invoice_payment_create_add_title",
    subLabel = "invoice_payment_create_add_subtitle"
  }) => {

  const intl = useIntl();

  return (
    <Col className="pe-3 mb-5" md={12}>
      <div className="d-flex">
        <div className="d-flex flex-column mb-3">
          <h2 className="ms-2 bold epow-label">{intl.formatMessage({id: label})}</h2>
          <span className="ms-2">{intl.formatMessage({id: subLabel})}</span>
        </div>
        <div className="ms-auto mb-2 me-2 align-self-end">
          <Icon
            name={iconName}
            size={IconSizeType.XS}
            color={iconColor}
            className="me-2 mb-1 cursor-pointer"
            onClick={onIconClicked}
          />
        </div>
      </div>

      <div>
        <hr className="mt-0 mb-2 d-none d-md-block"/>
        <Row className="bold mb-2">
          <Col>{intl.formatMessage({id: "invoice_payment_create_header_name"})}</Col>
          <Col>{intl.formatMessage({id: "invoice_payment_create_header_amount_to_paid"})}</Col>
          <Col>{intl.formatMessage({id: "invoice_payment_create_header_amount_remaining"})}</Col>
          <Col>{intl.formatMessage({id: "invoice_payment_create_header_amount_initial"})}</Col>
          <Col xs={2}></Col>
        </Row>
          {elements?.filter(predicate).map(row)}
      </div>
    </Col>
  )
}
export default InvoicePaymentCreateColumn;
