import * as Yup from "yup";
import {AnyObject} from "yup/lib/types";
import {
  getRequiredDateForCondition, yupDateIsBefore,
  yupNullableString,
  yupRequiredArray,
  yupRequiredBoolean,
  yupRequiredDate,
  yupRequiredEmail,
  yupRequiredString,
} from "../yup/Validators";

export const CreateResourceSchema = Yup.object().shape({
  resourceType: Yup.object().shape({
    id: yupRequiredString
  }),
  identity: Yup.object().shape({
    lastName: yupRequiredString,
    firstName: yupRequiredString,
    email: yupRequiredEmail(3, 50),
  }),
});

export const EditResourceGeneralInfoSchema = Yup.object().shape({
  gender: yupRequiredString,
  firstName: yupRequiredString,
  lastName: yupRequiredString,
  nationality: yupRequiredString,
  origin: yupRequiredString,
  email: yupRequiredEmail(3, 50),
  phoneNumber: yupRequiredString,
  otherPhoneNumber: yupNullableString,
  mainLabel: yupRequiredString,
  zipCode: yupRequiredString,
  city: yupRequiredString,
  country: yupRequiredString,
  resourceTypeId: yupNullableString,
}).when(["resourceTypeId"], {
  is: (exist) => !!exist,
  then: Yup.string().required(),
});

export const EditResourceProfessionalSituationSchema = Yup.object().shape({
  statusResource: yupRequiredString,
  level: yupRequiredString,
  dateFirstMission: yupRequiredDate,
  availability: yupRequiredString,
});

export const EditSelfResourceProfessionalSituationSchema = Yup.object().shape({
});

export const EditResourceJobInfoSchema = Yup.object().shape({
  activityAreas: yupRequiredArray(1),
  languages: yupRequiredArray(1),
  publicTypes: yupRequiredArray(1),
  isNocturnal: yupRequiredBoolean,
})

export const EditResourceLegalInfoSchema = (): Yup.ObjectSchema<AnyObject> => Yup.object().shape({
  rcpCoverStartDate: getRequiredDateForCondition("liabilityCardFile", (liabilityCardFile: string) => liabilityCardFile !== null),
  rcpValidityDate: getRequiredDateForCondition("liabilityCardFile", (liabilityCardFile: string) => liabilityCardFile !== null)
    .concat(yupDateIsBefore("rcpCoverStartDate", "resource_rcp_validity_date_before_start_date")),
  urssafSituationDate: getRequiredDateForCondition("urssafCardFile", (urssafCardFile: string) => urssafCardFile !== null),
})

