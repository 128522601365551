import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../../utils/toastUtils";
import Button from "../../../atoms/Button";
import FormInput from "../../input/FormInput";
import EpowForm from "../EpowForm";
import {AccountingConfig} from "../../../../interfaces/AccountingConfig";
import {accountingConfigService} from "../../../../services/AccountingConfigService";
import {AccountingConfigSchema} from "../../../../constants/validation/AccountingConfigValidationSchemas";

interface AccountingConfigFormProps {
  className?: string,
  onUpdate: () => void,
  onCancel: () => void,
  adminAccounts: AccountingConfig,
}

const AccountingConfigForm: FunctionComponent<AccountingConfigFormProps> = ({
    className = "",
    onUpdate,
    onCancel,
    adminAccounts,
  }) => {

  const intl = useIntl();
  const initialValues: AccountingConfig = {
    frAccountNumber: "",
    euAccountNumber: "",
    notUeAccountNumber: "",
    tvaAccountNumber: "",
    activityPurchaseAccountNumber: "",
  }
  const handleSubmit = (formValue: AccountingConfig) => {
    accountingConfigService.updateAccountingConfig(formValue)
      .then(() => {
        onUpdate()
        toastUtils.successToast(intl.formatMessage({id: "success_toast_update_admin_settings"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_save_admin_config"}))
      })
  }

  return (
    <Formik initialValues={adminAccounts || initialValues} validationSchema={AccountingConfigSchema} onSubmit={handleSubmit}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={"admin-accounts"} className={className}>
          <div className="col">
            <FormInput
              id="frAccountNumber"
              label="accounting_config_fr"
              type="text"
              value={values?.frAccountNumber}
              onChange={(e) => setFieldValue("frAccountNumber", e.target.value)}
              required
              error={errors.frAccountNumber}
              touched={touched.frAccountNumber}
            />

            <FormInput
              id="euAccountNumber"
              label="accounting_config_eu"
              type="text"
              value={values?.euAccountNumber}
              onChange={(e) => setFieldValue("euAccountNumber", e.target.value)}
              required
              error={errors.euAccountNumber}
              touched={touched.euAccountNumber}
            />

            <FormInput
              id="notUeAccountNumber"
              label="accounting_config_not_ue"
              type="text"
              value={values?.notUeAccountNumber}
              onChange={(e) => setFieldValue("notUeAccountNumber", e.target.value)}
              required
              error={errors.notUeAccountNumber}
              touched={touched.notUeAccountNumber}
            />

            <FormInput
              id="tvaAccountNumber"
              label="accounting_config_tva"
              type="text"
              value={values?.tvaAccountNumber}
              onChange={(e) => setFieldValue("tvaAccountNumber", e.target.value)}
              required
              error={errors.tvaAccountNumber}
              touched={touched.tvaAccountNumber}
            />

            <FormInput
              id="activityPurchaseAccountNumber"
              label="accounting_config_purchase"
              type="text"
              value={values?.activityPurchaseAccountNumber}
              onChange={(e) => setFieldValue("activityPurchaseAccountNumber", e.target.value)}
              required
              error={errors.activityPurchaseAccountNumber}
              touched={touched.activityPurchaseAccountNumber}
            />

          </div>

          <Button className="me-2" color={ColorType.SECONDARY} onClick={onCancel} >
            <FormattedMessage id="cancel_button" />
          </Button>

          <Button type="submit" >
            <FormattedMessage id="save_button" />
          </Button>


        </EpowForm>
      )}
    </Formik>
  )
}

export default AccountingConfigForm;
