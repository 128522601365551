import {authUtils} from "./authUtils";
import {PROFILE} from "../interfaces/UserInterfaces";
import {Option} from "../interfaces/inputs/OptionInterfaces";

const isAdmin = (): boolean => {
  const profileSelected = authUtils.getProfile()
  return profileSelected === PROFILE.ADMIN
}

const isResource = (): boolean => {
  const profileSelected = authUtils.getProfile()
  return profileSelected === PROFILE.RESOURCE
}

const isResourceAndValidated = (): boolean => {
  return !isResource() || authUtils.isProfileValidated() === "true"
}

const isTourLeader = (): boolean => {
  const profileSelected = authUtils.getProfile()
  return profileSelected === PROFILE.TOUR_LEADER
}

const isAccountant = (): boolean => {
  const profileSelected = authUtils.getProfile()
  return profileSelected === PROFILE.ACCOUNTANT
}

const canViewContact = (): boolean => {
  const profileSelected = authUtils.getProfile()
  return [
    PROFILE.ADMIN,
    PROFILE.INTERN,
    PROFILE.RESOURCE,
    PROFILE.CLIENT,
    PROFILE.TOUR_LEADER
  ].some(profile => profile === profileSelected)
}

const canViewCustomer = (): boolean => {
  const profileSelected = authUtils.getProfile()
  return [
    PROFILE.ADMIN,
    PROFILE.INTERN,
    PROFILE.CLIENT
  ].some(profile => profile === profileSelected)
}

const canViewTour = (): boolean => {
  const profileSelected = authUtils.getProfile()
  return [
    PROFILE.ADMIN,
    PROFILE.INTERN,
    PROFILE.RESOURCE,
    PROFILE.CLIENT,
    PROFILE.TOUR_LEADER,
    PROFILE.SUPPLIER
  ].some(profile => profile === profileSelected)
}

const isInterne = (): boolean => {
  const profileSelected = authUtils.getProfile()
  return [
    PROFILE.ADMIN,
    PROFILE.INTERN,
  ].some(profile => profile === profileSelected)
}

const getProfileOptions = (): Option<PROFILE>[] => {
  return Object.keys(PROFILE)
    .map(key => ({label: key, value: PROFILE[key]}))
}

export const profileUtils = {
  isAdmin,
  isResource,
  isResourceAndValidated,
  canViewContact,
  canViewCustomer,
  canViewTour,
  isInterne,
  isAccountant,
  isTourLeader,
  getProfileOptions
}
