import React, {FunctionComponent, useMemo, useState} from "react";
import {
  ResourceAvailabilityDto,
  ResourceAvailabilitySearchObject
} from "../../../interfaces/ResourceAvailabilityInterfaces";
import ResourceAvailabilityProvider from "../../../context/ResourceAvailabilityContext";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {useIntl} from "react-intl";
import {resourceAvailabilityService} from "../../../services/ResourceAvailabilityService";
import {toastUtils} from "../../../utils/toastUtils";
import {dateUtils} from "../../../utils/dateUtils";
import useSearchFilter from "../../../hooks/useSearchFilter";
import {Resource} from "../../../interfaces/ResourceInterfaces";
import DateFilter from "../filters/DateFilter";
import ResourceAvailabilityCardList from "./ResourceAvailabilityCardList";

interface ResourceAvailabilityContainerProps {
  resource: Resource;
}

const ResourceAvailabilityContainer: FunctionComponent<ResourceAvailabilityContainerProps> = ({
  resource
}) => {
  const intl = useIntl()

  const firstDay = dateUtils.today();
  const lastDay = new Date(dateUtils.today().setMonth(dateUtils.today().getMonth() + 1));

  const defaultFilter: ResourceAvailabilitySearchObject = {
    startDate: dateUtils.formatDateYYYYMMDD(firstDay),
    endDate: dateUtils.formatDateYYYYMMDD(lastDay),
  }
  const {searchObject, onReset, reloadSearch} = useSearchFilter<ResourceAvailabilitySearchObject>(defaultFilter)

  const [resourceAvailabilities, setResourceAvailabilities] = useState<ResourceAvailabilityDto[]>([])
  const [isNocturnal, setIsNocturnal] = useState<boolean>(false)

  const [startDate, setStartDate] = useState<string>(defaultFilter.startDate)
  const [endDate, setEndDate] = useState<string>(defaultFilter.endDate)

  useMemo(() => {
    if (resource) {
      resourceAvailabilityService.getResourceAvailabilitiesForCurrentUser(null, {...searchObject, resourceId: resource.id})
        .then((response) => {
          const dateRange = dateUtils.getDaysArrayBetweenDates(searchObject.startDate, searchObject.endDate)
          const values = dateRange.map(date => {
            const dateString = dateUtils.formatDateYYYYMMDD(date);
            const found = response.find(e => e.availabilityDate === dateString);
            return found ?? {
              availabilityDate: dateString,
              availableAm: true,
              availablePm: true,
              staffedAm: false,
              staffedPm: false,
            };
          })

          setIsNocturnal(resource.isNocturnal)
          setResourceAvailabilities(values)
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_get_resource_availability"}))
        })
    }
  }, [searchObject, resource])

  const updateFilters = () => {
    onReset({startDate, endDate})
  }

  const updateStartDate =(date: string) => {
    const newStartDate = dateUtils.parseDate(date);
    const currentEndDate = dateUtils.parseDate(endDate);

    if(newStartDate > currentEndDate) {
      const newEndDate = dateUtils.datePlusDays(newStartDate, 1)
      setEndDate(dateUtils.formatDateYYYYMMDD(newEndDate))
    }

    setStartDate(date)
  }

  return (
    <>
      <div className="d-flex gap-2 resource-availability-filters">
        <DateFilter
          value={startDate ?? ""}
          onChangeDate={(value) => updateStartDate(value)}
          title={intl.formatMessage({id: "resource_availability_filter_start"})}
        />
        <DateFilter
          value={endDate ?? ""}
          onChangeDate={(value) => setEndDate(value)}
          title={intl.formatMessage({id: "resource_availability_filter_end"})}
        />
        <div>
          <Button className="me-2" onClick={() => onReset(defaultFilter)} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
          <Button onClick={() => updateFilters()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "resource_availability_search"})}</Button>
        </div>
      </div>

      <ResourceAvailabilityProvider isNocturnal={isNocturnal}>
        <ResourceAvailabilityCardList
          resourceAvailabilities={resourceAvailabilities}
          reload={reloadSearch}
          resourceId={resource?.id}
        />
      </ResourceAvailabilityProvider>
    </>
  )
}

export default ResourceAvailabilityContainer;
