import * as Yup from "yup";
import {yupNullableString, yupRequiredArray, yupRequiredEmail, yupRequiredString} from "../yup/Validators";

export const CreateTourLeaderSchema = Yup.object().shape({
  identity: Yup.object().shape({
    firstName: yupRequiredString,
    lastName: yupRequiredString,
    email: yupRequiredEmail(3, 50)
  })
})

export const EditDetailsTourLeaderSchema = Yup.object().shape({
  identity: Yup.object().shape({
    firstName: yupRequiredString,
    lastName: yupRequiredString,
    phoneNumber: yupRequiredString,
    otherPhoneNumber: yupNullableString,
    email: yupRequiredEmail(3, 50)
  })
})

export const UpdateTourLeadersOrderSchema = Yup.object().shape({
  tourLeaderIds: yupRequiredArray(1)
})
