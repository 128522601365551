import React, { FunctionComponent, useMemo, useState } from "react";
import { IconSizeType } from "../../../types/bootstrap/BootstrapType";
import Icon from "../../icon/Icon";
import Dropdown from "../../atoms/input/Dropdown";
import { FormattedMessage, useIntl } from "react-intl";
import { DropdownOption } from "../../../interfaces/inputs/OptionInterfaces";
import { useNavigate } from "react-router-dom";
import { authUtils } from "../../../utils/authUtils";
import { LOGIN_PATH, PROFILE_PATH, SELF_PROFILE_PATH, getProfilePicture } from "../../../constants/routes/RoutePaths";
import { USER_PROFILE } from "../../../constants/user/UserConstants";
import { PROFILE } from "../../../interfaces/UserInterfaces";
import { Resource } from "interfaces/ResourceInterfaces";
import { userService } from "services/UserService";
import { toastUtils } from "utils/toastUtils";
import Avatar from "../images/Avatar";
interface UserMenuProps {
  className?: string,
}

const UserMenu: FunctionComponent<UserMenuProps> = ({ className = "" }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const profile = authUtils.getProfile()
  const [resource, setResource] = useState<Resource>();

  const userDropdownHeaderMenu: Array<DropdownOption> = useMemo(() => {
    if (profile === PROFILE.RESOURCE) {
      userService.getResource(authUtils.getUserId())
        .then((resource: Resource) => {
          setResource(resource);
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({ id: "resource_toast_resource_error" }));
        });
    }
    const userDropdownHeaderMenu = []
    if (profile === PROFILE.RESOURCE || profile === PROFILE.TOUR_LEADER) {
      userDropdownHeaderMenu.push({
        id: intl.formatMessage({ id: "dropdown_header_profile" }),
        label: intl.formatMessage({ id: "dropdown_header_profile" }),
        onClick: (): void => {
          navigate(PROFILE_PATH)
        }
      })
    } else {
      userDropdownHeaderMenu.push({
        id: "dropdown_header_profile",
        label: intl.formatMessage({ id: "dropdown_header_profile" }),
        onClick: (): void => {
          navigate(SELF_PROFILE_PATH)
        }
      })
    }

    userDropdownHeaderMenu.push({
      id: "dropdown_header_logout",
      label: intl.formatMessage({ id: "dropdown_header_logout" }),
      onClick: (): void => {
        authUtils.cleanSession()
        navigate(LOGIN_PATH)
      }
    })
    return userDropdownHeaderMenu;
  }, []);

  return (
    <Dropdown isToggle={false} id="header_menu_user" className={`menu-user ${className}`} options={userDropdownHeaderMenu}>
      <div className="profile-section d-flex flex-row align-items-center justify-content-between">
        <div className="d-flex flex-row align-items-center">
          {resource ? <Avatar url={getProfilePicture(resource?.id)} width={25} height={25} className="m-0 me-2" /> : <Icon className="me-2 profile-user-icon" size={IconSizeType.SM} name="UserRegular" />}
          <div className="flex-column text-start d-flex">
            <p className="fs-10 color-light p-0 m-0 text-nowrap">{`${authUtils.getUserFirstName()} ${authUtils.getUserLastName()}`}</p>
            <p className="profile p-0 m-0">
              {USER_PROFILE[profile] && (
                <FormattedMessage id={USER_PROFILE[profile].label} />
              )}
            </p>
          </div>
        </div>
        <Icon className="m-0 ms-2" size={IconSizeType.XS} name="ChevronDown" />
      </div>
    </Dropdown>
  )
}

export default UserMenu
