import React, {FunctionComponent, useEffect, useMemo} from "react";
import {RouteProps, useNavigate, useParams} from "react-router-dom";
import Layout from "../components/layout/Layout";
import PageTitle from "../components/molecules/header/PageTitle";
import {useIntl} from "react-intl";
import TextFilter from "../components/molecules/filters/TextFilter";
import FilterGroup from "../components/molecules/filters/FilterGroup";
import useSearchFilter from "../hooks/useSearchFilter";
import {
  ToggleUnpaidInvoice,
  ToggleUnpaidInvoiceBillingItem,
  InvoicePaymentCreateSearchObject,
  InvoicePaymentCreateRequest,
  UnpaidInvoice
} from "../interfaces/InvoicePaymentInterfaces";
import Button from "../components/atoms/Button";
import {ColorType} from "../types/bootstrap/BootstrapType";
import {Col, Row} from "reactstrap";
import InvoicePaymentCreatePaymentItemRow
  from "../components/molecules/table/billingPayment/InvoicePaymentCreatePaymentRow";
import InvoicePaymentCreateRow from "../components/molecules/table/billingPayment/InvoicePaymentCreateRow";
import InvoicePaymentCreateColumn from "../components/molecules/table/billingPayment/InvoicePaymentCreateColumn";
import FormInput from "../components/molecules/input/FormInput";
import {moneyUtils} from "../utils/moneyUtils";
import {invoicePaymentService} from "../services/InvoicePaymentService";
import {toastUtils} from "../utils/toastUtils";
import {INVOICE_PAYMENT_PATH} from "../constants/routes/RoutePaths";
import FieldLabel from "../components/atoms/FieldLabel";

const InvoicePaymentCreateView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  const initialSearchState: InvoicePaymentCreateSearchObject = useMemo(() => ({
    invoiceNumber: "",
  }), [])

  const {searchObject, updateSearchField} = useSearchFilter<InvoicePaymentCreateSearchObject>(initialSearchState);
  const { customerId } = useParams<{ customerId: string }>();
  const navigate = useNavigate();
  const [unpaidInvoices, setUnpaidInvoices] = React.useState<ToggleUnpaidInvoice[]>( [])
  const [paymentDate, setPaymentDate] = React.useState<string>(null);
  const [referenceNumber, setReferenceNumber] = React.useState<string>(null);

  const createPayload = (): InvoicePaymentCreateRequest => {
    return {
      referenceNumber,
      customerId,
      paymentDate,
      items: unpaidInvoices
        .filter(invoice => invoice.paymentItems.some(item => item.selected))
        .flatMap(invoice => invoice.paymentItems.filter(item => item.selected).map(item => ({
            invoiceBillingItemId: item.id,
            amount: item.amountToPaid ?? 0
          }))
        )
    }
  }

  const submit = () => {
    invoicePaymentService.createInvoicePayment(createPayload())
      .then((payment) => {
        toastUtils.successToast(intl.formatMessage({id: "invoice_payment_create_toast_success"}));
        navigate(INVOICE_PAYMENT_PATH.replace(":id", payment.id))
      })
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "invoice_payment_create_toast_error"})))
  }

  const mapToToggle = (data: UnpaidInvoice): ToggleUnpaidInvoice => {
    return {
      ...data,
      selected: false,
      rowAddOpen: false,
      rowAddedOpen: true,
      displayed: true,
      paymentItems: data.paymentItems.map(paymentItem => ({...paymentItem, selected: false, amountToPaid: paymentItem.amountRemaining}))
    }
  }

  const initInvoicePayment = () => {
    invoicePaymentService.getInvoicePaymentItemUnpaid(customerId, searchObject)
      .then((res) => setUnpaidInvoices(res.map(mapToToggle)))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "invoice_payment_create_toast_error"})))
  }

  const setData = () => {
    invoicePaymentService.getInvoicePaymentItemUnpaid(customerId, searchObject)
      .then((res) => {
        setUnpaidInvoices(prevElements => prevElements.map(element => ({
          ...element,
          displayed: res.some(item => item.referenceNumber === element.referenceNumber),
        })));
      })
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "invoice_payment_create_toast_error"})))
  }

  useEffect(() => {
    initInvoicePayment();
  }, [])

  useEffect(() => {
    setData();
  }, [searchObject])

  const updateToggleUnpaidInvoiceBillingItemProperty = (element: ToggleUnpaidInvoiceBillingItem, property: keyof ToggleUnpaidInvoiceBillingItem, value: unknown) => {
    setUnpaidInvoices(prevElements => {
      const newElements = [...prevElements];
      const index = newElements.findIndex(el => el.paymentItems.find(paymentItem => paymentItem.id === element.id));
      const paymentIndex = newElements[index].paymentItems.findIndex(paymentItem => paymentItem.id === element.id);
      newElements[index].paymentItems[paymentIndex] = { ...newElements[index].paymentItems[paymentIndex], [property]: value };
      if (property === "selected") {
        newElements[index].selected = newElements[index].paymentItems.every(paymentItem => paymentItem.selected);
      }
      return newElements;
    });
  }

  const selectAll = (selected: boolean) => {
    setUnpaidInvoices(prevElements => {
      return prevElements.map(element => ({...element, selected: selected, paymentItems: element.paymentItems.map(paymentItem => ({...paymentItem, selected: selected}))}))
    })
  };

  const updateSelectedAllRow = (element: ToggleUnpaidInvoice, selected: boolean) => {
    setUnpaidInvoices(prevElements => {
      const newElements = [...prevElements];
      const index = newElements.findIndex(e => e.referenceNumber === element.referenceNumber);
      newElements[index] = { ...newElements[index], selected: selected };
      newElements[index].paymentItems = element.paymentItems.map(paymentItem => ({...paymentItem, selected: selected}))
      return newElements;
    });
  }


  const updateRowOpen = (element: ToggleUnpaidInvoice, property: string) => {
    setUnpaidInvoices(prevElements => {
      const newElements = [...prevElements];
      const index = newElements.findIndex(e => e.referenceNumber === element.referenceNumber);
      newElements[index] = { ...newElements[index], [property]: !newElements[index][property] };
      return newElements;
    })
  }

  const sumSelectedAmountToPaid = (): number => {
    return unpaidInvoices
      .flatMap(invoice => invoice.paymentItems)
      .filter(item => item.selected)
      .map(item => item.amountToPaid)
      .reduce((sum: number, amountToPaid: number ) => sum + amountToPaid, 0);
  }

  const getToggleUnpaidInvoiceSelected = () => {
    return unpaidInvoices.filter(invoice => (invoice.paymentItems.some(items => items.selected)))
  }

  return (
    <Layout>
      <div className="epow-content-body">
        <div className="d-flex mb-5">
          <PageTitle pageTitle={intl.formatMessage({id: "invoice_payment_create_page_title"})}></PageTitle>
        </div>

        <FilterGroup className="d-flex gap-2 mb-4">
          <TextFilter
            className="w-25"
            title={intl.formatMessage({id: "invoice_filter_number"})}
            onChange={(value) => updateSearchField("invoiceNumber", value)}
            value={searchObject?.invoiceNumber}
            placeholder={intl.formatMessage({id: "invoice_filter_number_placeholder"})}
          />

          <Button onClick={() => updateSearchField("invoiceNumber", "")}
                  color={ColorType.SECONDARY}>{intl.formatMessage({id: "header_menu_clear_search"})}</Button>

        </FilterGroup>

        <Row className="epow-custom-table d-flex justify-content-between">
          <InvoicePaymentCreateColumn
            onIconClicked={() => selectAll(true)}
            elements={unpaidInvoices}
            predicate={element => !element.selected && element.displayed}
            row={element => (
              <InvoicePaymentCreateRow
                key={element.referenceNumber}
                onRowClicked={() => updateRowOpen(element, "rowAddOpen")}
                element={element}
                onIconClicked={() => updateSelectedAllRow(element, true)}
                predicate={items => !items.selected}
                open={element.rowAddOpen}
                itemsRow={paymentItem => (
                  <InvoicePaymentCreatePaymentItemRow
                    key={paymentItem.id}
                    paymentItem={paymentItem}
                    onIconClicked={() => updateToggleUnpaidInvoiceBillingItemProperty(paymentItem, "selected",true)}
                  />
                )}/>
          )}/>

          <InvoicePaymentCreateColumn
            onIconClicked={() => selectAll(false)}
            elements={unpaidInvoices}
            predicate={element => element.paymentItems.some(items => items.selected)}
            iconColor={ColorType.DANGER}
            iconName="CircleMinus"
            label={"invoice_payment_create_added_title"}
            subLabel={"invoice_payment_create_added_subtitle"}
            row={element => (
                <InvoicePaymentCreateRow
                  key={element.referenceNumber}
                  onRowClicked={() => updateRowOpen(element, "rowAddedOpen")}
                  element={element}
                  onIconClicked={() => updateSelectedAllRow(element,false)}
                  predicate={items => items.selected}
                  open={element.rowAddedOpen}
                  iconColor={ColorType.DANGER}
                  iconName="CircleMinus"
                  itemsRow={paymentItem => (
                    <InvoicePaymentCreatePaymentItemRow
                      key={paymentItem.id}
                      paymentItem={paymentItem}
                      onIconClicked={() => updateToggleUnpaidInvoiceBillingItemProperty( paymentItem, "selected", false)}
                      onAmountToPaidChange={(event) => updateToggleUnpaidInvoiceBillingItemProperty(paymentItem, "amountToPaid", parseFloat(event.target.value))}
                      iconColor={ColorType.DANGER}
                      iconName="CircleMinus"
                    />
                  )}/>
            )}/>

          { getToggleUnpaidInvoiceSelected().length !== 0 &&
            <Col className="d-flex flex-column justify-content-between offset-md-12 fs-6">
              <Row>
                <FieldLabel
                  className="col-12 mb-2"
                  label={intl.formatMessage({id : "invoice_payment_create_invoice_count"})}
                  value={getToggleUnpaidInvoiceSelected().length}
                />
                <FieldLabel
                  className="col-12 mb-2"
                  label={intl.formatMessage({id : "invoice_payment_create_invoice_total"})}
                  value={moneyUtils.formatNumberToCurrency(sumSelectedAmountToPaid())}
                />
              </Row>
              <Row>
                <FormInput
                  className="bold col-12"
                  type="date"
                  required
                  value={paymentDate}
                  label={intl.formatMessage({id: "invoice_payment_create_payment_date"})}
                  onChange={(e) => setPaymentDate(e.target.value)}
                />
                <FormInput
                  className="bold col-12"
                  type="text"
                  required
                  value={referenceNumber}
                  label={intl.formatMessage({id: "invoice_payment_create_reference_number"})}
                  placeholder={intl.formatMessage({id: "invoice_payment_create_reference_number_placeholder"})}
                  onChange={(e) => setReferenceNumber(e.target.value)}
                />
              </Row>

              <Button disabled={!paymentDate || !referenceNumber} className="extended" onClick={submit}>{intl.formatMessage({id: "save_button"})}</Button>
            </Col>}

        </Row>
      </div>
    </Layout>
  );
}
export default InvoicePaymentCreateView;


