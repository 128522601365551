import dayjs from "dayjs";
import "dayjs/locale/fr";
import "dayjs/locale/en";
import utc from "dayjs/plugin/utc";
import minMax from "dayjs/plugin/minMax";
import timezone from "dayjs/plugin/timezone";
import {DateFormats} from "../constants/DateConstants";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(minMax);


/**
 * Remplace le new Date() pour avoir une date avec heure française
 */
const today = (): Date => {
  return dayjs.utc().utcOffset(60).toDate();
}

function formatDateYYYYMMDD(date?: Date): string {
  if (!date) {
    date = today();
  }

  return dayjs(date).startOf("day").format(DateFormats.apiDate);
}

const datePlusDays = (date: Date, add: number) => {
  if(date === null) {
    return null
  }
  const newDate = new Date(date)
  newDate.setDate(date.getDate() + add);
  return newDate;
};

const dateMinusDays = (date: Date, minus: number) => {
  if(date === null) {
    return null
  }
  const newDate = new Date(date)
  newDate.setDate(date.getDate() - minus);
  return newDate;
};

const parseDate = (value: string): Date => {
  if (!value || value?.trim() == "") {
    return null
  }
  return dayjs.tz(value, DateFormats.parisTimezone).toDate();
};

/**
 * Formate une date au format souhaité en utilisant le format UTC pour éviter les changements de jour en fonction du fuseau horaire UTC+1 (Paris).
 *
 * @param value La valeur de la date (sans heure) à formater.
 * @param formatWanted Le format souhaité de la date. Par défaut : "DD/MM/YYYY".
 * @param currentFormat Le format actuel de la date. Par défaut : "YYYY-MM-DD".
 * @returns La date formatée selon le format spécifié.
 */
const formatDateDayJs = (value: string, formatWanted = DateFormats.shortDateFr, currentFormat = DateFormats.apiDate): string => {
  const userLocale = (navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language);

  // Conversion de la date en tenant compte du format spécifié et du fuseau horaire UTC+1 (Paris)
  return value ? dayjs.utc(value, currentFormat).locale(userLocale).utcOffset(60).format(formatWanted) : null;
}


function convertStringToObjectDate(date?: string): Date {
  const regexTimeUTC = /(Z|([+|-][0-9]{2}(:?[0-9]{2})?))$/;
  if (!date) {
    return dayjs.utc().utcOffset(60).toDate();
  }
  if (!regexTimeUTC.test(date)) {
    return new Date(`${date}Z`);
  }

  return dayjs.utc(date, DateFormats.apiDate).utcOffset(60).toDate();
}

/**
 *
 * @param dateTime
 *
 * return ex :06/02/2024 14:24
 */
function formatDateToLocalDateTime(dateTime?: string | Date): string {
  if (!dateTime) {
    return ""
  }
  const dateUTC = dayjs(dateTime).utc()
  const dateParis = dateUTC.tz(DateFormats.parisTimezone)

  return dateParis.format(DateFormats.shortDateTimeFr)
}

function formatDuration(totalSeconds?: number): string {
  if (!totalSeconds) {
    return "-";
  }
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds - hours * 3600 - minutes * 60;

  return [`${hours}h`, `${minutes}m`, `${seconds}s`]
    .filter((item) => item[0] !== "0")
    .join(" ");
}

function convertSecondsToTime(seconds?: number): string {
  if (!seconds) {
    return undefined;
  }
  return new Date(seconds * 1000).toISOString().substring(11, 19);
}

function convertTimeToSeconds(time?: string): number {
  if (!time) {
    return undefined;
  }
  const [hours, minutes] = time.split(":");
  return +hours * 3600 + +minutes * 60;
}

const getTimeWithoutSeconds = (time: string): string => {
  if (!time) {
    return undefined;
  }
  const [hours, minutes] = time.split(":");
  return [hours, minutes].join(":");
}

function formatLocalTime(time: string): string {
  if (!time) {
    return "";
  }
  const [hours, minutes] = time.split(":");
  return `${hours}h${minutes}`;
}

const getNumberOfMonthsBetweenTwoDates = (date1: Date, date2: Date): number => {
  const differenceInMonths = date2.getMonth() - date1.getMonth();
  const differenceInYears = date2.getFullYear() - date1.getFullYear();
  return 12 * differenceInYears + differenceInMonths;
};

const getHoursBetweenTwoDates = (date1: Date, date2: Date = today()): number => {
  const differenceEnMilliseconds: number = Math.abs(date2.getTime() - date1.getTime());
  return Math.round(differenceEnMilliseconds / (1000 * 60 * 60));
}

const firstDayOfLastMonth = (): Date => {
  const date = today();
  date.setMonth(date.getMonth() - 1);
  date.setDate(1);
  return date;
}

const lastDayOfLastMonth = (): Date => {
  const date = today();
  date.setDate(1);
  date.setDate(date.getDate() - 1);
  return date;
}

const firstDayOfTheMonth = (): Date => {
  const date = today();
  date.setDate(1);
  return date;
}


const lastDayOfTheMonth = (): Date => {
  const date = today();
  date.setMonth(date.getMonth() + 1);
  date.setDate(0);
  return date;
}


const getDaysArrayBetweenDates = (startDate: string, endDate: string): Date[] => {
  const result = [];
  const start = convertStringToObjectDate(startDate);
  const end = convertStringToObjectDate(endDate);

  for(let i = start ; i <= end; i.setDate(i.getDate() + 1)) {
    result.push(new Date(i));
  }
  return result;
};

const getMaxDate = (dates: string[]): string => {
  return dayjs.max(dates.map(date => dayjs(date))).format(DateFormats.apiDate)
}

const getDateOneMonthAgo = () : string => {
  return dayjs().subtract(1, "month").format(DateFormats.apiDate)
}

const isBefore = (date1: string, date2: string): boolean =>{
  return dayjs(date1).isBefore(date2);
}

export const dateUtils = Object.freeze({
  formatDateYYYYMMDD,
  parseDate,
  convertStringToObjectDate,
  formatDateToLocalDateTime,
  formatDuration,
  convertSecondsToTime,
  convertTimeToSeconds,
  getTimeWithoutSeconds,
  formatLocalTime,
  getNumberOfMonthsBetweenTwoDates,
  firstDayOfLastMonth,
  lastDayOfLastMonth,
  firstDayOfTheMonth,
  lastDayOfTheMonth,
  getDaysArrayBetweenDates,
  datePlusDays,
  dateMinusDays,
  getHoursBetweenTwoDates,
  getMaxDate,
  getDateOneMonthAgo,
  formatDateDayJs,
  today,
  isBefore
});
