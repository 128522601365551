import {httpService} from "./HttpService";
import {API_COMMENTS, API_RESOURCES_PATH} from "../constants/routes/RoutePaths";
import {
  FileData,
  Resource,
  ResourceGeneralInfo,
  ResourceJobInfoRequest,
  ResourceJobInfoResponse,
  ResourceLegalInfo,
  ResourceProfessionalSituation,
  ResourceSearchObject,
  ResourceTableDto,
} from "../interfaces/ResourceInterfaces";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {Comment, CommentRequest} from "interfaces/CommentInterface";
import {queryUtils} from "../utils/queryUtils";
import {fileUtils} from "../utils/fileUtils";
import {FileType} from "../constants/FileConstants";
import {OrderItemSearchObject, OrderItemWithActivityBillingDto} from "../interfaces/OrderItemInterfaces";
import {ActivityShortDTO} from "../interfaces/ActivityInterfaces";

function getResourcesPage(params: PaginationQueryParams, filter?: ResourceSearchObject): Promise<PageResponse<ResourceTableDto>> {
    const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filter)
    return httpService.get<PageResponse<ResourceTableDto>>(`${API_RESOURCES_PATH}/page${queryParams}`)
}

function getResource(id: string): Promise<Resource> {
    return httpService.get<Resource>(`${API_RESOURCES_PATH}/${id}`)
}
function getSelfResource(): Promise<Resource> {
  return httpService.get<Resource>(`${API_RESOURCES_PATH}/self`)
}

function updateResource (id: string, resource: Resource): Promise<Resource> {
    return httpService.put<Resource>(`${API_RESOURCES_PATH}/${id}`, resource)
}

function validate(): Promise<Resource> {
  return httpService.post<Resource>(`${API_RESOURCES_PATH}/self/validate`, {})
}

function invite(resourceId: string): Promise<Resource> {
  return httpService.post<Resource>(`${API_RESOURCES_PATH}/${resourceId}/invite`, {})
}

const updateResourceGeneralInfo = (resourceId: string, resourceGeneralInfo: ResourceGeneralInfo): Promise<Resource> => {
  return httpService.patch<Resource>(`${API_RESOURCES_PATH}/${resourceId}/general-info`, resourceGeneralInfo);
}

const updateSelfResourceGeneralInfo = (resourceGeneralInfo: ResourceGeneralInfo): Promise<Resource> => {
  return httpService.patch<Resource>(`${API_RESOURCES_PATH}/self/general-info`, resourceGeneralInfo);
}

const updateResourceProfessionalSituation = (id: string, resourceProfessionalSituation: ResourceProfessionalSituation): Promise<ResourceProfessionalSituation> => {
  return httpService.patch<ResourceProfessionalSituation>(`${API_RESOURCES_PATH}/${id}/professional-situation`, resourceProfessionalSituation);
}

const updateSelfResourceProfessionalSituation = (resourceProfessionalSituation: ResourceProfessionalSituation): Promise<ResourceProfessionalSituation> => {
  return httpService.patch<ResourceProfessionalSituation>(`${API_RESOURCES_PATH}/self/professional-situation`, resourceProfessionalSituation);
}

const updateResourceJobInfo = (id: string, resourceJobInfoFields: ResourceJobInfoRequest): Promise<ResourceJobInfoResponse> => {
  return httpService.patch(`${API_RESOURCES_PATH}/${id}/job-info`, resourceJobInfoFields);
}

const updateSelfResourceJobInfo = (resourceJobInfoFields: ResourceJobInfoRequest): Promise<ResourceJobInfoResponse> => {
  return httpService.patch(`${API_RESOURCES_PATH}/self/job-info`, resourceJobInfoFields);
}

const updateResourceLegalInfo = (id: string, resourceLegalInfo: ResourceLegalInfo): Promise<ResourceLegalInfo> => {
  return httpService.patch<ResourceLegalInfo>(`${API_RESOURCES_PATH}/${id}/legal-info`, resourceLegalInfo);
}

const updateSelfResourceLegalInfo = (resourceLegalInfo: ResourceLegalInfo): Promise<ResourceLegalInfo> => {
  return httpService.patch<ResourceLegalInfo>(`${API_RESOURCES_PATH}/self/legal-info`, resourceLegalInfo);
}

function deleteResource (id: string): Promise<Response> {
    return httpService.delete(`${API_RESOURCES_PATH}/${id}`)
}

function createResource (resourceRequest: Resource): Promise<Resource> {
    return httpService.post<Resource>(API_RESOURCES_PATH, resourceRequest)
}

const createResourceComment = (resourceId: string, commentRequest: CommentRequest): Promise<Comment> => {
  return httpService.post<Comment>(`${API_RESOURCES_PATH}/${resourceId}/${API_COMMENTS}`, commentRequest);
}

const updateResourceComment = (resourceId: string, commentRequest: CommentRequest): Promise<Comment> => {
  return httpService.put<Comment>(`${API_RESOURCES_PATH}/${resourceId}/${API_COMMENTS}`, commentRequest);
}

const deleteResourceComment = (resourceId: string): Promise<Response> => {
  return httpService.delete(`${API_RESOURCES_PATH}/${resourceId}/${API_COMMENTS}`);
}

const uploadCard = (resourceId: string, file: File, fileType: FileType): Promise<FileData> => {
  const formData = fileUtils.createFormDataWithFile(file);
  return httpService.postFormData(`${API_RESOURCES_PATH}/${resourceId}/file/${fileType}`, formData);
}

const deleteFile = (resourceId: string, fileType: FileType): Promise<Response> => {
  return httpService.delete(`${API_RESOURCES_PATH}/${resourceId}/file/${fileType}`)
}

const uploadSelfCard = (file: File, fileType: FileType): Promise<FileData> => {
  const formData = fileUtils.createFormDataWithFile(file);
  return httpService.postFormData(`${API_RESOURCES_PATH}/self/file/${fileType}`, formData);
}

const deleteSelfFile = (fileType: FileType): Promise<Response> => {
  return httpService.delete(`${API_RESOURCES_PATH}/self/file/${fileType}`)
}

const getCard = (resourceId: string, fileType: FileType): Promise<Blob> => {
  return httpService.getReadableStream(`${API_RESOURCES_PATH}/${resourceId}/file/${fileType}`);
}

const getProfilePicture = (resourceId: string): Promise<Blob> => {
  return httpService.getReadableStream(`${API_RESOURCES_PATH}/${resourceId}/profile-picture`);
}

const uploadProfilePicture = (resourceId: string, formData: FormData): Promise<Blob> => {
  return httpService.postFormData(`${API_RESOURCES_PATH}/${resourceId}/profile-picture`, formData);
}

const getOrderItemWithActivityBillingDtoPage = (pageParams: PaginationQueryParams, filterParams: OrderItemSearchObject): Promise<PageResponse<OrderItemWithActivityBillingDto>> => {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(pageParams, filterParams)
  return httpService.get(`${API_RESOURCES_PATH}/self/order-items${queryParams}`)
}

const getOrderItemWithActivityBillingExport = (resourceId: string, params: OrderItemSearchObject): Promise<Blob> => {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(null, params)
  return httpService.getReadableStream(`${API_RESOURCES_PATH}/${resourceId}/export/order-items${queryParams}`)
}

function getResourceActivities(resourceId: string): Promise<ActivityShortDTO[]> {
  return httpService.get<ActivityShortDTO[]>(`${API_RESOURCES_PATH}/${resourceId}/activities`)
}

function updateLinkedActivities (resourceId: string, activityIds: string[]): Promise<ActivityShortDTO[]> {
  return httpService.put<ActivityShortDTO[]>(`${API_RESOURCES_PATH}/${resourceId}/activities`, { activityIds })
}

function deleteLinkedActivities (resourceId: string, activityIds: string[]): Promise<Response> {
  return httpService.delete(`${API_RESOURCES_PATH}/${resourceId}/activities`, { activityIds })
}

function remindResourcesMail(resourceIds: string[], template: string, orderItemId: string): Promise<Response> {
  return httpService.post(`${API_RESOURCES_PATH}/reminder`, {resourceIds, template, orderItemId})
}

function downloadOrderItemIcs(orderId: string): Promise<Blob> {
  return httpService.getReadableStream(`${API_RESOURCES_PATH}/self/order-items/${orderId}/ics`);
}

export const resourceService = {
  getResourcesPage,
  getResource,
  getSelfResource,
  updateResource,
  updateResourceGeneralInfo,
  updateSelfResourceGeneralInfo,
  updateResourceProfessionalSituation,
  updateSelfResourceProfessionalSituation,
  updateSelfResourceLegalInfo,
  updateResourceJobInfo,
  updateSelfResourceJobInfo,
  updateResourceLegalInfo,
  deleteResource,
  createResource,
  createResourceComment,
  updateResourceComment,
  deleteResourceComment,
  uploadCard,
  deleteFile,
  deleteSelfFile,
  getCard,
  validate,
  uploadSelfCard,
  getOrderItemWithActivityBillingExport,
  getOrderItemWithActivityBillingDtoPage,
  invite,
  getResourceActivities,
  updateLinkedActivities,
  deleteLinkedActivities,
  uploadProfilePicture,
  getProfilePicture,
  remindResourcesMail,
  downloadOrderItemIcs
}
