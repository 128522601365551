import {ErrorResponse, Params} from "../interfaces/HttpInterfaces"
import {API_ROUTE} from "../constants/routes/ApiRoutes";
import {ContentTypeEnum} from "../constants/fileType";
import {authUtils} from "../utils/authUtils";
import {Data} from "../interfaces/TableInterfaces";
import {LOGIN_PATH} from "../constants/routes/RoutePaths";
import {languageUtils} from "../utils/languageUtils";

type RequestBody = Object | Params | Data | null;

function getAuthHeader() {
  const token = authUtils.getToken()
  if (token) {
    return { Authorization: `Bearer ${token}` }
  }
  return undefined
}

function handleResponseError(response: Response, data: ErrorResponse) {
  let error = response.statusText

  if(data?.message) {
    return Promise.reject(data)
  }

  if (data && data.status && data.status >= 500) {
    error = languageUtils.getMessage("server_error")
  }

  return Promise.reject(error)
}

function handleResponse<T>(response: Response): Promise<T> {
  return response.text().then((text: string) => {
    let data;
    try {
      data = text && JSON.parse(text)
    } catch (e) {
      data = {
        status: response.status,
        globalErrors: response.statusText,
        message: response.statusText,
      }
    }

    if (response.status === 401) {
      authUtils.cleanSession()
      window.location.href = LOGIN_PATH
    }

    if (!response.ok) {
      return handleResponseError(response, data)
    }

    return data as Promise<T>
  })
}

function get<T>(url: string, requestParams?: Record<string, string>): Promise<T> {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {Accept: ContentTypeEnum.JSON, ...getAuthHeader()}
  }
  const urlFull = requestParams ? url + "?" + new URLSearchParams(requestParams).toString() : url

  return fetch(API_ROUTE + urlFull, requestOptions).then((response) => {
    return handleResponse<T>(response)
  })
}

function getExternalApi<T>(url: string): Promise<T> {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {Accept: ContentTypeEnum.JSON}
  }

  return fetch(url, requestOptions).then((response) => {
    return handleResponse<T>(response)
  })
}

function submit<T>(
  url: string,
  body: RequestBody,
  method: string
): Promise<T> {
  const requestOptions: RequestInit = {
    method: method,
    headers: {"Content-Type": `${ContentTypeEnum.JSON};`, ...getAuthHeader()},
    body: body && JSON.stringify(body)
  }
  return fetch(API_ROUTE + url, requestOptions).then((response) =>
    handleResponse<T>(response)
  )
}

function post<T>(url: string, body: RequestBody): Promise<T> {
  return submit(url, body, "POST")
}

function put<T>(url: string, body: RequestBody): Promise<T> {
  return submit(url, body, "PUT")
}

function putFormData<T>(url: string, body: FormData): Promise<T> {
  const requestOptions: RequestInit = {
    method: "PUT",
    body: body,
    headers: {...getAuthHeader()},
  }
  return fetch(API_ROUTE + url, requestOptions).then((response) =>
    handleResponse<T>(response)
  )
}

function patch<T>(url: string, body: RequestBody): Promise<T> {
  return submit(url, body, "PATCH")
}

function postFormData<T>(url: string, body: FormData): Promise<T> {
  const requestOptions: RequestInit = {
    method: "POST",
    body: body,
    headers: {...getAuthHeader()},
  }
  return fetch(API_ROUTE + url, requestOptions).then((response) =>
    handleResponse<T>(response)
  )
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url: string, body?: RequestBody): Promise<Response> {
  const requestOptions: RequestInit = {
    method: "DELETE",
    body: body && JSON.stringify(body),
    headers: {"Content-Type": `${ContentTypeEnum.JSON};`, ...getAuthHeader()},
  }
  return fetch(API_ROUTE + url, requestOptions).then((response) =>
    handleResponse<Response>(response)
  )
}

function fetchReadableStream(url: string, options: RequestInit): Promise<Blob> {
  return fetch(API_ROUTE + url, options)
    .then((response) => {
      if (!response.ok) {
        return handleResponse(response);
      }
      return response.blob();
    })
}

function fetchReadableStreamWithFileName(url: string, options: RequestInit): Promise<{ fileName: string | null; blob: Blob }> {
  return fetch(API_ROUTE + url, options)
    .then((response) => {
      if (!response.ok) {
        return handleResponse(response);
      }
      const contentDispositionHeader = response.headers.get("Content-Disposition");
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDispositionHeader);
      let fileName = null;
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
      return response.blob().then(blob => ({ fileName, blob }));
    })
}

function getReadableStream(url: string, contentType?: string): Promise<Blob> {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {...getAuthHeader(), "Content-Type": contentType ? contentType: `${ContentTypeEnum.PDF};`}
  }

  return fetchReadableStream(url, requestOptions)
}

function postReadableStreamGeneric<T>(url: string, body: BodyInit | RequestBody, fetchFunction: (url: string, options: RequestInit) => Promise<T>, contentType?: string): Promise<T> {

  const requestOptions: RequestInit = {
    method: "POST",
    headers: {...getAuthHeader(), "Content-Type": contentType ? contentType: `${ContentTypeEnum.PDF};`},
    body: body && JSON.stringify(body)
  }

  return fetchFunction(url, requestOptions)
}

function postReadableStreamWithFileName(url: string, body: BodyInit | RequestBody, contentType?: string): Promise<{ fileName: string | null; blob: Blob }> {
  return postReadableStreamGeneric(url, body, fetchReadableStreamWithFileName, contentType);
}

function postReadableStream(url: string, body: BodyInit | RequestBody, contentType?: string): Promise<Blob> {
  return postReadableStreamGeneric(url, body, fetchReadableStream, contentType);
}

function encodeData (
  key: string,
  value: string|[]|object|number|boolean,
  keepEmpty = false
): string {
  if (!keepEmpty && (value === undefined || value === null || value === "")) {
    return ""
  } else if (Array.isArray(value)) {
    let arrayKey = key
    if (!arrayKey.endsWith("[]")) {
      arrayKey = `${key}[]`
    }
    return value
      .map(arrayValue => encodeData(arrayKey, arrayValue))
      .filter(uri => uri)
      .join("&")
  } else {
    if (value === undefined || value === null) {
      value = ""
    }
    return [key, value].map(encodeURIComponent).join("=")
  }
}

function encodeURIData (data: Params, keepEmpty = false): string {
  return Object.entries(data)
    .map(([key, value]) => encodeData(key, value, keepEmpty))
    .filter(uri => uri.length > 0)
    .join("&")
}


export const httpService = {
  get,
  post,
  postFormData,
  put,
  putFormData,
  patch,
  getReadableStream,
  postReadableStream,
  postReadableStreamWithFileName,
  delete: _delete,
  getExternalApi,
  encodeURIData
}
