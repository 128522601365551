import Button from "components/atoms/Button";
import {GENDER_OPTIONS} from "constants/OptionConstants";
import {ResourceGeneralInfoFields} from "interfaces/ResourceInterfaces";
import React, {FunctionComponent} from "react"
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Row} from "reactstrap";
import {ColorType, SizeType} from "types/bootstrap/BootstrapType";
import {getCountryLabel} from "utils/countryUtils";
import {optionUtils} from "utils/optionUtils";
import {COUNTRY_OPTIONS} from "../../../../constants/CountryConstants";
import FieldLabel from "../../../atoms/FieldLabel";
import {authUtils} from "../../../../utils/authUtils";
import {PROFILE} from "../../../../interfaces/UserInterfaces";

interface ResourceCardProps {
  className?: string;
  resourceGeneralInfoFields?: ResourceGeneralInfoFields;
  editInfo?: boolean;
  setEditInfo?: (editInfo: boolean) => void;
}

const ResourceCard: FunctionComponent<ResourceCardProps> = ({
  className = "",
  resourceGeneralInfoFields,
  editInfo,
  setEditInfo
}) => {
  const intl = useIntl();

  return (
    <div className={className}>
      <Row>
        <Col xs={24} md={12}>
          <div className="d-flex flex-column gap-3 mb-3">
            {(authUtils.getProfile() === PROFILE.ADMIN || authUtils.getProfile() === PROFILE.INTERN) && <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "resource_type" })}
              value={resourceGeneralInfoFields?.resourceTypeLabel}
            />}
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "resource_gender_field" })}
              value={optionUtils.translateOption(intl, GENDER_OPTIONS, resourceGeneralInfoFields?.gender)}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "resource_first_name_field" })}
              value={resourceGeneralInfoFields?.firstName}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "resource_last_name_field" })}
              value={resourceGeneralInfoFields?.lastName}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "resource_birth_name_field" })}
              value={resourceGeneralInfoFields?.birthName}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "resource_email_field" })}
              value={resourceGeneralInfoFields?.email}
              href={`mailto:${resourceGeneralInfoFields.email}`}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "resource_phone_number_field" })}
              value={resourceGeneralInfoFields?.phoneNumber}
              href={`tel:${resourceGeneralInfoFields.phoneNumber}`}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "resource_other_phone_number_field" })}
              value={resourceGeneralInfoFields?.otherPhoneNumber}
              href={`tel:${resourceGeneralInfoFields.otherPhoneNumber}`}
            />
          </div>
        </Col>

        <Col xs={24} md={12}>
          <div className="d-flex flex-column gap-3 mb-3">
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "resource_nationality_field" })}
              value={optionUtils.translateOption(intl, COUNTRY_OPTIONS, resourceGeneralInfoFields?.nationality)}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "resource_origin_field" })}
              value={resourceGeneralInfoFields?.origin}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "address_field" })}
              value={resourceGeneralInfoFields?.mainLabel}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "additional_address_field" })}
              value={resourceGeneralInfoFields?.additionalInformation}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "service_field" })}
              value={resourceGeneralInfoFields?.service}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "zip_code_field" })}
              value={resourceGeneralInfoFields?.zipCode}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "city_field" })}
              value={resourceGeneralInfoFields?.city}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "country_field" })}
              value={getCountryLabel(resourceGeneralInfoFields?.country)}
            />
          </div>
        </Col>
      </Row>
      <div>
        <Button
          size={SizeType.MD}
          color={ColorType.SECONDARY}
          onClick={() => setEditInfo(!editInfo)}
          className="extended"
        >
          <FormattedMessage id="edit_button" />
        </Button>
      </div>
    </div>
  )
}

export default ResourceCard;
