import {Resource, ResourceGeneralInfo, ResourceGeneralInfoFields} from "interfaces/ResourceInterfaces";
import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import GeneralInfoResourceCard from "./GeneralInfoResourceCard";
import ResourceContactAndCommentCard from "./ResourceContactAndCommentCard";
import {Col, Row} from "reactstrap";

interface DetailsResourceCardProps {
  className?: string;
  resource?: Resource;
  setResource?: (resource: Resource) => void;
  self: boolean
}

const DetailsResourceCard: FunctionComponent<DetailsResourceCardProps> = ({
  className = "",
  resource,
  setResource,
  self
}) => {
  const intl = useIntl();

const setResourceAfterUpdate = (resourceGeneralInfo: ResourceGeneralInfo) => {
  setResource({...resource, ...resourceGeneralInfo, resourceType: {id: resourceGeneralInfo.resourceTypeId, label: resourceGeneralInfo.resourceTypeLabel}});
}

  const getResourceGeneralInfoFields = (): ResourceGeneralInfoFields => ({
    id: resource.id,
    gender: resource.gender,
    birthName: resource.birthName,
    nationality: resource.nationality,
    firstName: resource.identity?.firstName,
    lastName: resource.identity?.lastName,
    email: resource.identity?.email,
    phoneNumber: resource.identity?.phoneNumber,
    otherPhoneNumber: resource.identity?.otherPhoneNumber,
    mainLabel: resource.address?.mainLabel,
    additionalInformation: resource.address?.additionalInformation,
    zipCode: resource.address?.zipCode,
    city: resource.address?.city,
    country: resource.address?.country,
    resourceTypeId: resource.resourceType?.id,
    resourceTypeLabel: resource.resourceType?.label,
    origin: resource?.origin,
  });

  return (
    <div className={`d-flex flex-row ${className}`}>
      <Row className="w-100">
        <Col xs={24} md={16} className="mb-4 mb-md-0">
          <GeneralInfoResourceCard
            self={self}
            className="d-flex flex-column"
            resourceGeneralInfoFields={getResourceGeneralInfoFields()}
            setResourceAfterUpdate={setResourceAfterUpdate}
            title={intl.formatMessage({ id: "resource_general_info" })}
          />
        </Col>
        <Col xs={24} md={8} className="mb-4 mb-md-0">
          <ResourceContactAndCommentCard
            resource={resource}
            setResource={setResource}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DetailsResourceCard;
