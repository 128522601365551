import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {OrderItemSearchObject} from "../../../../interfaces/OrderItemInterfaces";
import {resourceService} from "../../../../services/ResourceService";
import AutocompleteMultiselect from "../AutocompleteMultiselect";
import DateFilter from "../DateFilter";
import {activityService} from "../../../../services/ActivityService";
import {WorkflowStatesEnum} from "../../../../constants/workflow/WorkflowStates";
import {resourceTypeService} from "../../../../services/ResourceTypeService";
import TextFilter from "../TextFilter";

interface StaffingCompletedFiltersProps {
  searchObject: OrderItemSearchObject,
  updateSearchField: (field: string, value: unknown) => void
}

const StaffingCompletedFilters: FunctionComponent<StaffingCompletedFiltersProps> = ({
  searchObject,
  updateSearchField,
}) => {
  const intl = useIntl()

  return (
    <div className="d-flex gap-2 flex-wrap">
      <AutocompleteMultiselect
        title={intl.formatMessage({id: "staffing_resource"})}
        placeholder={intl.formatMessage({id: "staffing_resource"})}
        values={searchObject.resourceIds ?? []}
        onChange={(values) => updateSearchField("resourceIds", values)}
        fetchData={resourceService.getResourcesPage}
        filterFieldName="identity.lastName,identity.firstName"
        labelJoiner=" "
        filterFieldSearch="nameSurname"
        className="me-2"
        classNameLabel="mb-1 fw-bold"
      />

      <AutocompleteMultiselect
        title={intl.formatMessage({id: "staffing_order_item"})}
        placeholder={intl.formatMessage({id: "staffing_order_item"})}
        values={searchObject.activityIds ?? []}
        onChange={(values) => updateSearchField("activityIds", values)}
        fetchData={(page, filter) => activityService.getActivitiesPage(page, {...filter, status: WorkflowStatesEnum.ACTIVE })}
        filterFieldName="name"
        className="me-2"
        classNameLabel="mb-1 fw-bold"
      />
      <AutocompleteMultiselect
        title={intl.formatMessage({id: "resource_type"})}
        placeholder={intl.formatMessage({id: "resource_type"})}
        values={searchObject.resourceTypeIds ?? []}
        onChange={(values) => updateSearchField("resourceTypeIds", values)}
        fetchData={resourceTypeService.getResourceTypePage}
        filterFieldName="label"
        className="me-2"
        classNameLabel="mb-1 fw-bold"
      />
      <DateFilter value={searchObject.startDate} onChangeDate={(value) => updateSearchField("startDate", value)} title={intl.formatMessage({id: "filter_from"})} />
      <DateFilter value={searchObject.endDate} onChangeDate={(value) => updateSearchField("endDate", value)} title={intl.formatMessage({id: "filter_to"})} />
      <TextFilter
        className="main-filter"
        title={intl.formatMessage({id: "order_filter_code_title"})}
        value={searchObject.customerOrCode}
        onChange={(value) => updateSearchField("customerOrCode", value)}
        placeholder={intl.formatMessage({id: "order_filter_code_placeholder"})}
      />
    </div>
  )
}

export default StaffingCompletedFilters;
