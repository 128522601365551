import EpowForm from "./EpowForm";
import AutocompleteFilterPageable from "../filters/AutocompleteFilterPageable";
import {customerService} from "../../../services/CustomerService";
import {Formik} from "formik";
import React from "react";
import {useIntl} from "react-intl";


interface CustomerSelectorFormProps {
  handleSelectedCustomer: (customerId: string) => void
}

const CustomerSelectorForm: React.FC<CustomerSelectorFormProps> = ({handleSelectedCustomer}) => {
  const intl = useIntl()

  return (
    <Formik initialValues={{customerId: ""}} onSubmit={(value) => handleSelectedCustomer(value.customerId)}>
      {({ values, setFieldValue}) => (
        <EpowForm id="customer">
          <AutocompleteFilterPageable
            onChange={(value) => setFieldValue("customerId", value)}
            className="w-100"
            title={intl.formatMessage({id: "order_filter_customer"})}
            value={values?.customerId}
            fetchData={customerService.getCustomersPage}
            filterFieldName="name"
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CustomerSelectorForm
