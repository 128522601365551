import {httpService} from "./HttpService";
import {
  Order,
  OrderCsvItem,
  OrderField, OrderList, OrderMassRequest,
  OrderOption,
  OrderRequest,
  OrderSearchObject,
} from "../interfaces/OrderInterfaces";
import {API_ORDERS_PATH} from "../constants/routes/RoutePaths";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {queryUtils} from "../utils/queryUtils";
import {OrderItemCancellationRequest} from "../interfaces/OrderItemInterfaces";
import {TourLeader} from "../interfaces/TourLeaderInterfaces";
import {Contact} from "../interfaces/ContactInterfaces";

function sendCsv (file: File): Promise<OrderCsvItem[]> {
  const formData = new FormData()
  formData.append("file", file)
  return httpService.postFormData<OrderCsvItem[]>(`${API_ORDERS_PATH}/readCsv`, formData)
}

function importOrders (items: OrderCsvItem[]): Promise<OrderCsvItem[]> {
  return httpService.post<OrderCsvItem[]>( `${API_ORDERS_PATH}/import`, {orders: items})
}

function createOrder (item: OrderRequest): Promise<Order> {
  return httpService.post<Order>(API_ORDERS_PATH, item)
}

function getOrderById(id: string): Promise<Order> {
  return httpService.get<Order>(`${API_ORDERS_PATH}/${id}`)
}

function getPriceForOrderActivity(id: string, activityId: string): Promise<Order> {
  return httpService.get<Order>(`${API_ORDERS_PATH}/${id}/activities/${activityId}/price`)
}

function validateOrder(id: string): Promise<Order> {
  return httpService.put<Order>(`${API_ORDERS_PATH}/${id}/validate`, {})
}

function getOrdersPage(params: PaginationQueryParams, filterParams: OrderSearchObject): Promise<PageResponse<OrderList>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get<PageResponse<OrderList>>(`${API_ORDERS_PATH}${queryParams}`)
}


function getOrdersOptionPage(params: PaginationQueryParams, filterParams: OrderSearchObject): Promise<PageResponse<OrderOption>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get<PageResponse<OrderOption>>(`${API_ORDERS_PATH}/options${queryParams}`)
}

function updateOrder (id: string, item: OrderRequest): Promise<Order> {
  return httpService.put<Order>(`${API_ORDERS_PATH}/${id}`, item)
}

function updateMassOrder (item: OrderMassRequest[]): Promise<void> {
  return httpService.put<void>(`${API_ORDERS_PATH}/group`, item)
}

function updateOrderTourLeader(id: string, tourLeaderIds: string[]): Promise<Order> {
  return httpService.patch<Order>(`${API_ORDERS_PATH}/${id}/tourLeaders`, tourLeaderIds)
}

const getTemplateCsv = (): Promise<Blob> => {
  return httpService.getReadableStream(`${API_ORDERS_PATH}/import/template`);
}

function buildOrderRequest (ordersFields: OrderField, customerContacts: Contact[]): OrderRequest {
  return {
    customerId: ordersFields?.customerId,
    date: ordersFields?.date,
    dayZeroTour: ordersFields?.dayZeroTour,
    orderCodeCustomer :ordersFields?.customerReference,
    tourId: ordersFields.tourId,
    tourLeaderIds: ordersFields.tourLeaderIds.map(tl => tl.value),
    orderComment: ordersFields.orderComment,
    defaultPax: ordersFields.defaultPax,
    paxConfirmed: ordersFields.paxConfirmed,
    shouldUpdateOrderItemPax: ordersFields.shouldUpdateOrderItemPax,
    managerContacts: customerContacts.length > 0 ? ordersFields.managerContacts.map(contact => (customerContacts.find(c => c.id === contact.value))) : []
  }
}

function deleteOrder (id: string): Promise<Response> {
  return httpService.delete(`${API_ORDERS_PATH}/${id}`)
}

function cancelOrder (id: string, request: OrderItemCancellationRequest): Promise<Order> {
  return httpService.put(`${API_ORDERS_PATH}/${id}/cancel`, request)
}

function sendOrderEmail(orderId: string, formData: FormData): Promise<void> {
  return httpService.postFormData(`${API_ORDERS_PATH}/${orderId}/email`, formData);
}

function transitionToPending(orderIds: string[]): Promise<void> {
  return httpService.put(`${API_ORDERS_PATH}/validate`, {orderIds});
}

function getTravelBook(orderId: string): Promise<Blob> {
  return httpService.getReadableStream(`${API_ORDERS_PATH}/${orderId}/travelBook`);
}

function getAssignedTourLeader(orderId: string): Promise<TourLeader[]> {
  return httpService.get<TourLeader[]>(`${API_ORDERS_PATH}/${orderId}/tourLeaders`);
}

export const orderService = Object.freeze({
  sendCsv,
  importOrders,
  createOrder,
  buildOrderRequest,
  getOrderById,
  validateOrder,
  getOrdersPage,
  updateOrder,
  updateMassOrder,
  getPriceForOrderActivity,
  updateOrderTourLeader,
  getTemplateCsv,
  getOrdersOptionPage,
  deleteOrder,
  cancelOrder,
  transitionToPending,
  sendOrderEmail,
  getTravelBook,
  getAssignedTourLeader,
})
