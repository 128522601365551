import React, {FunctionComponent} from "react";
import {ActivityBillingPageData} from "../../../../interfaces/ActivityBllingInterface";
import {FormattedMessage} from "react-intl";

interface ActivityBillingOverviewTableRowTotalProps {
  className?: string,
  activityBillingPageData: ActivityBillingPageData[],
}

const ActivityBillingOverviewTableRowTotal: FunctionComponent<ActivityBillingOverviewTableRowTotalProps> = ({
                                                                                                                  className = "",
                                                                                                                  activityBillingPageData,
                                                                                                                }) => {


  return (
    <div className={`mt-5 d-flex justify-content-evenly fw-bold ${className}`}>
      <span>
        <FormattedMessage values={{ count: activityBillingPageData.length }} id="billing_overview_bills" />
      </span>
      <span>
        <FormattedMessage values={{ total: activityBillingPageData.reduce((acc, item) => acc + item.billAmount, 0) }} id="billing_overview_total_amount" />
      </span>
    </div>
  )
}

export default ActivityBillingOverviewTableRowTotal;
