import React, {FunctionComponent, useState} from "react";
import Button from "../../../../atoms/Button";
import {ColorType, SizeType} from "../../../../../types/bootstrap/BootstrapType";
import {FormattedMessage} from "react-intl";
import ModalStaffingRemindVoucher from "../../../modal/orderitem/ModalStaffingRemindVoucher";
import {OrderItemResponse} from "../../../../../interfaces/OrderItemInterfaces";
import {RemindResourceMail} from "../../../../../interfaces/ResourceInterfaces";
import {TourLeader} from "../../../../../interfaces/TourLeaderInterfaces";

interface RemindFormMessageProps {
  className?: string,
  userFirstName?: string,
  userLastName?: string,
  userId?: string,
  tourLeader?: TourLeader,
  orderItem: OrderItemResponse,
  handleSendMail: (userId: string[], content: RemindResourceMail, orderItemId: string) => void
}

const RemindFormMessage: FunctionComponent<RemindFormMessageProps> = ({
  className = "",
  userFirstName,
  userLastName,
  userId,
  orderItem,
  handleSendMail
}) => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <div className={`d-flex flex-column my-2 ${className ?? ""}`}>
      <div className={"d-flex flex-row justify-content-between p-3 border br-sm"}>
        <div className="d-flex align-items-center">
          {`${userLastName} ${userFirstName}`}
        </div>
        <div className="d-flex justify-content-end">
          <Button size={SizeType.XS} onClick={() => setOpenModal(true)} color={ColorType.PRIMARY}
                  className="extended me-2">
            <FormattedMessage id="send_button"/>
          </Button>
        </div>
      </div>
      <ModalStaffingRemindVoucher
        onSubmit={(content) => {
          setOpenModal(false)
          handleSendMail([userId], content, orderItem.id)
        }}
        orderItem={orderItem}
        open={openModal}
        onOpen={(open) => setOpenModal(open)}
        onClose={() => setOpenModal(false)}/>
    </div>

  )
}

export default RemindFormMessage;
