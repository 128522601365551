import React, {FunctionComponent, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {RouteProps, useSearchParams} from "react-router-dom";
import Layout from "../components/layout/Layout";
import PageTitle from "../components/molecules/header/PageTitle";
import HeaderButtonGroup, {HeaderStateButton} from "../components/molecules/header/HeaderButtonGroup";
import {TRUE_FALSE_ENUM} from "../constants/OptionConstants";
import {OrderItemSearchObject} from "../interfaces/OrderItemInterfaces";
import useSearchFilter from "../hooks/useSearchFilter";
import ActivityBillingList from "../components/molecules/list/ActivityBillingList";
import ActivityBillingOverviewForResourceList
  from "../components/molecules/list/ActivityBillingOverviewForResourceList";
import ActivityPanel from "../components/molecules/card/activity/ActivityPanel";
import {authUtils} from "../utils/authUtils";
import {dateUtils} from "../utils/dateUtils";

const ActivitiesBillingView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()

  const initialSearchState: OrderItemSearchObject = useMemo(() => ({
    startDate: null,
    endDate: null,
    billed: TRUE_FALSE_ENUM.FALSE
  }), [])
  const [isBilled, setIsBilled] = useState<boolean>(false)
  const [params] = useSearchParams()
  const [panelOpen, setPanelOpen] = React.useState<boolean>(false);
  const [orderItemId, setOrderItemId] = React.useState<string>();

  const { searchObject, updateSearchField, onReset } = useSearchFilter<OrderItemSearchObject>(
    {billed: TRUE_FALSE_ENUM.FALSE},
    {
      mutateInitialState: (params: URLSearchParams) => {
        // If there's no params, then we add start/end date to default filters!
        if (Array.from(params.keys()).length === 0) {
          return {
            ...initialSearchState,
            startDate: dateUtils.formatDateYYYYMMDD(dateUtils.firstDayOfLastMonth()),
            endDate: dateUtils.formatDateYYYYMMDD(dateUtils.lastDayOfLastMonth()),
            billed: TRUE_FALSE_ENUM.FALSE
          }
        }
        return initialSearchState
      }
    }
  );

  const stateFilterButtons: HeaderStateButton[] = useMemo(() => {
    const statutParam = params.get("billed");
    statutParam === "true" ? setIsBilled(true) : setIsBilled(false)
    
    return [{
      title: intl.formatMessage({ id: "activity_bill_filter_not_billed" }),
      onclick: () => {
        updateSearchField("startDate",  dateUtils.formatDateYYYYMMDD(dateUtils.firstDayOfLastMonth()))
        updateSearchField("endDate",  dateUtils.formatDateYYYYMMDD(dateUtils.lastDayOfLastMonth()))
        updateSearchField("billed", TRUE_FALSE_ENUM.FALSE)
      }, // a facturer
      active: statutParam === "false"
    },
    {
      title: intl.formatMessage({ id: "activity_bill_filter_billed" }),
      onclick: () => {
        updateSearchField("startDate", null)
        updateSearchField("endDate",  null)
        updateSearchField("billed", TRUE_FALSE_ENUM.TRUE)
      }, // facturées
      active: statutParam === "true"
    }]
  }, [intl, params.get("billed")])

  const onOrderItemClicked = (id: string) => {
    setOrderItemId(id);
    setPanelOpen(true)
  }


  return (
    <Layout>
      <div className="epow-content-body">
        <div className="d-flex mb-5">
          <PageTitle pageTitle={intl.formatMessage({ id: "activities_billing_title" })}></PageTitle>
          <HeaderButtonGroup className="d-flex flex-1 justify-content-center me-5" buttonList={stateFilterButtons} />
        </div>
        { isBilled
          ? <ActivityBillingOverviewForResourceList onOrderItemClicked={onOrderItemClicked}/>
          : <ActivityBillingList
            searchObject={searchObject}
            updateSearchField={updateSearchField}
            onReset={onReset}
            onOrderItemClicked={onOrderItemClicked}
          />}
      </div>

      <ActivityPanel open={panelOpen} setOpen={setPanelOpen} profile={authUtils.getProfile()} orderItemId={orderItemId} />

    </Layout>
  )
}

export default ActivitiesBillingView;
