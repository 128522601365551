import {SearchFilter} from "./api/FiltersInterface";
import {Data} from "./TableInterfaces";
import {ColorType} from "../types/bootstrap/BootstrapType";
import {StateMap} from "../constants/workflow/StateConstants";
import {QuoteItem} from "./QuoteItemInterfaces";


export interface QuoteOption {
    id: string,
    quoteNumber: string,
    refCustomer: string,
    customerName: string
}

export interface QuoteList extends Data {
    id: string,
    status: QuoteState
}

export const enum QuoteState {
    DRAFT = "DRAFT",
    SENT = "SENT",
    WIN = "WIN",
    LOOSE = "LOOSE",
}

export const QuoteStateMap: StateMap = {
    DRAFT: {
        id: QuoteState.DRAFT,
        label: "quote_state_draft",
        color: ColorType.SECONDARY,
    },
    SENT: {
        id: QuoteState.SENT,
        label: "quote_state_sent",
        color: ColorType.WARNING,
    },
    WIN: {
        id: QuoteState.WIN,
        label: "quote_state_win",
        color: ColorType.PRIMARY,
    },
    LOOSE: {
        id: QuoteState.LOOSE,
        label: "quote_state_loose",
        color: ColorType.DANGER,
    }
}


export interface QuoteConvertRequest {
    customerId: string,
    customerRef: string,
    orderDate: string
}

export interface Quote extends Data {
    quoteNumber: string,
    customerRef: string,
    customerId: string,
    customerDisbursements: boolean,
    emailContact: string,
    contactName: string,
    status: string,
    creationDate: string,
    quoteDate: string,
    beginTourDate: string,
    endOfValidityDate: string,
    customerName: string,
    tourName: string,
    tourId: string,
    orderId?: string,
    tourCode: string,
    numberOfQuoteItems: number,
    totalHt: number,
    pax: number,
    tourPax: number,
    quoteItems: QuoteItem[],
    geographicalZone: string,
}

export interface QuoteField {
    id?: string,
    customerId: string,
    tourId: string,
    customerRef: string,
    emailContact: string,
    rendezVousDate: string,
    creationDate: string,
    endOfValidity: string,
    pax: number,
    geographicalZone: string,
}

export interface QuoteRequest {
    customerId: string,
    tourId: string,
    customerRef: string,
    emailContact: string,
    rendezVousDate: string,
    creationDate: string,
    endOfValidity: string,
    pax: number,
    geographicalZone: string,
}

export interface QuoteSearchObject extends SearchFilter {
    tourLeaderId?: string,
    tourId?: string,
    status?: string[] | string,
    startDate?: string,
    endDate?: string,
    id?: string,
}
