import {SupplierPurchaseDto} from "../../../../interfaces/PurchaseInterfaces";
import {SortOptions} from "../../../../interfaces/api/PaginationInterface";
import React, {FunctionComponent, useMemo} from "react";
import {CustomTableColumn, CustomTableType, Data} from "../../../../interfaces/TableInterfaces";
import CustomTable, {CustomTableRef} from "../CustomTable";
import SupplierPurchaseTableRow from "./SupplierPurchaseTableRow";
import SupplierPurchaseTableRowDetails from "./SupplierPurchaseTableRowDetails";
import {FormattedMessage} from "react-intl";

interface SupplierPurchaseTableProps {
  className?: string,
  purchases: SupplierPurchaseDto[],
  sortOptions: SortOptions,
  onSortOptions: (sortOptions: SortOptions) => void
}

const SupplierPurchaseTable: FunctionComponent<SupplierPurchaseTableProps> = ({
  className = "",
  purchases,
  sortOptions,
  onSortOptions
}) => {
  const ref = React.useRef<CustomTableRef>();

  const columns: CustomTableColumn[] = useMemo(() => [
    {title: "purchase_order_code", accessor: "purchaseNumber", sortable: true },
    {title: "purchase_order_date", accessor: "purchaseDate", sortable: true},
    {title: "number_booking_validated", accessor: "numberBookingValidated", sortable: false},
    {title: "number_order_document", accessor: "numberOrderDocument", sortable: false},
    {title: "status", accessor: "status", sortable: false},
  ], [])

  const buildPurchaseRow = (data: Data, open: boolean, rest) => {
    const purchase = data as SupplierPurchaseDto;
    return (
      <div>
        <SupplierPurchaseTableRow
          purchase={purchase}
          {...rest}
        />
        {open && (
          purchase.bookingList.length ? purchase.bookingList.map(booking => (
            <div key={booking?.id}>
              <SupplierPurchaseTableRowDetails supplierPurchaseBooking={booking} className="mb-0 rounded-bottom"/>
            </div>
          )) :
            <div className={"epow-purchase-table-row-details"}>
              <FormattedMessage id="order_item_no_booking" />
            </div>
        )}
      </div>);
  }

  return (
    <div className={`epow-purchase-table ${className ?? ""}`}>

      <CustomTable
        ref={ref}
        onSortOptions={onSortOptions}
        sortOptions={sortOptions}
        className={`${className} no-actions`}
        type={CustomTableType.DETAILS_TABLE}
        datas={purchases}
        columns={columns}
        RowComponent={({data, open, ...rest}) => buildPurchaseRow(data, open, rest)
      }
      />
    </div>
  )
}

export default SupplierPurchaseTable
