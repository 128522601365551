import React, {FunctionComponent, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {PRODUCT_TYPE_TABLE} from "../../../constants/Table";
import {tableUtils} from "../../../utils/tableUtils";
import Button from "../../atoms/Button";
import Panel from "../panel/Panel";
import PagedTable from "../table/PagedTable";
import {PagedTableRef} from "../table/PagedTableWithRowSelection";
import {toastUtils} from "../../../utils/toastUtils";
import CreateProductTypeForm from "../form/CreateProductTypeForm";
import {productTypeService} from "../../../services/ProductTypeService";
import {ProductType} from "../../../interfaces/ProductTypeInterfaces";

interface ProductTypeListProps {
  className?: string;
}
const ProductTypeList: FunctionComponent<ProductTypeListProps> = ({
                                                      className = ""
                                                    }) => {
  const ref = useRef<PagedTableRef>()
  const intl = useIntl();
  const [ProductTypeFormOpen, setProductTypeFormOpen] = useState<boolean>(false)
  const [selectedProductType, setSelectedProductType] = useState<ProductType>(null)

  const onProductTypeEditionSelection = (productType: ProductType) => {
    setSelectedProductType(productType)
    setProductTypeFormOpen(true)
  }

  const onProductTypeDeletionSelection = (productType: ProductType) => {
    productTypeService.deleteProductType(productType.id)
      .then(() => toastUtils.successToast(intl.formatMessage({id : "product_type_toast_delete_success"})))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id : "product_type_toast_delete_error"})))
      .finally(() => ref.current.refresh())
  }

  const columns = useMemo(() => tableUtils.getColumns(
    PRODUCT_TYPE_TABLE(onProductTypeEditionSelection, onProductTypeDeletionSelection, intl)
  ), [intl]);

  const closeForm = () => {
    setProductTypeFormOpen(false)
    setSelectedProductType(null)
  }

  const onCreateProductType = () => {
    closeForm()
    ref.current.refresh()
  }

  return (
    <>
      <PagedTable
        ref={ref}
        columns={columns}
        labelNoResult="product_type_no_result"
        className={`${className} table`}
        fetchData={productTypeService.getProductTypes}
        filters={{}}
      />
      <Button onClick={() => setProductTypeFormOpen(true)}>
        {intl.formatMessage({id: "product_type_create"})}
      </Button>

      {ProductTypeFormOpen && (
        <Panel
          formId="createProductTypeForm"
          title={selectedProductType ? "product_type_edit" : "product_type_create"}
          open={ProductTypeFormOpen}
          onCancel={() => closeForm()}
        >
          <CreateProductTypeForm
            id="createProductTypeForm"
            onCreateProductType={onCreateProductType}
            initialProductType={selectedProductType}
          />
        </Panel>
      )}
    </>
  )
}

export default ProductTypeList;
