import React, {FunctionComponent} from "react";
import { LogoSizeType} from "../../../types/bootstrap/BootstrapType";
import {SIDE_MENU_BOTTOM_ITEMS, SIDE_MENU_ITEMS} from "../../../constants/menu/Menu";
import {LOGO_POW, LOGO_SOFTOURING} from "../../../constants/Assets";
import Logo from "../../atoms/logo/Logo";
import NavItem from "./NavItem";
import {authUtils} from "../../../utils/authUtils";
import SideMenuPanel from "../panel/SideMenuPanel";
import Icon from "../../icon/Icon";
import UserMenu from "./UserMenu";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

interface SideMenuProps {
  className?: string,
  onClose: () => void,
  open?: boolean
}

const SideMenu: FunctionComponent<SideMenuProps> = ({
  className = "",
  open = true,
  onClose
}) => {
  const profile = authUtils.getProfile()
  return (
      <SideMenuPanel open={open}>
        <nav className={`epow-nav d-lg-flex flex-column justify-content-between ${className}`}>
          <div>
            <div className="d-flex justify-content-center mb-lg-5 position-relative mb-5">
              <div className="epow-close cursor-pointer d-lg-none d-flex position-absolute align-self-center align-items-center color-gray"
                    onClick={onClose}>
                <Icon className="me-1 mt-1" name="Cross"/>
                <FormattedMessage id="close"/>
              </div>
              <Logo imageSource={LOGO_POW} height={LogoSizeType.MD} path={"/"} alt="Paris On The Way" />
            </div>
            <div className="d-lg-none mb-4 mb-lg-0">
              <UserMenu/>
            </div>
            {SIDE_MENU_ITEMS.map((item, key) => (item.roles.some(role => role === profile) && (
              <div key={`sideMenu-${key}`}>
                <NavItem
                  className="mt-2"
                  key={key}
                  label={item.title}
                  to={item.to}
                  icon={item.icon}
                  subMenus={item.subMenus}
                />
              </div>
            )))}
          </div>

          <div>
            <div className="mx-3 my-lg-1 separator d-none d-lg-block"></div>
            <div className="mt-5 mb-4 my-lg-0">
              {SIDE_MENU_BOTTOM_ITEMS.map((item, key) => (item.roles.some(role => role === profile) && (
                <NavItem
                  className="mb-2 ms-lg-4"
                  key={`bottom-${key}`}
                  label={item.title}
                  to={item.to}
                  icon={item.icon}
                  subMenus={item.subMenus}
                />
              )))}
            </div>
            <div className="d-flex flex-column align-items-center my-3">
              <Logo imageSource={LOGO_SOFTOURING} width={LogoSizeType.XL} path={"/"} alt="SOFTOURING" />
              <div className="color-gray fs-10">@Softouring 2022</div>
            </div>
            <Link to="#" className="d-flex justify-content-center color-gray fs-10 d-lg-none">
              <FormattedMessage id="footer_link_cgu"/>
            </Link>
          </div>
        </nav>
      </SideMenuPanel>
  )
}

export default SideMenu;
