const isMobileDevice = (): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
  const userAgent: string = window.navigator.userAgent || window.navigator.vendor || (window as any)?.opera;
  const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i];
  return regexs.some((regex) =>  regex.exec(userAgent));
}

const detectBrowserName = () => {
  const agent: string = window.navigator.userAgent.toLowerCase();
  switch (true) {
    case agent.indexOf("edge") > -1:
      return "edge";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
    case agent.indexOf("opr") > -1 && !!(window as any)?.opr:
      return "opera";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
    case agent.indexOf("chrome") > -1 && !!(window as any)?.chrome:
      return "chrome";
    case agent.indexOf("trident") > -1:
      return "ie";
    case agent.indexOf("firefox") > -1:
      return "firefox";
    case agent.indexOf("safari") > -1:
      return "safari";
    default:
      return "other";
  }
}

export const navigatorUtils = {
  isMobileDevice,
  detectBrowserName
}
