import {ActivityStaffingStatusPriority} from "../constants/ActivityStaffingConstants"
import {FileType} from "../constants/FileConstants"
import {StaffedResource} from "../interfaces/ActivityStaffingInterface"
import {OrderItem, OrderItemStaffingPageItemDto} from "../interfaces/OrderItemInterfaces"
import {PROFILE} from "../interfaces/UserInterfaces"
import {authUtils} from "./authUtils"
import {dateUtils} from "./dateUtils"

const buildDateHour = (orderItem: OrderItemStaffingPageItemDto) => {
  const datePrefix = dateUtils.parseDate(orderItem?.rendezVousDate)?.toLocaleDateString()
  const dateSuffix = orderItem.rendezVousHour ? ` - ${dateUtils.formatLocalTime(orderItem.rendezVousHour)}` : ""
  return `${datePrefix}${dateSuffix}`
}


export const sortStaffedResourceByAvailability = (a: StaffedResource, b: StaffedResource): number => {
  if (ActivityStaffingStatusPriority[a.status] < ActivityStaffingStatusPriority[b.status]) {
      return -1;
  }
  if (ActivityStaffingStatusPriority[a.status] > ActivityStaffingStatusPriority[b.status]) {
      return 1;
  }
  return 0;
}

export const orderItemNeedsTicket = (orderItem: OrderItem): boolean => {
  const tickets = orderItem?.bookingDocuments?.some(bd => bd.fileType === FileType.TICKET)
  const shouldBeShownToProfile = [PROFILE.CLIENT, PROFILE.ADMIN, PROFILE.INTERN].includes(authUtils.getProfile())
  return orderItem.canAddTicket && !tickets && shouldBeShownToProfile
}


export const orderItemUtils = Object.freeze({
  buildDateHour,
  sortStaffedResourceByAvailability,
  orderItemNeedsTicket,
});
