import React, {FunctionComponent, useState} from "react";
import {dateUtils} from "../../../utils/dateUtils";
import {FormattedMessage, useIntl} from "react-intl";
import Icon from "../../icon/Icon";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {ActivityResourceCatalog} from "../../../interfaces/ActivityInterfaces";
import {numberUtils} from "../../../utils/numberUtils";
import {Col, Row} from "reactstrap";
import FieldLabel from "../../atoms/FieldLabel";
import GenericModalDelete from "../modal/GenericModalDelete";
import {activityService} from "../../../services/ActivityService";

interface ActivityResourceRateListProps {
  className?: string,
  activitiesResourceCatalog?: ActivityResourceCatalog[],
  setLateralPanelActivityResourceRate?: (open?: boolean) => void,
  setInitialActivityResourceRate?: (activityResourceCatalog?: ActivityResourceCatalog) => void
  onValidate?: () => void
}

const ActivityResourceRateList: FunctionComponent<ActivityResourceRateListProps> = ({
  className = "",
  activitiesResourceCatalog,
  setLateralPanelActivityResourceRate,
  setInitialActivityResourceRate,
  onValidate
}) => {
  const intl = useIntl()
  const [openModalDeleteResourceRate, setOpenModalDeleteResourceRate] = useState<boolean>(false)

  const handleModalDeleteResourceRate = () => {
    setOpenModalDeleteResourceRate(false)
    onValidate && onValidate()
  }

  return (
    <div className={className}>
      {activitiesResourceCatalog?.length > 0 && activitiesResourceCatalog.map((activityResourceCatalog, key) =>
        <div key={key} className="d-flex flex-row justify-content-between mb-2">
          <Row className="w-100">
            <Col xs={24} sm={24} lg={4}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "activity_start_date_validity"})}
                value={dateUtils.formatDateDayJs(activityResourceCatalog?.startDateValidity)}
              />
            </Col>
            <Col xs={24} sm={12} lg={5}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "activity_junior_employee"})}
                value={numberUtils.formatPrice(activityResourceCatalog?.juniorEmployeeRate)}
              />
            </Col>
            <Col xs={24} sm={12} lg={5}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "activity_senior_employee"})}
                value={numberUtils.formatPrice(activityResourceCatalog?.seniorEmployeeRate)}
              />
            </Col>
            <Col xs={24} sm={12} lg={5}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "activity_junior_independent"})}
                value={numberUtils.formatPrice(activityResourceCatalog?.juniorIndependentRate)}
              />
            </Col>
            <Col xs={24} sm={12} lg={5}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "activity_senior_independent"})}
                value={numberUtils.formatPrice(activityResourceCatalog?.seniorIndependentRate)}
              />
            </Col>
          </Row>
          <div className=" d-flex flex-column">
            <span className="text-muted"><FormattedMessage id="action_button"/></span>
            <div className="d-flex flex-row">
              <Icon
                name="Pen"
                size={IconSizeType.XS}
                className={"pe-2"}
                onClick={() => {
                  setLateralPanelActivityResourceRate(true)
                  setInitialActivityResourceRate(activityResourceCatalog)
                }}
              />
              <Icon
                name="Trash"
                size={IconSizeType.XS}
                className={"pe-2"}
                onClick={() => setOpenModalDeleteResourceRate(true)}
              />
            </div>
          </div>
          <GenericModalDelete
            titleId="modal_title_delete_resource_rate"
            open={openModalDeleteResourceRate}
            onValidate={handleModalDeleteResourceRate}
            setOpen={setOpenModalDeleteResourceRate}
            idEntity={activityResourceCatalog?.id}
            onDelete={activityService.deleteActivityResourceCatalog}
            successToastMessageId="success_toast_delete_activity_resource_rate"
            errorToastMessageId="error_toast_delete_activity_resource_rate"
            bodyDeleteMessage={intl.formatMessage({id: "modal_body_delete_resource_rate"})}
          />
        </div>
      )}
    </div>
  )
}

export default ActivityResourceRateList
