import React, {FunctionComponent} from "react";
import useSearchFilter from "../../../../hooks/useSearchFilter";
import {OrderSearchObject} from "../../../../interfaces/OrderInterfaces";
import {dateUtils} from "../../../../utils/dateUtils";
import TourLeaderDashboardOrderList from "../../list/order/TourLeaderDashboardOrderList";
import {FormattedMessage} from "react-intl";

interface DashboardNextTourLeaderOrdersProps {
  className?: string,
}

const DashboardNextTourLeaderOrders: FunctionComponent<DashboardNextTourLeaderOrdersProps> = ({
  className = "",
}) => {

  const initialSearch: OrderSearchObject = {
    customerOrCode: "",
    customerId: "",
    startDate: dateUtils.formatDateYYYYMMDD(dateUtils.today()),
    endDate: "",
    status: "",
  }
  const {searchObject, updateSearchField, onReset} = useSearchFilter<OrderSearchObject>(initialSearch)

  return (
    <div className={className}>
      <h3 className="fs-16 mb-2"><FormattedMessage id="dashboard_next_tour_leader_orders" /></h3>

      <TourLeaderDashboardOrderList
        searchObject={searchObject}
        updateSearchField={updateSearchField}
        onReset={() => onReset()} />
    </div>
  )
}

export default DashboardNextTourLeaderOrders;
