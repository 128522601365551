import Button from "components/atoms/Button";
import {
  RESOURCE_AVAILABILITY_OPTIONS,
  RESOURCE_LEVEL_OPTIONS,
  RESOURCE_STATUS_OPTIONS,
} from "constants/OptionConstants";
import {ResourceProfessionalSituation, Seniority,} from "interfaces/ResourceInterfaces";
import {PROFILE} from "interfaces/UserInterfaces";
import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Row} from "reactstrap";
import {ColorType, IconSizeType, SizeType} from "types/bootstrap/BootstrapType";
import {authUtils} from "utils/authUtils";
import {optionUtils} from "utils/optionUtils";
import {dateUtils} from "../../../../utils/dateUtils";
import {resourceService} from "../../../../services/ResourceService";
import ResourceStructureCard from "./ResourceStructureCard";
import {fileUtils} from "../../../../utils/fileUtils";
import CardFileField from "../CardFileField";
import {FileType} from "../../../../constants/FileConstants";
import FieldLabel from "../../../atoms/FieldLabel";
import Icon from "../../../icon/Icon";
import GenericModalDelete from "../../modal/GenericModalDelete";

interface ProfessionalSituationResourceCardProps {
  className?: string;
  resourceProfessionalSituation?: ResourceProfessionalSituation;
  setEditInfo?: (value: React.SetStateAction<boolean>) => void;
  onChangeResourceAfterUpdate?: (resourceProfessionalSituation: ResourceProfessionalSituation) => void;
  self?: boolean;
}

const ProfessionalSituationResourceCard: FunctionComponent<
  ProfessionalSituationResourceCardProps
> = ({
       className = "",
       resourceProfessionalSituation,
       onChangeResourceAfterUpdate,
       setEditInfo,
       self = false
     }) => {
  const intl = useIntl();
  const seniority: Seniority = resourceProfessionalSituation?.seniority;

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>(resourceProfessionalSituation?.resourceCard?.id)

  const deleteCardBasedOnSelfStatus = () => {
    return self ?
      resourceService.deleteSelfFile(FileType.RESOURCE_CARD) :
      resourceService.deleteFile(resourceProfessionalSituation.id, FileType.RESOURCE_CARD);
  };

  const downloadResourceCard = () => {
    fileUtils.downloadFile(
      resourceService.getCard(resourceProfessionalSituation.id, FileType.RESOURCE_CARD),
      resourceProfessionalSituation.resourceCard,
      intl
    )
  }

  const deleteDocument = (documentId: string) => {
    setSelectedDocumentId(documentId);
    setOpenDeleteModal(true);
  }

  const doAfterDelete = () => {
    setOpenDeleteModal(false);
    onChangeResourceAfterUpdate({...resourceProfessionalSituation, resourceCard: null});
  }

  return (
    <div className={className}>
      <Row>
        {authUtils.getProfile() !== PROFILE.RESOURCE && (
          <Col xs={24} md={12}>
            <div className="d-flex flex-column gap-3 mb-3">
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "resource_status_field"})}
                value={optionUtils.translateOption(
                  intl,
                  RESOURCE_STATUS_OPTIONS,
                  resourceProfessionalSituation?.statusResource
                )}
              />
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "resource_level_field"})}
                value={optionUtils.translateOption(
                  intl,
                  RESOURCE_LEVEL_OPTIONS,
                  resourceProfessionalSituation?.level
                )}
              />
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({ id: "resource_date_first_mission_field" })}
                value={dateUtils.formatDateDayJs(resourceProfessionalSituation?.dateFirstMission)}
              />
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "resource_seniority_field"})}
                value={seniority &&
                  intl.formatMessage(
                    {id: "resource_seniority_value"},
                    {years: seniority.years, months: seniority.months}
                  )}
              />
            </div>
          </Col>)}

        <Col xs={24} md={12}>
          <div className="d-flex flex-column gap-3 mb-3">
            {authUtils.getProfile() !== PROFILE.RESOURCE && (
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "resource_availability_field"})}
                value={optionUtils.translateOption(
                  intl,
                  RESOURCE_AVAILABILITY_OPTIONS,
                  resourceProfessionalSituation?.availability
                )}
              />
            )}
            <div className="d-flex justify-content-between align-items-center">
              <CardFileField
                label={intl.formatMessage({id: "resource_card_field"})}
                value={fileUtils.getCompleteFileName(resourceProfessionalSituation?.resourceCard)}
                onClick={downloadResourceCard}
              />
              {resourceProfessionalSituation?.resourceCard &&
                <Icon name="Trash" size={IconSizeType.XS} className="cursor-pointer mb-1"
                      onClick={() => deleteDocument(selectedDocumentId)}/>}
            </div>
            <ResourceStructureCard
              structure={resourceProfessionalSituation.structure}
              self={self}
            />
          </div>
        </Col>
      </Row>

      <Button
        size={SizeType.MD}
        color={ColorType.SECONDARY}
        onClick={() => setEditInfo((previousState) => !previousState)}
        className="extended"
      >
        <FormattedMessage id="edit_button"/>
      </Button>
      <GenericModalDelete
        titleId="modal_title_delete_document"
        open={openDeleteModal}
        onValidate={doAfterDelete}
        setOpen={setOpenDeleteModal}
        idEntity={selectedDocumentId}
        onDelete={deleteCardBasedOnSelfStatus}
        successToastMessageId="success_delete_document"
        errorToastMessageId="error_toast_delete_document"
        bodyDeleteMessage={intl.formatMessage({id: "modal_body_delete_document"})}
      />
    </div>
  );
};

export default ProfessionalSituationResourceCard;
