import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Formik} from "formik";
import EpowForm from "../form/EpowForm";
import FormInput from "../input/FormInput";
import {ActivityBillingComment, ActivityBillingCommentRequest} from "../../../interfaces/ActivityBllingInterface";
import {activityBillingService} from "../../../services/ActivityBillingService";
import {dateUtils} from "../../../utils/dateUtils";
import {toastUtils} from "../../../utils/toastUtils";
import Panel from "./Panel";
import {authUtils} from "../../../utils/authUtils";

interface PanelActivityBillingCommentProps {
  className?: string;
  open: boolean,
  activityBillingId: string
  activityBillingNumber: string
  onSubmit?: (content: ActivityBillingCommentRequest) => void,
  onClose?: () => void,
}

const PanelActivityBillingComment: FunctionComponent<PanelActivityBillingCommentProps> = ({
    className = "",
    open,
    activityBillingId,
    activityBillingNumber,
    onClose,
    onSubmit: onSubmitProps,
  }) => {
  const intl = useIntl();
  const [comments, setComments] = useState<ActivityBillingComment[]>([])
  const onSubmit = (values : ActivityBillingCommentRequest) => {
    values.comment.length ? onSubmitProps(values) : onClose()
  };

  const getComments = () => {
    activityBillingService.getActivityBillingComment(activityBillingId)
      .then((comments) => {
        setComments(comments);
      })
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "activity_billing_comment_toast_error_get_comments"})))
  }

  useEffect(() => {
    if(activityBillingId) {
      getComments()
    }
  }, [activityBillingId]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        comment: ""
      }}
      onSubmit={onSubmit}>
      {({values, touched, setFieldValue}) => (
        <Panel
          formId={activityBillingId}
          className={className}
          title={intl.formatMessage({id: "activity_billing_comment_title"}, {number: activityBillingNumber})}
          open={open}
          onCancel={() => onClose()}
          cancelButtonLabel="close_button"
          validateButtonLabel="activity_billing_comment_send"
          disabledValidateButton={!values.comment.length}
        >
          <EpowForm id={activityBillingId}>
            <div className="scroller overflow-auto">
              <div className="scroller-content">
                {comments.map((comment) =>
                  <div key={comment.id} className={`mb-3 item ${comment.author.id === authUtils.getUserId() ? "comment-owner" : ""}`}>
                    <div className="date d-flex justify-content-between">
                      <span><FormattedMessage id="activity_billing_comment_from"/> {comment.author.firstName} {comment.author.lastName}</span>
                      <span><FormattedMessage id="activity_billing_comment_at"/> {dateUtils.formatDateToLocalDateTime(new Date(comment.dateCreation))}</span>
                    </div>
                    <div className="comment-bubble">{comment.comment}</div>
                  </div>
                )}
              </div>
            </div>
            <FormInput
              className="my-3"
              type="textarea"
              rows="3"
              id="comment"
              label="activity_billing_comment_add"
              value={values.comment}
              onChange={(e) => setFieldValue("comment", e.target.value)}
              touched={touched.comment}
            />
          </EpowForm>
        </Panel>
      )}
    </Formik>
  );
};

export default PanelActivityBillingComment;
