import React, {FunctionComponent, useRef, useState} from "react";
import {FormattedMessage} from "react-intl";
import {RouteProps} from "react-router-dom";
import {MONTHS_ABV, MONTHS_NUMBERS} from "../../../constants/StaffingReportingConstants";
import useLoading from "../../../hooks/useLoading";
import useSearchFilter from "../../../hooks/useSearchFilter";
import {ReportingData} from "../../../interfaces/CalendarInterface";
import {SearchFilter} from "../../../interfaces/api/FiltersInterface";
import {staffingCalendarService} from "../../../services/StaffingCalendarService";
import StaffingReportingFilters from "../filters/staffingcalendar/StaffingReportingFilters";
import CalendarTable, {CalendarTableRef} from "../table/staffingcalendar/CalendarTable";
import CellResourceReporting from "../table/staffingcalendar/CellResourceReporting";
import HeaderResourceCalendar from "../table/staffingcalendar/HeaderResourceCalendar";
import {dateUtils} from "../../../utils/dateUtils";

export interface StaffingReportingSearchFilter extends SearchFilter {
  year: string,
  resourceId: string,
  activityAreas: string[],
  resourceTypeIds?: string[],
}

const StaffingReporting: FunctionComponent<RouteProps> = () => {

  const [datas, setDatas] = useState<ReportingData[]>([])
  const [page, setPage] = useState<number>(0)
  const [last, setLast] = useState<boolean>(false)
  const [dynamicColumns, setDynamicColumns] = useState<string[]>([])
  const ref = useRef<CalendarTableRef>(null)

  const initialState: StaffingReportingSearchFilter = {
    year: dateUtils.today().getFullYear().toString(),
    resourceId: "",
    activityAreas: [],
  };

  const {
    searchObject,
    onReset,
    updateSearchField: updateSearchFieldFilter
  } = useSearchFilter<StaffingReportingSearchFilter>(initialState)

  const {loading, startLoading, stopLoading} = useLoading();

  const nextPage = () => {
    setPage(page + 1)
    startLoading()
    staffingCalendarService.getReporting({page, pageSize: 20}, searchObject)
      .then((data) => {
        setDatas(prev => [...prev, ...data.content.map(row => ({...row.header, ...row.reportingByMonth}))])
        setDynamicColumns(data.headers)
        setLast(data.last)
      }).catch(console.error)
      .finally(stopLoading)
  }

  const fetchDataFirstPage = (filters) => {
    startLoading()
    setPage(0)
    staffingCalendarService.getReporting({page: 0, pageSize: 20}, filters)
      .then((data) => {
        setDatas(data.content.map(row => ({...row.header, ...row.reportingByMonth})))
        setDynamicColumns(data.headers)
        setLast(data.last)
        ref?.current?.goTo(0)
      }).catch(console.error)
      .finally(stopLoading)
  }

  const updateSearchField = (field: string, value: boolean | string | string[]) => {
    updateSearchFieldFilter(field, value)
    fetchDataFirstPage({...searchObject, [field]: value})
  }

  const getCellClassName = (base: string, header: string) => {
    const currentMonthNumber = dateUtils.today().getMonth()
    const currentMonth = MONTHS_NUMBERS[currentMonthNumber];

    return `${base} ${header === currentMonth ? "epow-bg-blue" : ""}`
  }

  const columns = React.useMemo(() => {
    return [{
      accessor: "resource",
      sticky: "left",
      Header: <div className="empty"></div>,
      width: 300,
      Cell: (cell) => <HeaderResourceCalendar hideAvailability cell={cell} />
    }, ...dynamicColumns.map(header => ({
      Header: <div className={getCellClassName("staffing-calendar__cell p-2 justify-content-center d-flex bold ms-1", header)}><FormattedMessage id={MONTHS_ABV[header]} /></div>,
      accessor: header,
      minWidth: 85,
      maxWidth: 85,
      Cell: (cell) => <CellResourceReporting cell={cell} accessor={header} year={+searchObject.year} className={getCellClassName("cursor-pointer", header)} />
    }))]
  }, [dynamicColumns]);

  return (
    <div className="pt-2">
      <StaffingReportingFilters
        searchObject={searchObject}
        initialState={initialState}
        updateSearchField={updateSearchField}
        onReset={onReset}
        fetchDataFirstPage={fetchDataFirstPage}
      />

      <div className="table_wrapper mt-5">
        <CalendarTable
          ref={ref}
          columns={columns}
          datas={datas}
          className="staffing-calendar"
          hasNextPage={!last}
          itemSize={85}
          isNextPageLoading={loading}
          loadNextPage={nextPage} />
      </div>
    </div>)
}


export default StaffingReporting;
