import React, { FunctionComponent, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Col, Input, Row } from "reactstrap";
import { ActivityStaffingStatus } from "../../../../constants/ActivityStaffingConstants";
import { ORDERS_PATH } from "../../../../constants/routes/RoutePaths";
import { OrderItemStaffingPageItemDto, OrderItemStaffingStatus } from "../../../../interfaces/OrderItemInterfaces";
import { ColorType, IconSizeType } from "../../../../types/bootstrap/BootstrapType";
import { orderItemUtils } from "../../../../utils/orderItemUtils";
import TextLink from "../../../atoms/TextLink";
import Icon from "../../../icon/Icon";
import { orderUtils } from "../../../../utils/orderUtils";
import { orderItemService } from "../../../../services/OrderItemService";
import { toastUtils } from "../../../../utils/toastUtils";
import Badge from "../../../atoms/Badge";
import ModalStaffingCancel from "components/molecules/modal/orderitem/ModalStaffingCancel";
import {ResourceType} from "../../../../interfaces/ResourceTypeInterfaces";
import ResourceCircle from "../../circle/ResourceCircle";

interface StaffingPendingTableRowProps {
  className?: string,
  orderItem: OrderItemStaffingPageItemDto,
  open: boolean,
  onRowClick?: (orderItemId: string) => void,
  handleSelect?: (orderItemId: string) => void,
  openStaffingForm?: (orderItem: OrderItemStaffingPageItemDto) => void,
  selectedStaffing: string[],
  onCancelStaffing: () => void
}

const StaffingPendingTableRow: FunctionComponent<StaffingPendingTableRowProps> = ({
  className = "",
  orderItem,
  open,
  onRowClick,
  handleSelect,
  openStaffingForm,
  selectedStaffing,
  onCancelStaffing,
}) => {
  const intl = useIntl()
  const order = orderItem.order;
  const [staffingCancelFormOpen, setStaffingCancelFormOpen] = useState<boolean>(false)
  const [staffingButtonCancel, setStaffingButtonCancel] = useState<boolean>(true)

  const hasStaffingConfirmed = () => {
    return orderItem.resourceStaffing.some(staffing => ActivityStaffingStatus.CONFIRMED === staffing.status);
  }
  const getConfirmedStaffingByResourceType = (resourceType: ResourceType) => {
    return orderItem.resourceStaffing.filter(staffing => ActivityStaffingStatus.CONFIRMED === staffing.status && staffing.resourceType.id === resourceType.id)
  }
  const getAvailableStaffing = () => {
    return orderItem.resourceStaffing.filter(staffing => ActivityStaffingStatus.AVAILABLE === staffing.status)
  }
  const getSentStaffing = () => {
    return orderItem.resourceStaffing.filter(staffing => ActivityStaffingStatus.SENT === staffing.status)
  }

  const getRefusedStaffing = () => {
    return orderItem.resourceStaffing.filter(staffing => ActivityStaffingStatus.REFUSED === staffing.status)
  }

  const sendStaffingRequest = () => {
    [...getSentStaffing(), ...getRefusedStaffing()]?.map((resource) => (
      orderItemService.deleteStaffingAndChangeOrderItemStatus(resource.orderItemId, resource.staffingId, OrderItemStaffingStatus.TO_STAFF).then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_delete_staffing"}));
        onCancelStaffing()
      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_delete_staffing"}))
      })
    ))
    setStaffingCancelFormOpen(false);
  }

  return (
    <div onClick={() => onRowClick(orderItem.id)} className={`staffing-pending-row ${className ?? ""}`}>
      <Col className="d-flex bold align-items-center col-5">
        <div onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
          <Input
            className="me-2"
            type="checkbox"
            checked={selectedStaffing.some(id => id === orderItem.id)}
            onChange={() => handleSelect(orderItem.id)}
          />
        </div>
        <Icon className="m-0 me-2" name={open ? "ChevronUp" : "ChevronDown"} size={IconSizeType.XS} />
        {orderItem?.name}
      </Col>

      <Col className="col-5">
        <TextLink to={`${ORDERS_PATH}/${order.id}`}>
          {orderUtils.getOrderRefDisplay(order.orderNumber, order.customerReference, order.customerName)}
        </TextLink>
      </Col>

      <Col className="col-3">
        {orderItemUtils.buildDateHour(orderItem)}
      </Col>

      <Col className="col-5">
        <Row className="ms-1 align-items-center">
          <Col xs={14} className="p-0 d-flex gap-2">
            {
              orderItem?.orderItemResources?.map((orderItemResource) => (
                <div key={orderItemResource.id} className="d-flex">
                  <span>{`${getConfirmedStaffingByResourceType(orderItemResource.resourceType).length}/${orderItemResource.quantity}`}</span>
                  <ResourceCircle className="ms-1" resourceType={orderItemResource.resourceType} />
                </div>
              ))
            }
          </Col>
          <Col xs={10}>
            {getAvailableStaffing()?.length > 0 && (
              <Badge color={ColorType.GREEN}>
                <FormattedMessage id="staffing_request_number_available" values={{ count: getAvailableStaffing()?.length }} />
              </Badge>
            )}
          </Col>
        </Row>
      </Col>

      <Col className="float-end d-flex mb-1 col-6">
        {[...getSentStaffing(), ...getRefusedStaffing()]?.length > 0 && !hasStaffingConfirmed() && staffingButtonCancel && (
          <div className="epow-danger bold cursor-pointer d-flex justify-content-end ms-auto" color={ColorType.DANGER} onClick={() => setStaffingCancelFormOpen(true)}>
            <FormattedMessage id="staffing_request_cancel" />
          </div>
        )}
      {staffingCancelFormOpen && (
        <ModalStaffingCancel
          onDelete={() => sendStaffingRequest()}
          setOpen={setStaffingCancelFormOpen}
          setVisible={setStaffingButtonCancel}
          open={staffingCancelFormOpen}/>
      )}
        <div className="d-flex align-items-center bold cursor-pointer justify-content-end ms-auto mb-3" onClick={(e) => { e.preventDefault(); e.stopPropagation() }} >
          <div onClick={() => openStaffingForm(orderItem)}>
            <FormattedMessage id="staffing_request_renew" />
            <Icon name="ChevronRight" size={IconSizeType.XS} />
          </div>
        </div>
      </Col>
    </div>
  )
}

export default StaffingPendingTableRow;
