import {API_ACTIVITIES_BILLING_PATH} from "../constants/routes/RoutePaths";
import {
  ActivityBillingComment,
  ActivityBillingCommentRequest,
  ActivityBillingPageData,
  ActivityBillingRequest,
  ActivityBillingSearchObject
} from "../interfaces/ActivityBllingInterface";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {fileUtils} from "../utils/fileUtils";
import {queryUtils} from "../utils/queryUtils";
import {httpService} from "./HttpService";
import {ContentTypeEnum} from "../constants/fileType";

function createActivityBilling(request: ActivityBillingRequest): Promise<Object> {
  const formData = fileUtils.createFormDataForActivityBillingFiles(request);
  return httpService.postFormData(`${API_ACTIVITIES_BILLING_PATH}`, formData);
}

function getActivityBillingPage(params: PaginationQueryParams, filterParams: ActivityBillingSearchObject): Promise<PageResponse<ActivityBillingPageData>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get(`${API_ACTIVITIES_BILLING_PATH}${queryParams}`)
}

function getResourceActivityBillingPage(params: PaginationQueryParams, filterParams: ActivityBillingSearchObject): Promise<PageResponse<ActivityBillingPageData>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get(`${API_ACTIVITIES_BILLING_PATH}/self${queryParams}`)
}

function refuseActivityBilling(id: string, mailContent: string): Promise<ActivityBillingPageData> {
  return httpService.put(`${API_ACTIVITIES_BILLING_PATH}/refuse/${id}`, mailContent)
}

function acceptActivitiesBilling(ids: string[]): Promise<ActivityBillingPageData> {
  return httpService.put(`${API_ACTIVITIES_BILLING_PATH}/accept`, ids)
}

function generatePayment(activityBillingIds: string[]): Promise<{ fileName: string | null; blob: Blob }> {
  return httpService.postReadableStreamWithFileName(`${API_ACTIVITIES_BILLING_PATH}/payment`, {entityIds: activityBillingIds}, ContentTypeEnum.JSON)
}

function createActivityBillingComment(id: string, request: ActivityBillingCommentRequest): Promise<Object> {
  return httpService.post(`${API_ACTIVITIES_BILLING_PATH}/${id}/comments`, request);
}

function getActivityBillingComment(id: string): Promise<ActivityBillingComment[]> {
  return httpService.get(`${API_ACTIVITIES_BILLING_PATH}/${id}/comments`);
}


export const activityBillingService = Object.freeze({
  createActivityBilling,
  getActivityBillingPage,
  getResourceActivityBillingPage,
  generatePayment,
  acceptActivitiesBilling,
  createActivityBillingComment,
  getActivityBillingComment,
  refuseActivityBilling
});
