import React, {FunctionComponent, useEffect, useState} from "react";
import {Formik} from "formik";
import FormInput from "../input/FormInput";
import {toastUtils} from "../../../utils/toastUtils";
import {useLocation, useNavigate} from "react-router-dom";
import {Quote, QuoteField, QuoteState} from "interfaces/QuoteInterfaces";
import {quoteService} from "services/QuoteService";
import {customerService} from "services/CustomerService";
import {CreateQuoteSchema} from "constants/validation/QuoteValidationSchemas";
import {QUOTES_PATH} from "constants/routes/RoutePaths";
import {dateUtils} from "../../../utils/dateUtils";
import {tourService} from "../../../services/TourService";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import {useIntl} from "react-intl";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import EpowForm from "./EpowForm";
import {LoadingComponent} from "../../../constants/menu/Layout";
import {QUOTE_INITIAL_VALUES} from "../../../constants/QuoteConstants";
import {ApiError} from "../../../interfaces/ErrorInterfaces";
import FormSelect from "../input/FormSelect";
import {useIntlOptions} from "../../../hooks/useIntlOptions";
import {CUSTOMER_GEOGRAPHICAL_AREA_OPTIONS} from "../../../constants/CustomerConstants";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {FormikFieldSetter} from "../../../interfaces/FormikInterfaces";
import {customerContactService} from "../../../services/CustomerContactService";
import {ContactOption} from "../../../interfaces/ContactInterfaces";
import {PageResponse} from "../../../interfaces/api/PaginationInterface";

interface CreateQuoteFormProps extends LoadingComponent {
  id?: string,
  className?: string,
  quote?: Quote,
  afterSubmit?: () => void,
  onBeforeSubmit?: () => void
  onValidate?: (quote: Quote) => void
}

const CreateQuoteForm: FunctionComponent<CreateQuoteFormProps> = ({
  id,
  className = "",
  quote,
  afterSubmit = () => null,
  onBeforeSubmit = () => null,
  onValidate = () => null,
  startLoading,
  stopLoading,
  closeForm = () => null,
}) => {
  const intl = useIntl()
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const [alreadyExistRef, setAlreadyExistRef] = useState<boolean>(false);
  const [contactOptions, setContactOptions] = useState<Option<string>[]>([]);
  const geographicalZoneOptions = useIntlOptions(intl, CUSTOMER_GEOGRAPHICAL_AREA_OPTIONS)

  const handleError = (e: ApiError) => {
    if (e.status === 400) {
      setAlreadyExistRef(true)
    }
    afterSubmit()
  }

  const handleSubmitQuote = (quoteFields: QuoteField) => {
    const quoteRequest = quoteService.buildQuoteRequest(quoteFields)
    onBeforeSubmit()
    startLoading?.()
    if (quote?.id) {
      quoteService.updateQuote(quote.id, quoteRequest)
        .then((quote => {
          onValidate(quote)
          toastUtils.successToast(intl.formatMessage({id: "success_toast_update_quote"}));
        }))
        .catch(handleError).finally(() => {
          afterSubmit()
          stopLoading?.()
          closeForm()
        })
    } else {
      quoteService.createQuote(quoteRequest)
        .then((quote: Quote) => {
          if (pathname.includes(`${QUOTES_PATH}/`)) {
            window.location.href = `${QUOTES_PATH}/${quote?.id}`
          } else {
            navigate(`${QUOTES_PATH}/${quote?.id}`)
          }
        })
        .catch(handleError).finally(() => {
          afterSubmit()
          stopLoading?.()
          closeForm()
        })
    }
  }

  const getDefaultValues = (): QuoteField => {
    if (!quote?.id) {
      return {...QUOTE_INITIAL_VALUES}
    }

    return {
      customerRef: quote.customerRef ?? "",
      customerId: quote.customerId ?? "",
      emailContact: quote.emailContact ?? "",
      creationDate: dateUtils.formatDateYYYYMMDD(dateUtils.convertStringToObjectDate(quote.quoteDate)),
      rendezVousDate: dateUtils.formatDateYYYYMMDD(dateUtils.convertStringToObjectDate(quote.beginTourDate)),
      endOfValidity: dateUtils.formatDateYYYYMMDD(dateUtils.convertStringToObjectDate(quote.endOfValidityDate)),
      tourId: quote.tourId,
      pax: quote.pax ?? 0,
      geographicalZone: quote?.geographicalZone,
    }
  }

  const fetchContactOptions = (customerId: string) => {
    customerContactService.getAllContactOptionsByCustomerId(customerId)
      .then((response: PageResponse<ContactOption>) => {
        setContactOptions(response.content.map(contact => {
          return { value: contact.email, label: contact.email }
        }))
      })
      .catch(() => intl.formatMessage({id: "error_toast_quote_update"}))
  }

  const onCustomerChange = (customerId: string, setFieldValue: FormikFieldSetter) => {
    if (customerId) {
      customerService.getCustomerById(customerId)
        .then((customer) => {
          setFieldValue("customerId", customerId)
          setFieldValue("geographicalZone", customer.geographicalZone)
          fetchContactOptions(customerId)
        })
        .catch(() => intl.formatMessage({id: "error_toast_quote_update"}))
    } else {
      setFieldValue("customerId", undefined)
      setFieldValue("geographicalZone", undefined)
      setFieldValue("emailContact", undefined)
      setContactOptions([])
    }
  }

  useEffect(() => {
    if (quote) {
      fetchContactOptions(quote.customerId)
    }
  }, [])

  return (
    <Formik initialValues={getDefaultValues()} validationSchema={CreateQuoteSchema} onSubmit={value => handleSubmitQuote(value)}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={`${className}`}>
          <FormAutocompleteSelectPageable
            id="customer"
            label={intl.formatMessage({id: "quote_customer"})}
            placeholder={intl.formatMessage({id: "customer_placeholder"})}
            onChange={(value) => onCustomerChange(value, setFieldValue)}
            value={values?.customerId}
            fetchData={(page, filter) => customerService.getCustomersPage(page, {
              ...filter,
              currentState: WorkflowStatesEnum.ACTIVE
            })}
            required={!values?.emailContact}
            filterFieldName="fullName"
            filterFieldSearch="name"
            error={errors?.customerId}
            touched={touched?.customerId}
            readOnly={!!quote && quote?.status !== QuoteState.DRAFT}
          />

          {
            contactOptions?.length > 0 ? (
              <FormSelect
                id="selectEmailContact"
                label="quote_contact"
                value={contactOptions.find((option) => option?.value === values?.emailContact)}
                onChange={(option?: Option<string>) => setFieldValue("emailContact", option?.value)}
                options={contactOptions}
                isSearchable
                isClearable
                error={errors.emailContact}
                required={!values?.customerId}
                touched={touched.emailContact}
              />
            ) : (
              <FormInput
                id="inputEmailContact"
                label="quote_contact"
                value={values?.emailContact}
                onChange={(e) => setFieldValue("emailContact", e.target?.value)}
                error={errors.emailContact}
                required={!values?.customerId}
                touched={touched.emailContact}
              />
            )
          }

          <FormAutocompleteSelectPageable
            id="tour"
            label={intl.formatMessage({id: "quote_tour"})}
            placeholder={intl.formatMessage({id: "tour_placeholder"})}
            onChange={(value) => setFieldValue("tourId", value)}
            value={values?.tourId}
            fetchData={(page, filter) => tourService.getToursPage(page, {
              ...filter,
              customerId: values?.customerId,
              currentState: WorkflowStatesEnum.ACTIVE
            })}
            filterFieldName="fullName"
            filterFieldSearch="tourName"
            error={errors?.tourId}
            touched={touched?.tourId}
            readOnly={!!quote && quote?.status !== QuoteState.DRAFT}
          />

          <FormInput
            id="refClient"
            label="quote_reference_lient"
            value={values?.customerRef}
            onChange={(e) => {
              if (alreadyExistRef) {
                setAlreadyExistRef(false)
              }
              setFieldValue("customerRef", e.target?.value)
            }}
            error={errors.customerRef || (alreadyExistRef && "already_exists_customer_ref")}
            required
            touched={touched.customerRef}
          />

          <FormSelect
            id="geographicalZone"
            label="customer_geographical_zone_field"
            value={geographicalZoneOptions.find((option) => option?.value === values?.geographicalZone)}
            onChange={(option?: Option<string>) => setFieldValue("geographicalZone", option?.value)}
            options={geographicalZoneOptions}
            isSearchable
            isClearable
            error={errors?.geographicalZone}
            touched={touched?.geographicalZone}
          />

          <FormInput
            id="date"
            label="quote_date"
            type="date"
            required
            value={values?.creationDate}
            onChange={(e) => setFieldValue("creationDate", e.target?.value)}
            error={errors.creationDate}
            touched={touched.creationDate}
          />

          <FormInput
            id="dateBegin"
            label="quote_rendez_vous_date"
            type="date"
            minValue={dateUtils.formatDateYYYYMMDD() && dateUtils.formatDateYYYYMMDD(dateUtils.convertStringToObjectDate(values?.creationDate))}
            required
            value={values?.rendezVousDate}
            onChange={(e) => setFieldValue("rendezVousDate", e.target?.value)}
            error={errors.rendezVousDate}
            touched={touched.rendezVousDate}
          />

          <FormInput
            id="date"
            label="quote_end_of_validity"
            type="date"
            required
            value={values?.endOfValidity}
            onChange={(e) => setFieldValue("endOfValidity", e.target?.value)}
            error={errors.endOfValidity}
            touched={touched.endOfValidity}
          />

          <FormInput
            id="pax"
            label="quote_pax"
            type="number"
            required
            value={values?.pax}
            onChange={(e) => setFieldValue("pax", e.target?.value)}
            error={errors.pax}
            touched={touched.pax}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateQuoteForm
