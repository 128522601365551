import React, {FunctionComponent} from "react";
import Title from "../../atoms/Title";
import {FormattedMessage, useIntl} from "react-intl";
import {ColorType, TitleSizeType} from "../../../types/bootstrap/BootstrapType";
import Button from "../../atoms/Button";

interface LateralPanelProps {
  className?: string,
  open?: boolean,
  title?: string,
  subTitle?: string,
  children?: React.ReactNode,
  onCancel?: () => void,
  cancelButtonLabel?: string,
  validateButtonLabel?: string,
  formId?: string,
  displayButton?: boolean
  displayValidateButton?: boolean,
  loading?: boolean,
  panelHeight?: string
  disabledValidateButton?: boolean;
}

const LateralPanel: FunctionComponent<LateralPanelProps> = ({
  className = "",
  open = false,
  title,
  subTitle,
  children,
  onCancel,
  loading = false,
  cancelButtonLabel = "cancel_button",
  validateButtonLabel= "validate_button",
  formId = "",
  displayButton = true,
  displayValidateButton = true,
  disabledValidateButton = false
}) => {
  const intl = useIntl()

  return (
    <div className={`epow-lateral-panel ${className} ${!open ? "d-none" : ""} d-flex flex-column justify-content-between p-5`}>
      <div className="epow-lateral-panel-container overflow-y-auto">
        <div className="epow-lateral-panel-header d-flex flex-column mb-3">
          {title && (
            <Title size={TitleSizeType.H5} title={intl.formatMessage({id: title})}/>
          )}
          {subTitle && (
            <Title className="mt-1" size={TitleSizeType.H6} title={intl.formatMessage({id: subTitle})}/>
          )}
        </div>
        <div className="epow-lateral-panel-content d-flex flex-column align-items-center flex-shrink">
          {children}
        </div>
      </div>
      {displayButton && (
        <div className="epow-lateral-panel-footer d-flex flex-row justify-content-end">
          {cancelButtonLabel && (
            <Button onClick={onCancel} color={ColorType.SECONDARY} className="extended me-2">
              <FormattedMessage id={cancelButtonLabel}/>
            </Button>
          )}
          {displayValidateButton && validateButtonLabel && (
            <Button loading={loading} className="extended" form={formId} type="submit" disabled={disabledValidateButton}>
              <FormattedMessage id={validateButtonLabel}/>
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default LateralPanel
