import {Customer} from "../../../../interfaces/CustomerInterfaces";
import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "../../../atoms/Button";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import {customerService} from "../../../../services/CustomerService";
import {getCountryLabel} from "utils/countryUtils";
import FieldLabel from "../../../atoms/FieldLabel";
import {optionUtils} from "../../../../utils/optionUtils";
import {YES_NO_OPTIONS} from "../../../../constants/OptionConstants";

interface CustomerCardProps {
  className?: string,
  customer?: Customer,
  editInfo?: boolean,
  setEditInfo?: (editInfo: boolean) => void
}

const CustomerCard: FunctionComponent<CustomerCardProps> = ({
  className = "",
  customer,
  editInfo,
  setEditInfo
}) => {
  const intl = useIntl()
  const customerFields = customerService.buildCustomerFields(customer);

  return (
    <div className={className}>
      <div className="d-flex flex-column gap-3 mb-4">
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "customer_individual_field"})}
          value={optionUtils.translateOption(intl, YES_NO_OPTIONS, customer?.individual)}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : customer.individual ? "customer_name_field" : "company_name"})}
          value={customer?.name}
        />
        { customer?.individual && <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id: "customer_firstname_field"})}
          value={customer?.firstname}
        />}
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "customer_code"})}
          value={customer?.code}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "address_field"})}
          value={customerFields?.mainLabel}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "additional_address_field"})}
          value={customerFields?.additionalInformation}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "service_field"})}
          value={customerFields?.service}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "city_field"})}
          value={customerFields?.city}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "zip_code_field"})}
          value={customerFields?.zipCode}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "country_field"})}
          value={getCountryLabel(customerFields?.country)}
        />
      </div>
      <Button size={SizeType.MD} color={ColorType.SECONDARY} onClick={() => setEditInfo(!editInfo)} className="extended">
        <FormattedMessage id="edit_button"/>
      </Button>
    </div>
  )
}

export default CustomerCard
