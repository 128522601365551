import {capitalize, Dictionary, groupBy} from "lodash";
import React, {FunctionComponent, useContext, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {BookingItem} from "../../../../interfaces/BookingInterfaces";
import {supplierService} from "../../../../services/SupplierService";
import {dateUtils} from "../../../../utils/dateUtils";
import BookingItemCard from "../booking/BookingItemCard";
import {DateFormats} from "../../../../constants/DateConstants";
import {ActiveSupplierContext} from "../../../../context/ActiveSupplierContext";

interface DashboardNextServicesProps {
  className?: string,
  calendarDate: Date,
}

const DashboardNextServices: FunctionComponent<DashboardNextServicesProps> = ({
  className = "",
  calendarDate = dateUtils.today(),
}) => {
  const intl = useIntl()
  const [bookings, setBookings] = useState<BookingItem[]>([])
  const activeSupplier = useContext(ActiveSupplierContext);

  useEffect(() => {
    supplierService.getPurchaseForSupplier(activeSupplier?.id)
      .then((purchases) => setBookings(purchases))
      .catch(() => intl.formatMessage({id: "message_id"}))
  }, [activeSupplier])

  const getBookingsByDate = () => {
    const bookingsByDate: Dictionary<BookingItem[]> = groupBy(bookings, "date")
    const sortedBookingsByDate = Object.entries(bookingsByDate) ?? [];
    sortedBookingsByDate.sort((a, b) => dateUtils.parseDate(a[0]) < dateUtils.parseDate(b[0]) ? -1 : 1)
    if (calendarDate) {
      return sortedBookingsByDate.filter(([date]) => date === dateUtils.formatDateYYYYMMDD(calendarDate));
    }
    return sortedBookingsByDate;
  }

  const buildDate = (date: string) => {
    const localized = dateUtils.formatDateDayJs(date, DateFormats.longDate)
    return capitalize(localized)
  }

  return (
    <div className={className}>
      <h3 className="fs-16"><FormattedMessage id="dashboard_next_services" /></h3>
      {
        getBookingsByDate().length > 0 ? (
          getBookingsByDate().map(([date, bookings]) => (
            <div key={date}>
              <div className="d-flex align-items-center mb-2">
                {buildDate(date)}
              </div>
              <div className="d-flex gap-2 flex-wrap">
                {bookings.map(booking => (
                  <BookingItemCard key={booking.id} bookingItem={booking} />
                ))}
              </div>
            </div>
          ))
        ) : (
          <div>
            <p>{intl.formatMessage({id: "dashboard_no_next_services"})}</p>
          </div>
        )
      }
    </div>
  )
}

export default DashboardNextServices;
