import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {AdminConfigurationSchema} from "../../../../constants/validation/admin/AdminConfigurationValidationSchema";
import {AdminConfigurationDto} from "../../../../interfaces/AdminConfigurationInterfaces";
import {adminConfigurationService} from "../../../../services/AdminConfigurationService";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../../utils/toastUtils";
import Button from "../../../atoms/Button";
import FormInput from "../../input/FormInput";
import EpowForm from "../EpowForm";

interface AdminConfigurationFormProps {
  className?: string,
  onUpdate: () => void,
  onCancel: () => void,
  adminConfiguration: AdminConfigurationDto,
}

const AdminConfigurationForm: FunctionComponent<AdminConfigurationFormProps> = ({
  className = "",
  onUpdate,
  onCancel,
  adminConfiguration,
}) => {
  const intl = useIntl();
  const initialValues: AdminConfigurationDto = {
    dashboardBookingPendingWindow: 0,
    dashboardBookingTodoWindow: 0,
    dashboardStaffingWindow: 0,
    bookingPreBookingDelay: 10,
    bookingServiceDelay: 30,
    departureTimeMinuteDelta: 15
  }

  const handleSubmit = (formValue: AdminConfigurationDto) => {
    adminConfigurationService.updateAdminConfiguration(formValue)
      .then(() => {
        onUpdate()
        toastUtils.successToast(intl.formatMessage({id: "success_toast_update_admin_settings"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_save_admin_config"}))
      })
  }

  return (
    <Formik initialValues={adminConfiguration ?? initialValues} validationSchema={AdminConfigurationSchema} onSubmit={handleSubmit}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={"admin-config"} className={className}>
          <div className="col">
            <FormInput
              id="dashboardBookingTodoWindow"
              label="admin_config_dashboard_booking_todo"
              popover="admin_config_dashboard_booking_todo_tooltip"
              type="number"
              value={values?.dashboardBookingTodoWindow}
              onChange={(e) => setFieldValue("dashboardBookingTodoWindow", e.target.value)}
              required
              error={errors.dashboardBookingTodoWindow}
              touched={touched.dashboardBookingTodoWindow}
            />

            <FormInput
              id="dashboardBookingPendingWindow"
              label="admin_config_dashboard_booking_pending"
              popover="admin_config_dashboard_booking_pending_tooltip"
              type="number"
              value={values?.dashboardBookingPendingWindow}
              onChange={(e) => setFieldValue("dashboardBookingPendingWindow", e.target.value)}
              required
              error={errors.dashboardBookingPendingWindow}
              touched={touched.dashboardBookingPendingWindow}
            />

            <FormInput
              id="dashboardStaffingWindow"
              label="admin_config_dashboard_staffing"
              popover="admin_config_dashboard_staffing_tooltip"
              type="number"
              value={values?.dashboardStaffingWindow}
              onChange={(e) => setFieldValue("dashboardStaffingWindow", e.target.value)}
              required
              error={errors.dashboardStaffingWindow}
              touched={touched.dashboardStaffingWindow}
            />

            <FormInput
              id="bookingPreBookingDelay"
              label="admin_config_booking_prebooking_delay"
              popover="admin_config_booking_prebooking_delay_tooltip"
              type="number"
              value={values?.bookingPreBookingDelay}
              onChange={(e) => setFieldValue("bookingPreBookingDelay", e.target.value)}
              required
              error={errors.bookingPreBookingDelay}
              touched={touched.bookingPreBookingDelay}
            />

            <FormInput
              id="bookingServiceDelay"
              label="admin_config_booking_service_delay"
              popover="admin_config_booking_service_delay_tooltip"
              type="number"
              value={values?.bookingServiceDelay}
              onChange={(e) => setFieldValue("bookingServiceDelay", e.target.value)}
              required
              error={errors.bookingServiceDelay}
              touched={touched.bookingServiceDelay}
            />

            <FormInput
              id="departureTimeMinuteDelta"
              label="admin_config_booking_delta_minutes"
              popover="admin_config_booking_delta_minutes_tooltip"
              type="number"
              maxValue="60"
              value={values?.departureTimeMinuteDelta}
              onChange={(e) => setFieldValue("departureTimeMinuteDelta", e.target.value)}
              required
              error={errors.departureTimeMinuteDelta}
              touched={touched.departureTimeMinuteDelta}
            />
          </div>

          <Button className="me-2" color={ColorType.SECONDARY} onClick={onCancel} >
            <FormattedMessage id="cancel_button" />
          </Button>

          <Button type="submit" >
            <FormattedMessage id="save_button" />
          </Button>


        </EpowForm>
      )}
    </Formik>
  )
}

export default AdminConfigurationForm;
