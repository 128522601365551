import React, {Formik, validateYupSchema} from "formik";
import {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {ValidationError} from "yup";
import {Activity} from "../../../../interfaces/ActivityInterfaces";
import {Quote} from "../../../../interfaces/QuoteInterfaces";
import {QuoteItem, QuoteItemRequest} from "../../../../interfaces/QuoteItemInterfaces";
import {Service} from "../../../../interfaces/ServiceInterfaces";
import {quoteItemService} from "../../../../services/QuoteItemService";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../../utils/toastUtils";
import Button from "../../../atoms/Button";
import CreateQuoteItemFormStepOne from "./CreateQuoteItemFormStepOne";
import CreateQuoteItemFormStepTwo from "./CreateQuoteItemFormStepTwo";
import EpowForm from "../EpowForm";
import {ApiError} from "../../../../interfaces/ErrorInterfaces";
import {QUOTE_ITEM_INITIAL_VALUES} from "../../../../constants/QuoteItemConstants";
import {
  CreateQuoteItemSchema,
  QuoteItemCreationFirstStepSchema
} from "../../../../constants/validation/QuoteItemValidationSchema";

interface CreateQuoteItemFormProps {
  className?: string,
  id?: string,
  onCancel: () => void,
  onValidate: () => void,
  quote?: Quote,
  quoteItem?: QuoteItem,
}

const CreateQuoteItemForm: FunctionComponent<CreateQuoteItemFormProps> = ({
  className = "",
  id,
  onCancel,
  onValidate,
  quote,
  quoteItem,
}) => {

  const intl = useIntl()
  const [step, setStep] = useState<number>(0);
  const [selectedActivity, setSelectedActivity] = useState<Activity>(null);
  const [selectedService, setSelectedService] = useState<Service>(null);

  const handleSubmitQuoteItem = (newQuoteItem: QuoteItemRequest) => {
    if (quoteItem?.id) {
      quoteItemService.updateQuoteItem(quoteItem?.id, newQuoteItem)
        .then(() => {
          toastUtils.successToast(intl.formatMessage({id: "success_toast_update_quote_item"}));
          onValidate()
        })
        .catch((error: ApiError) => {
          toastUtils.errorToast(error.message)
        })
    } else {
      quoteItemService.createQuoteItem(newQuoteItem)
        .then(() => {
          toastUtils.successToast(intl.formatMessage({id: "success_toast_create_quote_item"}));
          onValidate()
        })
        .catch((error: ApiError) => {
          toastUtils.errorToast(error.message)
        })
    }
  }

  const getInitialValues = (): QuoteItemRequest => {
    if (quoteItem?.id) {
      return {
        id: quoteItem?.id,
        name: quoteItem.name,
        rendezVousDate: quoteItem.rendezVousDate,
        rendezVousHour: quoteItem.rendezVousHour,
        description: quoteItem.description,
        duration: quoteItem.duration,
        type: quoteItem.type,
        pax: quoteItem.pax,
        optional: quoteItem.optional,
        paxConfirmed: quoteItem.paxConfirmed,
        rendezVousPlace: quoteItem.rendezVousPlace,
        sellPrice: quoteItem?.sellingPriceHT,
        sellPriceTVA: quoteItem?.sellPriceTVA,
        sellPriceTTC: quoteItem?.sellingPriceTTC,
        unitType: quoteItem.unitType,
        disbursements: quoteItem.disbursement,
        quantity: quoteItem.quantity,
        paxPrice: quoteItem.unitPriceHt,
        quoteId: quote.id,
        billingServiceId: quote?.customerId,
        activity: quoteItem.activityId,
        service: quoteItem.serviceId,
      }
    }

    return {
      ...QUOTE_ITEM_INITIAL_VALUES,
      quoteId: quote.id,
      pax: quote.pax,
      quantity: quote.pax,
      billingServiceId: quote?.customerId,
      disbursements: quote?.customerDisbursements
    }
  }

  const validateFirstStep = (values: QuoteItemRequest, setErrors: (errors: unknown) => void, setTouched: (touched: unknown) => void) => {
    try {
      void validateYupSchema(values, QuoteItemCreationFirstStepSchema, true)
      setStep(1)
    } catch (error) {
      if (error instanceof ValidationError) {
        let errorObject = {}
        let touchedObject = {}
        error.inner.forEach(e => {
          errorObject = {...errorObject, [e.path]: e.message}
          touchedObject = {...touchedObject, [e.path]: true}
        })

        setErrors(errorObject)
        setTouched(touchedObject)
      }
    }
  }

  const formProps = {
    quote,
    selectedActivity,
    setSelectedActivity,
    selectedService,
    setSelectedService
  }

  return (
    <Formik initialValues={getInitialValues()} validationSchema={CreateQuoteItemSchema} onSubmit={value => {
      handleSubmitQuoteItem(value)
    }}>
      {({errors, touched, values, setFieldValue, setErrors, setTouched}) => (
        <EpowForm id={id} className={className}>
          {step === 0 && (
            <>
              <CreateQuoteItemFormStepOne
                errors={errors}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                quote={quote}
                {...formProps}
              />

              <div className="epow-lateral-panel-footer d-flex flex-row justify-content-end">
                <Button size={SizeType.MD} onClick={onCancel} color={ColorType.SECONDARY} className="extended me-2">
                  <FormattedMessage id={"cancel_button"} />
                </Button>
                <Button
                  size={SizeType.MD}
                  onClick={() => validateFirstStep(values, setErrors, setTouched)}
                  className="extended"
                >
                  <FormattedMessage id={"next_button"} />
                </Button>
              </div>
            </>
          )}

          {step === 1 && (
            <>
              <CreateQuoteItemFormStepTwo
                errors={errors}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                {...formProps}
              />

              <div className="d-flex justify-content-between">
                <div>
                  <Button size={SizeType.MD} onClick={() => setStep(0)} color={ColorType.SECONDARY}
                    className="extended">
                    <FormattedMessage id={"previous_button"} />
                  </Button>
                </div>
                <div>
                  <Button size={SizeType.MD} onClick={onCancel} color={ColorType.SECONDARY} className="extended me-2">
                    <FormattedMessage id={"cancel_button"} />
                  </Button>
                  <Button form={id} type="submit" size={SizeType.MD} className="extended">
                    <FormattedMessage id={"validate_button"} />
                  </Button>
                </div>
              </div>
            </>
          )}
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateQuoteItemForm;
