import React, {FunctionComponent, useState} from "react";
import {RouteProps, useSearchParams} from "react-router-dom";
import Layout from "../components/layout/Layout";
import StaffingCalendar from "../components/molecules/staffingcalendar/StaffingCalendar";
import StaffingReporting from "../components/molecules/staffingcalendar/StaffingReporting";
import HeaderButtonGroup, {HeaderStateButton} from "../components/molecules/header/HeaderButtonGroup";
import PageTitle from "../components/molecules/header/PageTitle";
import {useIntl} from "react-intl";
import ResourceCalendar from "../components/molecules/resourceCalendar/ResourceCalendar";

const StaffingCalendarView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  const [params, setParams] = useSearchParams();

  const [pendingCalendarListState, setPendingCalendarListState] = useState<{
    displayCalendar: boolean,
    displayReporting: boolean,
    displayResourceCalendar: boolean
  }>({
    displayCalendar: !!params.get("displayCalendar") || (!params.get("displayReporting") && !params.get("displayResourceCalendar")),
    displayReporting: !!params.get("displayReporting"),
    displayResourceCalendar: !!params.get("displayResourceCalendar"),
  })

  const changeState = (propertyName: string) => {
    // removes any search params to avoid conflicts between filters, and add new view as params to stay there on refresh
    const newSearchparams = {[propertyName]: "true"}
    const params = new URLSearchParams(newSearchparams)
    setParams(params, {replace: false})

    const emptyState = {displayReporting: false, displayResourceCalendar: false, displayCalendar: false}
    setPendingCalendarListState({...emptyState, [propertyName]: true})
  }

  const stateFilterButtons: HeaderStateButton[] = [
    {
      title: intl.formatMessage({id: "view_calendar"}),
      onclick: () => changeState("displayCalendar"),
      active: pendingCalendarListState?.displayCalendar
    },
    {
      title: intl.formatMessage({id: "view_reporting"}),
      onclick: () => changeState("displayReporting"),
      active: pendingCalendarListState?.displayReporting
    },
    {
      title: intl.formatMessage({id: "view_resource_calendar"}),
      onclick: () => changeState("displayResourceCalendar"),
      active: pendingCalendarListState?.displayResourceCalendar
    },
  ]

  const getComponent = () => {
    if (pendingCalendarListState?.displayReporting) {
      return <StaffingReporting />
    } else if (pendingCalendarListState?.displayResourceCalendar) {
      return <ResourceCalendar />
    } else {
      return <StaffingCalendar />
    }
  }

  return (<Layout>
    <div className="epow-content-body d-flex flex-column">
      <div className="d-flex mb-4">
        <PageTitle pageTitle={intl.formatMessage({id: "menu_staffing_calendar"})}></PageTitle>
        <HeaderButtonGroup className="d-flex flex-1 justify-content-center me-5" buttonList={stateFilterButtons} />
      </div>
      {getComponent()}
    </div>
  </Layout>)
}


export default StaffingCalendarView;
