import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {OrderList} from "interfaces/OrderInterfaces";
import {useIntl} from "react-intl";
import {tourLeaderService} from "../../../services/TourLeaderService";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import EpowForm from "./EpowForm";
import {orderService} from "../../../services/OrderService";
import {toastUtils} from "../../../utils/toastUtils";
import FormAutocompleteMultiselect from "../input/FormAutocompleteMultiSelect";
import {TOUR_LEADER_IDS} from "../../../constants/TourLeaderConstants";
import {UpdateTourLeadersOrderSchema} from "../../../constants/validation/TourLeaderValidationSchemas";
import {TourLeaderIdsOrderFormFields} from "../../../interfaces/TourLeaderInterfaces";

interface UpdateTourLeaderOrderFormProps {
  id?: string,
  className?: string,
  order?: OrderList,
  afterSubmit?: () => void,
  onBeforeSubmit?: () => void
  onValidate?: (order: OrderList) => void
}

const UpdateTourLeaderOrderForm: FunctionComponent<UpdateTourLeaderOrderFormProps> = ({
  id,
  className = "",
  order,
  afterSubmit = () => null,
  onBeforeSubmit = () => null,
  onValidate = () => null
}) => {

  const intl = useIntl()

  const handleSubmitOrder = ( tourLeaderIds: string[] ) => {
    onBeforeSubmit()
    orderService.updateOrderTourLeader(order.id, tourLeaderIds).then((order) => {
      onValidate({
        ...order,
        tourLeaders: order.tourLeaders.map(tl => ({
          id: tl.id,
          identity: tl.identity
        })),
        customer: order.customer,
      })
      afterSubmit()
      toastUtils.successToast(intl.formatMessage({id: "order_update_tour_leader_success"}))
    }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "order_update_tour_leader_error"}))
    })
  }

  const initialFields: TourLeaderIdsOrderFormFields = {
    tourLeaderIds: order?.tourLeaders.map(tl => ({
      label: `${tl.identity.firstName} ${tl.identity.lastName}`,
      value: tl.id
    })) || []
  };


  return (
    <Formik initialValues={initialFields || TOUR_LEADER_IDS}
            validationSchema={UpdateTourLeadersOrderSchema}
            onSubmit={value => handleSubmitOrder(value.tourLeaderIds.map(item => item.value))}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={`${className}`}>
          <FormAutocompleteMultiselect
            id="tourLeader"
            label={intl.formatMessage({id: "order_tour_leader"})}
            placeholder={intl.formatMessage({id: "tour_leader_search_placeholder"})}
            onChange={(option) => setFieldValue("tourLeaderIds", option)}
            values={values?.tourLeaderIds}
            fetchData={(inputValue) => tourLeaderService.getTourLeaderPage({pageSize: 50, page: 0},{
              nameSurname: inputValue,
              customerId: order?.customer.id,
              currentState: WorkflowStatesEnum.ACTIVE
            })}
            filterFieldName="fullName"
            error={errors?.tourLeaderIds}
            touched={touched?.tourLeaderIds}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default UpdateTourLeaderOrderForm
