import React, {FunctionComponent, useEffect, useState} from "react";
import BookingItemInformation from "../../booking/BookingItemInformation";
import {BookingDocumentData, BookingDocumentFormData, BookingStatus} from "../../../../interfaces/BookingInterfaces";
import FieldLabel from "../../../atoms/FieldLabel";
import {FormattedMessage, useIntl} from "react-intl";
import BookingDocumentFormCard from "./BookingDocumentFormCard";
import {Formik} from "formik";
import EpowForm from "../EpowForm";
import {GET_BOOKING_DOCUMENT_FORM_CATEGORIES} from "../../../../constants/booking/Booking";
import {bookingService} from "../../../../services/BookingService";
import {toastUtils} from "../../../../utils/toastUtils";
import {BookingDocumentFormValidationSchema} from "../../../../constants/validation/BookingValidationSchemas";
import FormInput from "../../input/FormInput";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import {orderUtils} from "../../../../utils/orderUtils";
import ModalItemsToPay from "../../modal/ModalItemsToPay";
import {fileUtils} from "../../../../utils/fileUtils";
import {ApiError} from "../../../../interfaces/ErrorInterfaces";

interface BookingDocumentFormProps {
  id?: string
  className?: string;
  bookingDocumentData: BookingDocumentData;
  onValidate?: () => void
  onChangedBookingFile?: () => void
  onDeleteDocument?: (documentId: string) => void
  onCancel?: () => void
  onKeep?: () => void
}

const BookingDocumentForm: FunctionComponent<BookingDocumentFormProps> = ({
  id,
  className = "",
  bookingDocumentData,
  onValidate,
  onChangedBookingFile,
  onDeleteDocument,
  onCancel,
  onKeep,
}) => {
  const intl = useIntl();
  const [payModalOpened, setPayModalOpened] = useState<boolean>(false);
  const [billFileDetails, setBillFileDetails] = useState({});


  const initialValues: BookingDocumentFormData = {
    isBilled: bookingDocumentData?.isBilled,
    isPaid: bookingDocumentData?.isPaid,
    isConfirmed: bookingDocumentData?.supportingDocuments?.length > 0 || bookingDocumentData.status === BookingStatus.DOCUMENT_RECEIVED,
    invoiceDate: bookingDocumentData?.invoiceDate,
    paymentDate: bookingDocumentData?.paymentDate,
    updateEmailContent: "", // todo : set initial value once received
    reference: bookingDocumentData?.reference
  }

  useEffect(() => {
      setBillFileDetails(bookingDocumentData?.billDocuments?.reduce((acc, doc) => {
        acc[doc.fileId] = billFileDetails[doc.fileId] || doc.fileDetails || null;
        return acc;
      }, {}));
  }, [bookingDocumentData]);

  const payBookings = () => {
    bookingService.generateSepaFile([bookingDocumentData?.id])
      .then((file) => {
        toastUtils.successToast(intl.formatMessage({id: "booking_pay_modal_success"}))
        fileUtils.saveFile(file, "bookings_sepa.xml")
        setPayModalOpened(false)
        onValidate && onValidate()
      }).catch((error: ApiError) => {
      if (error && error.message) {
        toastUtils.errorToast(error.message)
      } else {
        toastUtils.errorToast(intl.formatMessage({id: "booking_pay_modal_failure"}))
      }
    })
  }

  const handleSubmit = (values: BookingDocumentFormData) => {
    if (Object.keys(billFileDetails).filter(key => billFileDetails[key] === null).length !== 0) {
      toastUtils.errorToast(intl.formatMessage({id: "error_toast_missing_invoice_number"}))
    } else if (bookingDocumentData?.id) {
      values.documentDetails = billFileDetails;
      bookingService.updatePendingBooking(bookingDocumentData?.id, values)
        .then((response) => {
          if (response.status === BookingStatus.CLOSED) {
            toastUtils.successToast(intl.formatMessage({id: "success_toast_booking_closed"}));
          } else {
            toastUtils.successToast(intl.formatMessage({id: "success_toast_update_booking_document"}));
          }
          onValidate && onValidate()
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_booking_document"}))
        })
    }
  }
  const categories = GET_BOOKING_DOCUMENT_FORM_CATEGORIES(bookingDocumentData.isPaid, () => setPayModalOpened(true));

  const handleChangedBillFileDetails = (fileId: string, value: string) => {
    setBillFileDetails(prevValues => ({
      ...prevValues,
      [fileId]: value,
    }));
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={BookingDocumentFormValidationSchema}
        onSubmit={handleSubmit}>
        {({ errors, touched, values, setFieldValue }) => (
          <EpowForm id={id}>
            <div className={`w-100 d-flex flex-column flex-shrink gap-3 ${className}`}>
              <FieldLabel
                label={intl.formatMessage({id: "booking_form_client_order"})}
                value={orderUtils.getOrderRefDisplay(bookingDocumentData?.orderCode, bookingDocumentData?.orderRef, bookingDocumentData?.orderCustomerName)}
              />
              <BookingItemInformation date={bookingDocumentData?.date} hour={bookingDocumentData?.hour} pax={bookingDocumentData?.pax}/>
              <div className="d-flex gap-3">
                <div className="d-flex gap-5">
                  <FieldLabel
                    label={intl.formatMessage({id: "booking_pax"})}
                    value={bookingDocumentData?.pax}
                  />
                  <FieldLabel
                    label={intl.formatMessage({id: "purchase_booking_quantity"})}
                    value={bookingDocumentData?.quantity}
                  />
                </div>
              </div>
              <FieldLabel label={intl.formatMessage({id: "booking_form_purchase_order"})} value={bookingDocumentData?.purchaseNumber} />
              <FieldLabel label={intl.formatMessage({id: "SUPPLIER"})} value={bookingDocumentData?.defaultSupplierName} />
              <FieldLabel label={intl.formatMessage({id: "booking_service"})} value={bookingDocumentData?.serviceName} />
              <FormInput
                id="reference"
                label="booking_reference"
                value={values?.reference}
                onChange={(e) => setFieldValue("reference", e.target?.value)}
                disabled={bookingDocumentData?.status === BookingStatus.CLOSED}
              />

              {categories.map(category =>
                <BookingDocumentFormCard
                  key={category.title}
                  category={category}
                  bookingDocumentData={bookingDocumentData}
                  errors={errors}
                  touched={touched}
                  values={values}
                  onChangedBookingFile={onChangedBookingFile}
                  onChangedBookingDetails={handleChangedBillFileDetails}
                  bookingFileDetails={billFileDetails}
                  onChangeValue={setFieldValue}
                  onDeleteDocument={onDeleteDocument}
                />
              )}
              {(bookingDocumentData?.status) === BookingStatus.TO_CANCEL &&
                <div className="d-flex justify-content-start mb-2">
                  <Button
                      color={ColorType.DANGER}
                      className="border border-danger"
                      onClick={onCancel}>
                      <FormattedMessage id="booking_form_cancel"/>
                  </Button>

                  <Button
                    className="border border-primary ms-2"
                    onClick={onKeep}>
                    <FormattedMessage id="booking_form_keep"/>
                  </Button>
                </div>
              }
            </div>
          </EpowForm>
        )}
      </Formik>
    <ModalItemsToPay
      opened={payModalOpened}
      setOpen={setPayModalOpened}
      action={payBookings}
      title={intl.formatMessage({id: "booking_pay_modal_title"})}
      textContent={intl.formatMessage({id: "booking_pay_modal_body"})}
    />
  </>
  )
}

export default BookingDocumentForm;
