import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import FormInput from "../input/FormInput";
import EpowForm from "./EpowForm";
import {planItemService} from "../../../services/PlanItemService";
import {toastUtils} from "../../../utils/toastUtils";
import {useIntl} from "react-intl";
import {PLAN_ITEM_INITIAL_VALUES, PlanItem, PlanItemFormData} from "../../../interfaces/PlanItemInterfaces";
import {CreatePlanItemSchema} from "../../../constants/validation/PlanItemValidationSchema";
import FormSelect from "../input/FormSelect";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {useIntlOptions} from "../../../hooks/useIntlOptions";
import {CUSTOMER_GEOGRAPHICAL_AREA_OPTIONS} from "../../../constants/CustomerConstants";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import {productTypeService} from "../../../services/ProductTypeService";

interface CreatePlanItemFormProps {
  className?: string;
  id?: string;
  initialPlanItem?: PlanItem;
  onCreatePlanItem?: () => void;
}

const CreatePlanItemForm: FunctionComponent<CreatePlanItemFormProps> = ({
                                                                  className = "",
                                                                  id,
                                                                  initialPlanItem,
                                                                  onCreatePlanItem,
                                                                }) => {

  const intl = useIntl();
  const geographicalZoneOptions = useIntlOptions(intl, CUSTOMER_GEOGRAPHICAL_AREA_OPTIONS)
  const initialPlanItemFormData = initialPlanItem ? {
    ...initialPlanItem,
    productTypeId: initialPlanItem.productType.id
  } : PLAN_ITEM_INITIAL_VALUES


  const handleEditPlanItem = (planItem: PlanItemFormData) => {
    planItemService.updatePlanItem(planItem)
      .then(() => toastUtils.successToast(intl.formatMessage({id: "plan_item_toast_edit_success"})))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "plan_item_toast_edit_error"})))
      .finally(() => onCreatePlanItem());
  }

  const handleCreatePlanItem = (planItem: PlanItemFormData) => {
    planItemService.createPlanItem(planItem)
      .then(() => toastUtils.successToast(intl.formatMessage({id: "plan_item_toast_create_success"})))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "plan_item_toast_create_error"})))
      .finally(() => onCreatePlanItem());
  }

  return (
    <Formik
      initialValues={initialPlanItemFormData}
      validationSchema={CreatePlanItemSchema}
      onSubmit={(values) => initialPlanItem ? handleEditPlanItem(values) : handleCreatePlanItem(values)}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormSelect
            id="geographicalZone"
            label="plan_item_geographical_zone"
            value={geographicalZoneOptions.find((option) => option?.value === values?.geographicalZone)}
            onChange={(option?: Option<string>) => setFieldValue("geographicalZone", option?.value)}
            options={geographicalZoneOptions}
            required
            isSearchable
            isClearable
            error={errors?.geographicalZone}
            touched={touched?.geographicalZone}
          />
          <FormAutocompleteSelectPageable
            id="productTypeId"
            label={intl.formatMessage({id: "plan_item_product_type"})}
            value={values.productTypeId}
            onChange={(value) => setFieldValue("productTypeId", value)}
            required
            error={errors.productTypeId}
            touched={touched.productTypeId}
            fetchData={productTypeService.getProductTypesPage}
            filterFieldName="name"
          />
          <FormInput
            id="saleAccount"
            label="plan_item_sale_account"
            value={values?.saleAccount}
            onChange={(e) => setFieldValue("saleAccount", e.target.value)}
            error={errors.saleAccount}
            touched={touched.saleAccount}
          />
          <FormInput
            id="purchaseAccount"
            label="plan_item_purchase_account"
            value={values?.purchaseAccount}
            onChange={(e) => setFieldValue("purchaseAccount", e.target.value)}
            error={errors.purchaseAccount}
            touched={touched.purchaseAccount}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreatePlanItemForm;
