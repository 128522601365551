import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Order, OrderState, OrderStateMap} from "../../../interfaces/OrderInterfaces";
import {orderService} from "../../../services/OrderService";
import {ColorType, IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../utils/toastUtils";
import Badge from "../../atoms/Badge";
import Button from "../../atoms/Button";
import FieldLabel from "../../atoms/FieldLabel";
import CreateOrderForm from "../form/CreateOrderForm";
import Panel from "../panel/Panel";
import {dateUtils} from "../../../utils/dateUtils";
import {OrderItem, OrderItemState} from "../../../interfaces/OrderItemInterfaces";
import PageTitle from "./PageTitle";
import {ORDERS_PATH, BILLING_PATH} from "../../../constants/routes/RoutePaths";
import {useNavigate} from "react-router-dom";
import {orderUtils} from "../../../utils/orderUtils";
import FormInput from "../input/FormInput";
import GenericModal from "../modal/GenericModal";
import {profileUtils} from "../../../utils/profileUtils";
import useLoading from "../../../hooks/useLoading";
import OrderActionsDropdown from "../input/order/OrderActionsDropdown";
import Icon from "../../icon/Icon";
import {BookingStatus} from "../../../interfaces/BookingInterfaces";
import {ActivityStaffingStatus} from "../../../constants/ActivityStaffingConstants";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import FormRadioLight from "../input/FormRadioLight";
import {optionUtils} from "../../../utils/optionUtils";

interface OrderHeaderProps {
  className?: string,
  title: string,
  order: Order,
  setOrder: (order: Order) => void
  setOrderItems: (orderItems: OrderItem[]) => void,
  onDelete: () => void,
  path?: string
}

const OrderHeader: FunctionComponent<OrderHeaderProps> = ({
  className = "",
  order,
  setOrder,
  title = "orders_title",
  setOrderItems,
  onDelete,
  path = ORDERS_PATH
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const {startLoading, stopLoading} = useLoading();

  const [lateralPanelOrderOpen, setlateralPanelOrderOpen] = useState<boolean>(false)
  const [modalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false)
  const [cancellationDate, setCancellationDate] = useState<string>(null)


  const isDeletable = (): boolean => {
    const hasBooking =  order.items.some(orderItem => orderItem.bookings?.length > 0 && orderItem.bookings.some(b => b.status !== BookingStatus.TO_DO))
    const hasStatusValidated =  order.items.some(item => item.status === OrderItemState.VALIDATED)
    const hasStaffing =   order.items.some(orderItem =>orderItem.resourceStaffing?.length > 0 && (orderItem.resourceStaffing.some(r => r.status === ActivityStaffingStatus.CONFIRMED) || orderItem.resourceStaffing.some(r => r.status === ActivityStaffingStatus.SENT)))
    const hasBilling =   order.items.some(item => item.billingStatus !== null)

    return !hasBooking && !hasStatusValidated && !hasStaffing && !hasBilling;
  }

  const [isDelete, setIsDelete] = useState<boolean>(false)


  const deleteOrder = () => {
    orderService.deleteOrder(order?.id)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_delete_generic"}))
        navigate(`${path}`)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_order_update"}))
      }).finally(() => {
        setModalDeleteOpen(false)
      })
  }

  const cancelOrder = () => {
    orderService.cancelOrder(order?.id, {cancellationDate: cancellationDate})
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_cancel_order_item"}))
        onDelete()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_order_update"}))
      }).finally(() => {
        setCancellationDate(null)
        setModalDeleteOpen(false)
      })
  }

  const handleOrderUpdate = (order: Order) => {
    setlateralPanelOrderOpen(false)
    setOrder(order)
    setOrderItems(order.items)
  }

  const onValidateOrder = () => {
    orderService.validateOrder(order.id)
      .then(order => {
        setOrder(order)
        setOrderItems(order.items)
        toastUtils.successToast(intl.formatMessage({id: "success_toast_validate_order"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_validate_order"}))
      })
  }

  const buildTitle = () => {
    const tourDate = dateUtils.formatDateDayJs(order.beginTourDate)
    const orderRef = orderUtils.getOrderRefDisplay(order.orderNumber, order.customerReference, order.customer?.name)
    return `${orderRef} - ${tourDate}`
  }

  const IS_DELETE_OPTIONS: Option<string>[] = [
    {label: "cancel_button", value: "false"},
    {label: "delete_button", value: "true"},
  ]

  return (
    <div className={`order-header ${className}`}>

      <div className="d-flex align-items-center flex-wrap">
        <PageTitle
          pageTitle={buildTitle()}
          className="me-4 flex-wrap"
          breadcrumbItems={[{
            title: intl.formatMessage({id: title}),
            to: path
          }]}
        />

        <div>
          <span className="me-1 text-muted"><FormattedMessage id="order_status" /> :</span>
          <Badge pill color={OrderStateMap[order.orderStatus]?.color}>
            {intl.formatMessage({id: OrderStateMap[order.orderStatus]?.label})}
          </Badge>
        </div>

        {profileUtils.isInterne() &&
          <div className="ms-auto d-flex gap-2 flex-wrap">
            <OrderActionsDropdown orderId={order.id} />

            <Button color={ColorType.SECONDARY} className="extended" onClick={() => setModalDeleteOpen(true)} ><FormattedMessage id="delete_button" /></Button>

            {order.orderStatus !== OrderState.COMPLETED &&
              <Button color={ColorType.SECONDARY} className="extended" onClick={() => setlateralPanelOrderOpen(true)} ><FormattedMessage id="edit_button" /></Button>
            }

            {order.orderStatus === OrderState.DRAFT &&
              <Button className="extended" onClick={onValidateOrder} ><FormattedMessage id="confirm_button" /></Button>
            }

            {order.items.some(item => item.status === OrderItemState.VALIDATED) && (
              <Button color={ColorType.SECONDARY} className="ms-2 extended" onClick={() => navigate(`${BILLING_PATH}?codeOrReference=${order.orderNumber}`)} ><FormattedMessage id="order_button_bill" /></Button>
            )}
          </div>
        }
      </div>

      <div className="d-flex">
        <div className="me-2">
          <FieldLabel
            className="me-3"
            label={intl.formatMessage({id: "order_creation_date"})}
            value={dateUtils.formatDateDayJs(order.creationDate)}
          />
        </div>

        <div>
          <FieldLabel
            className="me-3"
            label={intl.formatMessage({id: "order_date"})}
            value={dateUtils.formatDateDayJs(order.date)}
          />
        </div>

        <div>
          <FieldLabel
            className="me-3"
            label={intl.formatMessage({id: "order_number"})}
            value={order.orderNumber}
          />
        </div>
        <div className="d-flex me-3">
          <FieldLabel
            label={intl.formatMessage({id: "order_default_pax"})}
            value={order.defaultPax}
          />
          {order?.paxConfirmed && (
            <Icon
              name="Check"
              size={IconSizeType.XS}
              className="ms-2"
              color={ColorType.PRIMARY}
            />
          )}
        </div>
      </div>

      {lateralPanelOrderOpen && (
        <Panel formId="editOrderForm" title="edit_tour_activity" open={lateralPanelOrderOpen} onCancel={() => setlateralPanelOrderOpen(false)}>
          <CreateOrderForm
            id="editOrderForm"
            order={order}
            startLoading={startLoading}
            onValidate={handleOrderUpdate}
            stopLoading={stopLoading}
          />
        </Panel>
      )}


      <GenericModal
        title={intl.formatMessage({id: isDelete ? "modal_body_delete_order" : "modal_body_cancel_order"})}
        open={modalDeleteOpen}
        closable
        onOpen={setModalDeleteOpen}
        footer={{
          primaryAction: {
            buttonLabel: intl.formatMessage({id: "validate_button"}),
            action: () => {
              isDelete ? deleteOrder() : cancelOrder()
            },
            disabled: ((!isDelete || !isDeletable())&& !cancellationDate)
          },
          secondaryAction: {
            buttonLabel: intl.formatMessage({id: "cancel_button"}),
            action: () => {
              setModalDeleteOpen(false)
              setCancellationDate(null)
            }
          }
        }}
      >
        <div className="d-flex flex-column">
          <FormRadioLight
            bold
            id="isDelete"
            value={IS_DELETE_OPTIONS.find(option => option.value === isDelete.toString())}
            options={IS_DELETE_OPTIONS}
            onChange={(option) => setIsDelete(optionUtils.getBoolean(option.value))}
          />

          <div className="d-flex flex-column">
            {intl.formatMessage({id: isDelete ? "modal_body_delete_order" : "modal_body_cancel_order"})}
            {!isDelete && (
              <FormInput
                id="date"
                className="mt-3"
                label="order_item_cancel_date"
                type="date"
                value={cancellationDate}
                onChange={(e) => setCancellationDate(e.target.value)}
                required
              />
            )}

            {isDelete && !isDeletable() && (
              <div className="epow-error">
                {intl.formatMessage({id: "modal_body_delete_order_cannot_delete"})}
              </div>
            )}

          </div>
        </div>
      </GenericModal>
    </div>
  )
}

export default OrderHeader;
