import {PageResponse} from "../interfaces/api/PaginationInterface";
import {httpService} from "./HttpService";
import {API_PLAN_ITEM_PATH} from "../constants/routes/RoutePaths";
import {PlanItem, PlanItemFormData} from "../interfaces/PlanItemInterfaces";

const getPlanItems = (): Promise<PageResponse<PlanItem>> => {
  return httpService.get<PageResponse<PlanItem>>(API_PLAN_ITEM_PATH);
}

const createPlanItem = (planItem: PlanItemFormData): Promise<PlanItem> => {
  return httpService.post<PlanItem>(API_PLAN_ITEM_PATH, planItem);
}

const updatePlanItem = (planItem: PlanItemFormData): Promise<PlanItem> => {
  return httpService.put<PlanItem>(`${API_PLAN_ITEM_PATH}/${planItem.id}`, planItem);
}

const deletePlanItem = (id: string): Promise<Response> => {
  return httpService.delete(`${API_PLAN_ITEM_PATH}/${id}`);
}

export const planItemService = {
  getPlanItems,
  createPlanItem,
  updatePlanItem,
  deletePlanItem,
}
