function formatPrice(price?: Number): string {
  if(price === undefined || price == null) return ""
  return price.toFixed(3)
}

function numberFormatField (value?: string): string {
  const numberFormat = value.replace(/\s+/g, "").replace(",", ".")
  return Number.parseFloat(numberFormat).toFixed(3)
}

function numberToPercent (value?: number): string {
  if (!value) return undefined;
  return `${value * 100} %`
}

export const numberUtils = Object.freeze({
  formatPrice,
  numberToPercent,
  numberFormatField
})
