import {
  RESOURCE_AVAILABILITY_OPTIONS,
  RESOURCE_LEVEL_OPTIONS,
  RESOURCE_STATUS_OPTIONS,
} from "constants/OptionConstants";
import {
  EditResourceProfessionalSituationSchema,
  EditSelfResourceProfessionalSituationSchema
} from "constants/validation/ResourceValidationSchemas";
import {Formik} from "formik";
import {
  FileData,
  ResourceProfessionalSituation,
  ResourceProfessionalSituationFields,
} from "interfaces/ResourceInterfaces";
import React, {FunctionComponent, useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import FormRadio from "../../input/FormRadio";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";
import FormInput from "../../input/FormInput";
import {PROFILE} from "interfaces/UserInterfaces";
import {authUtils} from "utils/authUtils";
import FormSelect from "../../input/FormSelect";
import {structureService} from "services/StructureService";
import {Structure} from "interfaces/StructureInterface";
import {toastUtils} from "utils/toastUtils";
import {useIntl} from "react-intl";
import Button from "components/atoms/Button";
import {ColorType} from "types/bootstrap/BootstrapType";
import Panel from "../../panel/Panel";
import CreateStructureForm from "../CreateStructureForm";
import {resourceService} from "services/ResourceService";
import {resourceUtils} from "../../../../utils/resourceUtils";
import {dateUtils} from "../../../../utils/dateUtils";
import FormFileUpload from "../../input/FormFileUpload";
import {fileUtils} from "../../../../utils/fileUtils";
import {FileType} from "../../../../constants/FileConstants";
import EpowForm from "../EpowForm";

interface EditResourceProfessionalSituationFormProps {
  id?: string;
  className?: string;
  self?: boolean,
  initialResourceProfessionalSituation?: ResourceProfessionalSituation;
  onChangeResourceAfterUpdate?: (
    resourceProfessionalSituation: ResourceProfessionalSituation
  ) => void;
  setEditInfo?: (editInfo: boolean) => void;
}

const EditResourceProfessionalSituationForm: FunctionComponent<
  EditResourceProfessionalSituationFormProps
> = ({
  className = "",
  id,
  initialResourceProfessionalSituation,
  onChangeResourceAfterUpdate,
  setEditInfo,
  self = false
}) => {
  const intl = useIntl();

  const [structureOptions, setStructureOptions] = useState<Option<string>[]>();
  const [createdStructureOption, setCreatedStructureOption] = useState<Option<string>>(null);
  const [lateralPanelStructure, setLateralPanelStructure] = useState<boolean>(false);

  const initialProfessionalSituationFields: ResourceProfessionalSituationFields =
    resourceUtils.buildResourceProfessionalSituationFields(
      initialResourceProfessionalSituation
    );

  const getStructures = () => {
    structureService
      .getStructures()
      .then((structures: Structure[]) => {
        const structureOptions: Option<string>[] = structures.map((structure) => ({
          value: structure.id,
          label: structure.name,
        }));
        setStructureOptions(structureOptions);
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage({ id: "error_get_structures" })
        );
      });
  };

  const onCreateStructure = (structure: Structure) => {
    setCreatedStructureOption({value: structure.id, label: structure.name});
    getStructures();
    setLateralPanelStructure(false);
  }

  const getPromisesToResolve = (
    submittedResourceProfessionalSituationFields: ResourceProfessionalSituationFields
  ) => {

    const resourceProfessionalSituationPromise = [];

    self ?
      resourceProfessionalSituationPromise.push(resourceService.updateSelfResourceProfessionalSituation(submittedResourceProfessionalSituationFields)) :
      resourceProfessionalSituationPromise.push(resourceService.updateResourceProfessionalSituation(
        initialResourceProfessionalSituation.id,
        submittedResourceProfessionalSituationFields
      ));

    if(submittedResourceProfessionalSituationFields.resourceCardFile) {
        self ? resourceProfessionalSituationPromise.push(resourceService.uploadSelfCard(submittedResourceProfessionalSituationFields.resourceCardFile,
          FileType.RESOURCE_CARD
        )) :
          resourceProfessionalSituationPromise.push(resourceService.uploadCard(
          initialResourceProfessionalSituation.id,
          submittedResourceProfessionalSituationFields.resourceCardFile,
          FileType.RESOURCE_CARD
        ))
    }
    return resourceProfessionalSituationPromise;
  };

  const handleSubmitResource = (
    submittedResourceProfessionalSituationFields: ResourceProfessionalSituationFields
  ) => {
    const promisesToResolve: Promise<
      ResourceProfessionalSituation | FileData
    >[] = getPromisesToResolve(submittedResourceProfessionalSituationFields);

    Promise.all(promisesToResolve)
      .then(([resourceProfessionalSituationUpdated, uploadedFile]) => {
        onChangeResourceAfterUpdate({
          ...resourceProfessionalSituationUpdated,
          resourceCard: uploadedFile ?? initialResourceProfessionalSituation.resourceCard,
        });
        setEditInfo(false);

        toastUtils.successToast(
          intl.formatMessage({ id: "success_update_resource" })
        );
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage({ id: "error_update_resource" })
        );
      });
  };

  useEffect(() => {
    getStructures();
  }, []);

  const getStructureOptionValue = (values: ResourceProfessionalSituationFields): Option<string> => {
    return createdStructureOption ?? structureOptions?.find(
        (option) => option?.value === values?.structureId
      )
  }

  return (
    <>
      <Formik
        initialValues={initialProfessionalSituationFields}
        validationSchema={self ? EditSelfResourceProfessionalSituationSchema : EditResourceProfessionalSituationSchema}
        onSubmit={(value) => handleSubmitResource(value)}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <EpowForm id={id} className={className}>
            <Row>
              {authUtils.getProfile() !== PROFILE.RESOURCE && (
              <Col>
                <FormRadio
                  id="status"
                  label="resource_status_field"
                  value={RESOURCE_STATUS_OPTIONS.find(
                    (option) => option.value === values.statusResource
                  )}
                  options={RESOURCE_STATUS_OPTIONS}
                  onChange={(option?: Option<string>) =>
                    setFieldValue("statusResource", option?.value)
                  }
                  required
                  error={errors.statusResource}
                  touched={touched.statusResource}
                />
                <FormRadio
                  id="level"
                  label="resource_level_field"
                  value={RESOURCE_LEVEL_OPTIONS.find(
                    (option) => option.value === values.level
                  )}
                  options={RESOURCE_LEVEL_OPTIONS}
                  onChange={(option?: Option<string>) =>
                    setFieldValue("level", option?.value)
                  }
                  required
                  error={errors.level}
                  touched={touched.level}
                />
                <FormInput
                  id="dateFirstMission"
                  label="resource_date_first_mission_field"
                  type="date"
                  required
                  value={values?.dateFirstMission ? dateUtils.formatDateYYYYMMDD(new Date(values?.dateFirstMission)) : null}
                  onChange={(e) => {
                    setFieldValue("dateFirstMission", e.target?.value)
                  }
                  }
                  error={errors.dateFirstMission}
                  touched={touched.dateFirstMission}
                />
              </Col>
              )}
              <Col>
                {authUtils.getProfile() !== PROFILE.RESOURCE && (
                  <FormRadio
                    id="availability"
                    label="resource_availability_field"
                    value={RESOURCE_AVAILABILITY_OPTIONS.find(
                      (option) => option.value === values.availability
                    )}
                    options={RESOURCE_AVAILABILITY_OPTIONS}
                    onChange={(option?: Option<string>) =>
                      setFieldValue("availability", option?.value)
                    }
                    required
                    error={errors.availability}
                    touched={touched.availability}
                  />
                )}
                <FormFileUpload
                  id="resourceCard"
                  label="resource_card_field"
                  fileName={fileUtils.getCompleteFileName(initialResourceProfessionalSituation?.resourceCard)}
                  accept={[".jpg", ".jpeg", ".pdf"]}
                  onChange={(file: File) =>
                    setFieldValue("resourceCardFile", file)
                  }
                  error={errors?.resourceCardFile}
                  touched={touched?.resourceCardFile}
                />
                {authUtils.getProfile() !== PROFILE.RESOURCE &&
                  <>
                    <FormSelect
                      id="structureId"
                      label="resource_structure_field"
                      value={getStructureOptionValue(values)}
                      onChange={(option?: Option<string>) => {
                        setFieldValue("structureId", option?.value);
                        setCreatedStructureOption(null);
                      }}
                      options={structureOptions}
                      isSearchable
                      isClearable
                      error={errors?.structureId}
                      touched={touched?.structureId}
                    />
                    <Button
                      className="mb-2"
                      color={ColorType.SECONDARY}
                      onClick={() => setLateralPanelStructure(true)}
                    >
                      {intl.formatMessage({ id: "create_new_structure" })}
                    </Button>
                </>
                }
              </Col>
            </Row>
          </EpowForm>
        )}
      </Formik>

      <div className={`${lateralPanelStructure ? "backdrop" : "d-none"}`}></div>
      {lateralPanelStructure && (
        <Panel
          formId="createStructureForm"
          title="create_new_structure"
          open={lateralPanelStructure}
          onCancel={() => setLateralPanelStructure(false)}
        >
          <CreateStructureForm
            id="createStructureForm"
            onCreateStructure={onCreateStructure}
          />
        </Panel>
      )}
    </>
  );
};

export default EditResourceProfessionalSituationForm;
