import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {toastUtils} from "../../../utils/toastUtils";
import GenericModal from "./GenericModal";
import {creditNoteService} from "../../../services/CreditNoteService";
import {CREDIT_NOTES_PATH} from "../../../constants/routes/RoutePaths";
import {useNavigate} from "react-router-dom";
import {Invoice} from "../../../interfaces/InvoiceInterfaces";
import TableCheckbox from "../../atoms/TableCheckbox";

interface ModalCreditNoteConfirmProps {
  className?: string;
  invoice: Invoice;
  invoiceNumber?: string;
  open: boolean;
  onOpen: (open: boolean) => void,
  onClose: () => void;
}

const ModalCreditNoteConfirm: FunctionComponent<
  ModalCreditNoteConfirmProps
> = ({
  className = "",
  invoiceNumber,
  invoice,
  open,
  onClose,
  onOpen
}) => {
    const intl = useIntl();
    const navigate = useNavigate()

    const getAllBillingItemsIds = (): string[] => {
      return invoice.invoiceOrderDetails?.flatMap(o => o.billingItemDetails?.map(bi => bi.id)) ?? []
    }

    const getBillingSubItemsIds = (): string[] => {
      return invoice.invoiceOrderDetails?.flatMap(o => o.billingItemDetails?.flatMap(bi => bi.billingSubItemDetails?.map(subItem => subItem.id))) ?? []
    }

  const getAllIds = (): string[] => {
    return [...getAllBillingItemsIds(), ...getBillingSubItemsIds()];
  }

    const [billingItemsIds, setBillingItemsIds] = React.useState<string[]>(getAllIds)

    const toggleSelectedBillingItem = (itemId: string) => {
      const hasItem = billingItemsIds.some(bi => bi === itemId);
      if (hasItem) {
        const filtered = [...billingItemsIds].filter(bi => bi !== itemId)
        setBillingItemsIds(filtered)
      } else {
        setBillingItemsIds([...billingItemsIds, itemId])
      }
    }

    const toggleSelectedAll = () => {
      if(billingItemsIds.length === getAllIds().length) {
        setBillingItemsIds([]);
      } else {
        setBillingItemsIds(getAllIds());
      }
    }

    const generateCreditNote = () => {
      creditNoteService.createCreditNote(invoice.id, billingItemsIds).then((response) => {
        navigate(`${CREDIT_NOTES_PATH}/${response.id}`);
        toastUtils.successToast(intl.formatMessage({id: "success_toast_create_credit_note"}))

      }).catch(() => {
        toastUtils.errorToast(
          intl.formatMessage({id: "error_toast_create_credit_note"})
        );
      })
    };

    return (
      <GenericModal
        className={className}
        title={intl.formatMessage({
          id: "modal_title_credit_note",
        })}
        open={open}
        onOpen={onOpen}
        closable
        footer={{
          primaryAction: {
            buttonLabel: intl.formatMessage({id: "validate_button"}),
            action: generateCreditNote,
          },
          secondaryAction: {
            buttonLabel: intl.formatMessage({id: "cancel_button"}),
            action: onClose,
          },
        }}
      >
        <div>

          <div className="mb-2">
            {intl.formatMessage(
              {id: "modal_body_credit_note"}, {invoiceNumber}
            )}
          </div>

          <div className="mb-1">
            {intl.formatMessage(
              {id: "modal_body_credit_note_billing_items"}, {invoiceNumber}
            )}
          </div>

          {getAllIds().length > 0 && <div className="d-flex gap-2 mb-2">
            <TableCheckbox
              onChange={() => toggleSelectedAll()}
              checked={billingItemsIds.length === getAllIds().length}
            />
            <div>
              {
                intl.formatMessage({id: billingItemsIds.length === getAllIds().length ? "modal_body_credit_note_unselect_all" : "modal_body_credit_note_select_all"})
              }
            </div>
          </div>}

          {invoice.invoiceOrderDetails.map(o => (
            o.billingItemDetails.map(billingItem => (
              <>
                <div key={billingItem.id} className="d-flex gap-2">
                  <TableCheckbox
                    checked={billingItemsIds.some(bi => bi === billingItem.id)}
                    onChange={() => toggleSelectedBillingItem(billingItem.id)}
                  />
                  <div>
                    {o.refCustomer} - {billingItem.name}
                  </div>
                </div>

                {billingItem.billingSubItemDetails.map(subItem => (
                  <div key={subItem.id} className="d-flex gap-2">
                    <TableCheckbox
                      checked={billingItemsIds.some(bi => bi === subItem.id)}
                      onChange={() => toggleSelectedBillingItem(subItem.id)}
                    />
                    <div>
                      {o.refCustomer} - {subItem.name}
                    </div>
                  </div>
                ))}

              </>
            ))

          ))}

        </div>
      </GenericModal>
    );
};

export default ModalCreditNoteConfirm;
