import {API_ACTIVITIES_BILLING_PATH} from "../constants/routes/RoutePaths";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {queryUtils} from "../utils/queryUtils";
import {httpService} from "./HttpService";
import {
  ActivityBillingPaymentPageData,
  ActivityBillingPaymentSearchObject
} from "../interfaces/ActivityBillingPaymentInterface";

function getActivityBillingPaymentPage(params: PaginationQueryParams, filterParams: ActivityBillingPaymentSearchObject): Promise<PageResponse<ActivityBillingPaymentPageData>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get(`${API_ACTIVITIES_BILLING_PATH}/payments${queryParams}`)
}

function getActivityBillingPaymentFile(fileId: string): Promise<Blob> {
  return httpService.getReadableStream(`${API_ACTIVITIES_BILLING_PATH}/payments/file/${fileId}`)
}

function getActivityBillingPaymentInvoices(paymentId: string): Promise<Blob> {
  return httpService.getReadableStream(`${API_ACTIVITIES_BILLING_PATH}/payments/${paymentId}/invoices/`)
}


export const activityBillingPaymentService = Object.freeze({
  getActivityBillingPaymentPage,
  getActivityBillingPaymentFile,
  getActivityBillingPaymentInvoices
});
