import {TabPaneItem} from "../../interfaces/TabInterfaces";
import React from "react";
import DetailsCustomerCard from "../../components/molecules/card/customer/DetailsCustomerCard";
import {Customer} from "../../interfaces/CustomerInterfaces";
import SettingInvoicingCustomerCard from "../../components/molecules/card/customer/SettingInvoicingCustomerCard";
import {Tour} from "../../interfaces/TourInterfaces";
import DetailsTourCard from "../../components/molecules/card/tour/DetailsTourCard";
import TourActivityCard from "../../components/molecules/card/tour/TourActivityCard";
import TourActivityCustomerCard from "../../components/molecules/card/tour/TourActivityCustomerCard";
import {Supplier} from "../../interfaces/SupplierInterfaces";
import DetailsSupplierCard from "../../components/molecules/card/supplier/DetailsSupplierCard";
import {Service} from "../../interfaces/ServiceInterfaces";
import DetailsServiceCard from "../../components/molecules/card/service/DetailsServiceCard";
import {Activity} from "../../interfaces/ActivityInterfaces";
import DetailsActivityCard from "../../components/molecules/card/activity/DetailsActivityCard";
import {TourLeader} from "../../interfaces/TourLeaderInterfaces";
import DetailsTourLeaderCard from "../../components/molecules/card/tourLeader/DetailsTourLeaderCard";
import {
  Resource,
  ResourceJobInfo,
  ResourceJobInfoResponse,
  ResourceLegalInfo,
  ResourceProfessionalSituation
} from "interfaces/ResourceInterfaces";
import DetailsResourceCard from "components/molecules/card/resource/DetailsResourceCard";
import ActivityCustomerCard from "../../components/molecules/card/activity/ActivityCustomerCard";
import CustomerServiceCard from "../../components/molecules/card/service/CustomerServiceCard";
import ProfessionalSituationResourceContainer
  from "../../components/molecules/card/resource/ProfessionalSituationResourceContainer";
import SupplierBankAccountsCard from "../../components/molecules/card/supplier/SupplierBankAccountsCard";
import JobInfoResourceContainer from "../../components/molecules/card/resource/JobInfoResourceContainer";
import {StatusResource} from "../ResourceConstants";
import LegalInfoResourceContainer from "../../components/molecules/card/resource/LegalInfoResourceContainer";
import ResourceBankAccountContainer from "../../components/molecules/card/resource/ResourceBankAccountContainer";
import UserList from "../../components/molecules/list/UserList";
import RuleList from "../../components/molecules/list/RuleList";
import AdminConfigurationCard from "../../components/molecules/card/admin/AdminConfigurationCard";
import InternalProfileDocumentList from "../../components/molecules/list/InternalProfileDocumentList";
import HomeCommentList from "../../components/molecules/list/HomeCommentList";
import {Col, Row} from "reactstrap";
import {BankAccount} from "../../interfaces/BankAccountInterface";
import CustomerContactList from "../../components/molecules/list/contactList/CustomerContactList";
import SupplierContactList from "../../components/molecules/list/contactList/SupplierContactList";
import ActivityAreaList from "../../components/molecules/list/ActivityAreaList";
import SupplierServiceCard from "../../components/molecules/card/service/SupplierServiceCard";
import AdminExports from "../../components/molecules/content/AdminExports";
import LinkedActivities from "../../components/molecules/content/activity/LinkedActivities";
import {resourceService} from "../../services/ResourceService";
import {IntlShape} from "react-intl";
import ActivitiesToggleContainer from "../../components/molecules/card/resource/ActivitiesToggleContainer";
import ResourceTypeList from "components/molecules/list/ResourceTypeList";
import ActivityResourceRequirementList from "../../components/molecules/list/ActivityResourceRequirementList";
import BlackDateCard from "components/molecules/card/supplier/BlackDateCard";
import VATRateList from "../../components/molecules/list/VATRateList";
import AccountingConfigList from "../../components/molecules/list/AccountingConfigList";
import AdminConfigMail from "../../components/molecules/card/admin/AdminConfigMail";
import ProductTypeList from "../../components/molecules/list/ProductTypeList";
import PlanItemList from "../../components/molecules/list/PlanItemList";

export function customerTab(customer?: Customer, setCustomer?: (customer: Customer) => void): Array<TabPaneItem> {
  return [
    {
      label: "details_tab",
      component: (
        <DetailsCustomerCard customer={customer} setCustomer={setCustomer} />
      ),
    },
    {
      label: "setting_invoicing_tab",
      component: (
        <SettingInvoicingCustomerCard
          customer={customer}
          setCustomer={setCustomer}
        />
      ),
    },
    {
      label: "contact_tab",
      component: <CustomerContactList customer={customer} />,
    },
    {
      label: "documents_tab",
      component:
        <InternalProfileDocumentList
          defaultSearchObject={({customerId: customer.id})}
          customerId={customer.id}
        />,
    }
  ];
}

export function tourTab(tour?: Tour, getTour?: () => void): Array<TabPaneItem> {
  return [
    {
      label: "details_tab",
      component: <DetailsTourCard tour={tour} getTour={getTour} />,
    },
    {
      label: "activity_tab",
      component: <TourActivityCard tourId={tour.id} />,
    },
    {
      label: "season_for_catalog_tab_title",
      component: <TourActivityCustomerCard tourId={tour.id} />,
    },
  ];
}

export function supplierTab(supplier?: Supplier, setSupplier?: (supplier: Supplier) => void): Array<TabPaneItem> {
  return [
    {
      label: "details_tab",
      component: (
        <DetailsSupplierCard supplier={supplier} setSupplier={setSupplier} />
      ),
    },
    {
      label: "contact_tab",
      component: <SupplierContactList supplier={supplier} />,
    },
    {
      label: "bank_accounts_tab",
      component: <SupplierBankAccountsCard supplierId={supplier.id} />,
    },
    //Décision de cacher la feature le 10/11/2023
     {
       label: "black_date_tab",
       component: <BlackDateCard supplierId={supplier.id} />,
     },
  ];
}

export function serviceTab(service?: Service, setService?: (service: Service) => void): Array<TabPaneItem> {
  return [
    {
      label: "details_tab",
      component: (
        <DetailsServiceCard service={service} setService={setService} />
      ),
    },
    {
      label: "customer_catalog",
      component: <CustomerServiceCard serviceId={service?.id} />,
    },
    {
      label: "supplier_catalog",
      component: <SupplierServiceCard serviceId={service?.id} />,
    }
  ];
}

export function activityTab(activity?: Activity, setActivity?: (activity: Activity) => void): Array<TabPaneItem> {
  return [
    {
      label: "details_tab",
      component: (
        <DetailsActivityCard activity={activity} setActivity={setActivity} />
      ),
    },
    {
      label: "customer_catalog",
      component: <ActivityCustomerCard activity={activity} numberPaxMax={activity?.numberPaxMax} billingName={activity?.billingName || activity.name}/>,
    },
    {
      label: "documents_tab",
      component:
        <InternalProfileDocumentList
          defaultSearchObject={({activityId: activity.id})}
          activityId={activity.id}
          activityName={activity.name}
        />,
    },
    {
      label: "linked_activities_tab",
      component: <LinkedActivities activity={activity} />,
    },
    {
      label: "required_resources_tab",
      component: <ActivityResourceRequirementList activityId={activity.id} />,
    },
  ];
}

export function tourLeaderTab(tourLeader?: TourLeader, setTourLeader?: (tourLeader?: TourLeader) => void): Array<TabPaneItem> {
  return [
    {
      label: "details_tab",
      component: (
        <DetailsTourLeaderCard
          tourLeader={tourLeader}
          setTourLeader={setTourLeader}
        />
      ),
    },
  ];
}

export const resourceTab = (resource: Resource, setResource: (resource: Resource) => void, fetchBankAccount: () => Promise<BankAccount[]>, self: boolean, intl: IntlShape): TabPaneItem[] => {

  const setResourceAfterUpdate = (resourceProfessionalSituation: ResourceProfessionalSituation) => {
    setResource({ ...resource, ...resourceProfessionalSituation })
  }

  const setResourceAfterJobInfoUpdate = (jobInfoResponse: ResourceJobInfoResponse) => {
    const jobInfos: ResourceJobInfo = {
      ...jobInfoResponse,
      isNocturnal: jobInfoResponse.nocturnal
    }
    setResource({ ...resource, ...jobInfos })
  }

  const getResourceProfessionalSituation = (): ResourceProfessionalSituation => ({
    id: resource.id,
    statusResource: resource.statusResource,
    level: resource.level,
    dateFirstMission: resource.dateFirstMission,
    availability: resource.availability,
    resourceCard: resource.resourceCard,
    structure: resource.structure,
  });

  const getResourceJobInfo = (): ResourceJobInfo => ({
    id: resource.id,
    activityAreas: resource.activityAreas,
    spokenLanguages: resource.spokenLanguages,
    publicTypes: resource.publicTypes,
    isNocturnal: resource.isNocturnal ?? false,
  });

  const getResourceLegalInfo = (): ResourceLegalInfo => ({
    id: resource.id,
    liabilityCard: resource.liabilityCard,
    rcpCoverStartDate: resource.rcpCoverStartDate,
    rcpValidityDate: resource.rcpValidityDate,
    urssafCard: resource.urssafCard,
    urssafSituationDate: resource.urssafSituationDate,
    lastTurnover: resource.lastTurnover
  })

  return [
    {
      label: "details_tab",
      component: (
        <DetailsResourceCard
          self={self}
          resource={resource}
          setResource={setResource} />
      )
    },
    {
      label: "professional_info_tab",
      component: (
        <Row>
          <Col xs={24} md={14} className="mb-4 mb-md-0">
            <ProfessionalSituationResourceContainer
              self={self}
              resourceProfessionalSituation={getResourceProfessionalSituation()}
              setResourceAfterUpdate={setResourceAfterUpdate} />
          </Col>
          <Col xs={24} md={10} className="mb-4 mb-md-0">
            <JobInfoResourceContainer
              self={self}
              resourceJobInfo={getResourceJobInfo()}
              setResourceAfterUpdate={setResourceAfterJobInfoUpdate}
            />
          </Col>
        </Row>
      )
    },
    {
      label: "legal_info_tab",
      component: (
        <LegalInfoResourceContainer
          self={self}
          resourceLegalInfo={getResourceLegalInfo()}
          setResourceAfterUpdate={setResourceAfterUpdate}
        />
      ),
      className: resource.statusResource !== StatusResource.INDEPENDANT ? "no-display" : ""
    },
    {
      label: "bank_accounts_tab",
      component: (
        <ResourceBankAccountContainer
          resourceId={resource.id}
          fetchBankAccounts={fetchBankAccount}/>
      )
    },
    {
      label: "activities_tab",
      component: (
        <ActivitiesToggleContainer
          title={intl.formatMessage({id: "resource_activities_handling"})}
          suppliersLabel={intl.formatMessage({id: "resource_activities_to_add"})}
          suppliersSubLabel={intl.formatMessage({id: "resource_activities_to_add_sublabel"})}
          elementsLabel={intl.formatMessage({id: "resource_activities_known"})}
          elementsSubLabel={intl.formatMessage({id: "resource_activities_known_sublabel"})}
          elementsMobileLabelWithCount={count => intl.formatMessage({id: "resource_activities_known_mobile_label"}, {count: count})}
          areaIds={resource.activityAreas?.map(area => area.id)}
          showOnlyOperationalActivities
          resourceId={resource.id}
          resourceTypeId={resource.resourceType?.id}
          onGet={() =>  resourceService.getResourceActivities(resource.id)}
          onUpdate={(ids) => resourceService.updateLinkedActivities(resource.id, ids)}
          onDelete={(ids) => resourceService.deleteLinkedActivities(resource.id, ids)}
        />
      )
    }
  ]
}

export const adminTabRef: TabPaneItem[] = [
    {
      label: "user_title",
      component: <UserList />
    },
    {
      label: "rules_tab",
      component: <RuleList />
    },
    {
      label: "documentary_base_title",
      component: <InternalProfileDocumentList />
    },
    {
      label: "home_comment_config_tab",
      component: <HomeCommentList />
    },
    {
      label: "activity_area_tab_title",
      component: <ActivityAreaList />
    },
    {
      label: "resource_type_tab_title",
      component: <ResourceTypeList />
    },
    {
      label: "vat_tab_title",
      component: <VATRateList />
    },
    {
      label: "product_type_tab_title",
      component: <ProductTypeList/>
    }
]

export const adminTabParams: TabPaneItem[] = [
  {
    label: "config_tab",
    component: <AdminConfigurationCard />
  },
  {
    label: "config_tab_mail",
    component: <AdminConfigMail />
  },
  {
    label: "accounting_config_tab_title",
    component: <AccountingConfigList />
  },
  {
    label: "pennylane_accounting_config_tab_title",
    component: <PlanItemList />
  }
]

export const adminTabExports: TabPaneItem[] = [
  {
    label: "exports_tab_title",
    component: <AdminExports />
  },
]
