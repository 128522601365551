import {Resource} from "../../../../interfaces/ResourceInterfaces";
import React, {FunctionComponent, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {resourceService} from "../../../../services/ResourceService";
import {RESOURCES_PATH} from "../../../../constants/routes/RoutePaths";
import {toastUtils} from "../../../../utils/toastUtils";
import {Formik} from "formik";
import {RESOURCE_INITIAL_VALUES} from "../../../../constants/ResourceConstants";
import {CreateResourceSchema} from "../../../../constants/validation/ResourceValidationSchemas";
import FormInput from "../../input/FormInput";
import {useIntl} from "react-intl";
import EpowForm from "../EpowForm";
import {LoadingComponent} from "../../../../constants/menu/Layout";
import FormRadio from "../../input/FormRadio";
import {
  RESOURCE_AVAILABILITY_OPTIONS,
  RESOURCE_LEVEL_OPTIONS,
  RESOURCE_STATUS_OPTIONS
} from "../../../../constants/OptionConstants";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";
import {dateUtils} from "../../../../utils/dateUtils";
import {resourceTypeService} from "../../../../services/ResourceTypeService";
import FormSelect from "../../input/FormSelect";

interface CreateResourceFormProps  extends LoadingComponent {
  id?: string;
  className?: string;
  initialResource?: Resource;
}

const CreateResourceForm: FunctionComponent<CreateResourceFormProps> = ({
  id,
  className = "",
  initialResource,
  stopLoading,
  startLoading,
  closeForm = () => null,
}) => {
  const intl = useIntl();
  const navigate = useNavigate()
  const resourceId = initialResource?.id

  const [resourceTypes, setResourceTypes] = useState<Option<string>[]>([])

  useMemo(() => {
    resourceTypeService.getResourceTypes()
      .then((resourceTypes) => {
        setResourceTypes(resourceTypes.map((type) => { return { value: type.id, label: type.label }}))
      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_fetch_resource_types"}));
      })}, []
  )

  const handleSubmitResource = (resourceFields: Resource) => {
    if (!resourceId) {
      startLoading?.()
      resourceService.createResource(resourceFields)
        .then((resource) => {
          toastUtils.successToast(intl.formatMessage({id: "success_create_resource"}))
          navigate(`${RESOURCES_PATH}/${resource?.id}`, {replace: true, state: {}})
        })
        .catch((error) => toastUtils.errorToast(error.message ? intl.formatMessage({id: error.message}) : intl.formatMessage({id: "error_create_resource"})))
        .finally(() => {
          stopLoading?.()
          closeForm()
        })
    }
  }

  return (
    <Formik initialValues={initialResource || RESOURCE_INITIAL_VALUES} validationSchema={CreateResourceSchema} onSubmit={values => {handleSubmitResource(values)}}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className} >
          <FormSelect
            id="resourceType"
            label="resource_type"
            value={resourceTypes.find((option) => option?.value === values?.resourceType?.id)}
            onChange={(option?: Option<string>) => setFieldValue("resourceType.id", option?.value)}
            options={resourceTypes}
            required
            isClearable
            error={errors.resourceType?.id}
            touched={touched.resourceType?.id}
          />
          <FormInput
            id="lastName"
            label="resource_last_name_field"
            value={values?.identity?.lastName}
            onChange={(e) => setFieldValue("identity.lastName", e.target.value)}
            required
            error={errors.identity?.lastName}
            touched={touched.identity?.lastName}
          />
          <FormInput
            id="firstName"
            label="resource_first_name_field"
            value={values?.identity?.firstName}
            onChange={(e) => setFieldValue("identity.firstName", e.target.value)}
            required
            error={errors.identity?.firstName}
            touched={touched.identity?.firstName}
          />
          <FormInput
            id="email"
            label="resource_email_field"
            value={values?.identity?.email}
            onChange={(e) => setFieldValue("identity.email", e.target.value)}
            required
            error={errors.identity?.email}
            touched={touched.identity?.email}
          />
          <FormRadio
            id="availability"
            label="resource_availability_field"
            value={RESOURCE_AVAILABILITY_OPTIONS.find(
              (option) => option.value === values.availability
            )}
            options={RESOURCE_AVAILABILITY_OPTIONS}
            onChange={(option?: Option<string>) =>
              setFieldValue("availability", option?.value)
            }
            required
            error={errors.availability}
            touched={touched.availability}
          />
          <FormRadio
            id="status"
            label="resource_status_field"
            value={RESOURCE_STATUS_OPTIONS.find(
              (option) => option.value === values.statusResource
            )}
            options={RESOURCE_STATUS_OPTIONS}
            onChange={(option?: Option<string>) =>
              setFieldValue("statusResource", option?.value)
            }
            required
            error={errors.statusResource}
            touched={touched.statusResource}
          />
          <FormRadio
            id="level"
            label="resource_level_field"
            value={RESOURCE_LEVEL_OPTIONS.find(
              (option) => option.value === values.level
            )}
            options={RESOURCE_LEVEL_OPTIONS}
            onChange={(option?: Option<string>) =>
              setFieldValue("level", option?.value)
            }
            required
            error={errors.level}
            touched={touched.level}
          />
          <FormInput
            id="dateFirstMission"
            label="resource_date_first_mission_field"
            type="date"
            required
            value={values?.dateFirstMission ? dateUtils.formatDateYYYYMMDD(new Date(values?.dateFirstMission)) : null}
            onChange={(e) =>
              setFieldValue("dateFirstMission", e.target?.value)
            }
            error={errors.dateFirstMission}
            touched={touched.dateFirstMission}
          />
        </EpowForm>
      )}
    </Formik>
  )

}
export default CreateResourceForm
