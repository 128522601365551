import React, {FunctionComponent} from "react";
import {RouteProps} from "react-router-dom";
import Layout from "../components/layout/Layout";
import PageTitle from "../components/molecules/header/PageTitle";
import {useIntl} from "react-intl";
import ActivityBillingPaymentList from "../components/molecules/list/ActivityBillingPaymentList";

const ActivitiesBillingPaymentListView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()


  return (
    <Layout>
      <div className="epow-content-body">
        <div className="d-flex mb-5">
          <PageTitle pageTitle={intl.formatMessage({ id: "payment_title" })}></PageTitle>
        </div>
        <ActivityBillingPaymentList/>
      </div>
    </Layout>
  )
}
export default ActivitiesBillingPaymentListView;
