import * as Yup from "yup";
import {yupRequiredPositiveNumber, yupRequiredString} from "../yup/Validators";

export const ActivityBillingValidationSchema = Yup.object().shape({
  date: yupRequiredString,
  number: yupRequiredString,
  amount: yupRequiredPositiveNumber,
  amountHT: yupRequiredPositiveNumber,
  file: yupRequiredString,
})
