import React, {FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";
import {Link, useNavigate} from "react-router-dom";
import {Col} from "reactstrap";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import Badge from "../../../atoms/Badge";
import Icon from "../../../icon/Icon";
import {
  CreditNoteStateMap,
  Invoice,
  InvoiceItemResponse,
  InvoiceState,
  InvoiceStateMap,
  InvoiceType
} from "../../../../interfaces/InvoiceInterfaces";
import {
  CREDIT_NOTES_PATH,
  CUSTOMERS_PATH,
  INVOICE_PAYMENTS_PATH,
  INVOICES_PATH,
} from "../../../../constants/routes/RoutePaths";
import {fileUtils} from "../../../../utils/fileUtils";
import {invoiceService} from "../../../../services/InvoiceService";
import {dateUtils} from "../../../../utils/dateUtils";
import {moneyUtils} from "../../../../utils/moneyUtils";
import TextLink from "../../../atoms/TextLink";
import {creditNoteService} from "../../../../services/CreditNoteService";
import ModalRemindInvoiceCustomer from "../../modal/ModalRemindInvoiceCustomer";
import {RemindInvoiceCustomerMailBody} from "../../../../interfaces/CustomerInterfaces";
import {customerService} from "../../../../services/CustomerService";
import {toastUtils} from "../../../../utils/toastUtils";
import Tooltip from "../../../atoms/Tooltip";
import {profileUtils} from "../../../../utils/profileUtils";

interface OrderTableRowProps {
  className?: string,
  invoice: InvoiceItemResponse,
  onRowClick?: (orderId: string) => void,
}

const BillingTableRow: FunctionComponent<OrderTableRowProps> = ({
  className = "",
  invoice,
  onRowClick = () => null,
}) => {
  const navigate = useNavigate();
  const intl = useIntl()
  const [openRemindInvoiceCustomerModal, setOpenRemindInvoiceCustomerModal] = useState<boolean>(false);
  const [invoiceDetails, setInvoiceDetails] = useState<Invoice | null>(null);

  const onClickDownload = () => fileUtils.downloadFile(invoice.type === InvoiceType.INVOICE ? invoiceService.downloadInvoices(invoice.id) : creditNoteService.downloadCreditNote(invoice.id), {
    name: invoice.invoiceNumber,
    extension: "pdf"
  }, intl);

  const handleSendMail = (request: RemindInvoiceCustomerMailBody) => {
    setOpenRemindInvoiceCustomerModal(false)
    customerService.remindInvoiceCustomerMail(invoiceDetails.billingCustomer.customerId, {template: request.template, invoiceId: request.invoiceId, reminderPdf: request.reminderPdf})
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "invoice_mail_remind_customer_toast_success"}))
      })
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "invoice_mail_remind_customer_toast_error"})));

  }

  const onClickMailIcon = () => {
    invoiceService.getInvoice(invoice.id)
      .then((invoice) => {
        setInvoiceDetails(invoice)
        setOpenRemindInvoiceCustomerModal(true)
      })
      .catch((err) => toastUtils.errorToast(err))
  }

  const onClickReceiptIcon = (invoiceId: string) => {
    navigate(`${INVOICE_PAYMENTS_PATH}?invoiceId=${invoiceId}`)
  }

  return (
    <div onClick={() => onRowClick(invoice.id)} className={`epow-custom-table-row-main ${className ?? ""}`}>
      <Col className="col-3">
        {invoice?.invoiceNumber}
      </Col>

      <Col className="col-3">
        <TextLink
          disable={profileUtils.isAccountant()}
          noUnderline={profileUtils.isAccountant()}
          to={`${CUSTOMERS_PATH}/${invoice.billingCustomerId}`}>
          {invoice?.billingCustomerName}
        </TextLink>
      </Col>

      <Col className="col-3">
        { invoice.customerOrderName  ?
          <TextLink
            disable={profileUtils.isAccountant()}
            noUnderline={profileUtils.isAccountant()}
            to={`${CUSTOMERS_PATH}/${invoice.customerOrderId}`}>
          {invoice.customerOrderName}
        </TextLink> : "-"
        }
      </Col>

      <Col className="col-3">
        {invoice?.firstOrderItemDate && invoice?.lastOrderItemDate ? `${dateUtils.formatDateDayJs(invoice?.firstOrderItemDate)} - ${dateUtils.formatDateDayJs(invoice?.lastOrderItemDate)}` : "-"}
      </Col>

      <Col className="col-3">
        {dateUtils.formatDateDayJs(invoice?.creationDate)}
      </Col>

      <Col className="col-2">
        {moneyUtils.formatNumberToCurrency(invoice?.amountHT)}
      </Col>

      <Col className="col-2">
        {moneyUtils.formatNumberToCurrency(invoice?.amountTTC)}
      </Col>

      <Col className="col-3">
        <Badge pill color={invoice.type === InvoiceType.INVOICE ? InvoiceStateMap[invoice.status]?.color : CreditNoteStateMap[invoice.status]?.color }>
          {intl.formatMessage({id: invoice.type === InvoiceType.INVOICE ? InvoiceStateMap[invoice.status]?.label : CreditNoteStateMap[invoice.status]?.label})}
        </Badge>
      </Col>

      <Col className="col-2" onClick={(event) => event.stopPropagation()}>
        <div className="float-end">
          <div className="d-flex flex-row">
            {!profileUtils.isAccountant() && <Tooltip text={intl.formatMessage({id: "invoice_details"})}>
              <Link className="table-action-link"
                    to={`${invoice.type === InvoiceType.INVOICE ? INVOICES_PATH : CREDIT_NOTES_PATH}/${invoice.id}`}>
                <Icon name="Search"
                      size={IconSizeType.XS}
                      className={"pe-2"}/>
              </Link>
            </Tooltip>}
            {invoice.type === InvoiceType.INVOICE && invoice.status === InvoiceState.LATE && !profileUtils.isAccountant() &&
              <Tooltip text={intl.formatMessage({id: "invoice_remind"})}>
                <div className="table-action-link cursor-pointer" onClick={onClickMailIcon}>
                  <Icon name="Email" size={IconSizeType.XS} className={"pe-2"}/>
                </div>
              </Tooltip>
            }
            <div className="table-action-link cursor-pointer"
              onClick={onClickDownload}
            >
              <Tooltip text={intl.formatMessage({id: "invoice_download_pdf"})}>
                <Icon name="Download" size={IconSizeType.XS} className={"pe-2"} />
              </Tooltip>
            </div>
            {invoice.hasPayment &&
              <Tooltip text={intl.formatMessage({id: "invoice_payments"})}>
                <div className="table-action-link cursor-pointer" onClick={() => onClickReceiptIcon(invoice.id)}>
                  <Icon name="Receipt" size={IconSizeType.XS} className={"pe-2"}/>
                </div>
              </Tooltip>
            }
          </div>
        </div>
      </Col>

      {openRemindInvoiceCustomerModal && <ModalRemindInvoiceCustomer
        onSubmit={(content) => handleSendMail(content)}
        open={openRemindInvoiceCustomerModal}
        onOpen={(open) => setOpenRemindInvoiceCustomerModal(open)}
        onClose={() => setOpenRemindInvoiceCustomerModal(false)}
        invoice={invoiceDetails}
      />}
    </div>
  )
}

export default BillingTableRow;
