import React, {ChangeEvent, DragEvent, FunctionComponent, useState} from "react";
import {
  BookingDocumentData,
  BookingDocumentFormCategory,
  BookingDocumentFormData,
  BookingStatus
} from "../../../../interfaces/BookingInterfaces";
import {Card, CardBody, CardHeader, Input, Label} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import Icon from "../../../icon/Icon";
import {ColorType, IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import FieldLabel from "../../../atoms/FieldLabel";
import FormInput from "../../input/FormInput";
import {DocumentDTO} from "../../../../interfaces/FileInterfaces";
import {fileUtils} from "../../../../utils/fileUtils";
import {bookingService} from "../../../../services/BookingService";
import {FormikErrors, FormikTouched} from "formik";
import FileDropZone from "components/atoms/input/FileDropZone";
import { toastUtils } from "utils/toastUtils";
import { FileType } from "constants/FileConstants";
import {profileUtils} from "../../../../utils/profileUtils";

interface BookingDocumentFormCardProps {
  className?: string;
  category: BookingDocumentFormCategory
  bookingDocumentData: BookingDocumentData;
  errors: FormikErrors<BookingDocumentFormData>
  touched: FormikTouched<BookingDocumentFormData>
  values: BookingDocumentFormData
  bookingFileDetails?: Record<string, string>
  onChangeValue: (field: string, value: unknown) => void
  onDeleteDocument: (documentId: string) => void
  onChangedBookingFile?: () => void
  onChangedBookingDetails?: (fileId: string, invoiceNumber: string) => void
}

const BookingDocumentFormCard: FunctionComponent<BookingDocumentFormCardProps> = ({
  category,
  bookingDocumentData,
  className = "",
  errors,
  touched,
  values,
  bookingFileDetails,
  onChangeValue,
  onDeleteDocument,
  onChangedBookingFile,
  onChangedBookingDetails
}) => {

  const intl = useIntl();
  const [addFile, setAddFile] = useState<boolean>(false);

  const documents: DocumentDTO[] = bookingDocumentData[category?.idDocument];
  const downloadDocument = (fileId: string, name: string, extension: string) => {
    const filePromise: Promise<Blob> = bookingService.getDocumentById(fileId);
    fileUtils.downloadFile(filePromise, { name, extension }, intl);
  }

  const onChangeBookingFile = (file: FileList, fileType: FileType) => {
    bookingService.addFiles(bookingDocumentData?.id, file, {fileType})
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_add_booking_file"}));
        onChangedBookingFile();
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_booking_document"}))
      });
  };

  return (
    <Card className={`mt-2 form-document-card ${className}`}>
      <CardHeader className={`fw-bold ${values[category.idCheckbox] && "success"}`}>
        <div className="d-flex">
          <Input
            id={category.idCheckbox}
            className="epow-checkbox-input rounded-circle me-2 cursor-pointer"
            type="checkbox"
            checked={values[category.idCheckbox]}
            disabled={
              category.idCheckbox === "isBilled"
                ? false
                : ((bookingDocumentData?.status) === BookingStatus.DOCUMENT_RECEIVED
                  && ((bookingDocumentData?.isPaid && values[category.idCheckbox]) || category.disableWhenUnpaid))
            }
            onClick={() => onChangeValue(category.idCheckbox, !values[category.idCheckbox])}/>
          <FormattedMessage id={category.title}/>
        </div>
      </CardHeader>
      <CardBody>
        {(bookingDocumentData?.documentCount) > 0 &&
          <div className="mb-3">
            {documents.map(document => {
              const fullName = fileUtils.getCompleteFileName({name: document.name, extension: document.extension});
              return (
                <div key={document.fileId} className="d-flex justify-content-between align-items-center">
                  <div className="d-flex flex-column w-100 me-3">
                  <div>
                    <span className="epow-text-link cursor-pointer me-3" onClick={() => downloadDocument(document.fileId, document.name, document.extension)}>{ fullName }</span>
                    {category.displayFileType && <Label className="color-gray"><FormattedMessage id={document.fileType}/></Label>}
                  </div>
                  { category.hasFileDetails &&
                    <>
                      {!profileUtils.isAccountant() ?
                        <FormInput
                          id={document.fileId}
                          label={intl.formatMessage({id: "booking_pay_invoice_number"})}
                          type="text"
                          className="mb-4 d-flex flex-row align-items-baseline"
                          value={bookingFileDetails[document.fileId]}
                          onChange={(e) => onChangedBookingDetails(document.fileId, e.target?.value)}
                          error={errors[document.fileId]}
                          touched={touched[document.fileId]}
                          required
                        /> : <FieldLabel label={intl.formatMessage({id: "booking_pay_invoice_number"})} value={bookingFileDetails[document.fileId]}/>
                      }
                    </>
                  }
                  </div>
                  { bookingDocumentData.status !== BookingStatus.CLOSED &&
                    <Icon name="Trash" size={IconSizeType.XS} className="cursor-pointer m-0" onClick={() => onDeleteDocument(document.fileId)} />
                  }
                </div>
              )
            })}
          </div>
        }
        {category.hasDate && values[category.editDateLabel] &&
          <FormInput
          id={category.idDate}
          label={category.dateLabel}
          type="date"
          className="w-50"
          required={values[category.editDateLabel]}
          value={values[category.idDate]}
          onChange={(e) => onChangeValue(category.idDate, e.target?.value)}
          error={errors[category.idDate]}
          touched={touched[category.idDate]}
          />
        }
        {category.hasDate && !values[category.editDateLabel] &&
          <div className="d-flex justify-content-between">
            <FieldLabel label={intl.formatMessage({id: category.dateLabel})}
                        value={bookingDocumentData[category?.idDate]}/>
            {
              bookingDocumentData?.status !== BookingStatus.CLOSED &&
              <Icon name="Pen" size={IconSizeType.XS} className="cursor-pointer m-0"
                    onClick={() => onChangeValue(category.editDateLabel, true)}/>
            }
          </div>
        }
        <div className="mt-3">
          {addFile ?
            <div className="d-flex justify-content-between align-items-center">
              {category.fileType === FileType.CONFIRMATION ?
                <>
                  <FileDropZone
                    label={category.fileLabel}
                    handleInputChange={(e: ChangeEvent<HTMLInputElement>) => onChangeBookingFile(e.target.files, FileType.CONFIRMATION)}
                    handleDrop={(e: DragEvent<HTMLDivElement>) => onChangeBookingFile(e.dataTransfer.files, FileType.CONFIRMATION)}
                    id="fileZone-confirmation"
                    className="me-2"
                  />
                  <FileDropZone
                    label="booking_add_ticket_file"
                    handleInputChange={(e: ChangeEvent<HTMLInputElement>) => onChangeBookingFile(e.target.files, FileType.TICKET)}
                    handleDrop={(e: DragEvent<HTMLDivElement>) => onChangeBookingFile(e.dataTransfer.files, FileType.TICKET)}
                    id="fileZone-ticket"
                    className="me-2"
                  />
                  <FileDropZone
                    label="booking_add_other_file"
                    handleInputChange={(e: ChangeEvent<HTMLInputElement>) => onChangeBookingFile(e.target.files, FileType.OTHER)}
                    handleDrop={(e: DragEvent<HTMLDivElement>) => onChangeBookingFile(e.dataTransfer.files, FileType.OTHER)}
                    id="fileZone-other"
                    className="me-2"
                  />
                </>
                :
                <FileDropZone
                  label={category.fileLabel}
                  handleInputChange={(e: ChangeEvent<HTMLInputElement>) => onChangeBookingFile(e.target.files, category.fileType)}
                  handleDrop={(e: DragEvent<HTMLDivElement>) => onChangeBookingFile(e.dataTransfer.files, category.fileType)}
                  id={`fileZone-${category.idFile}`}
                />
              }
            </div> :
            <div className="d-flex justify-content-around">
              { bookingDocumentData?.status !== BookingStatus.CLOSED &&
                <Button
                  color={ColorType.SECONDARY}
                  className="border border-dark mx-1"
                  onClick={() => setAddFile(true)}>
                  <FormattedMessage id={category.addButtonLabel}/>
                  <Icon className="ms-2" name={category.addButtonIcon}/>
                </Button>
              }
              {
                (category.extraButtonLabel && bookingDocumentData?.status !== BookingStatus.CLOSED && category.shouldDisplayExtraButton(bookingDocumentData?.isPaid)) && (
                  <Button
                    color={ColorType.SECONDARY}
                    className="border border-dark mx-1"
                    onClick={() => category.extraButtonAction()}>
                    <FormattedMessage id={category.extraButtonLabel}/>
                    <Icon className="ms-2" name={category.extraButtonIcon}/>
                  </Button>
                )
              }
            </div>
          }
        </div>
      </CardBody>
    </Card>
  )
}

export default BookingDocumentFormCard;
