import {FormattedMessage} from "react-intl";
import React from "react";
import {Notification} from "../interfaces/NotificationInterfaces";
import {dateUtils} from "../utils/dateUtils";
import {BOOKINGS_PATH, ORDERS_PATH, PROFILE_PATH, STAFF_REQUEST_PATH} from "./routes/RoutePaths";


interface NotificationBuilder {
  notification?: Notification,
  count?: number,
}

export const NOTIFICATIONS = {
  BOOKING_PAYMENT_ALERT: {
    build: ({notification}: NotificationBuilder) => ({
      title: "notification_booking_payment_alert_title",
      content: (
        <FormattedMessage
          id="notification_booking_payment_alert"
          values={{
            name: <span className="fw-bold">{`${notification.content.name} - ${notification.content.codeOrReference}`}</span>,
            bookingDate: <span className="fw-bold">{dateUtils.formatDateDayJs(notification.content.bookingDate)}</span>
          }}
        />),
      url: `${notification.url}&displayPending=true`
    })
  },
  BOOKING_TO_DO_ALERT: {
    build: ({notification}: NotificationBuilder) => ({
      title: "notification_booking_todo_alert_title",
      content: (
        <FormattedMessage
          id="notification_booking_todo_alert"
          values={{
            name: <span className="fw-bold">{`${notification.content.name} - ${notification.content.codeOrReference}`}</span>,
            bookingDate: <span className="fw-bold">{dateUtils.formatDateDayJs(notification.content.bookingDate)}</span>
          }}
        />),
      url: `${notification.url}`
    })
  },
  ORDER_ITEM_BOOKINGS_TO_UPDATE: {
    build: ({notification}: NotificationBuilder) => ({
      title: "notification_order_item_bookings_to_update_title",
      content: (<FormattedMessage id="notification_order_item_bookings_to_update" values={{name: notification.content.codeOrReference}}
      />),
      url: `${BOOKINGS_PATH}?orderIds=${notification.content.orderId}&displayPending=true`
    })
  },
  ORDER_ITEM_BOOKINGS_TO_CANCEL: {
    build: ({notification}: NotificationBuilder) => ({
      title: "notification_order_item_bookings_to_cancel_alert_title",
      content: (
        <FormattedMessage
          id="notification_order_item_bookings_to_cancel_alert"
          values={{
            name: <span className="fw-bold">{notification.content.activityName}</span>,
            customerReference: <span className="fw-bold">{notification.content.codeOrReference}</span>}}
      />),
      url: `${BOOKINGS_PATH}/?orderIds=${notification.content.orderId}&displayPending=true`
    })
  },
  ORDER_ITEM_INCOMPLETE: {
    build: ({notification}: NotificationBuilder) => ({
      title: "notification_order_item_incomplete_alert_title",
      content: (
        <FormattedMessage
          id="notification_order_item_incomplete_alert"
          values={{
            name:<span className="fw-bold">{notification.content.activityName}</span>,
            rendezVousDate: <span className="fw-bold">{dateUtils.formatDateDayJs(notification.content.rendezVousDate)}</span>}}
      />),
      url: `${ORDERS_PATH}/${notification.content.orderId}`
    })
  },
  STAFFING_ASSIGNED: {
    build: ({count = 0}: NotificationBuilder) => ({
      title: "notification_staffing_assigned_title",
      url: STAFF_REQUEST_PATH,
      content: (<FormattedMessage id="notification_staffing_assigned" values={{count}}/>)
    })
  },
  ORDER_ITEM_NO_SUPPLIER_AVAILABLE: {
    build: ({notification}: NotificationBuilder) => ({
      title: "notification_order_item_no_supplier_title",
      url: `${ORDERS_PATH}/${notification.content.orderId}`,
      content: (<FormattedMessage id="notification_order_item_no_supplier" values={{name: notification.content.name ?? "n" }}/>)
    })
  },
  RESOURCE_NOT_VALIDATED: {
    build: () => ({
      title: "notification_profile_title",
      url: PROFILE_PATH,
      content: (<FormattedMessage id="notification_profile"/>),
      removeOnResolve: true
    })
  },
  ACTIVITY_BILLING_RESOURCE_NEW_COMMENT: {
    build: ({notification}: NotificationBuilder) => ({
      title: "notification_activity_billing_new_comment_title",
      url: `${notification.url}`,
      content: (<FormattedMessage id="notification_activity_billing_new_comment" values={{number: notification.content.name}}/>),
      removeOnResolve: true
    })
  },
  ACTIVITY_UPDATED: {
    build: ({ notification }: NotificationBuilder) => ({
      title: "notification_activity_updated_title",
      subtitle: (<FormattedMessage id="notification_activity_updated_subtitle" values={{date: dateUtils.formatDateDayJs(notification.dateAction)}}/>),
      buttonMessage: "dashboard_resolve_and_close",
      url: `${STAFF_REQUEST_PATH}?id=${notification.content.orderId}`,
      content: (<div className="d-flex justify-content-between">
        <FormattedMessage
          id="notification_activity_updated"
          values={{
            code: notification.content.codeOrReference,
            date: dateUtils.formatDateDayJs(notification.content.activityDate) ?? " - ",
            name: notification.content.name,
          }}
        />

        <div className="d-flex flex-row text-end me-4 flex-column">
          {notification.content.oldActivityHours && notification.content.newActivityHours && <span>
            <span className="fw-bold "><FormattedMessage id="notification_activity_schedule_changed"/></span> :
            <span>
              <span className="text-decoration-line-through mx-2">{notification.content.oldActivityHours}</span>
              {notification.content.newActivityHours}
            </span>
          </span>}

          {notification.content.oldActivityPlace && notification.content.newActivityPlace && <span>
            <span className="fw-bold "><FormattedMessage id="notification_activity_place_changed"/></span> :
            <span>
              <span className="text-decoration-line-through mx-2">{notification.content.oldActivityPlace}</span>
              {notification.content.newActivityPlace}
            </span>
          </span>}

          {notification.content.newActivityPax && notification.content.oldActivityPax && <span>
            <span className="fw-bold "><FormattedMessage id="notification_activity_pax_changed"/></span> :
            <span>
              <span className="text-decoration-line-through mx-2">{notification.content.oldActivityPax}</span>
              {notification.content.newActivityPax}
            </span>
          </span>}

          {notification.content.newActivityDescription && <span>
            <span className="fw-bold "><FormattedMessage id="notification_activity_description_changed"/></span>
          </span>}

          {notification.content.newDocumentName && <span>
            <span className="fw-bold"><FormattedMessage id="notification_activity_document_changed"/></span> :
            <a href={notification.content.newDocumentNameUrl}>{notification.content.newDocumentName}</a>
          </span>}
        </div>
      </div>),
      removeOnResolve: true
    })
  },
} as const

export type NotificationCode = keyof typeof NOTIFICATIONS

export type NotificationType = "warning" | "danger"
