import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {DropdownItem, Label} from "reactstrap";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {dateUtils} from "../../../utils/dateUtils";
import Icon from "../../icon/Icon";
import PageTitle from "./PageTitle";
import {INVOICE_PAYMENTS_PATH} from "../../../constants/routes/RoutePaths";
import ButtonGroup from "../button/ButtonGroup";
import {InvoicePayment} from "../../../interfaces/InvoicePaymentInterfaces";
import {fileUtils} from "../../../utils/fileUtils";
import {invoicePaymentService} from "../../../services/InvoicePaymentService";

export interface InvoicePaymentHeaderProps {
  className?: string,
  invoicePayment: InvoicePayment,
}

const InvoicePaymentHeader: FunctionComponent<InvoicePaymentHeaderProps> = ({
  className = "",
  invoicePayment,
}) => {
  const intl = useIntl()

  const onClickDownload = () => fileUtils.downloadFile(invoicePaymentService.downloadInvoicePayments(invoicePayment.id), {
    name: invoicePayment.referenceNumber,
    extension: "pdf"
  }, intl);

  return (
    <div className={className}>
      <div className="d-flex align-items-center">
        <PageTitle
          pageTitle={invoicePayment?.referenceNumber}
          className="me-3"
          breadcrumbItems={[{
            title: intl.formatMessage({ id: "invoice_payment_title" }),
            to: INVOICE_PAYMENTS_PATH
          }]}
        />

        <div className="d-flex ms-auto">
          <ButtonGroup title="Actions" className="me-2">
            <>
              <DropdownItem color={ColorType.SECONDARY} className="epow-button d-flex align-items-center" onClick={onClickDownload}>
                <Icon name="Download" className="ms-0 me-2" />
                <div>{intl.formatMessage({ id: "invoice_download" })}</div>
              </DropdownItem>
            </>
          </ButtonGroup>
        </div>
      </div>

      <div className="d-flex">
        <div className="me-2">
          <Label className="me-1"><FormattedMessage id="purchase_order_creation" /></Label>
          <span className="me-5">{dateUtils.formatDateDayJs(invoicePayment?.creationDate)}</span>
          <Label className="me-1"><FormattedMessage id="payment_table_creation_date" /></Label>
            {dateUtils.formatDateDayJs(invoicePayment?.paymentDate)}
        </div>
      </div>
    </div>
  )
}

export default InvoicePaymentHeader
