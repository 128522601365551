import React, {FunctionComponent, useMemo} from "react";
import {IntlProvider, useIntl} from "react-intl";
import GenericModal from "./GenericModal";
import {Formik} from "formik";
import RichTextInput from "../input/RichTextInput";
import EpowForm from "../form/EpowForm";
import {Invoice} from "../../../interfaces/InvoiceInterfaces";
import {dateUtils} from "../../../utils/dateUtils";
import {RemindInvoiceCustomerMailBody} from "../../../interfaces/CustomerInterfaces";
import {moneyUtils} from "../../../utils/moneyUtils";
import CheckboxFilter from "../filters/CheckboxFilter";
import Messages from "../../../constants/messages/Messages"
import {FRENCH_CUSTOMER} from "../../../constants/CustomerConstants";

interface ModalRemindInvoiceCustomerProps {
  className?: string;
  invoice: Invoice;
  open: boolean,
  onClose: () => void,
  onOpen: (open: boolean) => void,
  onSubmit: (content: RemindInvoiceCustomerMailBody) => void,
}

const ModalRemindInvoiceCustomer: FunctionComponent<ModalRemindInvoiceCustomerProps> = ({
  className = "",
  open,
  onClose,
  onOpen,
  invoice,
  onSubmit: onSubmitProps,
}) => {

  const intl = useIntl();
  const reminderPdf= false;
  const invoiceId = invoice.id;
  const locale = invoice.geographicalZone === FRENCH_CUSTOMER  ? "fr" : "en";


  const onSubmit = (values: RemindInvoiceCustomerMailBody) => {
    onSubmitProps(values);
  };

  const template = useMemo(() => {
    const intlProvider: IntlProvider = new IntlProvider({ locale, messages: Messages[locale] }, {});
    const { intl } = intlProvider.state;

    const template = `
      <p>${intl.formatMessage({id: "invoice_mail_remind_customer_greetings"})}</p>
      <p>${intl.formatMessage({id: "invoice_mail_remind_customer_body_1"}, {invoiceNumber: invoice.invoiceNumber, invoiceDate: dateUtils.formatDateDayJs(invoice.date), dueDate: dateUtils.formatDateDayJs(invoice.limitPaymentDate), amountRemaining: moneyUtils.formatNumberToCurrency(invoice.totalAmountRemaining)})}</p>
      <p>${intl.formatMessage({id: "invoice_mail_remind_customer_body_2"})}</p>
      <p>${intl.formatMessage({id: "invoice_mail_remind_customer_body_3"})}</p>
      <p>${intl.formatMessage({id: "invoice_mail_remind_customer_body_4"})}</p>
      <p>${intl.formatMessage({id: "invoice_mail_remind_customer_body_5"})}</p>
      <p>${intl.formatMessage({id: "invoice_mail_remind_customer_bye"})}</p>`;
    return template.replaceAll("\n", "");
  }, [invoice, locale]);

  return (
    <Formik
      initialValues={{template, reminderPdf, invoiceId}}
      onSubmit={onSubmit}>
      {({values, errors, touched, setFieldValue}) => (
        <GenericModal
          className={className}
          title={intl.formatMessage({
            id: "invoice_modal_remind_customer",
          })}
          open={open}
          onOpen={onOpen}
          onClosed={() => onClose()}
          closable
          footer={{
            primaryAction: {
              buttonLabel: intl.formatMessage({id: "validate_button"}),
              action: () => onSubmit(values)
            },
            secondaryAction: {
              buttonLabel: intl.formatMessage({id: "cancel_button"}),
              action: () => onClose()
            },
          }}
        >
          <EpowForm>
            <div className="d-flex mb-2">
              <RichTextInput
              id="content"
              label="home_comment_content"
              value={values.template}
              onChange={(e) => setFieldValue("template", e.target.value)}
              required
              error={errors.template}
              touched={touched.template}
              />
            </div>
            <CheckboxFilter
              className="mt-2"
              title={intl.formatMessage({id: "billing_include_reminder_pdf_in_mail"})}
              value={values.reminderPdf}
              onChange={(checked) => setFieldValue("reminderPdf", checked)}
            />
          </EpowForm>
        </GenericModal>
      )}
    </Formik>
  );
};

export default ModalRemindInvoiceCustomer;
