import React, {FunctionComponent, useState} from "react";
import {VoucherStateMap, VoucherStatesEnum} from "../../../constants/voucher/VoucherStates";
import Badge from "../../atoms/Badge";
import Button from "../../atoms/Button";
import {ColorType, IconSizeType, SizeType} from "../../../types/bootstrap/BootstrapType";
import Icon from "../../icon/Icon";
import {bookingService} from "../../../services/BookingService";
import {fileUtils} from "../../../utils/fileUtils";
import {OrderItemResponse} from "../../../interfaces/OrderItemInterfaces";
import {IntlShape} from "react-intl";
import {FileType} from "../../../constants/FileConstants";

interface ActionVoucherCellProps {
  className?: string;
  intl: IntlShape;
  rowData: OrderItemResponse;
  onCheckButtonClick: (orderItemResponse: OrderItemResponse, fileId: string) => void;
  onRemindButtonClick: (orderItemResponse: OrderItemResponse) => void;
  onValidateButtonClick: () => void;
}

const ActionVoucherCell: FunctionComponent<ActionVoucherCellProps> = ({
    className,
    intl,
    rowData,
    onCheckButtonClick,
    onRemindButtonClick,
    onValidateButtonClick
  }) => {

  const voucherStatus = rowData.voucherStatus;
  const uploadedVouchers = rowData.bookingDocuments?.filter(document => document.fileType === FileType.VOUCHER);
  const [toggle, setToggle] = useState(false);

  const downloadDocument = (fileId: string, name: string, extension: string) => {
    const filePromise: Promise<Blob> = bookingService.getDocumentById(fileId);
    fileUtils.downloadFile(filePromise, {name, extension}, intl);
  }

  return (
    <div className={className}>
      <div className="d-flex align-items-center gap-3">
        {voucherStatus === VoucherStatesEnum.NOT_REQUIRED ?
          <></>
          :
          <Badge pill color={VoucherStateMap[voucherStatus]?.color}>
            {intl.formatMessage({id: VoucherStateMap[voucherStatus]?.label})}
          </Badge>
        }
        {voucherStatus === VoucherStatesEnum.MISSING &&
          <Button size={SizeType.XS} onClick={() => onRemindButtonClick(rowData)} color={ColorType.SECONDARY}>
            {intl.formatMessage({id: "staffing_renew"})}
          </Button>
        }
        {voucherStatus === VoucherStatesEnum.ADDED &&
          <div>
            <Button size={SizeType.XS} onClick={() => setToggle(!toggle)} color={ColorType.SECONDARY}>
              {intl.formatMessage({id: "staffing_check"})}
            </Button>
            {toggle &&  <Icon className="cursor-pointer ms-3" color="primary" name="ThumbsUp" size={IconSizeType.MD}
                              onClick={() => onValidateButtonClick()}/>}
          </div>
        }
        {voucherStatus === VoucherStatesEnum.VALIDATED &&
          <div>
            <Button size={SizeType.XS} onClick={() => setToggle(!toggle)} color={ColorType.SECONDARY}>
              {intl.formatMessage({id: "staffing_see"})}
            </Button>
          </div>
        }
      </div>
      {
        toggle &&
        <div className="mt-2">
          {uploadedVouchers?.map((document, index) => (
            <div key={index} className="flex-column">
              <span
                onClick={() => downloadDocument(document?.fileId, document?.name, document?.extension)}
                className="epow-text-link cursor-pointer me-2"
              >
                {document.name}.{document?.extension}
              </span>

              {voucherStatus === VoucherStatesEnum.ADDED &&
                <Icon className="cursor-pointer" color="danger" name="ThumbsDown" size={IconSizeType.XS}
                     onClick={() => onCheckButtonClick(rowData, document.fileId)}/>}
            </div>
          ))}
        </div>
      }
    </div>
  )
}

export default ActionVoucherCell;
