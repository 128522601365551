import {Purchase, PurchaseStateMap, PurchaseStatus} from "../../../../interfaces/PurchaseInterfaces";
import React, {FunctionComponent} from "react";
import {Col} from "reactstrap";
import {dateUtils} from "../../../../utils/dateUtils";
import {Link} from "react-router-dom";
import {PURCHASES_PATH, SUPPLIERS_PATH} from "../../../../constants/routes/RoutePaths";
import Icon from "../../../icon/Icon";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import TextLink from "../../../atoms/TextLink";
import Badge from "../../../atoms/Badge";
import {useIntl} from "react-intl";

interface PurchaseTableRowProps {
  className?: string,
  purchase: Purchase,
  onRowClick?: (purchaseId: string) => void
}

const PurchaseTableRow: FunctionComponent<PurchaseTableRowProps> = ({
  className = "",
  purchase,
  onRowClick
}) => {
  const intl = useIntl()
  return (
    <div onClick={() => onRowClick(purchase?.id)} className={`epow-custom-table-row-main ${className ?? ""}`}>

      <Col>
        <b>{purchase?.purchaseNumber}</b>
      </Col>

      <Col>
        {dateUtils.formatDateDayJs(purchase?.purchaseDate)}
      </Col>

      <Col>
        <TextLink to={`${SUPPLIERS_PATH}/${purchase?.supplier?.id}`}>
          <>{purchase?.supplier?.name}</>
        </TextLink>
      </Col>

      <Col>
        {purchase?.numberBookingValidated} / {purchase?.bookings?.length}
      </Col>

      <Col>
        {purchase?.numberOrderDocument}
        <Icon name="PaperClip" size={IconSizeType.XS} className={"ps-2 pe-2"}/>
      </Col>

      <Col>
        <Badge pill color={PurchaseStateMap[purchase?.status]?.color} >
          {intl.formatMessage({id: PurchaseStateMap[purchase?.status]?.label ?? PurchaseStatus.OPEN})}
        </Badge>
      </Col>

      <Col onClick={(event) => event.stopPropagation()}>
        <div className="float-end">
          <Link className="table-action-link" to={`${PURCHASES_PATH}/${purchase?.id}`}><Icon name="Search" size={IconSizeType.XS} className={"pe-2"} /></Link>
        </div>
      </Col>
    </div>
  )
}

export default PurchaseTableRow
