import {SearchFilter} from "./api/FiltersInterface";
import {SupplierDetails} from "./SupplierInterfaces";
import {Comment} from "./CommentInterface";
import {StateMap} from "../constants/workflow/StateConstants";
import {ColorType} from "../types/bootstrap/BootstrapType";
import { DocumentDTO } from "./FileInterfaces";

export interface Purchase {
  id?: string,
  supplier?: SupplierDetails,
  purchaseNumber?: string,
  purchaseDate?: string,
  comment?: Comment,
  status?: string,
  bookings?: PurchaseBooking[],
  numberBookingValidated?: number,
  numberOrderDocument?: number,
  canDelete: boolean
  purchaseDocuments?: DocumentDTO[],
}

export interface SupplierPurchaseDto {
  id?: string,
  purchaseNumber?: string,
  purchaseDate?: string,
  status?: string,
  bookings?: number,
  bookingList?: SupplierPurchaseBooking[],
  numberBookingValidated?: number,
  numberOrderDocument?: number,
}

export interface PurchaseRequest {
  supplierId?: string
}

export interface PurchasesFromBookingsRequest {
  bookings: BookingFields[]
}

export interface PurchaseBooking {
  id?: string,
  serviceId?: string,
  serviceName?: string,
  reference?: string,
  customerName?: string,
  referenceCustomer?: string,
  quantity?: number,
  date?: string,
  hour?: string,
  price?: number,
  status?: string,
  isBilled?: boolean,
  isPaid?: boolean,
  confirmationBookingDate?: string,
  paymentAlertDate?: string,
  invoiceDate?: string,
  totalExcludingVAT?: number,
  vat?: number,
  totalPrice?: number,
  orderNumber?: string,
  orderId?: string,
  orderItemId?: string,
  orderItemName?: string,
  pax?: number,
  disbursement?: boolean,
  comment?: Comment,
  documentCount?: number,
  sendUpdateEmail?: boolean,
  updateEmailContent?: string,
  confirmBookingUpdate?: boolean,
}

export interface SupplierPurchaseBooking {
  id?: string,
  serviceId?: string,
  serviceName?: string,
  quantity?: number,
  date?: string,
  hour?: string,
  price?: number,
}

export interface PurchaseBookingRequest {
  purchaseId?: string,
  serviceId?: string,
  orderId?: string,
  orderItemId?: string,
  quantity?: number,
  price?: number,
  sendUpdateEmail?: boolean,
  updateEmailContent?: string,
  confirmBookingUpdate?: boolean,
}

export interface BookingFields {
  isSelected?: boolean
  bookingId?: string
  supplierId?: string,
  orderItemId?: string,
  quantity?: number,
  price?: number,
  comment?: string
}

export interface PurchaseSearchObject extends SearchFilter {
  customerOrPurchase?: string,
  supplierId?: string,
  customerId?: string,
  startDate?: string,
  endDate?: string,
  status?: string[],
  isDisbursed?: boolean,
}

export const enum PurchaseStatus {
  OPEN = "OPEN",
  SENT = "SENT",
  CLOSED = "CLOSED",
}

export const PurchaseStateMap: StateMap = {
  OPEN: {
    id: PurchaseStatus.OPEN,
    label: "workflow_purchase_open",
    color: ColorType.GRAY,
  },
  SENT: {
    id: PurchaseStatus.SENT,
    label: "workflow_purchase_sent",
    color: ColorType.SECONDARY,
  },
  CLOSED: {
    id: PurchaseStatus.CLOSED,
    label: "workflow_purchase_closed",
    color: ColorType.PRIMARY,
  }
}
