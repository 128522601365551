import {capitalize, Dictionary, groupBy} from "lodash";
import React, {FunctionComponent, useContext, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {OrderItem, OrderItemState} from "../../../../interfaces/OrderItemInterfaces";
import {customerService} from "../../../../services/CustomerService";
import {dateUtils} from "../../../../utils/dateUtils";
import OrderItemPreviewCard from "../orderItem/OrderItemPreviewCard";
import {ActiveCustomerContext} from "../../../../context/ActiveCustomerContext";
import {ResourceDisplayOption} from "../../../../constants/ResourceDisplayOptionConstant";
import {DateFormats} from "../../../../constants/DateConstants";

interface DashboardNextCustomerOrdersProps {
  className?: string,
  resourceDisplayOption? : ResourceDisplayOption,
  calendarDate?: Date,
}

const DashboardNextCustomerOrders: FunctionComponent<DashboardNextCustomerOrdersProps> = ({
  className = "",
  resourceDisplayOption,
  calendarDate= dateUtils.today(),
}) => {

  const intl = useIntl()
  const [orderItems, setOrderItems] = useState<OrderItem[]>([])
  const activeCustomer = useContext(ActiveCustomerContext);

  const loadOrderItems = () => {
    setOrderItems([]); // Avoid displaying resource details if the display permissions are different for the active customer.
    const filter =  {
      customerId: activeCustomer?.id,
      status: [OrderItemState.PENDING, OrderItemState.VALIDATED],
      date: calendarDate?.toLocaleDateString(),
    }
    customerService.getSelfCustomersOrderItemPage({pageSize: 100}, filter)
      .then((purchases) => setOrderItems(purchases.content))
      .catch(() => intl.formatMessage({id: "error_toast_order_item_search"}))
  }

  useEffect(() => {
    loadOrderItems()
  }, [activeCustomer, calendarDate])

  const getOrderItemsByDate = () => {
    const orderItemsByDate: Dictionary<OrderItem[]> = groupBy(orderItems, "rendezVousDate")
    const sortedOrderItemsByDate = Object.entries(orderItemsByDate) ?? [];
    sortedOrderItemsByDate.sort((a, b) => dateUtils.parseDate(a[0]) < dateUtils.parseDate(b[0]) ? -1 : 1)
    return sortedOrderItemsByDate;
  }

  const buildDate = (date: string) => {
    const localized = dateUtils.formatDateDayJs(date, DateFormats.longDate)
    return capitalize(localized)
  }

  return (
    <div className={className}>
      <h3 className="fs-16 mb-2"><FormattedMessage id="dashboard_next_activities" /></h3>

      {getOrderItemsByDate().map(([date, orderItems]) => (
        <>
          <div className="d-flex align-items-center mb-2">
            {buildDate(date)}
          </div>
          <div className="d-flex flex-column mb-2">
            {orderItems.map(orderItem => (
              <OrderItemPreviewCard isCustomer={true} resourceDisplayOption={resourceDisplayOption} className="mb-1" key={orderItem.id} orderItem={orderItem} onOrderItemChange={() => loadOrderItems()} />
            ))}
          </div>
        </>
      ))}
    </div>
  )
}

export default DashboardNextCustomerOrders;
