import React, {FunctionComponent} from "react";
import Icon from "../../icon/Icon";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {FormattedMessage} from "react-intl";
import DashboardPriorityItem from "./DashboardPriorityItem";
import BackgroundIcon from "../../icon/BackgroundIcon";
import Card from "../../atoms/Card";
import {NotificationType} from "../../../constants/NotificationConstants";

export interface Priority {
  id: string,
  title: string,
  url?: string,
  content: JSX.Element,
  type: string,
  removeOnResolve?: boolean
}

interface DashboardPriorityProps {
  className: string;
  priorities: Priority[]
  onRemoveWarningPriority?: (priority: Priority) => void
  onRemoveDangerPriority?: (priority: Priority) => void

}

const DashboardPriority: FunctionComponent<DashboardPriorityProps> = ({
  className= "",
  priorities = [],
  onRemoveWarningPriority,
  onRemoveDangerPriority
}) => {

  const groupedPriorities = priorities.reduce((acc, priority) => {
    if (!acc[priority.title]) {
      acc[priority.title] = [];
    }
    acc[priority.title].push(priority);
    return acc;
  }, {} as Record<string, Priority[]>);

  const [selectedPriorityType, setSelectedPriorityType] = React.useState<string | null>(null)

  const toggleProperties = (key: string) => {
     setSelectedPriorityType(selectedPriorityType === key ? null : key)
  }

  const renderPriorityItems = (priorityType: NotificationType, onRemovePriority: (priority: Priority) => void) => {
    return priorities
      .filter(priority => priority.type === priorityType)
      .filter(priority => priority.title === selectedPriorityType)
      .map(priority => (
        <DashboardPriorityItem
          key={priority.id}
          priority={priority}
          onRemovePriority={onRemovePriority}
          type={priorityType}
        />)
      );
  };

  return (
    <div className={`epow-dashboard-priority ${className}`}>
      <h3 className="fs-16 fw-bold mb-3 flex-row d-flex align-items-center">
        <div className="rounded-circle border border-danger d-flex align-items-center justify-content-center me-1"
             style={{width: 15, height: 15}}>
          <Icon name="Exclamation" size={IconSizeType.XXS} color="danger"/>
        </div>
        <span><FormattedMessage id="dashboard_priority_title"/></span>
      </h3>

      <div className="epow-dashboard-next-tasks">
        <div className="d-flex flex-row flex-wrap next-task-wrapper">
          {Object.keys(groupedPriorities).map(key => (
            <Card key={key} className="next-task me-3 mt-2 mb-3 cursor-pointer color-border-danger" onClick={() => toggleProperties(key)}>
              <BackgroundIcon
                className="d-flex align-item-center rounded-circle bg-theme-light border-danger border mb-2 mt-1 me-2"
                iconName={selectedPriorityType === key ? "ChevronDown" : "ChevronRight"}
                size={42}
                color="danger"
                iconSize={IconSizeType.SM}/>
              <h4 className="fw-bold mt-2 me-2">{groupedPriorities[key].length}</h4>
              <span className="me-2"><FormattedMessage id={key}/></span>
            </Card>
          ))}
        </div>
      </div>

      {renderPriorityItems("danger", onRemoveDangerPriority)}
      {renderPriorityItems("warning", onRemoveWarningPriority)}
    </div>)
};

export default DashboardPriority;
