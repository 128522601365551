import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import GenericModal from "./GenericModal";
import {Formik} from "formik";
import {supplierContactService} from "../../../services/SupplierContactService";
import FormAutocompleteSelect from "../input/FormAutocompleteSelect";
import {ContactOption} from "../../../interfaces/ContactInterfaces";
import RichTextInput from "../input/RichTextInput";
import EpowForm from "../form/EpowForm";
import {Purchase, PurchaseStatus} from "../../../interfaces/PurchaseInterfaces";
import {dateUtils} from "../../../utils/dateUtils";
import {purchaseService} from "../../../services/PurchaseService";
import {toastUtils} from "../../../utils/toastUtils";
import {Input} from "reactstrap";

interface SupplierOption {
  id: string,
  email: string,
  name: string,
}

interface ModalSendPurchaseProps {
  className?: string;
  supplierOption: SupplierOption,
  purchase: Purchase,
  open: boolean,
  onSubmit: (purchase: Purchase) => void,
  onClose: () => void,
  onOpen: (open: boolean) => void,
}

const ModalSendPurchase: FunctionComponent<ModalSendPurchaseProps> = ({
  className = "",
  supplierOption,
  open,
  onClose,
  onOpen,
  onSubmit: onSubmitProps,
  purchase
}) => {
  const intl = useIntl();
  const [isSendMail, setIsSendMail] = useState<boolean>(true);
  const onSubmit = (values: { id: string, template: string }) => {
    if (purchase?.id) {
      purchaseService.updatePurchaseStatus(purchase.id,
        {status: PurchaseStatus.SENT,
        contactId: supplierOption.id !== values.id ? values.id : null,
        content: values.template}, isSendMail)
        .then((res) => {
          onSubmitProps(res)
          toastUtils.successToast(intl.formatMessage({id: "success_toast_update_purchase"}))
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_purchase"}))
        })
    }
  };

  const purchaseBookings =  structuredClone(purchase.bookings);
  purchaseBookings.sort((a, b) =>  +new Date(a.date) - +new Date(b.date));
  const template = `
    <p>Madame, Monsieur</p>
    <p>A l’attention du service: ${supplierOption.name}</p>
    <p>Veuillez trouver ci-joint la demande des réservations du 
      ${dateUtils.formatDateDayJs(purchaseBookings.at(0)?.date)} au 
      ${dateUtils.formatDateDayJs(purchaseBookings.at(-1)?.date)}</p>
    <p>Dans l’attente de vos retour</p>
    <p>Cordialement</p>
    <div>Paris On the Way</div>
    <div>13 rue Beudant</div>
    <div>75017 Paris</div>
    <div>Direct Line : +33 1 77 18 39 44</div>
    <div>Mobile phone : +33 6 68 14 46 20</div>
    <div>mail : contact@parisontheway.fr</div>
    <a>https://parisontheway.fr</a>`;

  return (

    <Formik initialValues={{
      id: purchase.supplier?.id,
      template,
    }} onSubmit={onSubmit}>
      {({values, errors, touched, setFieldValue}) => (
        <GenericModal
          className={className}
          title={intl.formatMessage({
            id: "modal_send_purchase_title",
          })}
          open={open}
          onOpen={onOpen}
          onClosed={() => onClose()}
          closable
          footer={{
            primaryAction: {
              buttonLabel: intl.formatMessage({id: "validate_button"}),
              action: () => onSubmit(values)
            },
            secondaryAction: {
              buttonLabel: intl.formatMessage({id: "cancel_button"}),
              action: () => onClose(),
            },
          }}
        >
          <EpowForm>
            <div className="d-flex mb-2">
              <Input
                id="isSendMail"
                className="cursor-pointer me-2"
                type="checkbox"
                checked={isSendMail}
                onChange={() => setIsSendMail(!isSendMail)}
              />
              <FormattedMessage id="purchase_send_mail"/>
            </div>
            {isSendMail &&
              <>
                <FormAutocompleteSelect
                  title={intl.formatMessage({id: "modal_send_purchase_contact"})}
                  error={errors?.id}
                  value={values?.id}
                  touched={touched?.id}
                  required
                  onChange={(value) => setFieldValue("email", value)}
                  fetchData={() => supplierContactService.getAllContactOptionsBySupplierId(supplierOption.id)}
                  manageOptions={(response: ContactOption[]) => response.map(option => ({
                    value: option.id,
                    label: `${option.name} - ${option.email}`
                  }))}
                  optionFromValue={(response: ContactOption[], value: string) => {
                    const option = response?.find((option) => option.id === value);
                    return option ? {
                      label: `${option.name} - ${option.email}`,
                      value: option?.id
                    } : null;
                  }}
                  filterFieldName="email"
                />

                <RichTextInput
                  id="content"
                  label="home_comment_content"
                  value={values.template}
                  onChange={(e) => setFieldValue("template", e.target.value)}
                  required
                  error={errors.template}
                  touched={touched.template}
                />
              </>
            }
          </EpowForm>
        </GenericModal>
      )}
    </Formik>
  );
};

export default ModalSendPurchase;
