import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {Col, Row} from "reactstrap";
import {dateUtils} from "../../../utils/dateUtils";
import {moneyUtils} from "../../../utils/moneyUtils";
import {InvoicePaymentItem} from "../../../interfaces/InvoicePaymentInterfaces";

interface InvoicePaymentOrderDetailsProps {
  className?: string,
  invoicePaymentItem: InvoicePaymentItem,
}

const InvoicePaymentDetails: FunctionComponent<InvoicePaymentOrderDetailsProps> = ({
  className = "",
  invoicePaymentItem,
}) => {
  return (
    <div className={`${className} p-2`}>
      <div className="d-flex flex-row align-items-center mb-2">
        <h6><FormattedMessage id="invoice_payment_invoice" />: {invoicePaymentItem.refCustomer}</h6>
      </div>
      <div className="card p-3 mb-3">
        <Row className="fw-bold">
          <Col><FormattedMessage id="payment_table_activity_date" /></Col>
          <Col xs={8}><FormattedMessage id="invoice_billing_item_header_service" /></Col>
          <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_title" /></Col>
          <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_unit_price" /></Col>
          <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_price" /></Col>
          <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_priceTTC" /></Col>
          <Col className="text-end"><FormattedMessage id="invoice_payment_paid" /></Col>
          <Col xs={3} className="text-end"><FormattedMessage id="invoice_payment_remaining" /></Col>
        </Row>
        {invoicePaymentItem.paymentItems.map((paymentItem) => (
          <>
            <Row className="pt-2"
              key={paymentItem.id}>
              <Col>{dateUtils.formatDateDayJs(paymentItem.date)}</Col>
              <Col xs={8}>{paymentItem.name}</Col>
              <Col className="text-end">{paymentItem.quantity}</Col>
              <Col className="text-end">{moneyUtils.formatNumberToCurrency(paymentItem.unitPriceHT)}</Col>
              <Col className="text-end">{moneyUtils.formatNumberToCurrency(paymentItem.amountHT)}</Col>
              <Col className="text-end">{moneyUtils.formatNumberToCurrency(paymentItem.amountTTC)}</Col>
              <Col className={`${paymentItem.amountRemaining == 0 ? "color-primary" : "color-warning"} text-end`}>{moneyUtils.formatNumberToCurrency(paymentItem.amountPaid)}</Col>
              <Col xs={3} className={`${paymentItem.amountRemaining == 0 ? "color-primary" : "color-warning"} text-end`}>{moneyUtils.formatNumberToCurrency(paymentItem.amountRemaining)}</Col>
            </Row>
          </>
        ))}
      </div>
    </div>
  )
}

export default InvoicePaymentDetails;
