import {httpService} from "./HttpService";
import {API_PURCHASES_PATH} from "../constants/routes/RoutePaths";
import {
  Purchase,
  PurchaseRequest,
  PurchaseSearchObject,
  PurchasesFromBookingsRequest
} from "../interfaces/PurchaseInterfaces";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {SearchFilter} from "../interfaces/api/FiltersInterface";
import {queryUtils} from "../utils/queryUtils";
import {Comment, CommentRequest} from "../interfaces/CommentInterface";
import {ContentTypeEnum} from "../constants/fileType";
import {DocumentDTO} from "interfaces/FileInterfaces";
import {fileUtils} from "utils/fileUtils";

function getPurchases (params: PaginationQueryParams, filterParams: SearchFilter): Promise<PageResponse<Purchase>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get<PageResponse<Purchase>>(`${API_PURCHASES_PATH}${queryParams}`)
}

function getPurchaseById(id: string): Promise<Purchase> {
  return httpService.get<Purchase>(`${API_PURCHASES_PATH}/${id}`)
}

function createPurchase(purchase: PurchaseRequest): Promise<Purchase> {
  return httpService.post<Purchase>(API_PURCHASES_PATH, purchase)
}

function createPurchasesFromBookings(bookings: PurchasesFromBookingsRequest): Promise<void> {
  return httpService.post<void>(`${API_PURCHASES_PATH}/mass`, bookings)
}

const createPurchaseComment = (purchaseId: string, commentRequest: CommentRequest): Promise<Comment> => {
  return httpService.post<Comment>(`${API_PURCHASES_PATH}/${purchaseId}/comments`, commentRequest);
}

function deleteCommentForPurchase (id: string): Promise<Response> {
  return httpService.delete(`${API_PURCHASES_PATH}/${id}/comments`)
}

function deletePurchase(id: string, message: string): Promise<Response> {
  return httpService.delete(`${API_PURCHASES_PATH}/${id}`, {message})
}

function updatePurchaseStatus(id: string, {status, contactId, content}, isSendMail: boolean): Promise<Purchase> {
  return httpService.patch<Purchase>(`${API_PURCHASES_PATH}/${id}/status`, {status, contactId, content, isSendMail})
}

function download (id: string): Promise<Blob> {
  return httpService.getReadableStream(`${API_PURCHASES_PATH}/${id}/bookings`, ContentTypeEnum.EXCEL)
}

function addFiles(id: string, files: FileList): Promise<DocumentDTO> {
  const formData = fileUtils.createFormDataWithFiles(files);
  return httpService.postFormData(`${API_PURCHASES_PATH}/${id}/files`, formData);
}

function deleteDocumentById(fileId: string): Promise<Response> {
  return httpService.delete(`${API_PURCHASES_PATH}/document/${fileId}`)
}

const getPurchasedServicesCsv = (params: PurchaseSearchObject): Promise<Blob> => {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(null, params)
  return httpService.getReadableStream(`${API_PURCHASES_PATH}/export/services${queryParams}`)
}

export const purchaseService = {
  getPurchases,
  download,
  getPurchaseById,
  deletePurchase,
  createPurchase,
  createPurchasesFromBookings,
  createPurchaseComment,
  deleteCommentForPurchase,
  updatePurchaseStatus,
  addFiles,
  deleteDocumentById,
  getPurchasedServicesCsv
}
