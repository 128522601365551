import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import FormInput from "../input/FormInput";
import EpowForm from "./EpowForm";
import {productTypeService} from "../../../services/ProductTypeService";
import {toastUtils} from "../../../utils/toastUtils";
import {useIntl} from "react-intl";
import {PRODUCT_TYPE_INITIAL_VALUES, ProductType} from "../../../interfaces/ProductTypeInterfaces";
import {CreateProductTypeSchema} from "../../../constants/validation/PorductTypeValidationSchema";

interface CreateProductTypeFormProps {
  className?: string;
  id?: string;
  initialProductType?: ProductType;
  onCreateProductType?: () => void;
}

const CreateProductTypeForm: FunctionComponent<CreateProductTypeFormProps> = ({
                                                                  className = "",
                                                                  id,
                                                                  initialProductType,
                                                                  onCreateProductType,
                                                                }) => {

  const intl = useIntl();

  const handleEditProductType = (ProductType: ProductType) => {
    productTypeService.updateProductType(ProductType)
      .then(() => toastUtils.successToast(intl.formatMessage({id: "product_type_toast_edit_success"})))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "product_type_toast_edit_error"})))
      .finally(() => onCreateProductType());
  }

  const handleCreateProductType = (ProductType: ProductType) => {
    productTypeService.createProductType(ProductType)
      .then(() => toastUtils.successToast(intl.formatMessage({id: "product_type_toast_create_success"})))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "product_type_toast_create_error"})))
      .finally(() => onCreateProductType());
  }

  return (
    <Formik
      initialValues={initialProductType || PRODUCT_TYPE_INITIAL_VALUES}
      validationSchema={CreateProductTypeSchema}
      onSubmit={(values) => initialProductType ? handleEditProductType(values) : handleCreateProductType(values)}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormInput
            id="name"
            label="product_type_name"
            type="text"
            value={values?.name}
            onChange={(e) => setFieldValue("name", e.target.value)}
            required
            error={errors.name}
            touched={touched.name}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateProductTypeForm;
