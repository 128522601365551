import React, {FunctionComponent} from "react";
import Card from "../../atoms/Card";
import {dateUtils} from "../../../utils/dateUtils";
import {orderUtils} from "../../../utils/orderUtils";
import {DashboardActivity} from "../../../interfaces/ActivityInterfaces";
import Icon from "../../icon/Icon";
import {ColorType, IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {DateFormats} from "../../../constants/DateConstants";

interface ActivityProps {
  activity: DashboardActivity,
  className?: string,
  onClickActivity?: (activity: DashboardActivity) => void
}

const Activity: FunctionComponent<ActivityProps> = ({activity, onClickActivity = () => null, className= ""}) => (
  <Card
    key={`${activity.activityName}-${activity.rendezVousDate}-${activity.rendezVousTime}`}
    className={`next-task me-3 mt-2 mb-3 cursor-pointer ${className}`}
    onClick={() => onClickActivity(activity)}>
    <span className="d-block fs-20 mb-1">
      <span className="text-capitalize fw-bold">{dateUtils.formatDateDayJs(activity.rendezVousDate, DateFormats.longDateWithoutYear)} - {dateUtils.formatLocalTime(activity.rendezVousTime)} </span>
      <span>:</span>
      <span> {activity.rendezVousPlace}</span>
    </span>
    <span className="d-block fs-12">
      <span>{activity.activityName} - </span>
      <span>{dateUtils.formatDuration(activity?.duration)} </span>
      <Icon name="Clock" color={ColorType.DARK_GRAY} size={IconSizeType.XXS}/>
      <span> - </span>
      <span>{orderUtils.getOrderRefDisplay(activity.orderRef, activity.clientRef, activity.clientName)}</span>
    </span>
  </Card>)


export default Activity;
