import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Input, Row} from "reactstrap";
import {
  ActivityBillingCommentRequest,
  ActivityBillingPageData,
  ActivityBillingStatus,
  ActivityBillingRefusedMail,
} from "../../../../interfaces/ActivityBllingInterface";
import {fileService} from "../../../../services/FileService";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import {dateUtils} from "../../../../utils/dateUtils";
import {fileUtils} from "../../../../utils/fileUtils";
import {toastUtils} from "../../../../utils/toastUtils";
import Icon from "../../../icon/Icon";
import {activityBillingService} from "../../../../services/ActivityBillingService";
import PanelActivityBillingComment from "../../panel/PanelActivityBillingComment";
import ModalActivityBillingRefused from "../../modal/ModalActivityBillingRefused";
import GenericModal from "../../modal/GenericModal";
import {moneyUtils} from "../../../../utils/moneyUtils";
import {useNavigate} from "react-router-dom";
import {PAYMENT_PATH} from "../../../../constants/routes/RoutePaths";
import {profileUtils} from "../../../../utils/profileUtils";

interface ActivityBillingOverviewTableRowProps {
  activityBilling: ActivityBillingPageData,
  onRowClick: (orderId: string) => void,
  selectLine: (billId: string) => void,
  refresh: () => void,
  open: boolean,
  selected: boolean
  className?: string,
  disableSelect: boolean
  status: ActivityBillingStatus
}

const ActivityBillingOverviewTableRow: FunctionComponent<ActivityBillingOverviewTableRowProps> = ({
  activityBilling,
  onRowClick,
  selectLine,
  refresh,
  open,
  className = "",
  selected,
  disableSelect,
  status
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [fileName, setFileName] = useState<string>()
  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false)
  const [refuseModalOpen, setRefuseModalOpen] = useState<boolean>(false)
  const [acceptModalOpen, setAcceptModalOpen] = useState<boolean>(false)

  const downloadBill = (fileId: string) => {
    const filePromise: Promise<Blob> = fileService.getDocumentById(fileId);
    fileService.getDocumentInfoById(fileId).then((response) => setFileName(response.name)).catch(() => toastUtils.errorToast(intl.formatMessage({id: "activity_bill_download_failure"})));

    fileUtils.downloadFile(filePromise, { name: fileName ?? "Facture", extension: "pdf" }, intl);
  }

  const acceptActivitiesBilling = () => {
    activityBillingService.acceptActivitiesBilling([activityBilling.id])
      .then(() => {
        refresh()
        toastUtils.successToast(intl.formatMessage({id: "activity_bill_accept_toast_success"}))
      })
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "activity_bill_accept_toast_error"})))
      .finally(() => setAcceptModalOpen(false)
      )
  }

  const navigateToPayment = (paymentNumber: string) => {
    navigate(`${PAYMENT_PATH}?paymentNumber=${paymentNumber}`)
  }

  const sendComment = (content: ActivityBillingCommentRequest) => {
    setCommentModalOpen(false);
    activityBillingService.createActivityBillingComment(activityBilling.id, content)
      .then(() => {
        refresh()
        toastUtils.successToast(intl.formatMessage({id: "activity_billing_comment_toast_success_send_comment"}))
      })
      .catch(() =>  toastUtils.errorToast(intl.formatMessage({id: "activity_billing_comment_toast_error_send_comment"})))
  }

  const getPeriod = () => {
    const start = dateUtils.formatDateDayJs(activityBilling.firstStaffingDate) ?? "";
    const end = dateUtils.formatDateDayJs(activityBilling.lastStaffingDate) ?? "";

    return `${start} - ${end}`
  }

  const getSubmittedDateTimer = (hours: number) => {
    return hours <= 24
      ? `${intl.formatMessage({id: "billing_overview_hour"}, {count: hours})}`
      : `${intl.formatMessage({id: "billing_overview_day"}, {count: Math.round(hours/24)})}`
  };

  const handleSendMail = (content: ActivityBillingRefusedMail) => {
      activityBillingService.refuseActivityBilling(activityBilling.id, content.template)
        .then(() => {
          toastUtils.successToast(intl.formatMessage({id: "activity_bill_refused_toast_success"}))
          setRefuseModalOpen(false)
          refresh()
        })
        .catch(() => toastUtils.errorToast(intl.formatMessage({id: "activity_bill_refused_toast_error"})));
  }


  return (
    <>
      <Row onClick={() => onRowClick(activityBilling.id)} className={`gx-0 py-3 ${className}`}>
        <Col className={disableSelect ? "d-none" : "p-2"} xs={1} onClick={(event) => { event.stopPropagation(); selectLine(activityBilling.id) }}>
          <Input
            className="me-2"
            type="checkbox"
            checked={selected}
            onChange={() => selectLine(activityBilling.id)}
          />
        </Col>
        <Col xs={2} className="ms-2 px-0">
          <Icon className="m-0 me-2" name={open ? "ChevronUp" : "ChevronDown"} size={IconSizeType.XS} />
          {`${activityBilling.resourceFirstName ?? ""} ${activityBilling.resourceLastName ?? ""}`}
        </Col>
        <Col xs={2} className="px-0 d-flex flex-column">
          <span>
            {dateUtils.formatDateDayJs(activityBilling.creationDate) ?? "-"}
          </span>
          <span className="epow-secondary">
            {activityBilling.creationDate ? getSubmittedDateTimer(dateUtils.getHoursBetweenTwoDates(dateUtils.parseDate(activityBilling.creationDate))) : "-"}
          </span>
        </Col>
        <Col xs={2} className="px-0">
          {dateUtils.formatDateDayJs(activityBilling.date) ?? "-"}
        </Col>
        <Col xs={3} className="px-0">
          {activityBilling.number ?? "-"}
        </Col>
        <Col xs={3} className="px-0">
          {getPeriod()}
        </Col>
        <Col xs={2} className="px-0">
          {moneyUtils.formatNumberToCurrency(activityBilling.billAmountHT)}
        </Col>
        <Col xs={2} className="px-0">
          {moneyUtils.formatNumberToCurrency(activityBilling.billAmount)}
        </Col>
        <Col xs={2} className={`px-0 ${activityBilling.billAmount === activityBilling.activitiesAmount ? "" : "epow-danger"}`}>
          {moneyUtils.formatNumberToCurrency(activityBilling.activitiesAmount)}
        </Col>
        <Col xs={1} className="px-0">
          {activityBilling.activityStaffing.length}
        </Col>
        <Col xs={2} className={disableSelect ? "px-5 ms-4" : "d-none"}>
          {dateUtils.formatDateDayJs(activityBilling.paymentDate) ?? "-"}
        </Col>
        <Col xs={2} onClick={(event) => event.stopPropagation()} className="px-0 ms-auto pe-2">
          {
            !profileUtils.isAccountant() && <Icon name={activityBilling.commented ? "Comment" : "CommentRegular"} size={IconSizeType.XS} onClick={() => setCommentModalOpen(true)} />
          }
          { status === ActivityBillingStatus.TO_CHECK &&
            <>
              <Icon color="primary" name="ThumbsUp" size={IconSizeType.XS} onClick={() => setAcceptModalOpen(true)}/>
              <Icon color="danger" name="ThumbsDown" size={IconSizeType.XS} onClick={() => setRefuseModalOpen(true)}/>
            </>
          }
          { !profileUtils.isAccountant() && status === ActivityBillingStatus.SETTLED &&
              <Icon name="EuroSign" size={IconSizeType.XS} onClick={() => navigateToPayment(activityBilling.paymentNumber)}/>
          }
          <Icon name="Download" size={IconSizeType.XS} onClick={() => downloadBill(activityBilling.fileId)} />
        </Col>
      </Row>

      <PanelActivityBillingComment
        open={commentModalOpen}
        onClose={() => setCommentModalOpen(false)}
        onSubmit={sendComment}
        className="flex-column"
        activityBillingId={commentModalOpen ? activityBilling.id : null}
        activityBillingNumber={commentModalOpen ? activityBilling.number : null}
      />

      <ModalActivityBillingRefused
        open={refuseModalOpen}
        onSubmit={(content) => handleSendMail(content)}
        activityBilling={activityBilling}
        onClose={() => setRefuseModalOpen(false)}
        onOpen={() => setRefuseModalOpen(true)}
      />

      <GenericModal
        open={acceptModalOpen}
        footer={{
          primaryAction: {
            buttonLabel: intl.formatMessage({id: "validate_button"}),
            action: acceptActivitiesBilling
          },
          secondaryAction: {
            buttonLabel: intl.formatMessage({id: "cancel_button"}),
            action: () => setAcceptModalOpen(false)
          }
        }}
        title={intl.formatMessage({id: "activity_billing_accept_modal_title"})}
      >
        <FormattedMessage id={"activity_billing_accept_modal_msg"} values={{number: activityBilling.number }}/>
      </GenericModal>
    </>
  )
}

export default ActivityBillingOverviewTableRow;
