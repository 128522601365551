import React, {FunctionComponent} from "react";
import {FileData} from "../../../../../interfaces/ResourceInterfaces";
import {ColorType} from "../../../../../types/bootstrap/BootstrapType";
import FilterGroup from "../../../filters/FilterGroup";
import {FormattedMessage, useIntl} from "react-intl";
import useSearchFilter from "../../../../../hooks/useSearchFilter";
import {fileUtils} from "../../../../../utils/fileUtils";
import Button from "../../../../atoms/Button";
import DateFilter from "../../../filters/DateFilter";
import {TRUE_FALSE_ENUM, YES_NO_OPTIONS} from "../../../../../constants/OptionConstants";
import SelectFilter from "../../../filters/SelectFilter";
import {purchaseService} from "../../../../../services/PurchaseService";
import {PurchaseSearchObject} from "../../../../../interfaces/PurchaseInterfaces";

interface AdminExportServicesCsvCardProps {
  className?: string,
}

const AdminExportServicesCsvCard: FunctionComponent<AdminExportServicesCsvCardProps> = ({
  className,
}) => {
  const intl = useIntl()
  const {searchObject, updateSearchField, onReset} = useSearchFilter<PurchaseSearchObject>({
    startDate: "",
    endDate: "",
    isDisbursed: null,
  })
  const [loading, setLoading] = React.useState<boolean>(false)

  const downloadExport = (searchObject: PurchaseSearchObject) => {
    setLoading(true)
    const fileData: FileData = {name: "export-purchased-services", extension: "csv"}
    fileUtils.downloadFile(
      purchaseService.getPurchasedServicesCsv(searchObject),
      fileData,
      intl,
      () => setLoading(false)
    )
  }

  return (
    <div className={`${className ?? ""}`}>
      <div className="fs-14"><FormattedMessage id="exports_purchased_services" /></div>
      <FilterGroup className="mb-4">
        <DateFilter value={searchObject.startDate} onChangeDate={(value) => updateSearchField("startDate", value)} title={intl.formatMessage({id: "filter_from"})} />
        <DateFilter value={searchObject.endDate} onChangeDate={(value) => updateSearchField("endDate", value)} title={intl.formatMessage({id: "filter_to"})} />

        <SelectFilter
          options={YES_NO_OPTIONS.map(o => ({label: intl.formatMessage({id: o.label}), value: o.value}))}
          onChange={(value) => updateSearchField("isDisbursed", value)}
          title={intl.formatMessage({id: "quote_item_disbursements"})}
          value={searchObject.isDisbursed === true ? TRUE_FALSE_ENUM.TRUE : ( searchObject.isDisbursed === false ? TRUE_FALSE_ENUM.FALSE : null)}
          isClearable
        />

        <div>
          <Button onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <Button onClick={() => downloadExport(searchObject)} color={ColorType.SECONDARY} >
        {loading && <span className="loader loader-button-group ms-1"/>}
        {intl.formatMessage({id: "exports_tab_services"})}
      </Button>
    </div>
  )
}

export default AdminExportServicesCsvCard;
