import {API_ACCOUNTING_CONFIG} from "../constants/routes/RoutePaths";
import {httpService} from "./HttpService"
import {AccountingConfig} from "../interfaces/AccountingConfig";

const getAccountingConfig = (): Promise<AccountingConfig> => {
  return httpService.get<AccountingConfig>(API_ACCOUNTING_CONFIG)
}

const updateAccountingConfig = (updatedConfiguration: AccountingConfig): Promise<AccountingConfig> => {
  return httpService.put<AccountingConfig>(`${API_ACCOUNTING_CONFIG}`, updatedConfiguration);
}

export const accountingConfigService = Object.freeze({
  getAccountingConfig,
  updateAccountingConfig,
})
